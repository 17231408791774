div#body {
  display: none;
  visibility: hidden;
}
div.it-header-slim-wrapper-content {
  justify-content: flex-end !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
div.it-header-socials-zone {
  display: block;
  color: white;
  padding-top: 16px;
  padding-right: 16px;
}
div.it-header-socials-zone ul {
  align-items: center;
  justify-content: center;
}
div.it-header-socials-zone ul li {
  display: inline-block;
  list-style-type: none;
  margin-left: 8px;
}

img#logo {
  width: 120px;
}
div.loader {
  text-align: center;
}
.card-header {
  border-bottom: 10px solid #5c6f82 !important;
}
.card-title {
  font-size: 25px;
}

/** Stile per le card mostrate in home page e che rappresentano i contenuti principali. **/
.card-root {
  border-bottom: 10px solid #0048ad !important;
}
.card-root h4 {
  overflow-wrap: normal;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  overflow-x: hidden;
  overflow-y: hidden;
}
/** Stile per le card simple block */
.card-content-type {
  border-bottom: 10px solid #5c6f82 !important;
}

.card-content-type p.sub {
  font-size: 16px;
  margin-top: -18px;
}

.smart-list a > p {
  font-size: 1em !important;
  text-align: justify;
}
.smart-list a > h5 {
  padding: 0px;
  margin: 0px;
  margin-bottom: -8px;
}
.smart-list-small {
  margin-top: 10px;
  margin-left: -24px;
  display: block;
  float: left;
}
.smart-list-small a > p {
  text-align: left;
}
.table-row-odd td {
  background-color: #e6f0fa;
}
.table-doc {
  text-decoration: none !important;
}
.table-doc > p {
  text-decoration: none;
}
.table-pagination {
  margin-left: 8px;
  margin-top: 8px;
  font-weight: bold;
}
.table-sort-icon {
  margin-top: -12px;
  margin-bottom: -8px;
}
.scrollable-horizontal {
  overflow-y: hidden;
  overflow-x: auto;
}

/** Ultimi contenuti pubblicati nel portale. **/
ul.list p {
  font-size: 18px !important;
}
ul.list li {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 10px solid #d3d3d3;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}
ul.list li:nth-child(odd) {
  background-color: #f2f7fc;
}
/** Contenuti di un nodo. */
ul.contents p {
  font-size: 18px !important;
}

ul.contents li {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 10px solid #d3d3d3;
}
ul.contents li:nth-child(odd) {
  background-color: #f2f7fc;
}

div.form-group.is-invalid > textarea {
  border: 1px solid #d9364f;
}
label.is-invalid {
  color: #d9364f;
}

/** Select **/
.react-select__control {
  border-radius: 0px !important;
  border-bottom: solid #435a70 1px !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  margin-bottom: 40px;
}
.is-invalid .react-select__control:focus,
.is-invalid .react-select__control:hover,
.is-invalid .react-select__control {
  border-bottom: solid #d9364f 1px !important;
}
.react-select__control:focus {
  box-shadow: none !important;
  border-bottom: solid #435a70 1.25px !important;
}
.react-select__control:hover {
  box-shadow: none !important;
  border-bottom: solid #435a70 1.25px !important;
}
.react-select__control--is-focused {
  box-shadow: none !important;
  border-style: none !important;
  border-bottom: solid #435a70 1.25px !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}
.react-select__indicator {
  fill: #435a70;
}
.react-select__placeholder {
  color: #435a70 !important;
}
.react-select__single-value {
  color: #435a70 !important;
  font-weight: bold;
}
.react-select__single-value:hover {
  color: black !important;
  font-weight: bold;
}
.notification {
  z-index: 100;
}
/** Navigazione laterale tra nodi **/
.collapse-body {
  padding-right: 0px !important;
  padding-top: 14px !important;
  padding-left: 14px !important;
  padding-bottom: 14px !important;
}
.collapsed {
  background-color: #f2f7fc;
}

.card-filter {
  margin-bottom: -40px !important;
}
.card-filter button {
  float: right;
  margin-left: 8px;
}

.card-search .bootstrap-select-wrapper {
  margin-top: 2rem !important;
  max-width: 300px;
}
.card-search .form-group {
  margin-bottom: 1rem;
}
.card-search fieldset legend {
  font-size: 1rem;
  margin-bottom: 0;
}
.card-search fieldset {
  margin-bottom: 1rem;
}
.table-search-form > div {
  margin-top: 24px;
}
.accordion-tree .collapse-header [data-toggle="collapse"] {
  padding-left: 8px;
}
.accordion-tree .collapse-header [data-toggle="collapse"] svg {
  margin-right: 8px;
}
.accordion-tree .collapse-header [data-toggle="collapse"]:before {
  content: none !important;
}

/** Risultati della ricerca */
/** Ultimi contenuti pubblicati nel portale. **/
ul.list-search div.p,
ul.list-search p {
  font-size: 16px !important;
  line-height: 22px;
  color: gray;
  text-align: justify;
}

ul.list-search li {
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 2px solid #d3d3d3;

  margin-bottom: 16px;
}
.list-search-wait {
  opacity: 0.5;
}

.list-search .card-body {
  padding: 0 !important;
}
.list-search .card-body table thead tr th,
.list-search .card-body table tbody tr td {
  padding: 4px;
  vertical-align: middle;
}
.list-search .card-body table thead tr th a {
  padding: 0;
}
.list-search .card-body table tbody tr td div.row {
  padding-left: 4px;
}
.list-search .card-body table tbody tr td div.row > div * {
  padding: 2px;
  margin: 2px;
}

.is-invalid .react-select {
  background-color: tomato;
}
.sharethis-section-title {
  margin-right: 16px;
}
.footer-fix {
  padding: 24px;
}
.footer-fix > div[tag="small"] {
  float: left;
}
.footer-fix > .sharethis {
  float: right;
  min-width: 200px;
}
.footer-fix > div[tag="small"] > a {
  margin-right: 8px;
}
ul.link-list > li > a > div > small > a,
ul.link-list > li > a > p > a {
  display: inline-block;
  padding-left: 0;
  padding-right: 8px;
}
.sharethis {
  text-align: right;
}
.sharethis h5 {
  margin-bottom: -10px;
}
label.autocomplete {
  z-index: 1;
  top: 10px;
}
.react-autocomplete__control {
  z-index: 0;
  margin-top: -13px;
  border-radius: 0px !important;
  border-bottom: solid #435a70 1px !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  background-color: yellow;
}
.react-autocomplete__control:focus {
  box-shadow: none !important;
  border-bottom: solid #435a70 1.25px !important;
}
.react-autocomplete__control--is-focused {
  box-shadow: none !important;
  border-style: none !important;
  border-bottom: solid #435a70 1.25px !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}
.react-autocomplete__indicator {
  fill: #435a70;
}
.react-autocomplete__placeholder {
  color: #435a70 !important;
}
.react-autocomplete__single-value {
  color: #435a70 !important;
  font-weight: bold;
}
.react-autocomplete__single-value:hover {
  color: black !important;
  font-weight: bold;
}

div.react-autocomplete__menu {
  z-index: 2;
}

.redirect-url {
  font-size: 14pt;
  width: 100%;
  text-align: right;
  display: block;
  clear: both;
}
.rs-1 {
  fill: none;
}
.rs-2 {
  clip-path: url(#clip-path-region-logo);
}
.rs-3 {
  fill: #fff;
}
.rs-4 {
  fill: #ffda00;
}
.rs-5 {
  fill: #ff4713;
}
.rs-6 {
  fill: #101820;
}
.rs-6,
.rs-7 {
  fill-rule: evenodd;
}
.rs-7 {
  fill: #fbe0d1;
}

.ri-1 {
  fill: none;
}
.ri-2 {
  clip-path: url(#clip-path-republic-logo);
}
.ri-3,
.ri-6 {
  fill: #fff;
}
.ri-4 {
  fill: #e0d9da;
}
.ri-5 {
  fill: #c5bcbc;
}
.ri-5,
.ri-6 {
  fill-rule: evenodd;
}
.ri-7 {
  fill: #ac2617;
}
.ri-8 {
  fill: #5c673d;
}
.ri-9 {
  fill: #778141;
}
.ri-10 {
  fill: #38402e;
}
.ri-11 {
  fill: #63755f;
}
.ri-12 {
  fill: #3f5023;
}
.ri-13 {
  fill: #937451;
}
.ri-14 {
  fill: #a58c78;
}
.ri-15 {
  fill: #75462d;
}
.ri-16 {
  fill: #b42818;
}
.ri-17 {
  fill: #932314;
}
.ri-18 {
  fill: #882012;
}
.ri-19 {
  fill: #9d2516;
}
.ri-20 {
  fill: #7f4e43;
}
.ri-21 {
  fill: #15200e;
}
.ri-22 {
  fill: #4e5d2f;
}
.ri-23 {
  fill: #958473;
}
.ri-24 {
  fill: #838f55;
}
.ri-25 {
  fill: #603f1e;
}
.ri-26 {
  fill: #422a0f;
}
.ri-27 {
  fill: #485442;
}
.ri-28 {
  fill: #63734b;
}
.ri-29 {
  fill: #7a8d6b;
}
.ri-30 {
  fill: #949062;
}
.ri-31 {
  fill: #6d6b23;
}
.ri-32 {
  fill: #6e775f;
}
.ri-33 {
  fill: #c1aa83;
}
.ri-34 {
  fill: #948069;
}
.ri-35 {
  fill: #a59176;
}
.ri-36 {
  fill: #b39e7b;
}
.ri-37 {
  fill: #768154;
}
.ri-38 {
  fill: #56704a;
}
.ri-39 {
  fill: #806853;
}
.eu-1 {
  fill: none;
}
.eu-2 {
  clip-path: url(#clip-path-europe-logo);
}
.eu-3 {
  fill: #fff;
}
.eu-4 {
  fill: #164194;
}
.eu-5 {
  fill: #fdeb18;
}
.pofesr-1 {
  fill: none;
}
.pofesr-2 {
  clip-path: url(#clip-path-pofesr-logo);
}
.pofesr-3 {
  fill: #fff;
}
.pofesr-4 {
  fill: #e11a17;
}
.pofesr-5 {
  fill: #fd1;
}
.pofesr-6 {
  fill: #00a553;
}
