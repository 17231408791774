@charset "UTF-8";
/* Variables override */
/** Palette **/
/* Variables override */
@keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle;
}
.bootstrap-select > .dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.bootstrap-select > .dropdown-toggle:after {
  margin-top: -1px;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.form-check
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder,
.form-check
  .bootstrap-select
  > [type="radio"]:focus
  + label.dropdown-toggle.bs-placeholder,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + .dropdown-toggle.bs-placeholder.lever,
.block-facet--checkbox
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus,
.form-check
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-primary,
.form-check
  .bootstrap-select
  > [type="radio"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-primary,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + .dropdown-toggle.bs-placeholder.btn-primary.lever,
.block-facet--checkbox
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-primary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus,
.form-check
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-secondary,
.form-check
  .bootstrap-select
  > [type="radio"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-secondary,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + .dropdown-toggle.bs-placeholder.btn-secondary.lever,
.block-facet--checkbox
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-secondary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus,
.form-check
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-success,
.form-check
  .bootstrap-select
  > [type="radio"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-success,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + .dropdown-toggle.bs-placeholder.btn-success.lever,
.block-facet--checkbox
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-success,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus,
.form-check
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-danger,
.form-check
  .bootstrap-select
  > [type="radio"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-danger,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + .dropdown-toggle.bs-placeholder.btn-danger.lever,
.block-facet--checkbox
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-danger,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus,
.form-check
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-info,
.form-check
  .bootstrap-select
  > [type="radio"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-info,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + .dropdown-toggle.bs-placeholder.btn-info.lever,
.block-facet--checkbox
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-info,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus,
.form-check
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-dark,
.form-check
  .bootstrap-select
  > [type="radio"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-dark,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + .dropdown-toggle.bs-placeholder.btn-dark.lever,
.block-facet--checkbox
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder.btn-dark,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
  color: rgba(255, 255, 255, 0.5);
}
.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
  z-index: 0 !important;
}
.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2 !important;
}
.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.bootstrap-select.is-invalid .dropdown-toggle,
.was-validated .bootstrap-select select:invalid + .dropdown-toggle {
  border-color: #b94a48;
}
.bootstrap-select.is-valid .dropdown-toggle,
.was-validated .bootstrap-select select:valid + .dropdown-toggle {
  border-color: #28a745;
}
.bootstrap-select.fit-width {
  width: auto !important;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + select.mobile-device.lever
  + .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .form-check [type="checkbox"]:focus + label.dropdown-toggle,
.form-check .bootstrap-select [type="checkbox"]:focus + label.dropdown-toggle,
.bootstrap-select .form-check [type="radio"]:focus + label.dropdown-toggle,
.form-check .bootstrap-select [type="radio"]:focus + label.dropdown-toggle,
.bootstrap-select
  .toggles
  label
  input[type="checkbox"]:focus
  + .dropdown-toggle.lever,
.toggles
  label
  .bootstrap-select
  input[type="checkbox"]:focus
  + .dropdown-toggle.lever,
.bootstrap-select
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.dropdown-toggle,
.block-facet--checkbox
  .bootstrap-select
  [type="checkbox"]:focus
  + label.dropdown-toggle {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
  height: auto;
}
:not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}
.bootstrap-select.form-control.input-group-btn {
  float: none;
  z-index: auto;
}

.form-inline .bootstrap-select,
.form-inline .bootstrap-select.form-control:not([class*="col-"]) {
  width: auto;
}

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}

.bootstrap-select.dropdown-menu-right,
.bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0;
}

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0;
}
.form-group-lg .bootstrap-select.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem;
}

.form-inline .bootstrap-select .form-control {
  width: 100%;
}

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed;
}
.bootstrap-select.disabled:focus,
.form-check [type="checkbox"]:focus + label.bootstrap-select.disabled,
.form-check [type="radio"]:focus + label.bootstrap-select.disabled,
.toggles label input[type="checkbox"]:focus + .bootstrap-select.disabled.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.bootstrap-select.disabled,
.bootstrap-select > .disabled:focus,
.form-check .bootstrap-select > [type="checkbox"]:focus + label.disabled,
.form-check .bootstrap-select > [type="radio"]:focus + label.disabled,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + .disabled.lever,
.block-facet--checkbox
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.disabled {
  outline: none !important;
}

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important;
}
.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060;
}

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  flex: 0 1 auto;
}
.bs3.bootstrap-select .dropdown-toggle .filter-option {
  padding-right: inherit;
}
.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  padding-top: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  float: none;
}
.input-group
  .bs3-has-addon.bootstrap-select
  .dropdown-toggle
  .filter-option
  .filter-option-inner {
  padding-right: inherit;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
}

.bootstrap-select .dropdown-toggle .filter-expand {
  width: 0 !important;
  float: left;
  opacity: 0 !important;
  overflow: hidden;
}

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit;
}

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bootstrap-select .dropdown-menu > .inner:focus,
.bootstrap-select
  .form-check
  .dropdown-menu
  > [type="checkbox"]:focus
  + label.inner,
.form-check
  .bootstrap-select
  .dropdown-menu
  > [type="checkbox"]:focus
  + label.inner,
.bootstrap-select
  .form-check
  .dropdown-menu
  > [type="radio"]:focus
  + label.inner,
.form-check
  .bootstrap-select
  .dropdown-menu
  > [type="radio"]:focus
  + label.inner,
.bootstrap-select
  .toggles
  label
  .dropdown-menu
  > input[type="checkbox"]:focus
  + .inner.lever,
.toggles
  label
  .bootstrap-select
  .dropdown-menu
  > input[type="checkbox"]:focus
  + .inner.lever,
.bootstrap-select
  .block-facet--checkbox
  .dropdown-menu
  > [type="checkbox"]:focus
  + label.inner,
.block-facet--checkbox
  .bootstrap-select
  .dropdown-menu
  > [type="checkbox"]:focus
  + label.inner {
  outline: none !important;
}
.bootstrap-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}
.bootstrap-select .dropdown-menu li {
  position: relative;
}
.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed;
}
.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  user-select: none;
}
.bootstrap-select .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}
.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none;
}
.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block;
}
.bootstrap-select .dropdown-menu li small {
  padding-left: 0.5em;
}
.bootstrap-select .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: whitesmoke;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bootstrap-select .dropdown-menu .notify.fadeOut {
  animation: 300ms linear 750ms forwards bs-notify-fadeOut;
}

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline;
}

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
  content: "\00a0";
}

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px;
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select .bs-ok-default:after {
  content: "";
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  transform-style: preserve-3d;
  transform: rotate(45deg);
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: "";
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}

.bootstrap-select.show-menu-arrow.dropup
  .dropdown-toggle
  .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right
  .dropdown-toggle
  .filter-option:before {
  right: 12px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right
  .dropdown-toggle
  .filter-option:after {
  right: 13px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bs-donebutton .btn-group button {
  width: 100%;
}

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/***************************** 1 ****************************************/
/***************************** 2 ****************************************/
/***************************** 1 ****************************************/
/***************************** 2 ****************************************/
/***************************** 1 ****************************************/
/***************************** 2 ****************************************/
/***************************** 3 ****************************************/
/***************************** 1 ****************************************/
/***************************** 2 ****************************************/
/***************************** 3 ****************************************/
/***************************** NEUTRAL 1 ****************************************/
/***************************** NEUTRAL 2 ****************************************/
/***************************** NEUTRAL 2 / 3 ****************************************/
.primary-bg {
  background-color: #0048ad;
}

.primary-color {
  color: #0048ad;
}

.primary-border-color {
  border-color: #0048ad !important;
}
.primary-border-color.border {
  border-color: #0048ad !important;
}

.white-bg {
  background-color: white;
}

.white-color {
  color: white;
}

.white-border-color- {
  border-color: white !important;
}
.white-border-color-.border {
  border-color: white !important;
}

.primary-bg-a1 {
  background-color: #bfdaff;
}

.primary-color-a1 {
  color: #bfdaff;
}

.primary-border-color-a1 {
  border-color: #bfdaff !important;
}
.primary-border-color-a1.border {
  border-color: #bfdaff !important;
}

.primary-bg-a2 {
  background-color: #93bcf5;
}

.primary-color-a2 {
  color: #93bcf5;
}

.primary-border-color-a2 {
  border-color: #93bcf5 !important;
}
.primary-border-color-a2.border {
  border-color: #93bcf5 !important;
}

.primary-bg-a3 {
  background-color: #6a9feb;
}

.primary-color-a3 {
  color: #6a9feb;
}

.primary-border-color-a3 {
  border-color: #6a9feb !important;
}
.primary-border-color-a3.border {
  border-color: #6a9feb !important;
}

.primary-bg-a4 {
  background-color: #4385e0;
}

.primary-color-a4 {
  color: #4385e0;
}

.primary-border-color-a4 {
  border-color: #4385e0 !important;
}
.primary-border-color-a4.border {
  border-color: #4385e0 !important;
}

.primary-bg-a5 {
  background-color: #206cd6;
}

.primary-color-a5 {
  color: #206cd6;
}

.primary-border-color-a5 {
  border-color: #206cd6 !important;
}
.primary-border-color-a5.border {
  border-color: #206cd6 !important;
}

.primary-bg-a6 {
  background-color: #0055cc;
}

.primary-color-a6 {
  color: #0055cc;
}

.primary-border-color-a6 {
  border-color: #0055cc !important;
}
.primary-border-color-a6.border {
  border-color: #0055cc !important;
}

.primary-bg-a7 {
  background-color: #004ab3;
}

.primary-color-a7 {
  color: #004ab3;
}

.primary-border-color-a7 {
  border-color: #004ab3 !important;
}
.primary-border-color-a7.border {
  border-color: #004ab3 !important;
}

.primary-bg-a8 {
  background-color: #004099;
}

.primary-color-a8 {
  color: #004099;
}

.primary-border-color-a8 {
  border-color: #004099 !important;
}
.primary-border-color-a8.border {
  border-color: #004099 !important;
}

.primary-bg-a9 {
  background-color: #003580;
}

.primary-color-a9 {
  color: #003580;
}

.primary-border-color-a9 {
  border-color: #003580 !important;
}
.primary-border-color-a9.border {
  border-color: #003580 !important;
}

.primary-bg-a10 {
  background-color: #002b66;
}

.primary-color-a10 {
  color: #002b66;
}

.primary-border-color-a10 {
  border-color: #002b66 !important;
}
.primary-border-color-a10.border {
  border-color: #002b66 !important;
}

.primary-bg-a11 {
  background-color: #00204d;
}

.primary-color-a11 {
  color: #00204d;
}

.primary-border-color-a11 {
  border-color: #00204d !important;
}
.primary-border-color-a11.border {
  border-color: #00204d !important;
}

.primary-bg-a12 {
  background-color: #001533;
}

.primary-color-a12 {
  color: #001533;
}

.primary-border-color-a12 {
  border-color: #001533 !important;
}
.primary-border-color-a12.border {
  border-color: #001533 !important;
}

.primary-bg-b1 {
  background-color: #0048ad;
}

.primary-color-b1 {
  color: #0048ad;
}

.primary-border-color-b1 {
  border-color: #0048ad !important;
}
.primary-border-color-b1.border {
  border-color: #0048ad !important;
}

.primary-bg-b2 {
  background-color: #0f4694;
}

.primary-color-b2 {
  color: #0f4694;
}

.primary-border-color-b2 {
  border-color: #0f4694 !important;
}
.primary-border-color-b2.border {
  border-color: #0f4694 !important;
}

.primary-bg-b3 {
  background-color: #18417a;
}

.primary-color-b3 {
  color: #18417a;
}

.primary-border-color-b3 {
  border-color: #18417a !important;
}
.primary-border-color-b3.border {
  border-color: #18417a !important;
}

.primary-bg-b4 {
  background-color: #1d3961;
}

.primary-color-b4 {
  color: #1d3961;
}

.primary-border-color-b4 {
  border-color: #1d3961 !important;
}
.primary-border-color-b4.border {
  border-color: #1d3961 !important;
}

.primary-bg-b5 {
  background-color: #1d2e47;
}

.primary-color-b5 {
  color: #1d2e47;
}

.primary-border-color-b5 {
  border-color: #1d2e47 !important;
}
.primary-border-color-b5.border {
  border-color: #1d2e47 !important;
}

.primary-bg-b6 {
  background-color: #17212e;
}

.primary-color-b6 {
  color: #17212e;
}

.primary-border-color-b6 {
  border-color: #17212e !important;
}
.primary-border-color-b6.border {
  border-color: #17212e !important;
}

.primary-bg-b7 {
  background-color: #0c1014;
}

.primary-color-b7 {
  color: #0c1014;
}

.primary-border-color-b7 {
  border-color: #0c1014 !important;
}
.primary-border-color-b7.border {
  border-color: #0c1014 !important;
}

.primary-bg-b8 {
  background-color: #020203;
}

.primary-color-b8 {
  color: #020203;
}

.primary-border-color-b8 {
  border-color: #020203 !important;
}
.primary-border-color-b8.border {
  border-color: #020203 !important;
}

.primary-bg-c1 {
  background-color: #bbc4d0;
}

.primary-color-c1 {
  color: #bbc4d0;
}

.primary-border-color-c1 {
  border-color: #bbc4d0 !important;
}
.primary-border-color-c1.border {
  border-color: #bbc4d0 !important;
}

.primary-bg-c2 {
  background-color: #a6b8d0;
}

.primary-color-c2 {
  color: #a6b8d0;
}

.primary-border-color-c2 {
  border-color: #a6b8d0 !important;
}
.primary-border-color-c2.border {
  border-color: #a6b8d0 !important;
}

.primary-bg-c3 {
  background-color: #92acd0;
}

.primary-color-c3 {
  color: #92acd0;
}

.primary-border-color-c3 {
  border-color: #92acd0 !important;
}
.primary-border-color-c3.border {
  border-color: #92acd0 !important;
}

.primary-bg-c4 {
  background-color: #7da0d0;
}

.primary-color-c4 {
  color: #7da0d0;
}

.primary-border-color-c4 {
  border-color: #7da0d0 !important;
}
.primary-border-color-c4.border {
  border-color: #7da0d0 !important;
}

.primary-bg-c5 {
  background-color: #6893d0;
}

.primary-color-c5 {
  color: #6893d0;
}

.primary-border-color-c5 {
  border-color: #6893d0 !important;
}
.primary-border-color-c5.border {
  border-color: #6893d0 !important;
}

.primary-bg-c6 {
  background-color: #5387d0;
}

.primary-color-c6 {
  color: #5387d0;
}

.primary-border-color-c6 {
  border-color: #5387d0 !important;
}
.primary-border-color-c6.border {
  border-color: #5387d0 !important;
}

.primary-bg-c7 {
  background-color: #3e7bd0;
}

.primary-color-c7 {
  color: #3e7bd0;
}

.primary-border-color-c7 {
  border-color: #3e7bd0 !important;
}
.primary-border-color-c7.border {
  border-color: #3e7bd0 !important;
}

.primary-bg-c8 {
  background-color: #2a6fd0;
}

.primary-color-c8 {
  color: #2a6fd0;
}

.primary-border-color-c8 {
  border-color: #2a6fd0 !important;
}
.primary-border-color-c8.border {
  border-color: #2a6fd0 !important;
}

.primary-bg-c9 {
  background-color: #1563d0;
}

.primary-color-c9 {
  color: #1563d0;
}

.primary-border-color-c9 {
  border-color: #1563d0 !important;
}
.primary-border-color-c9.border {
  border-color: #1563d0 !important;
}

.primary-bg-c10 {
  background-color: #0057d0;
}

.primary-color-c10 {
  color: #0057d0;
}

.primary-border-color-c10 {
  border-color: #0057d0 !important;
}
.primary-border-color-c10.border {
  border-color: #0057d0 !important;
}

.primary-bg-c11 {
  background-color: #004fbf;
}

.primary-color-c11 {
  color: #004fbf;
}

.primary-border-color-c11 {
  border-color: #004fbf !important;
}
.primary-border-color-c11.border {
  border-color: #004fbf !important;
}

.primary-bg-c12 {
  background-color: #0048ad;
}

.primary-color-c12 {
  color: #0048ad;
}

.primary-border-color-c12 {
  border-color: #0048ad !important;
}
.primary-border-color-c12.border {
  border-color: #0048ad !important;
}

.analogue-1-bg {
  background-color: #3126ff;
}

.analogue-1-color {
  color: #3126ff;
}

.analogue-1-border-color- {
  border-color: #3126ff !important;
}
.analogue-1-border-color-.border {
  border-color: #3126ff !important;
}

.analogue-1-bg-a1 {
  background-color: #e7e6ff;
}

.analogue-1-color-a1 {
  color: #e7e6ff;
}

.analogue-1-border-color-a1 {
  border-color: #e7e6ff !important;
}
.analogue-1-border-color-a1.border {
  border-color: #e7e6ff !important;
}

.analogue-1-bg-a2 {
  background-color: #bbb8f5;
}

.analogue-1-color-a2 {
  color: #bbb8f5;
}

.analogue-1-border-color-a2 {
  border-color: #bbb8f5 !important;
}
.analogue-1-border-color-a2.border {
  border-color: #bbb8f5 !important;
}

.analogue-1-bg-a3 {
  background-color: #918deb;
}

.analogue-1-color-a3 {
  color: #918deb;
}

.analogue-1-border-color-a3 {
  border-color: #918deb !important;
}
.analogue-1-border-color-a3.border {
  border-color: #918deb !important;
}

.analogue-1-bg-a4 {
  background-color: #6b65e0;
}

.analogue-1-color-a4 {
  color: #6b65e0;
}

.analogue-1-border-color-a4 {
  border-color: #6b65e0 !important;
}
.analogue-1-border-color-a4.border {
  border-color: #6b65e0 !important;
}

.analogue-1-bg-a5 {
  background-color: #4840d6;
}

.analogue-1-color-a5 {
  color: #4840d6;
}

.analogue-1-border-color-a5 {
  border-color: #4840d6 !important;
}
.analogue-1-border-color-a5.border {
  border-color: #4840d6 !important;
}

.analogue-1-bg-a6 {
  background-color: #271fcc;
}

.analogue-1-color-a6 {
  color: #271fcc;
}

.analogue-1-border-color-a6 {
  border-color: #271fcc !important;
}
.analogue-1-border-color-a6.border {
  border-color: #271fcc !important;
}

.analogue-1-bg-a7 {
  background-color: #221bb3;
}

.analogue-1-color-a7 {
  color: #221bb3;
}

.analogue-1-border-color-a7 {
  border-color: #221bb3 !important;
}
.analogue-1-border-color-a7.border {
  border-color: #221bb3 !important;
}

.analogue-1-bg-a8 {
  background-color: #1d1799;
}

.analogue-1-color-a8 {
  color: #1d1799;
}

.analogue-1-border-color-a8 {
  border-color: #1d1799 !important;
}
.analogue-1-border-color-a8.border {
  border-color: #1d1799 !important;
}

.analogue-1-bg-a9 {
  background-color: #191380;
}

.analogue-1-color-a9 {
  color: #191380;
}

.analogue-1-border-color-a9 {
  border-color: #191380 !important;
}
.analogue-1-border-color-a9.border {
  border-color: #191380 !important;
}

.analogue-1-bg-a10 {
  background-color: #140f66;
}

.analogue-1-color-a10 {
  color: #140f66;
}

.analogue-1-border-color-a10 {
  border-color: #140f66 !important;
}
.analogue-1-border-color-a10.border {
  border-color: #140f66 !important;
}

.analogue-1-bg-a11 {
  background-color: #0f0b4d;
}

.analogue-1-color-a11 {
  color: #0f0b4d;
}

.analogue-1-border-color-a11 {
  border-color: #0f0b4d !important;
}
.analogue-1-border-color-a11.border {
  border-color: #0f0b4d !important;
}

.analogue-1-bg-a12 {
  background-color: #0a0833;
}

.analogue-1-color-a12 {
  color: #0a0833;
}

.analogue-1-border-color-a12 {
  border-color: #0a0833 !important;
}
.analogue-1-border-color-a12.border {
  border-color: #0a0833 !important;
}

.analogue-2-bg {
  background-color: #0bd9d2;
}

.analogue-2-color {
  color: #0bd9d2;
}

.analogue-2-border-color- {
  border-color: #0bd9d2 !important;
}
.analogue-2-border-color-.border {
  border-color: #0bd9d2 !important;
}

.analogue-2-bg-a1 {
  background-color: #ccfffd;
}

.analogue-2-color-a1 {
  color: #ccfffd;
}

.analogue-2-border-color-a1 {
  border-color: #ccfffd !important;
}
.analogue-2-border-color-a1.border {
  border-color: #ccfffd !important;
}

.analogue-2-bg-a2 {
  background-color: #9ff5f2;
}

.analogue-2-color-a2 {
  color: #9ff5f2;
}

.analogue-2-border-color-a2 {
  border-color: #9ff5f2 !important;
}
.analogue-2-border-color-a2.border {
  border-color: #9ff5f2 !important;
}

.analogue-2-bg-a3 {
  background-color: #75ebe7;
}

.analogue-2-color-a3 {
  color: #75ebe7;
}

.analogue-2-border-color-a3 {
  border-color: #75ebe7 !important;
}
.analogue-2-border-color-a3.border {
  border-color: #75ebe7 !important;
}

.analogue-2-bg-a4 {
  background-color: #4fe0dc;
}

.analogue-2-color-a4 {
  color: #4fe0dc;
}

.analogue-2-border-color-a4 {
  border-color: #4fe0dc !important;
}
.analogue-2-border-color-a4.border {
  border-color: #4fe0dc !important;
}

.analogue-2-bg-a5 {
  background-color: #2bd6d0;
}

.analogue-2-color-a5 {
  color: #2bd6d0;
}

.analogue-2-border-color-a5 {
  border-color: #2bd6d0 !important;
}
.analogue-2-border-color-a5.border {
  border-color: #2bd6d0 !important;
}

.analogue-2-bg-a6 {
  background-color: #0accc6;
}

.analogue-2-color-a6 {
  color: #0accc6;
}

.analogue-2-border-color-a6 {
  border-color: #0accc6 !important;
}
.analogue-2-border-color-a6.border {
  border-color: #0accc6 !important;
}

.analogue-2-bg-a7 {
  background-color: #09b3ad;
}

.analogue-2-color-a7 {
  color: #09b3ad;
}

.analogue-2-border-color-a7 {
  border-color: #09b3ad !important;
}
.analogue-2-border-color-a7.border {
  border-color: #09b3ad !important;
}

.analogue-2-bg-a8 {
  background-color: #089994;
}

.analogue-2-color-a8 {
  color: #089994;
}

.analogue-2-border-color-a8 {
  border-color: #089994 !important;
}
.analogue-2-border-color-a8.border {
  border-color: #089994 !important;
}

.analogue-2-bg-a9 {
  background-color: #06807b;
}

.analogue-2-color-a9 {
  color: #06807b;
}

.analogue-2-border-color-a9 {
  border-color: #06807b !important;
}
.analogue-2-border-color-a9.border {
  border-color: #06807b !important;
}

.analogue-2-bg-a10 {
  background-color: #056663;
}

.analogue-2-color-a10 {
  color: #056663;
}

.analogue-2-border-color-a10 {
  border-color: #056663 !important;
}
.analogue-2-border-color-a10.border {
  border-color: #056663 !important;
}

.analogue-2-bg-a11 {
  background-color: #044d4a;
}

.analogue-2-color-a11 {
  color: #044d4a;
}

.analogue-2-border-color-a11 {
  border-color: #044d4a !important;
}
.analogue-2-border-color-a11.border {
  border-color: #044d4a !important;
}

.analogue-2-bg-a12 {
  background-color: #033331;
}

.analogue-2-color-a12 {
  color: #033331;
}

.analogue-2-border-color-a12 {
  border-color: #033331 !important;
}
.analogue-2-border-color-a12.border {
  border-color: #033331 !important;
}

.complementary-1-bg {
  background-color: #f73e5a;
}

.complementary-1-color {
  color: #ff9900;
}

.complementary-1-border-color- {
  border-color: #f73e5a !important;
}
.complementary-1-border-color-.border {
  border-color: #f73e5a !important;
}

.complementary-1-bg-a1 {
  background-color: #fffcfd;
}

.complementary-1-color-a1 {
  color: #fffcfd;
}

.complementary-1-border-color-a1 {
  border-color: #fffcfd !important;
}
.complementary-1-border-color-a1.border {
  border-color: #fffcfd !important;
}

.complementary-1-bg-a2 {
  background-color: #f5d0d6;
}

.complementary-1-color-a2 {
  color: #f5d0d6;
}

.complementary-1-border-color-a2 {
  border-color: #f5d0d6 !important;
}
.complementary-1-border-color-a2.border {
  border-color: #f5d0d6 !important;
}

.complementary-1-bg-a3 {
  background-color: #eba4af;
}

.complementary-1-color-a3 {
  color: #eba4af;
}

.complementary-1-border-color-a3 {
  border-color: #eba4af !important;
}
.complementary-1-border-color-a3.border {
  border-color: #eba4af !important;
}

.complementary-1-bg-a4 {
  background-color: #e07b8b;
}

.complementary-1-color-a4 {
  color: #e07b8b;
}

.complementary-1-border-color-a4 {
  border-color: #e07b8b !important;
}
.complementary-1-border-color-a4.border {
  border-color: #e07b8b !important;
}

.complementary-1-bg-a5 {
  background-color: #d65669;
}

.complementary-1-color-a5 {
  color: #d65669;
}

.complementary-1-border-color-a5 {
  border-color: #d65669 !important;
}
.complementary-1-border-color-a5.border {
  border-color: #d65669 !important;
}

.complementary-1-bg-a6 {
  background-color: #cc334a;
}

.complementary-1-color-a6 {
  color: #cc334a;
}

.complementary-1-border-color-a6 {
  border-color: #cc334a !important;
}
.complementary-1-border-color-a6.border {
  border-color: #cc334a !important;
}

.complementary-1-bg-a7 {
  background-color: #b32d41;
}

.complementary-1-color-a7 {
  color: #b32d41;
}

.complementary-1-border-color-a7 {
  border-color: #b32d41 !important;
}
.complementary-1-border-color-a7.border {
  border-color: #b32d41 !important;
}

.complementary-1-bg-a8 {
  background-color: #992637;
}

.complementary-1-color-a8 {
  color: #992637;
}

.complementary-1-border-color-a8 {
  border-color: #992637 !important;
}
.complementary-1-border-color-a8.border {
  border-color: #992637 !important;
}

.complementary-1-bg-a9 {
  background-color: #80202e;
}

.complementary-1-color-a9 {
  color: #80202e;
}

.complementary-1-border-color-a9 {
  border-color: #80202e !important;
}
.complementary-1-border-color-a9.border {
  border-color: #80202e !important;
}

.complementary-1-bg-a10 {
  background-color: #661a25;
}

.complementary-1-color-a10 {
  color: #661a25;
}

.complementary-1-border-color-a10 {
  border-color: #661a25 !important;
}
.complementary-1-border-color-a10.border {
  border-color: #661a25 !important;
}

.complementary-1-bg-a11 {
  background-color: #4d131c;
}

.complementary-1-color-a11 {
  color: #4d131c;
}

.complementary-1-border-color-a11 {
  border-color: #4d131c !important;
}
.complementary-1-border-color-a11.border {
  border-color: #4d131c !important;
}

.complementary-1-bg-a12 {
  background-color: #330d12;
}

.complementary-1-color-a12 {
  color: #330d12;
}

.complementary-1-border-color-a12 {
  border-color: #330d12 !important;
}
.complementary-1-border-color-a12.border {
  border-color: #330d12 !important;
}

.complementary-2-bg {
  background-color: #ff9900;
}

.complementary-2-color {
  color: #ff9900;
}

.complementary-2-border-color- {
  border-color: #ff9900 !important;
}
.complementary-2-border-color-.border {
  border-color: #ff9900 !important;
}

.complementary-2-bg-a1 {
  background-color: #ffe6bf;
}

.complementary-2-color-a1 {
  color: #ffe6bf;
}

.complementary-2-border-color-a1 {
  border-color: #ffe6bf !important;
}
.complementary-2-border-color-a1.border {
  border-color: #ffe6bf !important;
}

.complementary-2-bg-a2 {
  background-color: #f5ce93;
}

.complementary-2-color-a2 {
  color: #f5ce93;
}

.complementary-2-border-color-a2 {
  border-color: #f5ce93 !important;
}
.complementary-2-border-color-a2.border {
  border-color: #f5ce93 !important;
}

.complementary-2-bg-a3 {
  background-color: #ebb76a;
}

.complementary-2-color-a3 {
  color: #ebb76a;
}

.complementary-2-border-color-a3 {
  border-color: #ebb76a !important;
}
.complementary-2-border-color-a3.border {
  border-color: #ebb76a !important;
}

.complementary-2-bg-a4 {
  background-color: #e0a243;
}

.complementary-2-color-a4 {
  color: #e0a243;
}

.complementary-2-border-color-a4 {
  border-color: #e0a243 !important;
}
.complementary-2-border-color-a4.border {
  border-color: #e0a243 !important;
}

.complementary-2-bg-a5 {
  background-color: #d68d20;
}

.complementary-2-color-a5 {
  color: #d68d20;
}

.complementary-2-border-color-a5 {
  border-color: #d68d20 !important;
}
.complementary-2-border-color-a5.border {
  border-color: #d68d20 !important;
}

.complementary-2-bg-a6 {
  background-color: #cc7a00;
}

.complementary-2-color-a6 {
  color: #cc7a00;
}

.complementary-2-border-color-a6 {
  border-color: #cc7a00 !important;
}
.complementary-2-border-color-a6.border {
  border-color: #cc7a00 !important;
}

.complementary-2-bg-a7 {
  background-color: #b36b00;
}

.complementary-2-color-a7 {
  color: #b36b00;
}

.complementary-2-border-color-a7 {
  border-color: #b36b00 !important;
}
.complementary-2-border-color-a7.border {
  border-color: #b36b00 !important;
}

.complementary-2-bg-a8 {
  background-color: #995c00;
}

.complementary-2-color-a8 {
  color: #995c00;
}

.complementary-2-border-color-a8 {
  border-color: #995c00 !important;
}
.complementary-2-border-color-a8.border {
  border-color: #995c00 !important;
}

.complementary-2-bg-a9 {
  background-color: #804d00;
}

.complementary-2-color-a9 {
  color: #804d00;
}

.complementary-2-border-color-a9 {
  border-color: #804d00 !important;
}
.complementary-2-border-color-a9.border {
  border-color: #804d00 !important;
}

.complementary-2-bg-a10 {
  background-color: #663d00;
}

.complementary-2-color-a10 {
  color: #663d00;
}

.complementary-2-border-color-a10 {
  border-color: #663d00 !important;
}
.complementary-2-border-color-a10.border {
  border-color: #663d00 !important;
}

.complementary-2-bg-a11 {
  background-color: #4d2e00;
}

.complementary-2-color-a11 {
  color: #4d2e00;
}

.complementary-2-border-color-a11 {
  border-color: #4d2e00 !important;
}
.complementary-2-border-color-a11.border {
  border-color: #4d2e00 !important;
}

.complementary-2-bg-a12 {
  background-color: #331f00;
}

.complementary-2-color-a12 {
  color: #331f00;
}

.complementary-2-border-color-a12 {
  border-color: #331f00 !important;
}
.complementary-2-border-color-a12.border {
  border-color: #331f00 !important;
}

.complementary-3-bg {
  background-color: #00cf86;
}

.complementary-3-color {
  color: #00cf86;
}

.complementary-3-border-color- {
  border-color: #00cf86 !important;
}
.complementary-3-border-color-.border {
  border-color: #00cf86 !important;
}

.complementary-3-bg-a1 {
  background-color: #bfffe9;
}

.complementary-3-color-a1 {
  color: #bfffe9;
}

.complementary-3-border-color-a1 {
  border-color: #bfffe9 !important;
}
.complementary-3-border-color-a1.border {
  border-color: #bfffe9 !important;
}

.complementary-3-bg-a2 {
  background-color: #93f5d3;
}

.complementary-3-color-a2 {
  color: #93f5d3;
}

.complementary-3-border-color-a2 {
  border-color: #93f5d3 !important;
}
.complementary-3-border-color-a2.border {
  border-color: #93f5d3 !important;
}

.complementary-3-bg-a3 {
  background-color: #6aebbd;
}

.complementary-3-color-a3 {
  color: #6aebbd;
}

.complementary-3-border-color-a3 {
  border-color: #6aebbd !important;
}
.complementary-3-border-color-a3.border {
  border-color: #6aebbd !important;
}

.complementary-3-bg-a4 {
  background-color: #43e0a9;
}

.complementary-3-color-a4 {
  color: #43e0a9;
}

.complementary-3-border-color-a4 {
  border-color: #43e0a9 !important;
}
.complementary-3-border-color-a4.border {
  border-color: #43e0a9 !important;
}

.complementary-3-bg-a5 {
  background-color: #20d696;
}

.complementary-3-color-a5 {
  color: #20d696;
}

.complementary-3-border-color-a5 {
  border-color: #20d696 !important;
}
.complementary-3-border-color-a5.border {
  border-color: #20d696 !important;
}

.complementary-3-bg-a6 {
  background-color: #00cc85;
}

.complementary-3-color-a6 {
  color: #00cc85;
}

.complementary-3-border-color-a6 {
  border-color: #00cc85 !important;
}
.complementary-3-border-color-a6.border {
  border-color: #00cc85 !important;
}

.complementary-3-bg-a7 {
  background-color: #00b374;
}

.complementary-3-color-a7 {
  color: #00b374;
}

.complementary-3-border-color-a7 {
  border-color: #00b374 !important;
}
.complementary-3-border-color-a7.border {
  border-color: #00b374 !important;
}

.complementary-3-bg-a8 {
  background-color: #009963;
}

.complementary-3-color-a8 {
  color: #009963;
}

.complementary-3-border-color-a8 {
  border-color: #009963 !important;
}
.complementary-3-border-color-a8.border {
  border-color: #009963 !important;
}

.complementary-3-bg-a9 {
  background-color: #008053;
}

.complementary-3-color-a9 {
  color: #008053;
}

.complementary-3-border-color-a9 {
  border-color: #008053 !important;
}
.complementary-3-border-color-a9.border {
  border-color: #008053 !important;
}

.complementary-3-bg-a10 {
  background-color: #006642;
}

.complementary-3-color-a10 {
  color: #006642;
}

.complementary-3-border-color-a10 {
  border-color: #006642 !important;
}
.complementary-3-border-color-a10.border {
  border-color: #006642 !important;
}

.complementary-3-bg-a11 {
  background-color: #004d32;
}

.complementary-3-color-a11 {
  color: #004d32;
}

.complementary-3-border-color-a11 {
  border-color: #004d32 !important;
}
.complementary-3-border-color-a11.border {
  border-color: #004d32 !important;
}

.complementary-3-bg-a12 {
  background-color: #003321;
}

.complementary-3-color-a12 {
  color: #003321;
}

.complementary-3-border-color-a12 {
  border-color: #003321 !important;
}
.complementary-3-border-color-a12.border {
  border-color: #003321 !important;
}

.analogue-1-bg {
  background-color: #3126ff;
}

.analogue-1-color {
  color: #3126ff;
}

.analogue-1-border-color- {
  border-color: #3126ff !important;
}
.analogue-1-border-color-.border {
  border-color: #3126ff !important;
}

.analogue-1-bg-b1 {
  background-color: #3126ff;
}

.analogue-1-color-b1 {
  color: #3126ff;
}

.analogue-1-border-color-b1 {
  border-color: #3126ff !important;
}
.analogue-1-border-color-b1.border {
  border-color: #3126ff !important;
}

.analogue-1-bg-b2 {
  background-color: #4239e6;
}

.analogue-1-color-b2 {
  color: #4239e6;
}

.analogue-1-border-color-b2 {
  border-color: #4239e6 !important;
}
.analogue-1-border-color-b2.border {
  border-color: #4239e6 !important;
}

.analogue-1-bg-b3 {
  background-color: #4e47cc;
}

.analogue-1-color-b3 {
  color: #4e47cc;
}

.analogue-1-border-color-b3 {
  border-color: #4e47cc !important;
}
.analogue-1-border-color-b3.border {
  border-color: #4e47cc !important;
}

.analogue-1-bg-b4 {
  background-color: #5550b3;
}

.analogue-1-color-b4 {
  color: #5550b3;
}

.analogue-1-border-color-b4 {
  border-color: #5550b3 !important;
}
.analogue-1-border-color-b4.border {
  border-color: #5550b3 !important;
}

.analogue-1-bg-b5 {
  background-color: #585499;
}

.analogue-1-color-b5 {
  color: #585499;
}

.analogue-1-border-color-b5 {
  border-color: #585499 !important;
}
.analogue-1-border-color-b5.border {
  border-color: #585499 !important;
}

.analogue-1-bg-b6 {
  background-color: #555380;
}

.analogue-1-color-b6 {
  color: #555380;
}

.analogue-1-border-color-b6 {
  border-color: #555380 !important;
}
.analogue-1-border-color-b6.border {
  border-color: #555380 !important;
}

.analogue-1-bg-b7 {
  background-color: #4e4d66;
}

.analogue-1-color-b7 {
  color: #4e4d66;
}

.analogue-1-border-color-b7 {
  border-color: #4e4d66 !important;
}
.analogue-1-border-color-b7.border {
  border-color: #4e4d66 !important;
}

.analogue-1-bg-b8 {
  background-color: #42414d;
}

.analogue-1-color-b8 {
  color: #42414d;
}

.analogue-1-border-color-b8 {
  border-color: #42414d !important;
}
.analogue-1-border-color-b8.border {
  border-color: #42414d !important;
}

.analogue-2-bg {
  background-color: #0bd9d2;
}

.analogue-2-color {
  color: #0bd9d2;
}

.analogue-2-border-color- {
  border-color: #0bd9d2 !important;
}
.analogue-2-border-color-.border {
  border-color: #0bd9d2 !important;
}

.analogue-2-bg-b1 {
  background-color: #0bd9d2;
}

.analogue-2-color-b1 {
  color: #0bd9d2;
}

.analogue-2-border-color-b1 {
  border-color: #0bd9d2 !important;
}
.analogue-2-border-color-b1.border {
  border-color: #0bd9d2 !important;
}

.analogue-2-bg-b2 {
  background-color: #1dbfba;
}

.analogue-2-color-b2 {
  color: #1dbfba;
}

.analogue-2-border-color-b2 {
  border-color: #1dbfba !important;
}
.analogue-2-border-color-b2.border {
  border-color: #1dbfba !important;
}

.analogue-2-bg-b3 {
  background-color: #29a6a2;
}

.analogue-2-color-b3 {
  color: #29a6a2;
}

.analogue-2-border-color-b3 {
  border-color: #29a6a2 !important;
}
.analogue-2-border-color-b3.border {
  border-color: #29a6a2 !important;
}

.analogue-2-bg-b4 {
  background-color: #318c89;
}

.analogue-2-color-b4 {
  color: #318c89;
}

.analogue-2-border-color-b4 {
  border-color: #318c89 !important;
}
.analogue-2-border-color-b4.border {
  border-color: #318c89 !important;
}

.analogue-2-bg-b5 {
  background-color: #347371;
}

.analogue-2-color-b5 {
  color: #347371;
}

.analogue-2-border-color-b5 {
  border-color: #347371 !important;
}
.analogue-2-border-color-b5.border {
  border-color: #347371 !important;
}

.analogue-2-bg-b6 {
  background-color: #315958;
}

.analogue-2-color-b6 {
  color: #315958;
}

.analogue-2-border-color-b6 {
  border-color: #315958 !important;
}
.analogue-2-border-color-b6.border {
  border-color: #315958 !important;
}

.analogue-2-bg-b7 {
  background-color: #29403f;
}

.analogue-2-color-b7 {
  color: #29403f;
}

.analogue-2-border-color-b7 {
  border-color: #29403f !important;
}
.analogue-2-border-color-b7.border {
  border-color: #29403f !important;
}

.analogue-2-bg-b8 {
  background-color: #1d2626;
}

.analogue-2-color-b8 {
  color: #1d2626;
}

.analogue-2-border-color-b8 {
  border-color: #1d2626 !important;
}
.analogue-2-border-color-b8.border {
  border-color: #1d2626 !important;
}

.complementary-1-bg-b1 {
  background-color: #f73e5a;
}

.complementary-1-color-b1 {
  color: #f73e5a;
}

.complementary-1-border-color-b1 {
  border-color: #f73e5a !important;
}
.complementary-1-border-color-b1.border {
  border-color: #f73e5a !important;
}

.complementary-1-bg-b2 {
  background-color: #de4e63;
}

.complementary-1-color-b2 {
  color: #de4e63;
}

.complementary-1-border-color-b2 {
  border-color: #de4e63 !important;
}
.complementary-1-border-color-b2.border {
  border-color: #de4e63 !important;
}

.complementary-1-bg-b3 {
  background-color: #c45869;
}

.complementary-1-color-b3 {
  color: #c45869;
}

.complementary-1-border-color-b3 {
  border-color: #c45869 !important;
}
.complementary-1-border-color-b3.border {
  border-color: #c45869 !important;
}

.complementary-1-bg-b4 {
  background-color: #ab5e69;
}

.complementary-1-color-b4 {
  color: #ab5e69;
}

.complementary-1-border-color-b4 {
  border-color: #ab5e69 !important;
}
.complementary-1-border-color-b4.border {
  border-color: #ab5e69 !important;
}

.complementary-1-bg-b5 {
  background-color: #915e66;
}

.complementary-1-color-b5 {
  color: #915e66;
}

.complementary-1-border-color-b5 {
  border-color: #915e66 !important;
}
.complementary-1-border-color-b5.border {
  border-color: #915e66 !important;
}

.complementary-1-bg-b6 {
  background-color: #785a5e;
}

.complementary-1-color-b6 {
  color: #785a5e;
}

.complementary-1-border-color-b6 {
  border-color: #785a5e !important;
}
.complementary-1-border-color-b6.border {
  border-color: #785a5e !important;
}

.complementary-1-bg-b7 {
  background-color: #5e5052;
}

.complementary-1-color-b7 {
  color: #5e5052;
}

.complementary-1-border-color-b7 {
  border-color: #5e5052 !important;
}
.complementary-1-border-color-b7.border {
  border-color: #5e5052 !important;
}

.complementary-1-bg-b8 {
  background-color: #454142;
}

.complementary-1-color-b8 {
  color: #454142;
}

.complementary-1-border-color-b8 {
  border-color: #454142 !important;
}
.complementary-1-border-color-b8.border {
  border-color: #454142 !important;
}

.complementary-2-bg-b1 {
  background-color: #ff9900;
}

.complementary-2-color-b1 {
  color: #ff9900;
}

.complementary-2-border-color-b1 {
  border-color: #ff9900 !important;
}
.complementary-2-border-color-b1.border {
  border-color: #ff9900 !important;
}

.complementary-2-bg-b2 {
  background-color: #e69317;
}

.complementary-2-color-b2 {
  color: #e69317;
}

.complementary-2-border-color-b2 {
  border-color: #e69317 !important;
}
.complementary-2-border-color-b2.border {
  border-color: #e69317 !important;
}

.complementary-2-bg-b3 {
  background-color: #cc8b29;
}

.complementary-2-color-b3 {
  color: #cc8b29;
}

.complementary-2-border-color-b3 {
  border-color: #cc8b29 !important;
}
.complementary-2-border-color-b3.border {
  border-color: #cc8b29 !important;
}

.complementary-2-bg-b4 {
  background-color: #b38136;
}

.complementary-2-color-b4 {
  color: #b38136;
}

.complementary-2-border-color-b4 {
  border-color: #b38136 !important;
}
.complementary-2-border-color-b4.border {
  border-color: #b38136 !important;
}

.complementary-2-bg-b5 {
  background-color: #99743d;
}

.complementary-2-color-b5 {
  color: #99743d;
}

.complementary-2-border-color-b5 {
  border-color: #99743d !important;
}
.complementary-2-border-color-b5.border {
  border-color: #99743d !important;
}

.complementary-2-bg-b6 {
  background-color: #806640;
}

.complementary-2-color-b6 {
  color: #806640;
}

.complementary-2-border-color-b6 {
  border-color: #806640 !important;
}
.complementary-2-border-color-b6.border {
  border-color: #806640 !important;
}

.complementary-2-bg-b7 {
  background-color: #66563d;
}

.complementary-2-color-b7 {
  color: #66563d;
}

.complementary-2-border-color-b7 {
  border-color: #66563d !important;
}
.complementary-2-border-color-b7.border {
  border-color: #66563d !important;
}

.complementary-2-bg-b8 {
  background-color: #4d4336;
}

.complementary-2-color-b8 {
  color: #4d4336;
}

.complementary-2-border-color-b8 {
  border-color: #4d4336 !important;
}
.complementary-2-border-color-b8.border {
  border-color: #4d4336 !important;
}

.complementary-3-bg-b1 {
  background-color: #00cf86;
}

.complementary-3-color-b1 {
  color: #00cf86;
}

.complementary-3-border-color-b1 {
  border-color: #00cf86 !important;
}
.complementary-3-border-color-b1.border {
  border-color: #00cf86 !important;
}

.complementary-3-bg-b2 {
  background-color: #12b57c;
}

.complementary-3-color-b2 {
  color: #12b57c;
}

.complementary-3-border-color-b2 {
  border-color: #12b57c !important;
}
.complementary-3-border-color-b2.border {
  border-color: #12b57c !important;
}

.complementary-3-bg-b3 {
  background-color: #1f9c70;
}

.complementary-3-color-b3 {
  color: #1f9c70;
}

.complementary-3-border-color-b3 {
  border-color: #1f9c70 !important;
}
.complementary-3-border-color-b3.border {
  border-color: #1f9c70 !important;
}

.complementary-3-bg-b4 {
  background-color: #278262;
}

.complementary-3-color-b4 {
  color: #278262;
}

.complementary-3-border-color-b4 {
  border-color: #278262 !important;
}
.complementary-3-border-color-b4.border {
  border-color: #278262 !important;
}

.complementary-3-bg-b5 {
  background-color: #2a6953;
}

.complementary-3-color-b5 {
  color: #2a6953;
}

.complementary-3-border-color-b5 {
  border-color: #2a6953 !important;
}
.complementary-3-border-color-b5.border {
  border-color: #2a6953 !important;
}

.complementary-3-bg-b6 {
  background-color: #284f41;
}

.complementary-3-color-b6 {
  color: #284f41;
}

.complementary-3-border-color-b6 {
  border-color: #284f41 !important;
}
.complementary-3-border-color-b6.border {
  border-color: #284f41 !important;
}

.complementary-3-bg-b7 {
  background-color: #20362e;
}

.complementary-3-color-b7 {
  color: #20362e;
}

.complementary-3-border-color-b7 {
  border-color: #20362e !important;
}
.complementary-3-border-color-b7.border {
  border-color: #20362e !important;
}

.complementary-3-bg-b8 {
  background-color: #141c19;
}

.complementary-3-color-b8 {
  color: #141c19;
}

.complementary-3-border-color-b8 {
  border-color: #141c19 !important;
}
.complementary-3-border-color-b8.border {
  border-color: #141c19 !important;
}

.neutral-1-bg {
  background-color: #17324d;
}

.neutral-1-color {
  color: #17324d;
}

.neutral-1-border-color- {
  border-color: #17324d !important;
}
.neutral-1-border-color-.border {
  border-color: #17324d !important;
}

.neutral-1-bg-a1 {
  background-color: #ebeced;
}

.neutral-1-color-a1 {
  color: #ebeced;
}

.neutral-1-border-color-a1 {
  border-color: #ebeced !important;
}
.neutral-1-border-color-a1.border {
  border-color: #ebeced !important;
}

.neutral-1-bg-a2 {
  background-color: #d9dadb;
}

.neutral-1-color-a2 {
  color: #d9dadb;
}

.neutral-1-border-color-a2 {
  border-color: #d9dadb !important;
}
.neutral-1-border-color-a2.border {
  border-color: #d9dadb !important;
}

.neutral-1-bg-a3 {
  background-color: #c5c7c9;
}

.neutral-1-color-a3 {
  color: #c5c7c9;
}

.neutral-1-border-color-a3 {
  border-color: #c5c7c9 !important;
}
.neutral-1-border-color-a3.border {
  border-color: #c5c7c9 !important;
}

.neutral-1-bg-a4 {
  background-color: #adb2b8;
}

.neutral-1-color-a4 {
  color: #adb2b8;
}

.neutral-1-border-color-a4 {
  border-color: #adb2b8 !important;
}
.neutral-1-border-color-a4.border {
  border-color: #adb2b8 !important;
}

.neutral-1-bg-a5 {
  background-color: #959da6;
}

.neutral-1-color-a5 {
  color: #959da6;
}

.neutral-1-border-color-a5 {
  border-color: #959da6 !important;
}
.neutral-1-border-color-a5.border {
  border-color: #959da6 !important;
}

.neutral-1-bg-a6 {
  background-color: #768594;
}

.neutral-1-color-a6 {
  color: #768594;
}

.neutral-1-border-color-a6 {
  border-color: #768594 !important;
}
.neutral-1-border-color-a6.border {
  border-color: #768594 !important;
}

.neutral-1-bg-a7 {
  background-color: #5b6f82;
}

.neutral-1-color-a7 {
  color: #5b6f82;
}

.neutral-1-border-color-a7 {
  border-color: #5b6f82 !important;
}
.neutral-1-border-color-a7.border {
  border-color: #5b6f82 !important;
}

.neutral-1-bg-a8 {
  background-color: #435a70;
}

.neutral-1-color-a8 {
  color: #435a70;
}

.neutral-1-border-color-a8 {
  border-color: #435a70 !important;
}
.neutral-1-border-color-a8.border {
  border-color: #435a70 !important;
}

.neutral-1-bg-a9 {
  background-color: #2f475e;
}

.neutral-1-color-a9 {
  color: #2f475e;
}

.neutral-1-border-color-a9 {
  border-color: #2f475e !important;
}
.neutral-1-border-color-a9.border {
  border-color: #2f475e !important;
}

.neutral-1-bg-a10 {
  background-color: #17324d;
}

.neutral-1-color-a10 {
  color: #17324d;
}

.neutral-1-border-color-a10 {
  border-color: #17324d !important;
}
.neutral-1-border-color-a10.border {
  border-color: #17324d !important;
}

.neutral-2-bg {
  background-color: #e6ecf2;
}

.neutral-2-color {
  color: #e6ecf2;
}

.neutral-2-border-color- {
  border-color: #e6ecf2;
}

.neutral-2-bg-b1 {
  background-color: #e6ecf2;
}

.neutral-2-color-b1 {
  color: #e6ecf2;
}

.neutral-2-border-color-b1 {
  border-color: #e6ecf2 !important;
}
.neutral-2-border-color-b1.border {
  border-color: #e6ecf2 !important;
}

.neutral-2-bg-b2 {
  background-color: #c2c7cc;
}

.neutral-2-color-b2 {
  color: #c2c7cc;
}

.neutral-2-border-color-b2 {
  border-color: #c2c7cc !important;
}
.neutral-2-border-color-b2.border {
  border-color: #c2c7cc !important;
}

.neutral-2-bg-b3 {
  background-color: #9da2a6;
}

.neutral-2-color-b3 {
  color: #9da2a6;
}

.neutral-2-border-color-b3 {
  border-color: #9da2a6 !important;
}
.neutral-2-border-color-b3.border {
  border-color: #9da2a6 !important;
}

.neutral-2-bg-b4 {
  background-color: #797c80;
}

.neutral-2-color-b4 {
  color: #797c80;
}

.neutral-2-border-color-b4 {
  border-color: #797c80 !important;
}
.neutral-2-border-color-b4.border {
  border-color: #797c80 !important;
}

.neutral-2-bg-b5 {
  background-color: #565759;
}

.neutral-2-color-b5 {
  color: #565759;
}

.neutral-2-border-color-b5 {
  border-color: #565759 !important;
}
.neutral-2-border-color-b5.border {
  border-color: #565759 !important;
}

.neutral-2-bg-b6 {
  background-color: #2f3133;
}

.neutral-2-color-b6 {
  color: #2f3133;
}

.neutral-2-border-color-b6 {
  border-color: #2f3133 !important;
}
.neutral-2-border-color-b6.border {
  border-color: #2f3133 !important;
}

.neutral-2-bg-b7 {
  background-color: #0c0c0d;
}

.neutral-2-color-b7 {
  color: #0c0c0d;
}

.neutral-2-border-color-b7 {
  border-color: #0c0c0d !important;
}
.neutral-2-border-color-b7.border {
  border-color: #0c0c0d !important;
}

.neutral-2-bg-a1 {
  background-color: #e6ecf2;
}

.neutral-2-color-a1 {
  color: #e6ecf2;
}

.neutral-2-border-color-a1 {
  border-color: #e6ecf2 !important;
}
.neutral-2-border-color-a1.border {
  border-color: #e6ecf2 !important;
}

.neutral-2-bg-a2 {
  background-color: #bcc4cc;
}

.neutral-2-color-a2 {
  color: #bcc4cc;
}

.neutral-2-border-color-a2 {
  border-color: #bcc4cc !important;
}
.neutral-2-border-color-a2.border {
  border-color: #bcc4cc !important;
}

.neutral-2-bg-a3 {
  background-color: #9aa0a6;
}

.neutral-2-color-a3 {
  color: #9aa0a6;
}

.neutral-2-border-color-a3 {
  border-color: #9aa0a6 !important;
}
.neutral-2-border-color-a3.border {
  border-color: #9aa0a6 !important;
}

.neutral-2-bg-a4 {
  background-color: #777b80;
}

.neutral-2-color-a4 {
  color: #777b80;
}

.neutral-2-border-color-a4 {
  border-color: #777b80 !important;
}
.neutral-2-border-color-a4.border {
  border-color: #777b80 !important;
}

.neutral-2-bg-a5 {
  background-color: #535659;
}

.neutral-2-color-a5 {
  color: #535659;
}

.neutral-2-border-color-a5 {
  border-color: #535659 !important;
}
.neutral-2-border-color-a5.border {
  border-color: #535659 !important;
}

.neutral-2-bg-a6 {
  background-color: #2f3133;
}

.neutral-2-color-a6 {
  color: #2f3133;
}

.neutral-2-border-color-a6 {
  border-color: #2f3133 !important;
}
.neutral-2-border-color-a6.border {
  border-color: #2f3133 !important;
}

.neutral-2-bg-a7 {
  background-color: #17181a;
}

.neutral-2-color-a7 {
  color: #17181a;
}

.neutral-2-border-color-a7 {
  border-color: #17181a !important;
}
.neutral-2-border-color-a7.border {
  border-color: #17181a !important;
}

.lightgrey-bg-a1 {
  background-color: #e8f2fc;
}

.lightgrey-color-a1 {
  color: #e8f2fc;
}

.lightgrey-border-color-a1 {
  border-color: #e8f2fc !important;
}
.lightgrey-border-color-a1.border {
  border-color: #e8f2fc !important;
}

.lightgrey-bg-a2 {
  background-color: #edf5fc;
}

.lightgrey-color-a2 {
  color: #edf5fc;
}

.lightgrey-border-color-a2 {
  border-color: #edf5fc !important;
}
.lightgrey-border-color-a2.border {
  border-color: #edf5fc !important;
}

.lightgrey-bg-a3 {
  background-color: #f2f7fc;
}

.lightgrey-color-a3 {
  color: #f2f7fc;
}

.lightgrey-border-color-a3 {
  border-color: #f2f7fc !important;
}
.lightgrey-border-color-a3.border {
  border-color: #f2f7fc !important;
}

.lightgrey-bg-a4 {
  background-color: #f5f9fc;
}

.lightgrey-color-a4 {
  color: #f5f9fc;
}

.lightgrey-border-color-a4 {
  border-color: #f5f9fc !important;
}
.lightgrey-border-color-a4.border {
  border-color: #f5f9fc !important;
}

.lightgrey-bg-b1 {
  background-color: #e6f0fa;
}

.lightgrey-color-b1 {
  color: #e6f0fa;
}

.lightgrey-border-color-b1 {
  border-color: #e6f0fa !important;
}
.lightgrey-border-color-b1.border {
  border-color: #e6f0fa !important;
}

.lightgrey-bg-b2 {
  background-color: #ebf2fa;
}

.lightgrey-color-b2 {
  color: #ebf2fa;
}

.lightgrey-border-color-b2 {
  border-color: #ebf2fa !important;
}
.lightgrey-border-color-b2.border {
  border-color: #ebf2fa !important;
}

.lightgrey-bg-b3 {
  background-color: #edf4fa;
}

.lightgrey-color-b3 {
  color: #edf4fa;
}

.lightgrey-border-color-b3 {
  border-color: #edf4fa !important;
}
.lightgrey-border-color-b3.border {
  border-color: #edf4fa !important;
}

.lightgrey-bg-b4 {
  background-color: #f2f6fa;
}

.lightgrey-color-b4 {
  color: #f2f6fa;
}

.lightgrey-border-color-b4 {
  border-color: #f2f6fa !important;
}
.lightgrey-border-color-b4.border {
  border-color: #f2f6fa !important;
}

.lightgrey-bg-c1 {
  background-color: #f7f9fa;
}

.lightgrey-color-c1 {
  color: #f7f9fa;
}

.lightgrey-border-color-c1 {
  border-color: #f7f9fa !important;
}
.lightgrey-border-color-c1.border {
  border-color: #f7f9fa !important;
}

.lightgrey-bg-c2 {
  background-color: #f5f6f7;
}

.lightgrey-color-c2 {
  color: #f5f6f7;
}

.lightgrey-border-color-c2 {
  border-color: #f5f6f7 !important;
}
.lightgrey-border-color-c2.border {
  border-color: #f5f6f7 !important;
}

:root {
  --blue: #0049ad;
  --indigo: #554dff;
  --purple: #9e99ff;
  --pink: #ffb3bf;
  --red: #f73e5a;
  --orange: #ff9900;
  --yellow: #ffda73;
  --green: #00cc85;
  --teal: #0bd9d2;
  --cyan: #00fff7;
  --white: white;
  --gray: #656566;
  --gray-dark: #323333;
  --gray-secondary: #5c6f82;
  --gray-tertiary: #5a768a;
  --gray-quaternary: #fcfdff;
  --primary: #0048ad;
  --secondary: #5c6f82;
  --success: #008758;
  --info: #979899;
  --warning: #a66300;
  --danger: #d9364f;
  --light: #e9e6f2;
  --dark: #17324d;
  --black: black;
  --white: white;
  --100: #e3e4e6;
  --200: #cacacc;
  --300: #b1b1b3;
  --400: #979899;
  --500: #7e7f80;
  --600: #656566;
  --700: #4c4c4d;
  --800: #323333;
  --900: #19191a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --font-family-sans-serif: "Titillium Web", Geneva, Tahoma, sans-serif;
  --font-family-monospace: "Roboto Mono", monospace;
  --footer-bg-color: "#30373c"
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #7e7f80;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid black;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: white !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #b1b1b3 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #d6dce3;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #d6dce3;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #19191a;
  text-align: left;
  background-color: white;
}

[tabindex="-1"]:focus:not(:focus-visible),
.form-check [type="checkbox"]:focus + label[tabindex="-1"]:not(:focus-visible),
.form-check [type="radio"]:focus + label[tabindex="-1"]:not(:focus-visible),
.toggles
  label
  input[type="checkbox"]:focus
  + .lever[tabindex="-1"]:not(:focus-visible),
.block-facet--checkbox
  [type="checkbox"]:focus
  + label[tabindex="-1"]:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 8px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0048ad;
  text-decoration: underline;
  background-color: transparent;
}
a:hover {
  color: #002861;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: "Roboto Mono", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1em;
  padding-bottom: 1em;
  color: #5a768a;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus,
.toggles label input[type="checkbox"]:focus + button.lever {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.display-2 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.display-3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.display-4 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.777rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 16px;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.777rem;
  color: #656566;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: white;
  border: 1px solid #b1b1b3;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 8px;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #656566;
}

code {
  font-size: 87.5%;
  color: #17324d;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: white;
  background-color: #19191a;
  border-radius: 2px;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #19191a;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1180px;
  }
}

@media (min-width: 1440px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1380px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1440px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 16px;
  color: #19191a;
}
.table th,
.table td {
  padding: 1em;
  vertical-align: top;
  border-top: 1px solid #d6dce3;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #d6dce3;
}
.table tbody + tbody {
  border-top: 2px solid #d6dce3;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #d6dce3;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #d6dce3;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f6f7f9;
}

.table-hover tbody tr:hover {
  color: #19191a;
  background-color: #e5f1fa;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8cce8;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7aa0d5;
}

.table-hover .table-primary:hover {
  background-color: #a5bee2;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a5bee2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d1d7dc;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #aab4be;
}

.table-hover .table-secondary:hover {
  background-color: #c3cad1;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c3cad1;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8ddd0;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ac1a8;
}

.table-hover .table-success:hover {
  background-color: #a7d5c5;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a7d5c5;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e2e2e2;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #c9c9ca;
}

.table-hover .table-info:hover {
  background-color: #d5d5d5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #d5d5d5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #e6d3b8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #d1ae7a;
}

.table-hover .table-warning:hover {
  background-color: #dfc7a5;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #dfc7a5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4c7ce;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eb97a3;
}

.table-hover .table-danger:hover {
  background-color: #f0b2bb;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f0b2bb;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9f8fb;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f2f8;
}

.table-hover .table-light:hover {
  background-color: #ebe8f2;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ebe8f2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bec6cd;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8694a2;
}

.table-hover .table-dark:hover {
  background-color: #b0b9c2;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b0b9c2;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-100,
.table-100 > th,
.table-100 > td {
  background-color: #f7f7f8;
}

.table-100 th,
.table-100 td,
.table-100 thead th,
.table-100 tbody + tbody {
  border-color: #f1f1f2;
}

.table-hover .table-100:hover {
  background-color: #e9e9ec;
}
.table-hover .table-100:hover > td,
.table-hover .table-100:hover > th {
  background-color: #e9e9ec;
}

.table-200,
.table-200 > th,
.table-200 > td {
  background-color: #f0f0f1;
}

.table-200 th,
.table-200 td,
.table-200 thead th,
.table-200 tbody + tbody {
  border-color: #e3e4e4;
}

.table-hover .table-200:hover {
  background-color: #e3e3e5;
}
.table-hover .table-200:hover > td,
.table-hover .table-200:hover > th {
  background-color: #e3e3e5;
}

.table-300,
.table-300 > th,
.table-300 > td {
  background-color: #e9e9ea;
}

.table-300 th,
.table-300 td,
.table-300 thead th,
.table-300 tbody + tbody {
  border-color: #d6d7d7;
}

.table-hover .table-300:hover {
  background-color: #dcdcde;
}
.table-hover .table-300:hover > td,
.table-hover .table-300:hover > th {
  background-color: #dcdcde;
}

.table-400,
.table-400 > th,
.table-400 > td {
  background-color: #e2e2e2;
}

.table-400 th,
.table-400 td,
.table-400 thead th,
.table-400 tbody + tbody {
  border-color: #c9c9ca;
}

.table-hover .table-400:hover {
  background-color: #d5d5d5;
}
.table-hover .table-400:hover > td,
.table-hover .table-400:hover > th {
  background-color: #d5d5d5;
}

.table-500,
.table-500 > th,
.table-500 > td {
  background-color: #dbdbdb;
}

.table-500 th,
.table-500 td,
.table-500 thead th,
.table-500 tbody + tbody {
  border-color: #bcbcbd;
}

.table-hover .table-500:hover {
  background-color: #cecece;
}
.table-hover .table-500:hover > td,
.table-hover .table-500:hover > th {
  background-color: #cecece;
}

.table-600,
.table-600 > th,
.table-600 > td {
  background-color: #d4d4d4;
}

.table-600 th,
.table-600 td,
.table-600 thead th,
.table-600 tbody + tbody {
  border-color: #afafaf;
}

.table-hover .table-600:hover {
  background-color: #c7c7c7;
}
.table-hover .table-600:hover > td,
.table-hover .table-600:hover > th {
  background-color: #c7c7c7;
}

.table-700,
.table-700 > th,
.table-700 > td {
  background-color: #cdcdcd;
}

.table-700 th,
.table-700 td,
.table-700 thead th,
.table-700 tbody + tbody {
  border-color: #a2a2a2;
}

.table-hover .table-700:hover {
  background-color: silver;
}
.table-hover .table-700:hover > td,
.table-hover .table-700:hover > th {
  background-color: silver;
}

.table-800,
.table-800 > th,
.table-800 > td {
  background-color: #c6c6c6;
}

.table-800 th,
.table-800 td,
.table-800 thead th,
.table-800 tbody + tbody {
  border-color: #959595;
}

.table-hover .table-800:hover {
  background-color: #b9b9b9;
}
.table-hover .table-800:hover > td,
.table-hover .table-800:hover > th {
  background-color: #b9b9b9;
}

.table-900,
.table-900 > th,
.table-900 > td {
  background-color: #bfbfbf;
}

.table-900 th,
.table-900 td,
.table-900 thead th,
.table-900 tbody + tbody {
  border-color: #888888;
}

.table-hover .table-900:hover {
  background-color: #b2b2b2;
}
.table-hover .table-900:hover > td,
.table-hover .table-900:hover > th {
  background-color: #b2b2b2;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #e5f1fa;
}

.table-hover .table-active:hover {
  background-color: #d0e5f6;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #d0e5f6;
}

.table .thead-dark th {
  color: white;
  background-color: #323333;
  border-color: #464646;
}

.table .thead-light th {
  color: #4c4c4d;
  background-color: #cacacc;
  border-color: #d6dce3;
}

.table-dark {
  color: white;
  background-color: #323333;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #464646;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #4c4c4d;
  background-color: white;
  background-clip: padding-box;
  border: 0 solid #979899;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4c4c4d;
}
.form-control:focus,
.form-check [type="checkbox"]:focus + label.form-control,
.form-check [type="radio"]:focus + label.form-control,
.toggles label input[type="checkbox"]:focus + .form-control.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.form-control {
  color: #4c4c4d;
  background-color: white;
  border-color: #5c6f82;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(0, 72, 173, 0.25);
}
.form-control::placeholder {
  color: #656566;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e6e9f2;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value,
.toggles
  label
  input[type="checkbox"]:focus
  + select.form-control.lever::-ms-value {
  color: #4c4c4d;
  background-color: white;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.6;
}

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.45;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #19191a;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.45em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.45;
  border-radius: 2px;
}

.form-control-lg {
  height: calc(1.6em + 1rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.6;
  border-radius: 8px;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 3rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #5a768a;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.777rem;
  color: #008758;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 1rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: white;
  background-color: #008758;
  border-radius: 4px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #008758;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23008758' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.was-validated .form-check [type="checkbox"]:focus + label.form-control:valid,
.form-check .was-validated [type="checkbox"]:focus + label.form-control:valid,
.was-validated .form-check [type="radio"]:focus + label.form-control:valid,
.form-check .was-validated [type="radio"]:focus + label.form-control:valid,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .form-control.lever:valid,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .form-control.lever:valid,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.form-control:valid,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.form-control:valid,
.form-control.is-valid:focus,
.form-check [type="checkbox"]:focus + label.form-control.is-valid,
.form-check [type="radio"]:focus + label.form-control.is-valid,
.toggles label input[type="checkbox"]:focus + .form-control.is-valid.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.form-control.is-valid {
  border-color: #008758;
  box-shadow: 0 0 0 0.2rem rgba(0, 135, 88, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #008758;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23323333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23008758' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      white no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus,
.was-validated .form-check [type="checkbox"]:focus + label.custom-select:valid,
.form-check .was-validated [type="checkbox"]:focus + label.custom-select:valid,
.was-validated .form-check [type="radio"]:focus + label.custom-select:valid,
.form-check .was-validated [type="radio"]:focus + label.custom-select:valid,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-select.lever:valid,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .custom-select.lever:valid,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-select:valid,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.custom-select:valid,
.custom-select.is-valid:focus,
.form-check [type="checkbox"]:focus + label.custom-select.is-valid,
.form-check [type="radio"]:focus + label.custom-select.is-valid,
.toggles label input[type="checkbox"]:focus + .custom-select.is-valid.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.custom-select.is-valid {
  border-color: #008758;
  box-shadow: 0 0 0 0.2rem rgba(0, 135, 88, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #008758;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #008758;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #008758;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00ba79;
  background-color: #00ba79;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.was-validated
  .form-check
  [type="checkbox"]:focus
  + label.custom-control-input:valid
  ~ .custom-control-label::before,
.form-check
  .was-validated
  [type="checkbox"]:focus
  + label.custom-control-input:valid
  ~ .custom-control-label::before,
.was-validated
  .form-check
  [type="radio"]:focus
  + label.custom-control-input:valid
  ~ .custom-control-label::before,
.form-check
  .was-validated
  [type="radio"]:focus
  + label.custom-control-input:valid
  ~ .custom-control-label::before,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-control-input.lever:valid
  ~ .custom-control-label::before,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .custom-control-input.lever:valid
  ~ .custom-control-label::before,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-control-input:valid
  ~ .custom-control-label::before,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.custom-control-input:valid
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.form-check
  [type="checkbox"]:focus
  + label.custom-control-input.is-valid
  ~ .custom-control-label::before,
.form-check
  [type="radio"]:focus
  + label.custom-control-input.is-valid
  ~ .custom-control-label::before,
.toggles
  label
  input[type="checkbox"]:focus
  + .custom-control-input.is-valid.lever
  ~ .custom-control-label::before,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-control-input.is-valid
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 135, 88, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .form-check
  [type="checkbox"]:focus
  + label.custom-control-input:valid:not(:checked)
  ~ .custom-control-label::before,
.form-check
  .was-validated
  [type="checkbox"]:focus
  + label.custom-control-input:valid:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .form-check
  [type="radio"]:focus
  + label.custom-control-input:valid:not(:checked)
  ~ .custom-control-label::before,
.form-check
  .was-validated
  [type="radio"]:focus
  + label.custom-control-input:valid:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-control-input.lever:valid:not(:checked)
  ~ .custom-control-label::before,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .custom-control-input.lever:valid:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-control-input:valid:not(:checked)
  ~ .custom-control-label::before,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.custom-control-input:valid:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.form-check
  [type="checkbox"]:focus
  + label.custom-control-input.is-valid:not(:checked)
  ~ .custom-control-label::before,
.form-check
  [type="radio"]:focus
  + label.custom-control-input.is-valid:not(:checked)
  ~ .custom-control-label::before,
.toggles
  label
  input[type="checkbox"]:focus
  + .custom-control-input.is-valid.lever:not(:checked)
  ~ .custom-control-label::before,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-control-input.is-valid:not(:checked)
  ~ .custom-control-label::before {
  border-color: #008758;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #008758;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.was-validated
  .form-check
  [type="checkbox"]:focus
  + label.custom-file-input:valid
  ~ .custom-file-label,
.form-check
  .was-validated
  [type="checkbox"]:focus
  + label.custom-file-input:valid
  ~ .custom-file-label,
.was-validated
  .form-check
  [type="radio"]:focus
  + label.custom-file-input:valid
  ~ .custom-file-label,
.form-check
  .was-validated
  [type="radio"]:focus
  + label.custom-file-input:valid
  ~ .custom-file-label,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-file-input.lever:valid
  ~ .custom-file-label,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .custom-file-input.lever:valid
  ~ .custom-file-label,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-file-input:valid
  ~ .custom-file-label,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.custom-file-input:valid
  ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label,
.form-check
  [type="checkbox"]:focus
  + label.custom-file-input.is-valid
  ~ .custom-file-label,
.form-check
  [type="radio"]:focus
  + label.custom-file-input.is-valid
  ~ .custom-file-label,
.toggles
  label
  input[type="checkbox"]:focus
  + .custom-file-input.is-valid.lever
  ~ .custom-file-label,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-file-input.is-valid
  ~ .custom-file-label {
  border-color: #008758;
  box-shadow: 0 0 0 0.2rem rgba(0, 135, 88, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.777rem;
  color: #d9364f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 1rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: white;
  background-color: #d9364f;
  border-radius: 4px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #d9364f;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9364f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9364f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.was-validated .form-check [type="checkbox"]:focus + label.form-control:invalid,
.form-check .was-validated [type="checkbox"]:focus + label.form-control:invalid,
.was-validated .form-check [type="radio"]:focus + label.form-control:invalid,
.form-check .was-validated [type="radio"]:focus + label.form-control:invalid,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .form-control.lever:invalid,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .form-control.lever:invalid,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.form-control:invalid,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.form-control:invalid,
.form-control.is-invalid:focus,
.form-check [type="checkbox"]:focus + label.form-control.is-invalid,
.form-check [type="radio"]:focus + label.form-control.is-invalid,
.toggles label input[type="checkbox"]:focus + .form-control.is-invalid.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.form-control.is-invalid {
  border-color: #d9364f;
  box-shadow: 0 0 0 0.2rem rgba(217, 54, 79, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #d9364f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23323333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9364f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9364f' stroke='none'/%3e%3c/svg%3e")
      white no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus,
.was-validated
  .form-check
  [type="checkbox"]:focus
  + label.custom-select:invalid,
.form-check
  .was-validated
  [type="checkbox"]:focus
  + label.custom-select:invalid,
.was-validated .form-check [type="radio"]:focus + label.custom-select:invalid,
.form-check .was-validated [type="radio"]:focus + label.custom-select:invalid,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-select.lever:invalid,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .custom-select.lever:invalid,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-select:invalid,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.custom-select:invalid,
.custom-select.is-invalid:focus,
.form-check [type="checkbox"]:focus + label.custom-select.is-invalid,
.form-check [type="radio"]:focus + label.custom-select.is-invalid,
.toggles label input[type="checkbox"]:focus + .custom-select.is-invalid.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-select.is-invalid {
  border-color: #d9364f;
  box-shadow: 0 0 0 0.2rem rgba(217, 54, 79, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #d9364f;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9364f;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #d9364f;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e16174;
  background-color: #e16174;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.was-validated
  .form-check
  [type="checkbox"]:focus
  + label.custom-control-input:invalid
  ~ .custom-control-label::before,
.form-check
  .was-validated
  [type="checkbox"]:focus
  + label.custom-control-input:invalid
  ~ .custom-control-label::before,
.was-validated
  .form-check
  [type="radio"]:focus
  + label.custom-control-input:invalid
  ~ .custom-control-label::before,
.form-check
  .was-validated
  [type="radio"]:focus
  + label.custom-control-input:invalid
  ~ .custom-control-label::before,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-control-input.lever:invalid
  ~ .custom-control-label::before,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .custom-control-input.lever:invalid
  ~ .custom-control-label::before,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-control-input:invalid
  ~ .custom-control-label::before,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.custom-control-input:invalid
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.form-check
  [type="checkbox"]:focus
  + label.custom-control-input.is-invalid
  ~ .custom-control-label::before,
.form-check
  [type="radio"]:focus
  + label.custom-control-input.is-invalid
  ~ .custom-control-label::before,
.toggles
  label
  input[type="checkbox"]:focus
  + .custom-control-input.is-invalid.lever
  ~ .custom-control-label::before,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-control-input.is-invalid
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 54, 79, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .form-check
  [type="checkbox"]:focus
  + label.custom-control-input:invalid:not(:checked)
  ~ .custom-control-label::before,
.form-check
  .was-validated
  [type="checkbox"]:focus
  + label.custom-control-input:invalid:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .form-check
  [type="radio"]:focus
  + label.custom-control-input:invalid:not(:checked)
  ~ .custom-control-label::before,
.form-check
  .was-validated
  [type="radio"]:focus
  + label.custom-control-input:invalid:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-control-input.lever:invalid:not(:checked)
  ~ .custom-control-label::before,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .custom-control-input.lever:invalid:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-control-input:invalid:not(:checked)
  ~ .custom-control-label::before,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.custom-control-input:invalid:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.form-check
  [type="checkbox"]:focus
  + label.custom-control-input.is-invalid:not(:checked)
  ~ .custom-control-label::before,
.form-check
  [type="radio"]:focus
  + label.custom-control-input.is-invalid:not(:checked)
  ~ .custom-control-label::before,
.toggles
  label
  input[type="checkbox"]:focus
  + .custom-control-input.is-invalid.lever:not(:checked)
  ~ .custom-control-label::before,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-control-input.is-invalid:not(:checked)
  ~ .custom-control-label::before {
  border-color: #d9364f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9364f;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.was-validated
  .form-check
  [type="checkbox"]:focus
  + label.custom-file-input:invalid
  ~ .custom-file-label,
.form-check
  .was-validated
  [type="checkbox"]:focus
  + label.custom-file-input:invalid
  ~ .custom-file-label,
.was-validated
  .form-check
  [type="radio"]:focus
  + label.custom-file-input:invalid
  ~ .custom-file-label,
.form-check
  .was-validated
  [type="radio"]:focus
  + label.custom-file-input:invalid
  ~ .custom-file-label,
.was-validated
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-file-input.lever:invalid
  ~ .custom-file-label,
.toggles
  label
  .was-validated
  input[type="checkbox"]:focus
  + .custom-file-input.lever:invalid
  ~ .custom-file-label,
.was-validated
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-file-input:invalid
  ~ .custom-file-label,
.block-facet--checkbox
  .was-validated
  [type="checkbox"]:focus
  + label.custom-file-input:invalid
  ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.form-check
  [type="checkbox"]:focus
  + label.custom-file-input.is-invalid
  ~ .custom-file-label,
.form-check
  [type="radio"]:focus
  + label.custom-file-input.is-invalid
  ~ .custom-file-label,
.toggles
  label
  input[type="checkbox"]:focus
  + .custom-file-input.is-invalid.lever
  ~ .custom-file-label,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-file-input.is-invalid
  ~ .custom-file-label {
  border-color: #d9364f;
  box-shadow: 0 0 0 0.2rem rgba(217, 54, 79, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  color: #19191a;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #19191a;
  text-decoration: none;
}
.btn:focus,
.form-check [type="checkbox"]:focus + label.btn,
.form-check [type="radio"]:focus + label.btn,
.toggles label input[type="checkbox"]:focus + .btn.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 72, 173, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn:not(:disabled):not(.disabled).active {
  box-shadow: 0 0 0 0.2rem rgba(0, 72, 173, 0.25),
    inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: white;
  background-color: #0048ad;
  border-color: #0048ad;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
  color: white;
  background-color: #003887;
  border-color: #00337a;
}
.btn-primary:focus,
.form-check [type="checkbox"]:focus + label.btn-primary,
.form-check [type="radio"]:focus + label.btn-primary,
.toggles label input[type="checkbox"]:focus + .btn-primary.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-primary,
.btn-primary.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 72, 173, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: white;
  background-color: #0048ad;
  border-color: #0048ad;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: white;
  background-color: #00337a;
  border-color: #002e6e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-primary:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-primary.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-primary:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-primary.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-primary.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-primary.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-primary.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-primary.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(0, 72, 173, 0.5);
}

.btn-secondary {
  color: white;
  background-color: #5c6f82;
  border-color: #5c6f82;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-secondary:hover {
  color: white;
  background-color: #4c5c6c;
  border-color: #475664;
}
.btn-secondary:focus,
.form-check [type="checkbox"]:focus + label.btn-secondary,
.form-check [type="radio"]:focus + label.btn-secondary,
.toggles label input[type="checkbox"]:focus + .btn-secondary.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-secondary,
.btn-secondary.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(92, 111, 130, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: white;
  background-color: #5c6f82;
  border-color: #5c6f82;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: white;
  background-color: #475664;
  border-color: #424f5d;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-secondary.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-secondary.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-secondary.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-secondary.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-secondary.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-secondary.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(92, 111, 130, 0.5);
}

.btn-success {
  color: white;
  background-color: #008758;
  border-color: #008758;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-success:hover {
  color: white;
  background-color: #00613f;
  border-color: #005437;
}
.btn-success:focus,
.form-check [type="checkbox"]:focus + label.btn-success,
.form-check [type="radio"]:focus + label.btn-success,
.toggles label input[type="checkbox"]:focus + .btn-success.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-success,
.btn-success.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 135, 88, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: white;
  background-color: #008758;
  border-color: #008758;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: white;
  background-color: #005437;
  border-color: #00472e;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-success:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-success:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-success.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-success:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-success:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-success.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-success.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-success.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-success.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-success.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(0, 135, 88, 0.5);
}

.btn-info {
  color: black;
  background-color: #979899;
  border-color: #979899;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-info:hover {
  color: black;
  background-color: #848586;
  border-color: #7e7e80;
}
.btn-info:focus,
.form-check [type="checkbox"]:focus + label.btn-info,
.form-check [type="radio"]:focus + label.btn-info,
.toggles label input[type="checkbox"]:focus + .btn-info.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-info,
.btn-info.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: black;
  background-color: #979899;
  border-color: #979899;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: black;
  background-color: #7e7e80;
  border-color: #777879;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-info:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-info:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-info.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-info:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-info:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-info.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-info.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-info.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-info.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-info.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}

.btn-warning {
  color: white;
  background-color: #a66300;
  border-color: #a66300;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-warning:hover {
  color: white;
  background-color: #804d00;
  border-color: #734500;
}
.btn-warning:focus,
.form-check [type="checkbox"]:focus + label.btn-warning,
.form-check [type="radio"]:focus + label.btn-warning,
.toggles label input[type="checkbox"]:focus + .btn-warning.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-warning,
.btn-warning.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(166, 99, 0, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: white;
  background-color: #a66300;
  border-color: #a66300;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: white;
  background-color: #734500;
  border-color: #663d00;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-warning:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-warning:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-warning.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-warning:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-warning:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-warning.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-warning.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-warning.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-warning.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-warning.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(166, 99, 0, 0.5);
}

.btn-danger {
  color: white;
  background-color: #d9364f;
  border-color: #d9364f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-danger:hover {
  color: white;
  background-color: #c3253d;
  border-color: #b9233a;
}
.btn-danger:focus,
.form-check [type="checkbox"]:focus + label.btn-danger,
.form-check [type="radio"]:focus + label.btn-danger,
.toggles label input[type="checkbox"]:focus + .btn-danger.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-danger,
.btn-danger.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(217, 54, 79, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: white;
  background-color: #d9364f;
  border-color: #d9364f;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: white;
  background-color: #b9233a;
  border-color: #ae2136;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-danger:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-danger:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-danger.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-danger:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-danger:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-danger.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-danger.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-danger.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-danger.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-danger.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(217, 54, 79, 0.5);
}

.btn-light {
  color: black;
  background-color: #e9e6f2;
  border-color: #e9e6f2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-light:hover {
  color: black;
  background-color: #d3cde5;
  border-color: #ccc4e1;
}
.btn-light:focus,
.form-check [type="checkbox"]:focus + label.btn-light,
.form-check [type="radio"]:focus + label.btn-light,
.toggles label input[type="checkbox"]:focus + .btn-light.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-light,
.btn-light.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 230, 242, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: black;
  background-color: #e9e6f2;
  border-color: #e9e6f2;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: black;
  background-color: #ccc4e1;
  border-color: #c4bcdd;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-light:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-light:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-light.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-light:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-light:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-light.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-light.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-light.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-light.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-light.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(233, 230, 242, 0.5);
}

.btn-dark {
  color: white;
  background-color: #17324d;
  border-color: #17324d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-dark:hover {
  color: white;
  background-color: #0e1f2f;
  border-color: #0b1825;
}
.btn-dark:focus,
.form-check [type="checkbox"]:focus + label.btn-dark,
.form-check [type="radio"]:focus + label.btn-dark,
.toggles label input[type="checkbox"]:focus + .btn-dark.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-dark,
.btn-dark.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(23, 50, 77, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: white;
  background-color: #17324d;
  border-color: #17324d;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: white;
  background-color: #0b1825;
  border-color: #08121b;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-dark:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-dark:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-dark.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-dark:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-dark:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-dark.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-dark.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-dark.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-dark.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-dark.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(23, 50, 77, 0.5);
}

.btn-black {
  color: white;
  background-color: black;
  border-color: black;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-black:hover {
  color: white;
  background-color: black;
  border-color: black;
}
.btn-black:focus,
.form-check [type="checkbox"]:focus + label.btn-black,
.form-check [type="radio"]:focus + label.btn-black,
.toggles label input[type="checkbox"]:focus + .btn-black.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-black,
.btn-black.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-black.disabled,
.btn-black:disabled {
  color: white;
  background-color: black;
  border-color: black;
}
.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle {
  color: white;
  background-color: black;
  border-color: black;
}
.btn-black:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-black:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-black:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-black.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-black:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-black:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-black.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-black:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-black.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-black.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-black.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-black.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-white {
  color: black;
  background-color: white;
  border-color: white;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-white:hover {
  color: black;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white:focus,
.form-check [type="checkbox"]:focus + label.btn-white,
.form-check [type="radio"]:focus + label.btn-white,
.toggles label input[type="checkbox"]:focus + .btn-white.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-white,
.btn-white.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-white.disabled,
.btn-white:disabled {
  color: black;
  background-color: white;
  border-color: white;
}
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: black;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-white:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-white:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-white.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-white:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-white:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-white.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-white.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-white.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-white.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-white.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-100 {
  color: black;
  background-color: #e3e4e6;
  border-color: #e3e4e6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-100:hover {
  color: black;
  background-color: #cfd0d3;
  border-color: #c9cacd;
}
.btn-100:focus,
.form-check [type="checkbox"]:focus + label.btn-100,
.form-check [type="radio"]:focus + label.btn-100,
.toggles label input[type="checkbox"]:focus + .btn-100.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-100,
.btn-100.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(227, 228, 230, 0.5);
}
.btn-100.disabled,
.btn-100:disabled {
  color: black;
  background-color: #e3e4e6;
  border-color: #e3e4e6;
}
.btn-100:not(:disabled):not(.disabled):active,
.btn-100:not(:disabled):not(.disabled).active,
.show > .btn-100.dropdown-toggle {
  color: black;
  background-color: #c9cacd;
  border-color: #c2c3c7;
}
.btn-100:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-100:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-100:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-100.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-100:not(:disabled):not(.disabled):active,
.btn-100:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-100:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-100:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-100.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-100:not(:disabled):not(.disabled).active,
.show > .btn-100.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-100.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-100.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-100.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-100.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(227, 228, 230, 0.5);
}

.btn-200 {
  color: black;
  background-color: #cacacc;
  border-color: #cacacc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-200:hover {
  color: black;
  background-color: #b6b7b9;
  border-color: #b0b1b3;
}
.btn-200:focus,
.form-check [type="checkbox"]:focus + label.btn-200,
.form-check [type="radio"]:focus + label.btn-200,
.toggles label input[type="checkbox"]:focus + .btn-200.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-200,
.btn-200.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(202, 202, 204, 0.5);
}
.btn-200.disabled,
.btn-200:disabled {
  color: black;
  background-color: #cacacc;
  border-color: #cacacc;
}
.btn-200:not(:disabled):not(.disabled):active,
.btn-200:not(:disabled):not(.disabled).active,
.show > .btn-200.dropdown-toggle {
  color: black;
  background-color: #b0b1b3;
  border-color: #a9aaad;
}
.btn-200:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-200:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-200:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-200.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-200:not(:disabled):not(.disabled):active,
.btn-200:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-200:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-200:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-200.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-200:not(:disabled):not(.disabled).active,
.show > .btn-200.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-200.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-200.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-200.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-200.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(202, 202, 204, 0.5);
}

.btn-300 {
  color: black;
  background-color: #b1b1b3;
  border-color: #b1b1b3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-300:hover {
  color: black;
  background-color: #9d9ea0;
  border-color: #979899;
}
.btn-300:focus,
.form-check [type="checkbox"]:focus + label.btn-300,
.form-check [type="radio"]:focus + label.btn-300,
.toggles label input[type="checkbox"]:focus + .btn-300.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-300,
.btn-300.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(177, 177, 179, 0.5);
}
.btn-300.disabled,
.btn-300:disabled {
  color: black;
  background-color: #b1b1b3;
  border-color: #b1b1b3;
}
.btn-300:not(:disabled):not(.disabled):active,
.btn-300:not(:disabled):not(.disabled).active,
.show > .btn-300.dropdown-toggle {
  color: black;
  background-color: #979899;
  border-color: #909193;
}
.btn-300:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-300:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-300:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-300.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-300:not(:disabled):not(.disabled):active,
.btn-300:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-300:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-300:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-300.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-300:not(:disabled):not(.disabled).active,
.show > .btn-300.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-300.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-300.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-300.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-300.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(177, 177, 179, 0.5);
}

.btn-400 {
  color: black;
  background-color: #979899;
  border-color: #979899;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-400:hover {
  color: black;
  background-color: #848586;
  border-color: #7e7e80;
}
.btn-400:focus,
.form-check [type="checkbox"]:focus + label.btn-400,
.form-check [type="radio"]:focus + label.btn-400,
.toggles label input[type="checkbox"]:focus + .btn-400.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-400,
.btn-400.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}
.btn-400.disabled,
.btn-400:disabled {
  color: black;
  background-color: #979899;
  border-color: #979899;
}
.btn-400:not(:disabled):not(.disabled):active,
.btn-400:not(:disabled):not(.disabled).active,
.show > .btn-400.dropdown-toggle {
  color: black;
  background-color: #7e7e80;
  border-color: #777879;
}
.btn-400:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-400:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-400:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-400.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-400:not(:disabled):not(.disabled):active,
.btn-400:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-400:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-400:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-400.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-400:not(:disabled):not(.disabled).active,
.show > .btn-400.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-400.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-400.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-400.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-400.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}

.btn-500 {
  color: black;
  background-color: #7e7f80;
  border-color: #7e7f80;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-500:hover {
  color: white;
  background-color: #6b6b6c;
  border-color: #656566;
}
.btn-500:focus,
.form-check [type="checkbox"]:focus + label.btn-500,
.form-check [type="radio"]:focus + label.btn-500,
.toggles label input[type="checkbox"]:focus + .btn-500.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-500,
.btn-500.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(126, 127, 128, 0.5);
}
.btn-500.disabled,
.btn-500:disabled {
  color: black;
  background-color: #7e7f80;
  border-color: #7e7f80;
}
.btn-500:not(:disabled):not(.disabled):active,
.btn-500:not(:disabled):not(.disabled).active,
.show > .btn-500.dropdown-toggle {
  color: white;
  background-color: #656566;
  border-color: #5f5f5f;
}
.btn-500:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-500:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-500:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-500.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-500:not(:disabled):not(.disabled):active,
.btn-500:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-500:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-500:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-500.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-500:not(:disabled):not(.disabled).active,
.show > .btn-500.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-500.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-500.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-500.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-500.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(126, 127, 128, 0.5);
}

.btn-600 {
  color: white;
  background-color: #656566;
  border-color: #656566;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-600:hover {
  color: white;
  background-color: #525253;
  border-color: #4c4c4c;
}
.btn-600:focus,
.form-check [type="checkbox"]:focus + label.btn-600,
.form-check [type="radio"]:focus + label.btn-600,
.toggles label input[type="checkbox"]:focus + .btn-600.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-600,
.btn-600.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(101, 101, 102, 0.5);
}
.btn-600.disabled,
.btn-600:disabled {
  color: white;
  background-color: #656566;
  border-color: #656566;
}
.btn-600:not(:disabled):not(.disabled):active,
.btn-600:not(:disabled):not(.disabled).active,
.show > .btn-600.dropdown-toggle {
  color: white;
  background-color: #4c4c4c;
  border-color: #454546;
}
.btn-600:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-600:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-600:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-600.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-600:not(:disabled):not(.disabled):active,
.btn-600:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-600:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-600:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-600.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-600:not(:disabled):not(.disabled).active,
.show > .btn-600.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-600.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-600.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-600.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-600.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(101, 101, 102, 0.5);
}

.btn-700 {
  color: white;
  background-color: #4c4c4d;
  border-color: #4c4c4d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-700:hover {
  color: white;
  background-color: #393939;
  border-color: #323233;
}
.btn-700:focus,
.form-check [type="checkbox"]:focus + label.btn-700,
.form-check [type="radio"]:focus + label.btn-700,
.toggles label input[type="checkbox"]:focus + .btn-700.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-700,
.btn-700.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 76, 77, 0.5);
}
.btn-700.disabled,
.btn-700:disabled {
  color: white;
  background-color: #4c4c4d;
  border-color: #4c4c4d;
}
.btn-700:not(:disabled):not(.disabled):active,
.btn-700:not(:disabled):not(.disabled).active,
.show > .btn-700.dropdown-toggle {
  color: white;
  background-color: #323233;
  border-color: #2c2c2c;
}
.btn-700:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-700:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-700:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-700.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-700:not(:disabled):not(.disabled):active,
.btn-700:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-700:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-700:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-700.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-700:not(:disabled):not(.disabled).active,
.show > .btn-700.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-700.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-700.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-700.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-700.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(76, 76, 77, 0.5);
}

.btn-800 {
  color: white;
  background-color: #323333;
  border-color: #323333;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-800:hover {
  color: white;
  background-color: #1f2020;
  border-color: #191919;
}
.btn-800:focus,
.form-check [type="checkbox"]:focus + label.btn-800,
.form-check [type="radio"]:focus + label.btn-800,
.toggles label input[type="checkbox"]:focus + .btn-800.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-800,
.btn-800.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(50, 51, 51, 0.5);
}
.btn-800.disabled,
.btn-800:disabled {
  color: white;
  background-color: #323333;
  border-color: #323333;
}
.btn-800:not(:disabled):not(.disabled):active,
.btn-800:not(:disabled):not(.disabled).active,
.show > .btn-800.dropdown-toggle {
  color: white;
  background-color: #191919;
  border-color: #131313;
}
.btn-800:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-800:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-800:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-800.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-800:not(:disabled):not(.disabled):active,
.btn-800:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-800:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-800:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-800.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-800:not(:disabled):not(.disabled).active,
.show > .btn-800.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-800.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-800.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-800.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-800.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(50, 51, 51, 0.5);
}

.btn-900 {
  color: white;
  background-color: #19191a;
  border-color: #19191a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-900:hover {
  color: white;
  background-color: #060606;
  border-color: black;
}
.btn-900:focus,
.form-check [type="checkbox"]:focus + label.btn-900,
.form-check [type="radio"]:focus + label.btn-900,
.toggles label input[type="checkbox"]:focus + .btn-900.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-900,
.btn-900.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(25, 25, 26, 0.5);
}
.btn-900.disabled,
.btn-900:disabled {
  color: white;
  background-color: #19191a;
  border-color: #19191a;
}
.btn-900:not(:disabled):not(.disabled):active,
.btn-900:not(:disabled):not(.disabled).active,
.show > .btn-900.dropdown-toggle {
  color: white;
  background-color: black;
  border-color: black;
}
.btn-900:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-900:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-900:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-900.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-900:not(:disabled):not(.disabled):active,
.btn-900:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-900:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-900:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-900.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-900:not(:disabled):not(.disabled).active,
.show > .btn-900.dropdown-toggle:focus,
.form-check .show > [type="checkbox"]:focus + label.btn-900.dropdown-toggle,
.form-check .show > [type="radio"]:focus + label.btn-900.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-900.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-900.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(25, 25, 26, 0.5);
}

.btn-outline-primary {
  color: #0048ad;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #0048ad;
}
.btn-outline-primary:hover {
  color: #063474;
  box-shadow: inset 0 0 0 1px #063474;
}
.btn-outline-primary:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-primary,
.form-check [type="radio"]:focus + label.btn-outline-primary,
.toggles label input[type="checkbox"]:focus + .btn-outline-primary.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-primary,
.btn-outline-primary.focus {
  box-shadow: inset 0 0 0 1px #0048ad, 0 0 0 0.2rem rgba(0, 72, 173, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #0048ad;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #0048ad;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #0048ad;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-primary.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-primary.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-primary.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-primary.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(0, 72, 173, 0.5);
}

.btn-outline-secondary {
  color: #5c6f82;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #5c6f82;
}
.btn-outline-secondary:hover {
  color: #50565c;
  box-shadow: inset 0 0 0 1px #50565c;
}
.btn-outline-secondary:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-secondary,
.form-check [type="radio"]:focus + label.btn-outline-secondary,
.toggles label input[type="checkbox"]:focus + .btn-outline-secondary.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-secondary,
.btn-outline-secondary.focus {
  box-shadow: inset 0 0 0 1px #5c6f82, 0 0 0 0.2rem rgba(92, 111, 130, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #5c6f82;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #5c6f82;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #5c6f82;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-secondary.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-secondary.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-secondary.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-secondary.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(92, 111, 130, 0.5);
}

.btn-outline-success {
  color: #008758;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #008758;
}
.btn-outline-success:hover {
  color: #045035;
  box-shadow: inset 0 0 0 1px #045035;
}
.btn-outline-success:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-success,
.form-check [type="radio"]:focus + label.btn-outline-success,
.toggles label input[type="checkbox"]:focus + .btn-outline-success.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-success,
.btn-outline-success.focus {
  box-shadow: inset 0 0 0 1px #008758, 0 0 0 0.2rem rgba(0, 135, 88, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #008758;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #008758;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #008758;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-success:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-success:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-success.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-success:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-success:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-success.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-success.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-success.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-success.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-success.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(0, 135, 88, 0.5);
}

.btn-outline-info {
  color: #979899;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #979899;
}
.btn-outline-info:hover {
  color: #7f7f7f;
  box-shadow: inset 0 0 0 1px #7f7f7f;
}
.btn-outline-info:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-info,
.form-check [type="radio"]:focus + label.btn-outline-info,
.toggles label input[type="checkbox"]:focus + .btn-outline-info.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-info,
.btn-outline-info.focus {
  box-shadow: inset 0 0 0 1px #979899, 0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #979899;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #979899;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #979899;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-info:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-info:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-info.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-info:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-info:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-info.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-info.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-info.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-info.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-info.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}

.btn-outline-warning {
  color: #a66300;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #a66300;
}
.btn-outline-warning:hover {
  color: #6d4406;
  box-shadow: inset 0 0 0 1px #6d4406;
}
.btn-outline-warning:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-warning,
.form-check [type="radio"]:focus + label.btn-outline-warning,
.toggles label input[type="checkbox"]:focus + .btn-outline-warning.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-warning,
.btn-outline-warning.focus {
  box-shadow: inset 0 0 0 1px #a66300, 0 0 0 0.2rem rgba(166, 99, 0, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #a66300;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #a66300;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #a66300;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-warning:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-warning:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-warning.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-warning:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-warning:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-warning.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-warning.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-warning.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-warning.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-warning.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(166, 99, 0, 0.5);
}

.btn-outline-danger {
  color: #d9364f;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #d9364f;
}
.btn-outline-danger:hover {
  color: #ae2e41;
  box-shadow: inset 0 0 0 1px #ae2e41;
}
.btn-outline-danger:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-danger,
.form-check [type="radio"]:focus + label.btn-outline-danger,
.toggles label input[type="checkbox"]:focus + .btn-outline-danger.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-danger,
.btn-outline-danger.focus {
  box-shadow: inset 0 0 0 1px #d9364f, 0 0 0 0.2rem rgba(217, 54, 79, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #d9364f;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #d9364f;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #d9364f;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-danger:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-danger:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-danger.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-danger:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-danger:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-danger.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-danger.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-danger.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-danger.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-danger.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(217, 54, 79, 0.5);
}

.btn-outline-light {
  color: #e9e6f2;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #e9e6f2;
}
.btn-outline-light:hover {
  color: #cec9dd;
  box-shadow: inset 0 0 0 1px #cec9dd;
}
.btn-outline-light:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-light,
.form-check [type="radio"]:focus + label.btn-outline-light,
.toggles label input[type="checkbox"]:focus + .btn-outline-light.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-light,
.btn-outline-light.focus {
  box-shadow: inset 0 0 0 1px #e9e6f2, 0 0 0 0.2rem rgba(233, 230, 242, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #e9e6f2;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #e9e6f2;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #e9e6f2;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-light:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-light:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-light.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-light:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-light:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-light.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-light.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-light.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-light.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-light.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(233, 230, 242, 0.5);
}

.btn-outline-dark {
  color: #17324d;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #17324d;
}
.btn-outline-dark:hover {
  color: #0e1823;
  box-shadow: inset 0 0 0 1px #0e1823;
}
.btn-outline-dark:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-dark,
.form-check [type="radio"]:focus + label.btn-outline-dark,
.toggles label input[type="checkbox"]:focus + .btn-outline-dark.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-dark,
.btn-outline-dark.focus {
  box-shadow: inset 0 0 0 1px #17324d, 0 0 0 0.2rem rgba(23, 50, 77, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #17324d;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #17324d;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #17324d;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-dark:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-dark:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-dark.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-dark:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-dark:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-dark.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-dark.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-dark.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-dark.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-dark.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(23, 50, 77, 0.5);
}

.btn-outline-black {
  color: black;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px black;
}
.btn-outline-black:hover {
  color: black;
  box-shadow: inset 0 0 0 1px black;
}
.btn-outline-black:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-black,
.form-check [type="radio"]:focus + label.btn-outline-black,
.toggles label input[type="checkbox"]:focus + .btn-outline-black.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-black,
.btn-outline-black.focus {
  box-shadow: inset 0 0 0 1px black, 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-black.disabled,
.btn-outline-black:disabled {
  color: black;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black.dropdown-toggle {
  color: black;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px black;
}
.btn-outline-black:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-black:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-black:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-black.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-outline-black:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-black:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-black:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-black.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-black.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-black.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-black.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-black.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-white {
  color: white;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px white;
}
.btn-outline-white:hover {
  color: #e6e6e6;
  box-shadow: inset 0 0 0 1px #e6e6e6;
}
.btn-outline-white:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-white,
.form-check [type="radio"]:focus + label.btn-outline-white,
.toggles label input[type="checkbox"]:focus + .btn-outline-white.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-white,
.btn-outline-white.focus {
  box-shadow: inset 0 0 0 1px white, 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: white;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: white;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px white;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-white:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-white:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-white.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-white:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-white:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-white.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-white.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-white.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-white.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-white.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-100 {
  color: #e3e4e6;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #e3e4e6;
}
.btn-outline-100:hover {
  color: #cbcbcb;
  box-shadow: inset 0 0 0 1px #cbcbcb;
}
.btn-outline-100:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-100,
.form-check [type="radio"]:focus + label.btn-outline-100,
.toggles label input[type="checkbox"]:focus + .btn-outline-100.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-100,
.btn-outline-100.focus {
  box-shadow: inset 0 0 0 1px #e3e4e6, 0 0 0 0.2rem rgba(227, 228, 230, 0.5);
}
.btn-outline-100.disabled,
.btn-outline-100:disabled {
  color: #e3e4e6;
  background-color: transparent;
}
.btn-outline-100:not(:disabled):not(.disabled):active,
.btn-outline-100:not(:disabled):not(.disabled).active,
.show > .btn-outline-100.dropdown-toggle {
  color: #e3e4e6;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #e3e4e6;
}
.btn-outline-100:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-100:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-100:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-100.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-100:not(:disabled):not(.disabled):active,
.btn-outline-100:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-100:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-100:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-100.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-100:not(:disabled):not(.disabled).active,
.show > .btn-outline-100.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-100.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-100.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-100.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-100.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(227, 228, 230, 0.5);
}

.btn-outline-200 {
  color: #cacacc;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #cacacc;
}
.btn-outline-200:hover {
  color: #b1b1b1;
  box-shadow: inset 0 0 0 1px #b1b1b1;
}
.btn-outline-200:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-200,
.form-check [type="radio"]:focus + label.btn-outline-200,
.toggles label input[type="checkbox"]:focus + .btn-outline-200.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-200,
.btn-outline-200.focus {
  box-shadow: inset 0 0 0 1px #cacacc, 0 0 0 0.2rem rgba(202, 202, 204, 0.5);
}
.btn-outline-200.disabled,
.btn-outline-200:disabled {
  color: #cacacc;
  background-color: transparent;
}
.btn-outline-200:not(:disabled):not(.disabled):active,
.btn-outline-200:not(:disabled):not(.disabled).active,
.show > .btn-outline-200.dropdown-toggle {
  color: #cacacc;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #cacacc;
}
.btn-outline-200:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-200:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-200:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-200.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-200:not(:disabled):not(.disabled):active,
.btn-outline-200:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-200:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-200:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-200.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-200:not(:disabled):not(.disabled).active,
.show > .btn-outline-200.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-200.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-200.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-200.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-200.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(202, 202, 204, 0.5);
}

.btn-outline-300 {
  color: #b1b1b3;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #b1b1b3;
}
.btn-outline-300:hover {
  color: #989898;
  box-shadow: inset 0 0 0 1px #989898;
}
.btn-outline-300:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-300,
.form-check [type="radio"]:focus + label.btn-outline-300,
.toggles label input[type="checkbox"]:focus + .btn-outline-300.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-300,
.btn-outline-300.focus {
  box-shadow: inset 0 0 0 1px #b1b1b3, 0 0 0 0.2rem rgba(177, 177, 179, 0.5);
}
.btn-outline-300.disabled,
.btn-outline-300:disabled {
  color: #b1b1b3;
  background-color: transparent;
}
.btn-outline-300:not(:disabled):not(.disabled):active,
.btn-outline-300:not(:disabled):not(.disabled).active,
.show > .btn-outline-300.dropdown-toggle {
  color: #b1b1b3;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #b1b1b3;
}
.btn-outline-300:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-300:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-300:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-300.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-300:not(:disabled):not(.disabled):active,
.btn-outline-300:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-300:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-300:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-300.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-300:not(:disabled):not(.disabled).active,
.show > .btn-outline-300.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-300.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-300.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-300.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-300.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(177, 177, 179, 0.5);
}

.btn-outline-400 {
  color: #979899;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #979899;
}
.btn-outline-400:hover {
  color: #7f7f7f;
  box-shadow: inset 0 0 0 1px #7f7f7f;
}
.btn-outline-400:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-400,
.form-check [type="radio"]:focus + label.btn-outline-400,
.toggles label input[type="checkbox"]:focus + .btn-outline-400.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-400,
.btn-outline-400.focus {
  box-shadow: inset 0 0 0 1px #979899, 0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}
.btn-outline-400.disabled,
.btn-outline-400:disabled {
  color: #979899;
  background-color: transparent;
}
.btn-outline-400:not(:disabled):not(.disabled):active,
.btn-outline-400:not(:disabled):not(.disabled).active,
.show > .btn-outline-400.dropdown-toggle {
  color: #979899;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #979899;
}
.btn-outline-400:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-400:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-400:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-400.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-400:not(:disabled):not(.disabled):active,
.btn-outline-400:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-400:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-400:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-400.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-400:not(:disabled):not(.disabled).active,
.show > .btn-outline-400.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-400.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-400.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-400.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-400.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}

.btn-outline-500 {
  color: #7e7f80;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #7e7f80;
}
.btn-outline-500:hover {
  color: #656565;
  box-shadow: inset 0 0 0 1px #656565;
}
.btn-outline-500:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-500,
.form-check [type="radio"]:focus + label.btn-outline-500,
.toggles label input[type="checkbox"]:focus + .btn-outline-500.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-500,
.btn-outline-500.focus {
  box-shadow: inset 0 0 0 1px #7e7f80, 0 0 0 0.2rem rgba(126, 127, 128, 0.5);
}
.btn-outline-500.disabled,
.btn-outline-500:disabled {
  color: #7e7f80;
  background-color: transparent;
}
.btn-outline-500:not(:disabled):not(.disabled):active,
.btn-outline-500:not(:disabled):not(.disabled).active,
.show > .btn-outline-500.dropdown-toggle {
  color: #7e7f80;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #7e7f80;
}
.btn-outline-500:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-500:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-500:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-500.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-500:not(:disabled):not(.disabled):active,
.btn-outline-500:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-500:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-500:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-500.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-500:not(:disabled):not(.disabled).active,
.show > .btn-outline-500.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-500.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-500.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-500.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-500.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(126, 127, 128, 0.5);
}

.btn-outline-600 {
  color: #656566;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #656566;
}
.btn-outline-600:hover {
  color: #4c4c4c;
  box-shadow: inset 0 0 0 1px #4c4c4c;
}
.btn-outline-600:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-600,
.form-check [type="radio"]:focus + label.btn-outline-600,
.toggles label input[type="checkbox"]:focus + .btn-outline-600.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-600,
.btn-outline-600.focus {
  box-shadow: inset 0 0 0 1px #656566, 0 0 0 0.2rem rgba(101, 101, 102, 0.5);
}
.btn-outline-600.disabled,
.btn-outline-600:disabled {
  color: #656566;
  background-color: transparent;
}
.btn-outline-600:not(:disabled):not(.disabled):active,
.btn-outline-600:not(:disabled):not(.disabled).active,
.show > .btn-outline-600.dropdown-toggle {
  color: #656566;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #656566;
}
.btn-outline-600:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-600:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-600:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-600.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-600:not(:disabled):not(.disabled):active,
.btn-outline-600:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-600:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-600:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-600.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-600:not(:disabled):not(.disabled).active,
.show > .btn-outline-600.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-600.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-600.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-600.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-600.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(101, 101, 102, 0.5);
}

.btn-outline-700 {
  color: #4c4c4d;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #4c4c4d;
}
.btn-outline-700:hover {
  color: #333333;
  box-shadow: inset 0 0 0 1px #333333;
}
.btn-outline-700:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-700,
.form-check [type="radio"]:focus + label.btn-outline-700,
.toggles label input[type="checkbox"]:focus + .btn-outline-700.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-700,
.btn-outline-700.focus {
  box-shadow: inset 0 0 0 1px #4c4c4d, 0 0 0 0.2rem rgba(76, 76, 77, 0.5);
}
.btn-outline-700.disabled,
.btn-outline-700:disabled {
  color: #4c4c4d;
  background-color: transparent;
}
.btn-outline-700:not(:disabled):not(.disabled):active,
.btn-outline-700:not(:disabled):not(.disabled).active,
.show > .btn-outline-700.dropdown-toggle {
  color: #4c4c4d;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #4c4c4d;
}
.btn-outline-700:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-700:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-700:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-700.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-700:not(:disabled):not(.disabled):active,
.btn-outline-700:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-700:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-700:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-700.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-700:not(:disabled):not(.disabled).active,
.show > .btn-outline-700.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-700.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-700.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-700.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-700.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(76, 76, 77, 0.5);
}

.btn-outline-800 {
  color: #323333;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #323333;
}
.btn-outline-800:hover {
  color: #191919;
  box-shadow: inset 0 0 0 1px #191919;
}
.btn-outline-800:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-800,
.form-check [type="radio"]:focus + label.btn-outline-800,
.toggles label input[type="checkbox"]:focus + .btn-outline-800.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-800,
.btn-outline-800.focus {
  box-shadow: inset 0 0 0 1px #323333, 0 0 0 0.2rem rgba(50, 51, 51, 0.5);
}
.btn-outline-800.disabled,
.btn-outline-800:disabled {
  color: #323333;
  background-color: transparent;
}
.btn-outline-800:not(:disabled):not(.disabled):active,
.btn-outline-800:not(:disabled):not(.disabled).active,
.show > .btn-outline-800.dropdown-toggle {
  color: #323333;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #323333;
}
.btn-outline-800:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-800:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-800:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-800.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-800:not(:disabled):not(.disabled):active,
.btn-outline-800:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-800:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-800:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-800.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-800:not(:disabled):not(.disabled).active,
.show > .btn-outline-800.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-800.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-800.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-800.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-800.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(50, 51, 51, 0.5);
}

.btn-outline-900 {
  color: #19191a;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #19191a;
}
.btn-outline-900:hover {
  color: black;
  box-shadow: inset 0 0 0 1px black;
}
.btn-outline-900:focus,
.form-check [type="checkbox"]:focus + label.btn-outline-900,
.form-check [type="radio"]:focus + label.btn-outline-900,
.toggles label input[type="checkbox"]:focus + .btn-outline-900.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-outline-900,
.btn-outline-900.focus {
  box-shadow: inset 0 0 0 1px #19191a, 0 0 0 0.2rem rgba(25, 25, 26, 0.5);
}
.btn-outline-900.disabled,
.btn-outline-900:disabled {
  color: #19191a;
  background-color: transparent;
}
.btn-outline-900:not(:disabled):not(.disabled):active,
.btn-outline-900:not(:disabled):not(.disabled).active,
.show > .btn-outline-900.dropdown-toggle {
  color: #19191a;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #19191a;
}
.btn-outline-900:not(:disabled):not(.disabled):active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-900:not(:disabled):not(.disabled):active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-900:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-900.lever:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-900:not(:disabled):not(.disabled):active,
.btn-outline-900:not(:disabled):not(.disabled).active:focus,
.form-check
  [type="checkbox"]:focus
  + label.btn-outline-900:not(:disabled):not(.disabled).active,
.form-check
  [type="radio"]:focus
  + label.btn-outline-900:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-900.lever:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-900:not(:disabled):not(.disabled).active,
.show > .btn-outline-900.dropdown-toggle:focus,
.form-check
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-900.dropdown-toggle,
.form-check
  .show
  > [type="radio"]:focus
  + label.btn-outline-900.dropdown-toggle,
.toggles
  label
  .show
  > input[type="checkbox"]:focus
  + .btn-outline-900.dropdown-toggle.lever,
.block-facet--checkbox
  .show
  > [type="checkbox"]:focus
  + label.btn-outline-900.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(25, 25, 26, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0048ad;
  text-decoration: underline;
}
.btn-link:hover {
  color: #002861;
  text-decoration: none;
}
.btn-link:focus,
.form-check [type="checkbox"]:focus + label.btn-link,
.form-check [type="radio"]:focus + label.btn-link,
.toggles label input[type="checkbox"]:focus + .btn-link.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.btn-link,
.btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #656566;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.6;
  border-radius: 8px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.45;
  border-radius: 2px;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #19191a;
  text-align: left;
  list-style: none;
  background-color: white;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 4px;
  box-shadow: 0 20px 30px 5px rgba(0, 0, 0, 0.05);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 8px 0;
  overflow: hidden;
  border-top: 1px solid #cacacc;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 12px 24px;
  clear: both;
  font-weight: 400;
  color: #17324d;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus,
.form-check [type="checkbox"]:focus + label.dropdown-item,
.form-check [type="radio"]:focus + label.dropdown-item,
.toggles label input[type="checkbox"]:focus + .dropdown-item.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.dropdown-item {
  color: #17324d;
  text-decoration: none;
  background-color: #e6ecf2;
}
.dropdown-item.active,
.dropdown-item:active {
  color: white;
  text-decoration: none;
  background-color: #0048ad;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #656566;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 24px;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #17324d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 12px 24px;
  color: #17324d;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.form-check .btn-group > [type="checkbox"]:focus + label.btn,
.form-check .btn-group > [type="radio"]:focus + label.btn,
.toggles label .btn-group > input[type="checkbox"]:focus + .btn.lever,
.block-facet--checkbox .btn-group > [type="checkbox"]:focus + label.btn,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.form-check .btn-group-vertical > [type="checkbox"]:focus + label.btn,
.form-check .btn-group-vertical > [type="radio"]:focus + label.btn,
.toggles label .btn-group-vertical > input[type="checkbox"]:focus + .btn.lever,
.block-facet--checkbox
  .btn-group-vertical
  > [type="checkbox"]:focus
  + label.btn,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: 0;
}
.input-group > .form-control:focus,
.form-check .input-group > [type="checkbox"]:focus + label.form-control,
.form-check .input-group > [type="radio"]:focus + label.form-control,
.toggles
  label
  .input-group
  > input[type="checkbox"]:focus
  + .form-control.lever,
.block-facet--checkbox
  .input-group
  > [type="checkbox"]:focus
  + label.form-control,
.input-group > .custom-select:focus,
.form-check .input-group > [type="checkbox"]:focus + label.custom-select,
.form-check .input-group > [type="radio"]:focus + label.custom-select,
.toggles
  label
  .input-group
  > input[type="checkbox"]:focus
  + .custom-select.lever,
.block-facet--checkbox
  .input-group
  > [type="checkbox"]:focus
  + label.custom-select,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group
  > .custom-file
  .form-check
  [type="checkbox"]:focus
  + label.custom-file-input
  ~ .custom-file-label,
.form-check
  .input-group
  > .custom-file
  [type="checkbox"]:focus
  + label.custom-file-input
  ~ .custom-file-label,
.input-group
  > .custom-file
  .form-check
  [type="radio"]:focus
  + label.custom-file-input
  ~ .custom-file-label,
.form-check
  .input-group
  > .custom-file
  [type="radio"]:focus
  + label.custom-file-input
  ~ .custom-file-label,
.input-group
  > .custom-file
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-file-input.lever
  ~ .custom-file-label,
.toggles
  label
  .input-group
  > .custom-file
  input[type="checkbox"]:focus
  + .custom-file-input.lever
  ~ .custom-file-label,
.input-group
  > .custom-file
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-file-input
  ~ .custom-file-label,
.block-facet--checkbox
  .input-group
  > .custom-file
  [type="checkbox"]:focus
  + label.custom-file-input
  ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus,
.input-group
  > .custom-file
  .form-check
  [type="checkbox"]:focus
  + label.custom-file-input,
.form-check
  .input-group
  > .custom-file
  [type="checkbox"]:focus
  + label.custom-file-input,
.input-group
  > .custom-file
  .form-check
  [type="radio"]:focus
  + label.custom-file-input,
.form-check
  .input-group
  > .custom-file
  [type="radio"]:focus
  + label.custom-file-input,
.input-group
  > .custom-file
  .toggles
  label
  input[type="checkbox"]:focus
  + .custom-file-input.lever,
.toggles
  label
  .input-group
  > .custom-file
  input[type="checkbox"]:focus
  + .custom-file-input.lever,
.input-group
  > .custom-file
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.custom-file-input,
.block-facet--checkbox
  .input-group
  > .custom-file
  [type="checkbox"]:focus
  + label.custom-file-input {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-prepend .form-check [type="checkbox"]:focus + label.btn,
.form-check .input-group-prepend [type="checkbox"]:focus + label.btn,
.input-group-prepend .form-check [type="radio"]:focus + label.btn,
.form-check .input-group-prepend [type="radio"]:focus + label.btn,
.input-group-prepend .toggles label input[type="checkbox"]:focus + .btn.lever,
.toggles label .input-group-prepend input[type="checkbox"]:focus + .btn.lever,
.input-group-prepend .block-facet--checkbox [type="checkbox"]:focus + label.btn,
.block-facet--checkbox .input-group-prepend [type="checkbox"]:focus + label.btn,
.input-group-append .btn:focus,
.input-group-append .form-check [type="checkbox"]:focus + label.btn,
.form-check .input-group-append [type="checkbox"]:focus + label.btn,
.input-group-append .form-check [type="radio"]:focus + label.btn,
.form-check .input-group-append [type="radio"]:focus + label.btn,
.input-group-append .toggles label input[type="checkbox"]:focus + .btn.lever,
.toggles label .input-group-append input[type="checkbox"]:focus + .btn.lever,
.input-group-append .block-facet--checkbox [type="checkbox"]:focus + label.btn,
.block-facet--checkbox .input-group-append [type="checkbox"]:focus + label.btn {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: 0;
}

.input-group-prepend {
  margin-right: 0;
}

.input-group-append {
  margin-left: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4c4c4d;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 0 solid #979899;
  border-radius: 0;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.6em + 1rem);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.6;
  border-radius: 8px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.45em + 0.5rem);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.45;
  border-radius: 2px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
.nav-link:hover,
.nav-link:focus,
.form-check [type="checkbox"]:focus + label.nav-link,
.form-check [type="radio"]:focus + label.nav-link,
.toggles label input[type="checkbox"]:focus + .nav-link.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.nav-link {
  text-decoration: none;
}
.nav-link.disabled {
  color: #656566;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #b1b1b3;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .form-check [type="checkbox"]:focus + label.nav-link,
.form-check .nav-tabs [type="checkbox"]:focus + label.nav-link,
.nav-tabs .form-check [type="radio"]:focus + label.nav-link,
.form-check .nav-tabs [type="radio"]:focus + label.nav-link,
.nav-tabs .toggles label input[type="checkbox"]:focus + .nav-link.lever,
.toggles label .nav-tabs input[type="checkbox"]:focus + .nav-link.lever,
.nav-tabs .block-facet--checkbox [type="checkbox"]:focus + label.nav-link,
.block-facet--checkbox .nav-tabs [type="checkbox"]:focus + label.nav-link {
  border-color: #cacacc #cacacc #b1b1b3;
}
.nav-tabs .nav-link.disabled {
  color: #656566;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4c4c4d;
  background-color: white;
  border-color: #b1b1b3 #b1b1b3 white;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 4px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
  background-color: #0048ad;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 16px;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus,
.form-check [type="checkbox"]:focus + label.navbar-brand,
.form-check [type="radio"]:focus + label.navbar-brand,
.toggles label input[type="checkbox"]:focus + .navbar-brand.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.navbar-brand {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.navbar-toggler:hover,
.navbar-toggler:focus,
.form-check [type="checkbox"]:focus + label.navbar-toggler,
.form-check [type="radio"]:focus + label.navbar-toggler,
.toggles label input[type="checkbox"]:focus + .navbar-toggler.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.navbar-toggler {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1439.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus,
.navbar-light .form-check [type="checkbox"]:focus + label.navbar-brand,
.form-check .navbar-light [type="checkbox"]:focus + label.navbar-brand,
.navbar-light .form-check [type="radio"]:focus + label.navbar-brand,
.form-check .navbar-light [type="radio"]:focus + label.navbar-brand,
.navbar-light .toggles label input[type="checkbox"]:focus + .navbar-brand.lever,
.toggles label .navbar-light input[type="checkbox"]:focus + .navbar-brand.lever,
.navbar-light
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.navbar-brand,
.block-facet--checkbox
  .navbar-light
  [type="checkbox"]:focus
  + label.navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .form-check [type="checkbox"]:focus + label.nav-link,
.form-check .navbar-light .navbar-nav [type="checkbox"]:focus + label.nav-link,
.navbar-light .navbar-nav .form-check [type="radio"]:focus + label.nav-link,
.form-check .navbar-light .navbar-nav [type="radio"]:focus + label.nav-link,
.navbar-light
  .navbar-nav
  .toggles
  label
  input[type="checkbox"]:focus
  + .nav-link.lever,
.toggles
  label
  .navbar-light
  .navbar-nav
  input[type="checkbox"]:focus
  + .nav-link.lever,
.navbar-light
  .navbar-nav
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.nav-link,
.block-facet--checkbox
  .navbar-light
  .navbar-nav
  [type="checkbox"]:focus
  + label.nav-link {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus,
.navbar-light
  .navbar-text
  .toggles
  label
  input[type="checkbox"]:focus
  + a.lever,
.toggles
  label
  .navbar-light
  .navbar-text
  input[type="checkbox"]:focus
  + a.lever {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: white;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus,
.navbar-dark .form-check [type="checkbox"]:focus + label.navbar-brand,
.form-check .navbar-dark [type="checkbox"]:focus + label.navbar-brand,
.navbar-dark .form-check [type="radio"]:focus + label.navbar-brand,
.form-check .navbar-dark [type="radio"]:focus + label.navbar-brand,
.navbar-dark .toggles label input[type="checkbox"]:focus + .navbar-brand.lever,
.toggles label .navbar-dark input[type="checkbox"]:focus + .navbar-brand.lever,
.navbar-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.navbar-brand,
.block-facet--checkbox
  .navbar-dark
  [type="checkbox"]:focus
  + label.navbar-brand {
  color: white;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .form-check [type="checkbox"]:focus + label.nav-link,
.form-check .navbar-dark .navbar-nav [type="checkbox"]:focus + label.nav-link,
.navbar-dark .navbar-nav .form-check [type="radio"]:focus + label.nav-link,
.form-check .navbar-dark .navbar-nav [type="radio"]:focus + label.nav-link,
.navbar-dark
  .navbar-nav
  .toggles
  label
  input[type="checkbox"]:focus
  + .nav-link.lever,
.toggles
  label
  .navbar-dark
  .navbar-nav
  input[type="checkbox"]:focus
  + .nav-link.lever,
.navbar-dark
  .navbar-nav
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.nav-link,
.block-facet--checkbox
  .navbar-dark
  .navbar-nav
  [type="checkbox"]:focus
  + label.nav-link {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: transparent;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkuMnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMiAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSIxMDI0dXAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSItLWhvbWUtLS1wYXJhbGxheC0tLW1vYmlsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0LjAwMDAwMCwgLTIwLjAwMDAwMCkiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8ZyBpZD0iLW5ldHdvcmstc2xpbS1oZWFkZXIiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTI0LDI0IEwzNiwyNCBMMzYsMjYgTDI0LDI2IEwyNCwyNCBaIE0yNCwyMCBMMzIsMjAgTDMyLDIyIEwyNCwyMiBMMjQsMjAgWiBNMjQsMjggTDMyLDI4IEwzMiwzMCBMMjQsMzAgTDI0LDI4IFoiIGlkPSJpY29uLXNtYWxsLWJ1cmdlciI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: white;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text .toggles label input[type="checkbox"]:focus + a.lever,
.toggles
  label
  .navbar-dark
  .navbar-text
  input[type="checkbox"]:focus
  + a.lever {
  color: white;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: white;
  background-clip: border-box;
  border: 1px solid #d9dadb;
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 2rem;
}

.card-header {
  padding: 1rem 2rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid #d9dadb;
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 1rem 2rem;
  background-color: transparent;
  border-top: 1px solid #d9dadb;
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 12px;
  }
}

.card-group > .card {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #656566;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #656566;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 4px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0048ad;
  text-decoration: none;
  background-color: white;
  border: 1px solid #b1b1b3;
}
.page-link:hover {
  z-index: 2;
  color: #002861;
  text-decoration: none;
  background-color: #cacacc;
  border-color: #b1b1b3;
}
.page-link:focus,
.form-check [type="checkbox"]:focus + label.page-link,
.form-check [type="radio"]:focus + label.page-link,
.toggles label input[type="checkbox"]:focus + .page-link.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.page-link {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 72, 173, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.page-item.active .page-link {
  z-index: 3;
  color: white;
  background-color: #0048ad;
  border-color: #0048ad;
}

.page-item.disabled .page-link {
  color: #656566;
  pointer-events: none;
  cursor: auto;
  background-color: white;
  border-color: #b1b1b3;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover,
a.badge:focus,
.toggles label input[type="checkbox"]:focus + a.badge.lever {
  text-decoration: none;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: white;
  background-color: #0048ad;
}
a.badge-primary:hover,
a.badge-primary:focus,
.toggles label input[type="checkbox"]:focus + a.badge-primary.lever {
  color: white;
  background-color: #00337a;
}
a.badge-primary:focus,
.toggles label input[type="checkbox"]:focus + a.badge-primary.lever,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 72, 173, 0.5);
}

.badge-secondary {
  color: white;
  background-color: #5c6f82;
}
a.badge-secondary:hover,
a.badge-secondary:focus,
.toggles label input[type="checkbox"]:focus + a.badge-secondary.lever {
  color: white;
  background-color: #475664;
}
a.badge-secondary:focus,
.toggles label input[type="checkbox"]:focus + a.badge-secondary.lever,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(92, 111, 130, 0.5);
}

.badge-success {
  color: white;
  background-color: #008758;
}
a.badge-success:hover,
a.badge-success:focus,
.toggles label input[type="checkbox"]:focus + a.badge-success.lever {
  color: white;
  background-color: #005437;
}
a.badge-success:focus,
.toggles label input[type="checkbox"]:focus + a.badge-success.lever,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 135, 88, 0.5);
}

.badge-info {
  color: black;
  background-color: #979899;
}
a.badge-info:hover,
a.badge-info:focus,
.toggles label input[type="checkbox"]:focus + a.badge-info.lever {
  color: black;
  background-color: #7e7e80;
}
a.badge-info:focus,
.toggles label input[type="checkbox"]:focus + a.badge-info.lever,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}

.badge-warning {
  color: white;
  background-color: #a66300;
}
a.badge-warning:hover,
a.badge-warning:focus,
.toggles label input[type="checkbox"]:focus + a.badge-warning.lever {
  color: white;
  background-color: #734500;
}
a.badge-warning:focus,
.toggles label input[type="checkbox"]:focus + a.badge-warning.lever,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(166, 99, 0, 0.5);
}

.badge-danger {
  color: white;
  background-color: #d9364f;
}
a.badge-danger:hover,
a.badge-danger:focus,
.toggles label input[type="checkbox"]:focus + a.badge-danger.lever {
  color: white;
  background-color: #b9233a;
}
a.badge-danger:focus,
.toggles label input[type="checkbox"]:focus + a.badge-danger.lever,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(217, 54, 79, 0.5);
}

.badge-light {
  color: black;
  background-color: #e9e6f2;
}
a.badge-light:hover,
a.badge-light:focus,
.toggles label input[type="checkbox"]:focus + a.badge-light.lever {
  color: black;
  background-color: #ccc4e1;
}
a.badge-light:focus,
.toggles label input[type="checkbox"]:focus + a.badge-light.lever,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 230, 242, 0.5);
}

.badge-dark {
  color: white;
  background-color: #17324d;
}
a.badge-dark:hover,
a.badge-dark:focus,
.toggles label input[type="checkbox"]:focus + a.badge-dark.lever {
  color: white;
  background-color: #0b1825;
}
a.badge-dark:focus,
.toggles label input[type="checkbox"]:focus + a.badge-dark.lever,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 50, 77, 0.5);
}

.badge-black {
  color: white;
  background-color: black;
}
a.badge-black:hover,
a.badge-black:focus,
.toggles label input[type="checkbox"]:focus + a.badge-black.lever {
  color: white;
  background-color: black;
}
a.badge-black:focus,
.toggles label input[type="checkbox"]:focus + a.badge-black.lever,
a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-white {
  color: black;
  background-color: white;
}
a.badge-white:hover,
a.badge-white:focus,
.toggles label input[type="checkbox"]:focus + a.badge-white.lever {
  color: black;
  background-color: #e6e6e6;
}
a.badge-white:focus,
.toggles label input[type="checkbox"]:focus + a.badge-white.lever,
a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-100 {
  color: black;
  background-color: #e3e4e6;
}
a.badge-100:hover,
a.badge-100:focus,
.toggles label input[type="checkbox"]:focus + a.badge-100.lever {
  color: black;
  background-color: #c9cacd;
}
a.badge-100:focus,
.toggles label input[type="checkbox"]:focus + a.badge-100.lever,
a.badge-100.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 228, 230, 0.5);
}

.badge-200 {
  color: black;
  background-color: #cacacc;
}
a.badge-200:hover,
a.badge-200:focus,
.toggles label input[type="checkbox"]:focus + a.badge-200.lever {
  color: black;
  background-color: #b0b1b3;
}
a.badge-200:focus,
.toggles label input[type="checkbox"]:focus + a.badge-200.lever,
a.badge-200.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(202, 202, 204, 0.5);
}

.badge-300 {
  color: black;
  background-color: #b1b1b3;
}
a.badge-300:hover,
a.badge-300:focus,
.toggles label input[type="checkbox"]:focus + a.badge-300.lever {
  color: black;
  background-color: #979899;
}
a.badge-300:focus,
.toggles label input[type="checkbox"]:focus + a.badge-300.lever,
a.badge-300.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(177, 177, 179, 0.5);
}

.badge-400 {
  color: black;
  background-color: #979899;
}
a.badge-400:hover,
a.badge-400:focus,
.toggles label input[type="checkbox"]:focus + a.badge-400.lever {
  color: black;
  background-color: #7e7e80;
}
a.badge-400:focus,
.toggles label input[type="checkbox"]:focus + a.badge-400.lever,
a.badge-400.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(151, 152, 153, 0.5);
}

.badge-500 {
  color: black;
  background-color: #7e7f80;
}
a.badge-500:hover,
a.badge-500:focus,
.toggles label input[type="checkbox"]:focus + a.badge-500.lever {
  color: black;
  background-color: #656566;
}
a.badge-500:focus,
.toggles label input[type="checkbox"]:focus + a.badge-500.lever,
a.badge-500.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(126, 127, 128, 0.5);
}

.badge-600 {
  color: white;
  background-color: #656566;
}
a.badge-600:hover,
a.badge-600:focus,
.toggles label input[type="checkbox"]:focus + a.badge-600.lever {
  color: white;
  background-color: #4c4c4c;
}
a.badge-600:focus,
.toggles label input[type="checkbox"]:focus + a.badge-600.lever,
a.badge-600.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(101, 101, 102, 0.5);
}

.badge-700 {
  color: white;
  background-color: #4c4c4d;
}
a.badge-700:hover,
a.badge-700:focus,
.toggles label input[type="checkbox"]:focus + a.badge-700.lever {
  color: white;
  background-color: #323233;
}
a.badge-700:focus,
.toggles label input[type="checkbox"]:focus + a.badge-700.lever,
a.badge-700.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(76, 76, 77, 0.5);
}

.badge-800 {
  color: white;
  background-color: #323333;
}
a.badge-800:hover,
a.badge-800:focus,
.toggles label input[type="checkbox"]:focus + a.badge-800.lever {
  color: white;
  background-color: #191919;
}
a.badge-800:focus,
.toggles label input[type="checkbox"]:focus + a.badge-800.lever,
a.badge-800.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(50, 51, 51, 0.5);
}

.badge-900 {
  color: white;
  background-color: #19191a;
}
a.badge-900:hover,
a.badge-900:focus,
.toggles label input[type="checkbox"]:focus + a.badge-900.lever {
  color: white;
  background-color: black;
}
a.badge-900:focus,
.toggles label input[type="checkbox"]:focus + a.badge-900.lever,
a.badge-900.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 25, 26, 0.5);
}

@keyframes progress-bar-stripes {
  from {
    background-position: 16px 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 16px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #cacacc;
  border-radius: 0;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: white;
  text-align: center;
  white-space: nowrap;
  background-color: #0048ad;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 16px 16px;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 4px;
}

.list-group-item-action {
  width: 100%;
  color: #0048ad;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus,
.form-check [type="checkbox"]:focus + label.list-group-item-action,
.form-check [type="radio"]:focus + label.list-group-item-action,
.toggles label input[type="checkbox"]:focus + .list-group-item-action.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.list-group-item-action {
  z-index: 1;
  color: #0048ad;
  text-decoration: none;
  background-color: #e3e4e6;
}
.list-group-item-action:active {
  color: #19191a;
  background-color: #cacacc;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1.25rem;
  text-decoration: none;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #656566;
  pointer-events: none;
  background-color: white;
}
.list-group-item.active {
  z-index: 2;
  color: white;
  background-color: #0048ad;
  border-color: #0048ad;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #00265a;
  background-color: #b8cce8;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-primary.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-primary.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-primary.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-primary.list-group-item-action {
  color: #00265a;
  background-color: #a5bee2;
}
.list-group-item-primary.list-group-item-action.active {
  color: white;
  background-color: #00265a;
  border-color: #00265a;
}

.list-group-item-secondary {
  color: #303a44;
  background-color: #d1d7dc;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-secondary.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-secondary.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-secondary.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-secondary.list-group-item-action {
  color: #303a44;
  background-color: #c3cad1;
}
.list-group-item-secondary.list-group-item-action.active {
  color: white;
  background-color: #303a44;
  border-color: #303a44;
}

.list-group-item-success {
  color: #00462e;
  background-color: #b8ddd0;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-success.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-success.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-success.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-success.list-group-item-action {
  color: #00462e;
  background-color: #a7d5c5;
}
.list-group-item-success.list-group-item-action.active {
  color: white;
  background-color: #00462e;
  border-color: #00462e;
}

.list-group-item-info {
  color: #4f4f50;
  background-color: #e2e2e2;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-info.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-info.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-info.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-info.list-group-item-action {
  color: #4f4f50;
  background-color: #d5d5d5;
}
.list-group-item-info.list-group-item-action.active {
  color: white;
  background-color: #4f4f50;
  border-color: #4f4f50;
}

.list-group-item-warning {
  color: #563400;
  background-color: #e6d3b8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-warning.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-warning.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-warning.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-warning.list-group-item-action {
  color: #563400;
  background-color: #dfc7a5;
}
.list-group-item-warning.list-group-item-action.active {
  color: white;
  background-color: #563400;
  border-color: #563400;
}

.list-group-item-danger {
  color: #711c29;
  background-color: #f4c7ce;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-danger.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-danger.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-danger.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-danger.list-group-item-action {
  color: #711c29;
  background-color: #f0b2bb;
}
.list-group-item-danger.list-group-item-action.active {
  color: white;
  background-color: #711c29;
  border-color: #711c29;
}

.list-group-item-light {
  color: #79787e;
  background-color: #f9f8fb;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-light.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-light.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-light.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-light.list-group-item-action {
  color: #79787e;
  background-color: #ebe8f2;
}
.list-group-item-light.list-group-item-action.active {
  color: white;
  background-color: #79787e;
  border-color: #79787e;
}

.list-group-item-dark {
  color: #0c1a28;
  background-color: #bec6cd;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-dark.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-dark.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-dark.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-dark.list-group-item-action {
  color: #0c1a28;
  background-color: #b0b9c2;
}
.list-group-item-dark.list-group-item-action.active {
  color: white;
  background-color: #0c1a28;
  border-color: #0c1a28;
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-black.list-group-item-action:hover,
.list-group-item-black.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-black.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-black.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-black.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-black.list-group-item-action {
  color: black;
  background-color: #ababab;
}
.list-group-item-black.list-group-item-action.active {
  color: white;
  background-color: black;
  border-color: black;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-white.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-white.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-white.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-white.list-group-item-action {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-white.list-group-item-action.active {
  color: white;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-100 {
  color: #767677;
  background-color: #f7f7f8;
}
.list-group-item-100.list-group-item-action:hover,
.list-group-item-100.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-100.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-100.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-100.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-100.list-group-item-action {
  color: #767677;
  background-color: #e9e9ec;
}
.list-group-item-100.list-group-item-action.active {
  color: white;
  background-color: #767677;
  border-color: #767677;
}

.list-group-item-200 {
  color: #69696a;
  background-color: #f0f0f1;
}
.list-group-item-200.list-group-item-action:hover,
.list-group-item-200.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-200.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-200.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-200.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-200.list-group-item-action {
  color: #69696a;
  background-color: #e3e3e5;
}
.list-group-item-200.list-group-item-action.active {
  color: white;
  background-color: #69696a;
  border-color: #69696a;
}

.list-group-item-300 {
  color: #5c5c5d;
  background-color: #e9e9ea;
}
.list-group-item-300.list-group-item-action:hover,
.list-group-item-300.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-300.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-300.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-300.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-300.list-group-item-action {
  color: #5c5c5d;
  background-color: #dcdcde;
}
.list-group-item-300.list-group-item-action.active {
  color: white;
  background-color: #5c5c5d;
  border-color: #5c5c5d;
}

.list-group-item-400 {
  color: #4f4f50;
  background-color: #e2e2e2;
}
.list-group-item-400.list-group-item-action:hover,
.list-group-item-400.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-400.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-400.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-400.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-400.list-group-item-action {
  color: #4f4f50;
  background-color: #d5d5d5;
}
.list-group-item-400.list-group-item-action.active {
  color: white;
  background-color: #4f4f50;
  border-color: #4f4f50;
}

.list-group-item-500 {
  color: #424242;
  background-color: #dbdbdb;
}
.list-group-item-500.list-group-item-action:hover,
.list-group-item-500.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-500.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-500.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-500.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-500.list-group-item-action {
  color: #424242;
  background-color: #cecece;
}
.list-group-item-500.list-group-item-action.active {
  color: white;
  background-color: #424242;
  border-color: #424242;
}

.list-group-item-600 {
  color: #353535;
  background-color: #d4d4d4;
}
.list-group-item-600.list-group-item-action:hover,
.list-group-item-600.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-600.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-600.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-600.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-600.list-group-item-action {
  color: #353535;
  background-color: #c7c7c7;
}
.list-group-item-600.list-group-item-action.active {
  color: white;
  background-color: #353535;
  border-color: #353535;
}

.list-group-item-700 {
  color: #272728;
  background-color: #cdcdcd;
}
.list-group-item-700.list-group-item-action:hover,
.list-group-item-700.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-700.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-700.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-700.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-700.list-group-item-action {
  color: #272728;
  background-color: silver;
}
.list-group-item-700.list-group-item-action.active {
  color: white;
  background-color: #272728;
  border-color: #272728;
}

.list-group-item-800 {
  color: #1a1a1b;
  background-color: #c6c6c6;
}
.list-group-item-800.list-group-item-action:hover,
.list-group-item-800.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-800.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-800.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-800.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-800.list-group-item-action {
  color: #1a1a1b;
  background-color: #b9b9b9;
}
.list-group-item-800.list-group-item-action.active {
  color: white;
  background-color: #1a1a1b;
  border-color: #1a1a1b;
}

.list-group-item-900 {
  color: #0d0d0d;
  background-color: #bfbfbf;
}
.list-group-item-900.list-group-item-action:hover,
.list-group-item-900.list-group-item-action:focus,
.form-check
  [type="checkbox"]:focus
  + label.list-group-item-900.list-group-item-action,
.form-check
  [type="radio"]:focus
  + label.list-group-item-900.list-group-item-action,
.toggles
  label
  input[type="checkbox"]:focus
  + .list-group-item-900.list-group-item-action.lever,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.list-group-item-900.list-group-item-action {
  color: #0d0d0d;
  background-color: #b2b2b2;
}
.list-group-item-900.list-group-item-action.active {
  color: white;
  background-color: #0d0d0d;
  border-color: #0d0d0d;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: black;
  text-shadow: 0 1px 0 white;
  opacity: 0.5;
}
.close:hover {
  color: black;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus,
.form-check [type="checkbox"]:focus + label.close:not(:disabled):not(.disabled),
.form-check [type="radio"]:focus + label.close:not(:disabled):not(.disabled),
.toggles
  label
  input[type="checkbox"]:focus
  + .close.lever:not(:disabled):not(.disabled),
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.close:not(:disabled):not(.disabled) {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.8;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 0 solid #b1b1b3;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.modal-header .close {
  padding: 1.5rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 0 solid #b1b1b3;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.5rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3rem);
    height: min-content;
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: black;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: black;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: black;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: black;
}

.tooltip-inner {
  max-width: 32em;
  padding: 1rem 1rem;
  color: white;
  text-align: center;
  background-color: black;
  border-radius: 4px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 8px;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: white;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 8px 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: white;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: white;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 8px 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: white;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #19191a;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  color: #5c6f82;
  text-align: center;
  opacity: 1;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.form-check [type="checkbox"]:focus + label.carousel-control-prev,
.form-check [type="radio"]:focus + label.carousel-control-prev,
.toggles label input[type="checkbox"]:focus + .carousel-control-prev.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.carousel-control-prev,
.carousel-control-next:hover,
.carousel-control-next:focus,
.form-check [type="checkbox"]:focus + label.carousel-control-next,
.form-check [type="radio"]:focus + label.carousel-control-next,
.toggles label input[type="checkbox"]:focus + .carousel-control-next.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.carousel-control-next {
  color: #5c6f82;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%235c6f82' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%235c6f82' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: white;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0048ad !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
.toggles label input[type="checkbox"]:focus + a.bg-primary.lever,
button.bg-primary:hover,
button.bg-primary:focus,
.toggles label input[type="checkbox"]:focus + button.bg-primary.lever {
  background-color: #00337a !important;
}

.bg-secondary {
  background-color: #5c6f82 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
.toggles label input[type="checkbox"]:focus + a.bg-secondary.lever,
button.bg-secondary:hover,
button.bg-secondary:focus,
.toggles label input[type="checkbox"]:focus + button.bg-secondary.lever {
  background-color: #475664 !important;
}

.bg-success {
  background-color: #008758 !important;
}

a.bg-success:hover,
a.bg-success:focus,
.toggles label input[type="checkbox"]:focus + a.bg-success.lever,
button.bg-success:hover,
button.bg-success:focus,
.toggles label input[type="checkbox"]:focus + button.bg-success.lever {
  background-color: #005437 !important;
}

.bg-info {
  background-color: #979899 !important;
}

a.bg-info:hover,
a.bg-info:focus,
.toggles label input[type="checkbox"]:focus + a.bg-info.lever,
button.bg-info:hover,
button.bg-info:focus,
.toggles label input[type="checkbox"]:focus + button.bg-info.lever {
  background-color: #7e7e80 !important;
}

.bg-warning {
  background-color: #a66300 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
.toggles label input[type="checkbox"]:focus + a.bg-warning.lever,
button.bg-warning:hover,
button.bg-warning:focus,
.toggles label input[type="checkbox"]:focus + button.bg-warning.lever {
  background-color: #734500 !important;
}

.bg-danger {
  background-color: #d9364f !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
.toggles label input[type="checkbox"]:focus + a.bg-danger.lever,
button.bg-danger:hover,
button.bg-danger:focus,
.toggles label input[type="checkbox"]:focus + button.bg-danger.lever {
  background-color: #b9233a !important;
}

.bg-light {
  background-color: #e9e6f2 !important;
}

a.bg-light:hover,
a.bg-light:focus,
.toggles label input[type="checkbox"]:focus + a.bg-light.lever,
button.bg-light:hover,
button.bg-light:focus,
.toggles label input[type="checkbox"]:focus + button.bg-light.lever {
  background-color: #ccc4e1 !important;
}

.bg-dark {
  background-color: #17324d !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
.toggles label input[type="checkbox"]:focus + a.bg-dark.lever,
button.bg-dark:hover,
button.bg-dark:focus,
.toggles label input[type="checkbox"]:focus + button.bg-dark.lever {
  background-color: #0b1825 !important;
}

.bg-black {
  background-color: black !important;
}

a.bg-black:hover,
a.bg-black:focus,
.toggles label input[type="checkbox"]:focus + a.bg-black.lever,
button.bg-black:hover,
button.bg-black:focus,
.toggles label input[type="checkbox"]:focus + button.bg-black.lever {
  background-color: black !important;
}

.bg-white {
  background-color: white !important;
}

a.bg-white:hover,
a.bg-white:focus,
.toggles label input[type="checkbox"]:focus + a.bg-white.lever,
button.bg-white:hover,
button.bg-white:focus,
.toggles label input[type="checkbox"]:focus + button.bg-white.lever {
  background-color: #e6e6e6 !important;
}

.bg-100 {
  background-color: #e3e4e6 !important;
}

a.bg-100:hover,
a.bg-100:focus,
.toggles label input[type="checkbox"]:focus + a.bg-100.lever,
button.bg-100:hover,
button.bg-100:focus,
.toggles label input[type="checkbox"]:focus + button.bg-100.lever {
  background-color: #c9cacd !important;
}

.bg-200 {
  background-color: #cacacc !important;
}

a.bg-200:hover,
a.bg-200:focus,
.toggles label input[type="checkbox"]:focus + a.bg-200.lever,
button.bg-200:hover,
button.bg-200:focus,
.toggles label input[type="checkbox"]:focus + button.bg-200.lever {
  background-color: #b0b1b3 !important;
}

.bg-300 {
  background-color: #b1b1b3 !important;
}

a.bg-300:hover,
a.bg-300:focus,
.toggles label input[type="checkbox"]:focus + a.bg-300.lever,
button.bg-300:hover,
button.bg-300:focus,
.toggles label input[type="checkbox"]:focus + button.bg-300.lever {
  background-color: #979899 !important;
}

.bg-400 {
  background-color: #979899 !important;
}

a.bg-400:hover,
a.bg-400:focus,
.toggles label input[type="checkbox"]:focus + a.bg-400.lever,
button.bg-400:hover,
button.bg-400:focus,
.toggles label input[type="checkbox"]:focus + button.bg-400.lever {
  background-color: #7e7e80 !important;
}

.bg-500 {
  background-color: #7e7f80 !important;
}

a.bg-500:hover,
a.bg-500:focus,
.toggles label input[type="checkbox"]:focus + a.bg-500.lever,
button.bg-500:hover,
button.bg-500:focus,
.toggles label input[type="checkbox"]:focus + button.bg-500.lever {
  background-color: #656566 !important;
}

.bg-600 {
  background-color: #656566 !important;
}

a.bg-600:hover,
a.bg-600:focus,
.toggles label input[type="checkbox"]:focus + a.bg-600.lever,
button.bg-600:hover,
button.bg-600:focus,
.toggles label input[type="checkbox"]:focus + button.bg-600.lever {
  background-color: #4c4c4c !important;
}

.bg-700 {
  background-color: #4c4c4d !important;
}

a.bg-700:hover,
a.bg-700:focus,
.toggles label input[type="checkbox"]:focus + a.bg-700.lever,
button.bg-700:hover,
button.bg-700:focus,
.toggles label input[type="checkbox"]:focus + button.bg-700.lever {
  background-color: #323233 !important;
}

.bg-800 {
  background-color: #323333 !important;
}

a.bg-800:hover,
a.bg-800:focus,
.toggles label input[type="checkbox"]:focus + a.bg-800.lever,
button.bg-800:hover,
button.bg-800:focus,
.toggles label input[type="checkbox"]:focus + button.bg-800.lever {
  background-color: #191919 !important;
}

.bg-900 {
  background-color: #19191a !important;
}

a.bg-900:hover,
a.bg-900:focus,
.toggles label input[type="checkbox"]:focus + a.bg-900.lever,
button.bg-900:hover,
button.bg-900:focus,
.toggles label input[type="checkbox"]:focus + button.bg-900.lever {
  background-color: black !important;
}

.bg-white {
  background-color: white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #b1b1b3 !important;
}

.border-top {
  border-top: 1px solid #b1b1b3 !important;
}

.border-right {
  border-right: 1px solid #b1b1b3 !important;
}

.border-bottom {
  border-bottom: 1px solid #b1b1b3 !important;
}

.border-left {
  border-left: 1px solid #b1b1b3 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0048ad !important;
}

.border-secondary {
  border-color: #5c6f82 !important;
}

.border-success {
  border-color: #008758 !important;
}

.border-info {
  border-color: #979899 !important;
}

.border-warning {
  border-color: #a66300 !important;
}

.border-danger {
  border-color: #d9364f !important;
}

.border-light {
  border-color: #e9e6f2 !important;
}

.border-dark {
  border-color: #17324d !important;
}

.border-black {
  border-color: black !important;
}

.border-white {
  border-color: white !important;
}

.border-100 {
  border-color: #e3e4e6 !important;
}

.border-200 {
  border-color: #cacacc !important;
}

.border-300 {
  border-color: #b1b1b3 !important;
}

.border-400 {
  border-color: #979899 !important;
}

.border-500 {
  border-color: #7e7f80 !important;
}

.border-600 {
  border-color: #656566 !important;
}

.border-700 {
  border-color: #4c4c4d !important;
}

.border-800 {
  border-color: #323333 !important;
}

.border-900 {
  border-color: #19191a !important;
}

.border-white {
  border-color: white !important;
}

.rounded-sm {
  border-radius: 2px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-lg {
  border-radius: 8px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1440px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus,
.form-check [type="checkbox"]:focus + label.sr-only-focusable,
.form-check [type="radio"]:focus + label.sr-only-focusable,
.toggles label input[type="checkbox"]:focus + .sr-only-focusable.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.sr-only-focusable {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 4px !important;
}

.mt-1,
.my-1 {
  margin-top: 4px !important;
}

.mr-1,
.mx-1 {
  margin-right: 4px !important;
}

.mb-1,
.my-1 {
  margin-bottom: 4px !important;
}

.ml-1,
.mx-1 {
  margin-left: 4px !important;
}

.m-2 {
  margin: 8px !important;
}

.mt-2,
.my-2 {
  margin-top: 8px !important;
}

.mr-2,
.mx-2 {
  margin-right: 8px !important;
}

.mb-2,
.my-2 {
  margin-bottom: 8px !important;
}

.ml-2,
.mx-2 {
  margin-left: 8px !important;
}

.m-3 {
  margin: 16px !important;
}

.mt-3,
.my-3 {
  margin-top: 16px !important;
}

.mr-3,
.mx-3 {
  margin-right: 16px !important;
}

.mb-3,
.my-3 {
  margin-bottom: 16px !important;
}

.ml-3,
.mx-3 {
  margin-left: 16px !important;
}

.m-4 {
  margin: 24px !important;
}

.mt-4,
.my-4 {
  margin-top: 24px !important;
}

.mr-4,
.mx-4 {
  margin-right: 24px !important;
}

.mb-4,
.my-4 {
  margin-bottom: 24px !important;
}

.ml-4,
.mx-4 {
  margin-left: 24px !important;
}

.m-5 {
  margin: 48px !important;
}

.mt-5,
.my-5 {
  margin-top: 48px !important;
}

.mr-5,
.mx-5 {
  margin-right: 48px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 48px !important;
}

.ml-5,
.mx-5 {
  margin-left: 48px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 4px !important;
}

.pt-1,
.py-1 {
  padding-top: 4px !important;
}

.pr-1,
.px-1 {
  padding-right: 4px !important;
}

.pb-1,
.py-1 {
  padding-bottom: 4px !important;
}

.pl-1,
.px-1 {
  padding-left: 4px !important;
}

.p-2 {
  padding: 8px !important;
}

.pt-2,
.py-2 {
  padding-top: 8px !important;
}

.pr-2,
.px-2 {
  padding-right: 8px !important;
}

.pb-2,
.py-2 {
  padding-bottom: 8px !important;
}

.pl-2,
.px-2 {
  padding-left: 8px !important;
}

.p-3 {
  padding: 16px !important;
}

.pt-3,
.py-3 {
  padding-top: 16px !important;
}

.pr-3,
.px-3 {
  padding-right: 16px !important;
}

.pb-3,
.py-3 {
  padding-bottom: 16px !important;
}

.pl-3,
.px-3 {
  padding-left: 16px !important;
}

.p-4 {
  padding: 24px !important;
}

.pt-4,
.py-4 {
  padding-top: 24px !important;
}

.pr-4,
.px-4 {
  padding-right: 24px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 24px !important;
}

.pl-4,
.px-4 {
  padding-left: 24px !important;
}

.p-5 {
  padding: 48px !important;
}

.pt-5,
.py-5 {
  padding-top: 48px !important;
}

.pr-5,
.px-5 {
  padding-right: 48px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 48px !important;
}

.pl-5,
.px-5 {
  padding-left: 48px !important;
}

.m-n1 {
  margin: -4px !important;
}

.mt-n1,
.my-n1 {
  margin-top: -4px !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -4px !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -4px !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -4px !important;
}

.m-n2 {
  margin: -8px !important;
}

.mt-n2,
.my-n2 {
  margin-top: -8px !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -8px !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -8px !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -8px !important;
}

.m-n3 {
  margin: -16px !important;
}

.mt-n3,
.my-n3 {
  margin-top: -16px !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -16px !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -16px !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -16px !important;
}

.m-n4 {
  margin: -24px !important;
}

.mt-n4,
.my-n4 {
  margin-top: -24px !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -24px !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -24px !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -24px !important;
}

.m-n5 {
  margin: -48px !important;
}

.mt-n5,
.my-n5 {
  margin-top: -48px !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -48px !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -48px !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -48px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 4px !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 4px !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 4px !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 4px !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 4px !important;
  }
  .m-sm-2 {
    margin: 8px !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 8px !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 8px !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 8px !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 8px !important;
  }
  .m-sm-3 {
    margin: 16px !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 16px !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 16px !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 16px !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 16px !important;
  }
  .m-sm-4 {
    margin: 24px !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 24px !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 24px !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 24px !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 24px !important;
  }
  .m-sm-5 {
    margin: 48px !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 48px !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 48px !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 48px !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 48px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 4px !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 4px !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 4px !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 4px !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 4px !important;
  }
  .p-sm-2 {
    padding: 8px !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 8px !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 8px !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 8px !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 8px !important;
  }
  .p-sm-3 {
    padding: 16px !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 16px !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 16px !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 16px !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 16px !important;
  }
  .p-sm-4 {
    padding: 24px !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 24px !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 24px !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 24px !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 24px !important;
  }
  .p-sm-5 {
    padding: 48px !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 48px !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 48px !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 48px !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 48px !important;
  }
  .m-sm-n1 {
    margin: -4px !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -4px !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -4px !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -4px !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -4px !important;
  }
  .m-sm-n2 {
    margin: -8px !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -8px !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -8px !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -8px !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -8px !important;
  }
  .m-sm-n3 {
    margin: -16px !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -16px !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -16px !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -16px !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -16px !important;
  }
  .m-sm-n4 {
    margin: -24px !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -24px !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -24px !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -24px !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -24px !important;
  }
  .m-sm-n5 {
    margin: -48px !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -48px !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -48px !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -48px !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -48px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 4px !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 4px !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 4px !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 4px !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 4px !important;
  }
  .m-md-2 {
    margin: 8px !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 8px !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 8px !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 8px !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 8px !important;
  }
  .m-md-3 {
    margin: 16px !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 16px !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 16px !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 16px !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 16px !important;
  }
  .m-md-4 {
    margin: 24px !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 24px !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 24px !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 24px !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 24px !important;
  }
  .m-md-5 {
    margin: 48px !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 48px !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 48px !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 48px !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 48px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 4px !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 4px !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 4px !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 4px !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 4px !important;
  }
  .p-md-2 {
    padding: 8px !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 8px !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 8px !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 8px !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 8px !important;
  }
  .p-md-3 {
    padding: 16px !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 16px !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 16px !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 16px !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 16px !important;
  }
  .p-md-4 {
    padding: 24px !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 24px !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 24px !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 24px !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 24px !important;
  }
  .p-md-5 {
    padding: 48px !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 48px !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 48px !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 48px !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 48px !important;
  }
  .m-md-n1 {
    margin: -4px !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -4px !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -4px !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -4px !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -4px !important;
  }
  .m-md-n2 {
    margin: -8px !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -8px !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -8px !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -8px !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -8px !important;
  }
  .m-md-n3 {
    margin: -16px !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -16px !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -16px !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -16px !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -16px !important;
  }
  .m-md-n4 {
    margin: -24px !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -24px !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -24px !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -24px !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -24px !important;
  }
  .m-md-n5 {
    margin: -48px !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -48px !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -48px !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -48px !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -48px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 4px !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 4px !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 4px !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 4px !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 4px !important;
  }
  .m-lg-2 {
    margin: 8px !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 8px !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 8px !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 8px !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 8px !important;
  }
  .m-lg-3 {
    margin: 16px !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 16px !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 16px !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 16px !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 16px !important;
  }
  .m-lg-4 {
    margin: 24px !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 24px !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 24px !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 24px !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 24px !important;
  }
  .m-lg-5 {
    margin: 48px !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 48px !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 48px !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 48px !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 48px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 4px !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 4px !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 4px !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 4px !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 4px !important;
  }
  .p-lg-2 {
    padding: 8px !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 8px !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 8px !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 8px !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 8px !important;
  }
  .p-lg-3 {
    padding: 16px !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 16px !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 16px !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 16px !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 16px !important;
  }
  .p-lg-4 {
    padding: 24px !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 24px !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 24px !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 24px !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 24px !important;
  }
  .p-lg-5 {
    padding: 48px !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 48px !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 48px !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 48px !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 48px !important;
  }
  .m-lg-n1 {
    margin: -4px !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -4px !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -4px !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -4px !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -4px !important;
  }
  .m-lg-n2 {
    margin: -8px !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -8px !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -8px !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -8px !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -8px !important;
  }
  .m-lg-n3 {
    margin: -16px !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -16px !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -16px !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -16px !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -16px !important;
  }
  .m-lg-n4 {
    margin: -24px !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -24px !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -24px !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -24px !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -24px !important;
  }
  .m-lg-n5 {
    margin: -48px !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -48px !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -48px !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -48px !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -48px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 4px !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 4px !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 4px !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 4px !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 4px !important;
  }
  .m-xl-2 {
    margin: 8px !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 8px !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 8px !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 8px !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 8px !important;
  }
  .m-xl-3 {
    margin: 16px !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 16px !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 16px !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 16px !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 16px !important;
  }
  .m-xl-4 {
    margin: 24px !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 24px !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 24px !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 24px !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 24px !important;
  }
  .m-xl-5 {
    margin: 48px !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 48px !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 48px !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 48px !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 48px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 4px !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 4px !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 4px !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 4px !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 4px !important;
  }
  .p-xl-2 {
    padding: 8px !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 8px !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 8px !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 8px !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 8px !important;
  }
  .p-xl-3 {
    padding: 16px !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 16px !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 16px !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 16px !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 16px !important;
  }
  .p-xl-4 {
    padding: 24px !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 24px !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 24px !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 24px !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 24px !important;
  }
  .p-xl-5 {
    padding: 48px !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 48px !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 48px !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 48px !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 48px !important;
  }
  .m-xl-n1 {
    margin: -4px !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -4px !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -4px !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -4px !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -4px !important;
  }
  .m-xl-n2 {
    margin: -8px !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -8px !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -8px !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -8px !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -8px !important;
  }
  .m-xl-n3 {
    margin: -16px !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -16px !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -16px !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -16px !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -16px !important;
  }
  .m-xl-n4 {
    margin: -24px !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -24px !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -24px !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -24px !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -24px !important;
  }
  .m-xl-n5 {
    margin: -48px !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -48px !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -48px !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -48px !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -48px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 4px !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 4px !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 4px !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 4px !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 4px !important;
  }
  .m-xxl-2 {
    margin: 8px !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 8px !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 8px !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 8px !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 8px !important;
  }
  .m-xxl-3 {
    margin: 16px !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 16px !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 16px !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 16px !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 16px !important;
  }
  .m-xxl-4 {
    margin: 24px !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 24px !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 24px !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 24px !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 24px !important;
  }
  .m-xxl-5 {
    margin: 48px !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 48px !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 48px !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 48px !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 48px !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 4px !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 4px !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 4px !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 4px !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 4px !important;
  }
  .p-xxl-2 {
    padding: 8px !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 8px !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 8px !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 8px !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 8px !important;
  }
  .p-xxl-3 {
    padding: 16px !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 16px !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 16px !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 16px !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 16px !important;
  }
  .p-xxl-4 {
    padding: 24px !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 24px !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 24px !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 24px !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 24px !important;
  }
  .p-xxl-5 {
    padding: 48px !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 48px !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 48px !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 48px !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 48px !important;
  }
  .m-xxl-n1 {
    margin: -4px !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -4px !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -4px !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -4px !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -4px !important;
  }
  .m-xxl-n2 {
    margin: -8px !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -8px !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -8px !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -8px !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -8px !important;
  }
  .m-xxl-n3 {
    margin: -16px !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -16px !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -16px !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -16px !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -16px !important;
  }
  .m-xxl-n4 {
    margin: -24px !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -24px !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -24px !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -24px !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -24px !important;
  }
  .m-xxl-n5 {
    margin: -48px !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -48px !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -48px !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -48px !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -48px !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: "Roboto Mono", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: white !important;
}

.text-primary {
  color: #002861 !important;
}

a.text-primary:hover,
a.text-primary:focus,
.toggles label input[type="checkbox"]:focus + a.text-primary.lever {
  color: #001e47 !important;
}

.text-secondary {
  color: #3d4955 !important;
}

a.text-secondary:hover,
a.text-secondary:focus,
.toggles label input[type="checkbox"]:focus + a.text-secondary.lever {
  color: #323c46 !important;
}

.text-success {
  color: #003b26 !important;
}

a.text-success:hover,
a.text-success:focus,
.toggles label input[type="checkbox"]:focus + a.text-success.lever {
  color: #002116 !important;
}

.text-info {
  color: #717273 !important;
}

a.text-info:hover,
a.text-info:focus,
.toggles label input[type="checkbox"]:focus + a.text-info.lever {
  color: #646566 !important;
}

.text-warning {
  color: #4a2c00 !important;
}

a.text-warning:hover,
a.text-warning:focus,
.toggles label input[type="checkbox"]:focus + a.text-warning.lever {
  color: #402600 !important;
}

.text-danger {
  color: #a31f33 !important;
}

a.text-danger:hover,
a.text-danger:focus,
.toggles label input[type="checkbox"]:focus + a.text-danger.lever {
  color: #8e1b2c !important;
}

.text-light {
  color: #bdb4d8 !important;
}

a.text-light:hover,
a.text-light:focus,
.toggles label input[type="checkbox"]:focus + a.text-light.lever {
  color: #aea3d0 !important;
}

.text-dark {
  color: #050b12 !important;
}

a.text-dark:hover,
a.text-dark:focus,
.toggles label input[type="checkbox"]:focus + a.text-dark.lever {
  color: black !important;
}

.text-black {
  color: black !important;
}

a.text-black:hover,
a.text-black:focus,
.toggles label input[type="checkbox"]:focus + a.text-black.lever {
  color: black !important;
}

.text-white {
  color: white !important;
}

a.text-white:hover,
a.text-white:focus,
.toggles label input[type="checkbox"]:focus + a.text-white.lever {
  color: #cccccc !important;
}

.text-100 {
  color: #bbbdc1 !important;
}

a.text-100:hover,
a.text-100:focus,
.toggles label input[type="checkbox"]:focus + a.text-100.lever {
  color: #aeb0b5 !important;
}

.text-200 {
  color: #a3a4a7 !important;
}

a.text-200:hover,
a.text-200:focus,
.toggles label input[type="checkbox"]:focus + a.text-200.lever {
  color: #96979a !important;
}

.text-300 {
  color: #8a8b8d !important;
}

a.text-300:hover,
a.text-300:focus,
.toggles label input[type="checkbox"]:focus + a.text-300.lever {
  color: #7d7e80 !important;
}

.text-400 {
  color: #717273 !important;
}

a.text-400:hover,
a.text-400:focus,
.toggles label input[type="checkbox"]:focus + a.text-400.lever {
  color: #646566 !important;
}

.text-500 {
  color: #585859 !important;
}

a.text-500:hover,
a.text-500:focus,
.toggles label input[type="checkbox"]:focus + a.text-500.lever {
  color: #4b4c4c !important;
}

.text-600 {
  color: #3f3f40 !important;
}

a.text-600:hover,
a.text-600:focus,
.toggles label input[type="checkbox"]:focus + a.text-600.lever {
  color: #323233 !important;
}

.text-700 {
  color: #262626 !important;
}

a.text-700:hover,
a.text-700:focus,
.toggles label input[type="checkbox"]:focus + a.text-700.lever {
  color: #191919 !important;
}

.text-800 {
  color: #0c0c0d !important;
}

a.text-800:hover,
a.text-800:focus,
.toggles label input[type="checkbox"]:focus + a.text-800.lever {
  color: black !important;
}

.text-900 {
  color: black !important;
}

a.text-900:hover,
a.text-900:focus,
.toggles label input[type="checkbox"]:focus + a.text-900.lever {
  color: black !important;
}

.text-body {
  color: #19191a !important;
}

.text-muted {
  color: #5a768a !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #656566;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

html,
body {
  font-size: 16px;
  line-height: 1.5;
}
@media (min-width: 576px) {
  html,
  body {
    font-size: 18px;
    line-height: 1.555;
  }
}

h1,
.h1 {
  letter-spacing: -1px;
}
@media (min-width: 576px) {
  h1,
  .h1 {
    font-size: 2.666rem;
    letter-spacing: -1.2px;
    line-height: 1.25;
  }
}

h2,
.h2 {
  line-height: 1.25;
  letter-spacing: normal;
}
@media (min-width: 576px) {
  h2,
  .h2 {
    font-size: 2.222rem;
    line-height: 1.2;
  }
}

h3,
.h3 {
  line-height: 1.1428;
  letter-spacing: normal;
}
@media (min-width: 576px) {
  h3,
  .h3 {
    font-size: 1.777rem;
    line-height: 1.25;
  }
}

h4,
.h4 {
  line-height: 1.1666;
  letter-spacing: normal;
}
@media (min-width: 576px) {
  h4,
  .h4 {
    font-size: 1.555rem;
    line-height: 1.428;
    font-weight: 600;
  }
}

h5,
.h5 {
  font-weight: 400;
  letter-spacing: normal;
}
@media (min-width: 576px) {
  h5,
  .h5 {
    font-size: 1.333rem;
  }
}

h6,
.h6 {
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
}

.display-1 {
  font-size: 3.111rem;
}
@media (min-width: 576px) {
  .display-1 {
    font-size: 3.111rem;
    line-height: 1.428;
  }
}

blockquote,
.blockquote {
  font-family: Lora, Georgia, serif;
  margin: 1.5rem 0;
}

caption {
  font-size: 0.777rem;
  line-height: 1.4285;
}

b,
strong {
  font-weight: 600;
}

small,
.small {
  font-size: 0.9375rem;
  font-weight: 400;
}
@media (min-width: 576px) {
  small,
  .small {
    font-size: 0.8888rem;
  }
}

.x-small {
  font-size: 0.875rem;
  font-weight: 400;
}
@media (min-width: 576px) {
  .x-small {
    font-size: 0.7777rem;
  }
}

.blockquote-footer {
  color: #656566;
}
.bg-dark .blockquote-footer {
  color: #979899;
}

.text-serif {
  font-family: Lora, Georgia, serif !important;
}

.text-sans-serif {
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif !important;
}

.text-monospace {
  font-family: "Roboto Mono", monospace !important;
}

.row.variable-gutters {
  margin-right: -12px;
  margin-left: -12px;
}
.row.variable-gutters > .col,
.row.variable-gutters > [class*="col-"] {
  padding-right: 12px;
  padding-left: 12px;
}
@media (min-width: 576px) {
  .row.variable-gutters {
    margin-right: -6px;
    margin-left: -6px;
  }
  .row.variable-gutters > .col,
  .row.variable-gutters > [class*="col-"] {
    padding-right: 6px;
    padding-left: 6px;
  }
}
@media (min-width: 768px) {
  .row.variable-gutters {
    margin-right: -10px;
    margin-left: -10px;
  }
  .row.variable-gutters > .col,
  .row.variable-gutters > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (min-width: 992px) {
  .row.variable-gutters {
    margin-right: -10px;
    margin-left: -10px;
  }
  .row.variable-gutters > .col,
  .row.variable-gutters > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (min-width: 1200px) {
  .row.variable-gutters {
    margin-right: -14px;
    margin-left: -14px;
  }
  .row.variable-gutters > .col,
  .row.variable-gutters > [class*="col-"] {
    padding-right: 14px;
    padding-left: 14px;
  }
}

.row.row-column-border {
  border-top: 1px solid #b1b1b3;
}
.row.row-column-border > [class^="col-"] {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid #b1b1b3;
}
.row.row-column-border > [class^="col-"]:first-child {
  border: none;
}
.row.row-column-border > [class^="col-"] .navbar {
  padding: 0;
}
.row.row-column-border > [class^="col-"] .navbar .menu-wrapper .nav-link {
  padding-right: 0;
}

.row.row-column-menu-left > [class^="col-"]:first-child {
  padding: 1rem 0;
}

.row.row-column-menu-right > [class^="col-"]:last-child {
  padding: 1rem 0;
}

.row.row-card {
  background-color: white;
}

@media (min-width: 992px) {
  .row.row-column-border {
    margin-top: 1rem;
  }
  .row.row-column-border > [class^="col-"] {
    padding: 3rem 3rem;
    border-top: none;
    border-left: 1px solid #b1b1b3;
  }
  .row.row-column-border > [class^="col-"]:first-child {
    border: none;
    padding-left: 0;
  }
  .row.row-column-border > [class^="col-"] .navbar .menu-wrapper {
    padding: 0;
  }
  .row.row-column-menu-left > [class^="col-"]:first-child {
    padding: 0;
  }
  .row.row-column-menu-right > [class^="col-"]:last-child {
    padding: 0;
  }
}

.row.row-full-width {
  max-width: calc(100vw);
  margin: 0 calc(-50vw + 50%);
}
.row.row-full-width > * {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.row.row-full-width > * img {
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
}

.row.row-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 576px) {
  .row.row-title {
    flex-direction: row;
    align-items: flex-start;
  }
}

.row.row-border h1 {
  border-bottom: 1px solid #b1b1b3;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.row.row-border h2 {
  border-bottom: 1px solid #b1b1b3;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.row.row-border h3 {
  border-bottom: 1px solid #b1b1b3;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.row.row-border h4 {
  border-bottom: 1px solid #b1b1b3;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.row.row-border h5 {
  border-bottom: 1px solid #b1b1b3;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.row.row-border h6 {
  border-bottom: 1px solid #b1b1b3;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .row.row-border {
    border-bottom: 1px solid #b1b1b3;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .row.row-border h1 {
    border: none;
    margin: 0;
    padding: 0;
  }
  .row.row-border h2 {
    border: none;
    margin: 0;
    padding: 0;
  }
  .row.row-border h3 {
    border: none;
    margin: 0;
    padding: 0;
  }
  .row.row-border h4 {
    border: none;
    margin: 0;
    padding: 0;
  }
  .row.row-border h5 {
    border: none;
    margin: 0;
    padding: 0;
  }
  .row.row-border h6 {
    border: none;
    margin: 0;
    padding: 0;
  }
}

.row.row-calendar {
  display: block;
}

.sticky-wrapper.is-sticky {
  position: fixed;
}
.sticky-wrapper.is-sticky.navbar-wrapper {
  z-index: 1;
  left: 0;
  right: 0;
  width: auto;
}
.sticky-wrapper.is-sticky.navbar-wrapper .navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #b1b1b3;
}
.sticky-wrapper.is-sticky.navbar-wrapper.sticky-expanded {
  z-index: auto;
}
@media (min-width: 992px) {
  .sticky-wrapper.is-sticky.navbar-wrapper {
    z-index: auto;
    left: auto;
    right: auto;
    width: unset;
  }
  .sticky-wrapper.is-sticky.navbar-wrapper .navbar {
    border: none;
    background-color: transparent;
    padding: 0;
  }
  .sticky-wrapper.is-sticky.navbar-wrapper .navbar .menu-wrapper {
    padding: 0;
  }
  .sticky-wrapper.is-sticky.navbar-wrapper .navbar .menu-wrapper .nav-link {
    padding-right: 0;
  }
  .sticky-wrapper.is-sticky.navbar-wrapper .navbar.it-bottom-navscroll {
    border: none;
  }
  .sticky-wrapper.is-sticky.navbar-wrapper.at-bottom {
    position: absolute;
    top: auto !important;
    bottom: 0;
  }
}

.sticky-wrapper.navbar-wrapper .navbar.it-top-navscroll,
.sticky-wrapper.navbar-wrapper .navbar.it-bottom-navscroll {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.table th,
.table td {
  text-align: left;
  border-bottom: 1px solid #d6dce3;
  border-top: none;
}

.breadcrumb-container .breadcrumb {
  padding: 0.5em 0;
  border-radius: 0;
}
.breadcrumb-container .breadcrumb .breadcrumb-item {
  padding-left: 0;
}
.breadcrumb-container .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  display: none;
}
.breadcrumb-container .breadcrumb .breadcrumb-item i {
  padding-right: 0.5em;
}
.breadcrumb-container .breadcrumb .breadcrumb-item a {
  color: #5b6f82;
  font-weight: 600;
}
.breadcrumb-container .breadcrumb .breadcrumb-item.active a {
  font-weight: normal;
  pointer-events: none;
}
.breadcrumb-container .breadcrumb .breadcrumb-item span.separator {
  display: inline-block;
  font-weight: 600;
  padding: 0 0.5em;
}
.breadcrumb-container .breadcrumb.dark {
  background: #435a70;
  color: white;
}
.breadcrumb-container .breadcrumb.dark .breadcrumb-item a {
  color: white;
}
.breadcrumb-container .breadcrumb.dark .breadcrumb-item span.separator {
  color: white;
}
.breadcrumb-container .breadcrumb.dark .breadcrumb-item i {
  color: #0bd9d2;
}
.breadcrumb-container .breadcrumb.dark .breadcrumb-item.active {
  color: white;
}

.modal-content,
.modal-header {
  border-radius: 0;
}

*[data-toggle="collapse"] .collapse-icon::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 0.5rem solid;
  border-right: 0.5rem solid transparent;
  border-bottom: 0;
  border-left: 0.5rem solid transparent;
  vertical-align: 0.1875rem;
}

*[data-toggle="collapse"].collapsed .collapse-icon::before {
  transform: rotate(180deg);
}

.collapse-div {
  border-bottom: 1px solid #e3e4e6;
}
.collapse-div .collapse-div {
  border: 1px solid #e3e4e6;
  border-top: 0;
}
.collapse-div.collapse-background-active
  .collapse-header
  *[data-toggle="collapse"][aria-expanded="true"] {
  background-color: #0048ad;
  color: white;
  border-color: #0048ad;
}
.collapse-div.collapse-background-active
  .collapse-header
  *[data-toggle="collapse"][aria-expanded="true"]:before {
  color: white;
}
.collapse-div.collapse-background-hover
  .collapse-header
  *[data-toggle="collapse"]:hover {
  background-color: #0048ad;
  color: white;
  border-color: #0048ad;
}
.collapse-div.collapse-background-hover
  .collapse-header
  *[data-toggle="collapse"]:hover:before {
  color: white;
}
.collapse-div.collapse-left-icon
  .collapse-header
  *[data-toggle="collapse"]:before {
  content: "-";
  float: left;
  margin: 0 1rem 0 0;
  font-size: 1.5rem;
  line-height: 1.2rem;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
  transform: none;
}
.collapse-div.collapse-left-icon
  .collapse-header
  *[data-toggle="collapse"][aria-expanded="false"]:before {
  content: "+";
}

.collapse-header {
  position: relative;
}
.collapse-header *[data-toggle="collapse"] {
  width: 100%;
  text-align: left;
  border: 0;
  background-color: transparent;
  border-top: 1px solid #e3e4e6;
  padding: 14px 24px;
  cursor: pointer;
  color: #5c6f82;
  font-weight: 600;
}
.collapse-header *[data-toggle="collapse"][aria-expanded="false"] {
  color: #0048ad;
}
.collapse-header *[data-toggle="collapse"][aria-expanded="false"]:before {
  transform: scaleY(-1);
}
.collapse-header *[data-toggle="collapse"][aria-expanded="false"]:hover:before,
.collapse-header *[data-toggle="collapse"][aria-expanded="true"]:hover:before {
  text-decoration: none;
}
.collapse-header *[data-toggle="collapse"]:before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPml0LWNvbGxhcHNlPC90aXRsZT48ZyBpZD0iTGl2ZWxsb18xMyIgZGF0YS1uYW1lPSJMaXZlbGxvIDEzIj48cGF0aCBmaWxsPSIjN0ZCMkU1IiBkPSJNMTIsMTAuMjUsMTYuNzcsMTVhLjc1Ljc1LDAsMCwwLDEuMDYsMCwuNzQuNzQsMCwwLDAsMC0xLjA2TDEzLjA2LDkuMTlhMS41MSwxLjUxLDAsMCwwLTIuMTIsMEw2LjE3LDE0YS43NC43NCwwLDAsMCwwLDEuMDYuNzUuNzUsMCwwLDAsMS4wNiwwWiIvPjwvZz48L3N2Zz4K");
  float: right;
  width: 1.5rem;
  line-height: 0.1rem;
  color: #6893d0;
  transition: transform 0.3s;
}
.collapse-header *[data-toggle="collapse"]:hover {
  background: none;
  text-decoration: underline;
}

.collapse-body {
  padding: 12px 24px 42px;
}
.collapse-body .collapse-header button[aria-expanded="true"]:before {
  height: 0;
  width: 0;
}

.it-carousel-wrapper {
  margin: 0 auto;
}
.it-carousel-wrapper .it-special-card h5.card-title {
  margin-bottom: 0;
}
.it-carousel-wrapper .it-header-block h2 {
  font-size: 1.75rem;
  border-bottom: 1px solid #d9dadb;
  padding-bottom: 8px;
  margin-bottom: 0;
  letter-spacing: unset;
}
.it-carousel-wrapper .owl-stage-outer {
  padding-top: 24px;
  padding-bottom: 0;
}
.it-carousel-wrapper .card-wrapper {
  padding-bottom: 16px;
}
.it-carousel-wrapper.it-carousel-landscape-abstract
  .it-single-slide-wrapper
  > a {
  position: relative;
  display: block;
  position: relative;
}
.it-carousel-wrapper.it-carousel-landscape-abstract
  .it-single-slide-wrapper
  .it-text-slider-wrapper-outside
  .card {
  border-right: none;
  margin-right: 0;
}
.it-carousel-wrapper.it-carousel-landscape-abstract
  .it-single-slide-wrapper
  .it-text-slider-wrapper-outside
  .card:after {
  display: none;
}
.it-carousel-wrapper.it-carousel-landscape-abstract
  .it-single-slide-wrapper
  .it-text-slider-wrapper-outside
  .card
  .card-body
  .card-signature {
  float: left;
  margin-bottom: 0;
  width: 60%;
}
.it-carousel-wrapper.it-carousel-landscape-abstract
  .it-single-slide-wrapper
  .it-text-slider-wrapper-outside
  .card
  .card-body
  a.read-more {
  float: right;
  position: inherit;
  bottom: inherit;
  margin-bottom: 0;
  width: 35%;
  justify-content: flex-end;
  line-height: 21px;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3 {
  margin: 0 auto;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3 .it-header-block {
  padding: 0 24px;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3.it-full-carousel
  .it-img-card
  .owl-stage {
  left: unset;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3 .owl-stage {
  left: -40px;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3.it-full-carousel
  .owl-stage {
  left: unset;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3.it-full-carousel
  .owl-dots {
  text-align: center;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3 .it-img-card .owl-stage {
  left: -16px;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3 .card.card-img {
  margin-left: 0;
  margin-right: 0;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3
  .it-card-bg
  .card.card-bg {
  margin-left: 0;
  margin-right: 0;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3
  .it-card-bg
  .owl-stage-outer {
  border-right: none;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3 .it-card-bg .owl-stage {
  left: -16px;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3 .it-card-bg .owl-dots {
  text-align: center;
  margin-top: 0;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3 .owl-dots {
  text-align: center;
  margin-top: 16px;
  margin-left: 38px;
  margin-right: 38px;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3 .it-img-card .owl-dots {
  text-align: center;
}
.it-carousel-wrapper.it-carousel-landscape-abstract-3
  .it-img-card
  .it-img-card
  .owl-stage {
  left: -16px;
}
.it-carousel-wrapper .owl-dots {
  text-align: center;
}
.it-carousel-wrapper .owl-dots .owl-dot {
  width: 8px;
  height: 8px;
  background: #004ab3;
  display: inline-block;
  opacity: 0.3;
  transition: all 0.3s;
  border-radius: 50px;
  margin: 0 8px;
}
.it-carousel-wrapper .owl-dots .owl-dot.active {
  opacity: 1;
}

@media (min-width: 768px) {
  .it-carousel-wrapper.it-carousel-landscape-abstract .owl-stage-outer {
    border-right: 3px solid #fff;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3
    .it-img-card
    .owl-stage {
    left: -16px;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3.it-full-carousel
    .it-img-card
    .owl-stage {
    left: unset;
  }
}

@media (min-width: 992px) {
  .it-carousel-wrapper.it-carousel-landscape-abstract {
    max-width: 960px;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract .owl-stage {
    display: flex;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract .owl-stage .owl-item {
    display: flex;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract
    .owl-stage
    .owl-item
    .it-single-slide-wrapper {
    height: 100%;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract .it-single-slide-wrapper {
    display: flex;
    flex-basis: 100%;
    width: 100%;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract
    .it-single-slide-wrapper
    > a {
    flex-basis: 50%;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract
    .it-single-slide-wrapper
    > a
    .img-responsive-wrapper
    .img-responsive {
    padding-bottom: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract
    .it-single-slide-wrapper
    .it-text-slider-wrapper-outside {
    flex-basis: 50%;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract
    .it-single-slide-wrapper
    .it-text-slider-wrapper-outside
    .card {
    border-right: none;
    margin-right: 0;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract
    .it-single-slide-wrapper
    .it-text-slider-wrapper-outside
    .card:after {
    display: none;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract
    .it-single-slide-wrapper
    .it-text-slider-wrapper-outside
    .card
    .card-body {
    padding-left: 48px;
    padding-bottom: 48px;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract
    .it-single-slide-wrapper
    .it-text-slider-wrapper-outside
    .card
    .card-body
    .card-signature {
    float: left;
    width: 60%;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract
    .it-single-slide-wrapper
    .it-text-slider-wrapper-outside
    .card
    .card-body
    a.read-more {
    float: right;
    position: inherit;
    bottom: inherit;
    margin-bottom: 0;
    width: 35%;
    justify-content: flex-end;
    line-height: 26px;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract .owl-dots {
    position: absolute;
    left: 50%;
    margin-left: 40px;
    right: 0;
    bottom: 5px;
    text-align: left;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3 {
    max-width: 960px;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3.it-full-carousel {
    max-width: unset;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3 .it-header-block {
    padding: 0 12px;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3 .owl-stage-outer {
    border-right: 1px solid #fff;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3 .owl-stage {
    left: inherit;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3 .it-card-bg .owl-stage {
    left: unset;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3
    .it-card-bg
    .owl-stage-outer {
    border-right: none;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3 .owl-dots {
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 1200px) {
  .it-carousel-wrapper.it-carousel-landscape-abstract {
    max-width: 1180px;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3 {
    max-width: 1180px;
  }
  .it-carousel-wrapper.it-carousel-landscape-abstract-3.it-full-carousel {
    max-width: unset;
  }
}

.it-calendar-wrapper .it-header-block-title {
  background-color: #0048ad;
  color: white;
  padding: 0.5em 0;
  border-radius: 4px 4px 0 0;
}

.it-calendar-wrapper .card-wrapper {
  padding: 0;
}

.it-calendar-wrapper .owl-stage-outer {
  padding-top: 0;
  margin-bottom: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.it-calendar-wrapper .owl-item .card-bg {
  box-shadow: none;
}

.it-calendar-wrapper .owl-item.active + .active .card-bg {
  border-bottom-left-radius: 0;
  box-shadow: inset 1rem 0 1rem -1rem rgba(0, 0, 0, 0.3);
}

.it-calendar-wrapper .card .card-title,
.it-calendar-wrapper .card .card-text {
  border-bottom: 1px solid #b1b1b3;
  padding-bottom: 16px;
}

.it-calendar-wrapper .card .card-body .card-title {
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
  font-size: 3em;
  font-weight: 300;
  padding-top: 16px;
}
.it-calendar-wrapper .card .card-body .card-title span {
  font-size: 0.3em;
  display: block;
  padding-top: 1em;
  padding-left: 1em;
  font-family: "Roboto Mono", monospace;
}

.it-calendar-wrapper .card .card-text a {
  text-decoration: none;
  color: #4c4c4d;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
  font-size: 1em;
}
.it-calendar-wrapper .card .card-text a:hover {
  text-decoration: underline;
  color: #0048ad;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid #b1b1b3;
  padding-left: 4em;
}

.alert-heading {
  color: inherit;
  line-height: 1;
}

.alert-link {
  font-weight: 700;
  text-decoration: none;
}

.alert-dismissible {
  padding-right: 3.5rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8rem 1rem;
  color: inherit;
}

.alert-success {
  background-position: 20px 16px;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  border-left: 8px solid #008758;
}

.alert-info {
  background-position: 20px 16px;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  border-left: 8px solid #979899;
}

.alert-warning {
  background-position: 20px 16px;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  border-left: 8px solid #a66300;
}

.alert-danger {
  background-position: 20px 16px;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  border-left: 8px solid #d9364f;
}

.alert-danger {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11.5 14.2V5.7h1.2v8.5zm-.1 4.1h1.2v-1.8h-1.2zM22 7.9v8.3L16.1 22H7.9L2 16.2V7.9L7.9 2h8.2zm-1 .4L15.7 3H8.3L3 8.3v7.5L8.3 21h7.4l5.3-5.2z'%3E%3C/path%3E%3C/svg%3E");
}

.alert-warning {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 19a9 9 0 1 1 9-9 9 9 0 0 1-9 9zm-.5-6.8V5.7h1.2v8.5zm-.1 2.3h1.2v1.8h-1.2z'%3E%3C/path%3E%3C/svg%3E");
}

.alert-success {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M17.1 7.9l.7.7-7.8 7.6-4.7-4.6.7-.7 4 3.9zM22 12A10 10 0 1 1 12 2a10 10 0 0 1 10 10zm-1 0a9 9 0 1 0-9 9 9 9 0 0 0 9-9z'%3E%3C/path%3E%3C/svg%3E");
}

.alert-info {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 19a9 9 0 1 1 9-9 9 9 0 0 1-9 9zm-.7-15h1.5v2h-1.5zm0 3h1.5v9h-1.5z'%3E%3C/path%3E%3C/svg%3E");
}

.btn {
  padding: 12px 24px;
  font-size: 16px;
  white-space: initial;
  text-decoration: none;
}

.btn-xs,
.btn-sm,
.btn-group-sm > .btn,
.btn-lg,
.btn-group-lg > .btn {
  border-radius: 4px;
  line-height: 1.5;
}

.btn-xs {
  padding: 8px 8px;
  font-size: 14px;
  line-height: 1.45;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.45;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 14px 28px;
  font-size: 18px;
  line-height: 1.6;
}

.btn-block {
  border-radius: 0;
}

.btn-progress {
  position: relative;
}

.btn-icon {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.btn-icon .rounded-icon {
  width: 1.5em;
  height: 1.5em;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.btn-icon .rounded-icon .icon {
  margin-right: 0;
}
.btn-icon .rounded-icon.rounded-primary {
  background-color: #0048ad;
}
.btn-icon .rounded-icon.rounded-secondary {
  background-color: #5c6f82;
}
.btn-icon .rounded-icon.rounded-success {
  background-color: #008758;
}
.btn-icon .rounded-icon.rounded-info {
  background-color: #979899;
}
.btn-icon .rounded-icon.rounded-warning {
  background-color: #a66300;
}
.btn-icon .rounded-icon.rounded-danger {
  background-color: #d9364f;
}
.btn-icon .rounded-icon.rounded-light {
  background-color: #e9e6f2;
}
.btn-icon .rounded-icon.rounded-dark {
  background-color: #17324d;
}
.btn-icon .rounded-icon.rounded-black {
  background-color: black;
}
.btn-icon .rounded-icon.rounded-white {
  background-color: white;
}
.btn-icon .rounded-icon.rounded-100 {
  background-color: #e3e4e6;
}
.btn-icon .rounded-icon.rounded-200 {
  background-color: #cacacc;
}
.btn-icon .rounded-icon.rounded-300 {
  background-color: #b1b1b3;
}
.btn-icon .rounded-icon.rounded-400 {
  background-color: #979899;
}
.btn-icon .rounded-icon.rounded-500 {
  background-color: #7e7f80;
}
.btn-icon .rounded-icon.rounded-600 {
  background-color: #656566;
}
.btn-icon .rounded-icon.rounded-700 {
  background-color: #4c4c4d;
}
.btn-icon .rounded-icon.rounded-800 {
  background-color: #323333;
}
.btn-icon .rounded-icon.rounded-900 {
  background-color: #19191a;
}
.btn-icon .rounded-icon + * {
  margin-left: 0.5em;
}
.btn-icon .icon {
  border: none;
  width: 1.2em;
  height: 1.2em;
}
.btn-icon .icon + * {
  margin-left: 0.25em;
}

.btn-full {
  border: none;
  box-shadow: none;
  line-height: 1.555;
  border-radius: 0;
  align-self: stretch;
  width: inherit;
  margin-top: -7.5px;
  margin-bottom: -7.5px;
  margin-right: -7.5px;
  padding-left: 8px;
  padding-right: 8px;
}
.btn-full:hover {
  text-decoration: none !important;
}
@media (min-width: 576px) {
  .btn-full {
    padding: 16px;
  }
}
@media (min-width: 992px) {
  .btn-full {
    padding: 12px 24px !important;
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #bfc2c9;
  background-color: #e6e9f2;
  border-color: #dfe4f2;
}
.btn-primary:disabled.btn-progress,
.btn-primary.disabled.btn-progress {
  background-color: #6a9feb;
  border-color: #6a9feb;
  color: white;
  opacity: 1;
}

.btn-secondary:disabled.btn-progress,
.btn-secondary.disabled.btn-progress {
  background-color: #75899d;
  border-color: #75899d;
  opacity: 1;
}

.btn-outline-secondary {
  background: #fcfdff;
  box-shadow: inset 0 0 0 1px #e6e9f2;
}
.btn-outline-secondary:hover {
  box-shadow: inset 0 0 0 1px #c9cedc;
}

.bg-dark .btn-link {
  color: white;
}

.bg-dark .btn-primary {
  color: black;
  background-color: white;
  border-color: #0048ad;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  color: #0048ad;
}
.bg-dark .btn-primary:hover {
  color: black;
  background-color: white;
  border-color: #00337a;
}
.bg-dark .btn-primary:focus,
.bg-dark .form-check [type="checkbox"]:focus + label.btn-primary,
.form-check .bg-dark [type="checkbox"]:focus + label.btn-primary,
.bg-dark .form-check [type="radio"]:focus + label.btn-primary,
.form-check .bg-dark [type="radio"]:focus + label.btn-primary,
.bg-dark .toggles label input[type="checkbox"]:focus + .btn-primary.lever,
.toggles label .bg-dark input[type="checkbox"]:focus + .btn-primary.lever,
.bg-dark .block-facet--checkbox [type="checkbox"]:focus + label.btn-primary,
.block-facet--checkbox .bg-dark [type="checkbox"]:focus + label.btn-primary,
.bg-dark .btn-primary.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 72, 173, 0.5);
}
.bg-dark .btn-primary.disabled,
.bg-dark .btn-primary:disabled {
  color: black;
  background-color: white;
  border-color: #0048ad;
}
.bg-dark .btn-primary:not(:disabled):not(.disabled):active,
.bg-dark .btn-primary:not(:disabled):not(.disabled).active,
.show > .bg-dark .btn-primary.dropdown-toggle {
  color: black;
  background-color: white;
  border-color: #002e6e;
}
.bg-dark .btn-primary:not(:disabled):not(.disabled):active:focus,
.bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled):active,
.form-check
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled):active,
.bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-primary:not(:disabled):not(.disabled):active,
.form-check
  .bg-dark
  [type="radio"]:focus
  + label.btn-primary:not(:disabled):not(.disabled):active,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-primary.lever:not(:disabled):not(.disabled):active,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-primary.lever:not(:disabled):not(.disabled):active,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled):active,
.bg-dark .btn-primary:not(:disabled):not(.disabled).active:focus,
.bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled).active,
.form-check
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled).active,
.bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-primary:not(:disabled):not(.disabled).active,
.form-check
  .bg-dark
  [type="radio"]:focus
  + label.btn-primary:not(:disabled):not(.disabled).active,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-primary.lever:not(:disabled):not(.disabled).active,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-primary.lever:not(:disabled):not(.disabled).active,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-primary:not(:disabled):not(.disabled).active,
.show > .bg-dark .btn-primary.dropdown-toggle:focus,
.show
  > .bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-primary.dropdown-toggle,
.form-check
  .show
  > .bg-dark
  [type="checkbox"]:focus
  + label.btn-primary.dropdown-toggle,
.show
  > .bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-primary.dropdown-toggle,
.form-check
  .show
  > .bg-dark
  [type="radio"]:focus
  + label.btn-primary.dropdown-toggle,
.show
  > .bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-primary.dropdown-toggle.lever,
.toggles
  label
  .show
  > .bg-dark
  input[type="checkbox"]:focus
  + .btn-primary.dropdown-toggle.lever,
.show
  > .bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-primary.dropdown-toggle,
.block-facet--checkbox
  .show
  > .bg-dark
  [type="checkbox"]:focus
  + label.btn-primary.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(0, 72, 173, 0.5);
}
.bg-dark .btn-primary:hover {
  color: #00337a;
}
.bg-dark .btn-primary.disabled,
.bg-dark .btn-primary:disabled {
  color: #00337a;
}

.bg-dark .btn-outline-primary {
  color: white;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px white;
  box-shadow: inset 0 0 0 2px white;
}
.bg-dark .btn-outline-primary:hover {
  color: #e6e6e6;
  box-shadow: inset 0 0 0 1px #e6e6e6;
}
.bg-dark .btn-outline-primary:focus,
.bg-dark .form-check [type="checkbox"]:focus + label.btn-outline-primary,
.form-check .bg-dark [type="checkbox"]:focus + label.btn-outline-primary,
.bg-dark .form-check [type="radio"]:focus + label.btn-outline-primary,
.form-check .bg-dark [type="radio"]:focus + label.btn-outline-primary,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-primary,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-primary,
.bg-dark .btn-outline-primary.focus {
  box-shadow: inset 0 0 0 1px #e6e6e6, 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.bg-dark .btn-outline-primary.disabled,
.bg-dark .btn-outline-primary:disabled {
  color: white;
  background-color: transparent;
}
.bg-dark .btn-outline-primary:not(:disabled):not(.disabled):active,
.bg-dark .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .bg-dark .btn-outline-primary.dropdown-toggle {
  color: #e6e6e6;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #e6e6e6;
}
.bg-dark .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled):active,
.form-check
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled):active,
.bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled):active,
.form-check
  .bg-dark
  [type="radio"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled):active,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever:not(:disabled):not(.disabled):active,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever:not(:disabled):not(.disabled):active,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled):active,
.bg-dark .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled).active,
.form-check
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled).active,
.bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled).active,
.form-check
  .bg-dark
  [type="radio"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled).active,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever:not(:disabled):not(.disabled).active,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever:not(:disabled):not(.disabled).active,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .bg-dark .btn-outline-primary.dropdown-toggle:focus,
.show
  > .bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-outline-primary.dropdown-toggle,
.form-check
  .show
  > .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-primary.dropdown-toggle,
.show
  > .bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-outline-primary.dropdown-toggle,
.form-check
  .show
  > .bg-dark
  [type="radio"]:focus
  + label.btn-outline-primary.dropdown-toggle,
.show
  > .bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-primary.dropdown-toggle.lever,
.toggles
  label
  .show
  > .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-primary.dropdown-toggle.lever,
.show
  > .bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-primary.dropdown-toggle,
.block-facet--checkbox
  .show
  > .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-primary.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.bg-dark .btn-outline-primary:hover {
  box-shadow: inset 0 0 0 2px #e6e6e6;
}
.bg-dark .btn-outline-primary:focus,
.bg-dark .form-check [type="checkbox"]:focus + label.btn-outline-primary,
.form-check .bg-dark [type="checkbox"]:focus + label.btn-outline-primary,
.bg-dark .form-check [type="radio"]:focus + label.btn-outline-primary,
.form-check .bg-dark [type="radio"]:focus + label.btn-outline-primary,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-primary.lever,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-primary,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-primary,
.bg-dark .btn-outline-primary.focus {
  box-shadow: inset 0 0 0 2px white, 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.bg-dark .btn-outline-primary:not(:disabled):not(.disabled).active,
.bg-dark .btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .bg-dark .btn-outline-primary.dropdown-toggle {
  box-shadow: inset 0 0 0 2px white;
}

.bg-dark .btn-secondary {
  color: white;
  background-color: #0048ad;
  border-color: white;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  color: white;
}
.bg-dark .btn-secondary:hover {
  color: white;
  background-color: #0058d4;
  border-color: #e6e6e6;
}
.bg-dark .btn-secondary:focus,
.bg-dark .form-check [type="checkbox"]:focus + label.btn-secondary,
.form-check .bg-dark [type="checkbox"]:focus + label.btn-secondary,
.bg-dark .form-check [type="radio"]:focus + label.btn-secondary,
.form-check .bg-dark [type="radio"]:focus + label.btn-secondary,
.bg-dark .toggles label input[type="checkbox"]:focus + .btn-secondary.lever,
.toggles label .bg-dark input[type="checkbox"]:focus + .btn-secondary.lever,
.bg-dark .block-facet--checkbox [type="checkbox"]:focus + label.btn-secondary,
.block-facet--checkbox .bg-dark [type="checkbox"]:focus + label.btn-secondary,
.bg-dark .btn-secondary.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.bg-dark .btn-secondary.disabled,
.bg-dark .btn-secondary:disabled {
  color: white;
  background-color: #0048ad;
  border-color: white;
}
.bg-dark .btn-secondary:not(:disabled):not(.disabled):active,
.bg-dark .btn-secondary:not(:disabled):not(.disabled).active,
.show > .bg-dark .btn-secondary.dropdown-toggle {
  color: white;
  background-color: #005ee0;
  border-color: #dfdfdf;
}
.bg-dark .btn-secondary:not(:disabled):not(.disabled):active:focus,
.bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled):active,
.form-check
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled):active,
.bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled):active,
.form-check
  .bg-dark
  [type="radio"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled):active,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-secondary.lever:not(:disabled):not(.disabled):active,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-secondary.lever:not(:disabled):not(.disabled):active,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled):active,
.bg-dark .btn-secondary:not(:disabled):not(.disabled).active:focus,
.bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled).active,
.form-check
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled).active,
.bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled).active,
.form-check
  .bg-dark
  [type="radio"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled).active,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-secondary.lever:not(:disabled):not(.disabled).active,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-secondary.lever:not(:disabled):not(.disabled).active,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-secondary:not(:disabled):not(.disabled).active,
.show > .bg-dark .btn-secondary.dropdown-toggle:focus,
.show
  > .bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-secondary.dropdown-toggle,
.form-check
  .show
  > .bg-dark
  [type="checkbox"]:focus
  + label.btn-secondary.dropdown-toggle,
.show
  > .bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-secondary.dropdown-toggle,
.form-check
  .show
  > .bg-dark
  [type="radio"]:focus
  + label.btn-secondary.dropdown-toggle,
.show
  > .bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-secondary.dropdown-toggle.lever,
.toggles
  label
  .show
  > .bg-dark
  input[type="checkbox"]:focus
  + .btn-secondary.dropdown-toggle.lever,
.show
  > .bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-secondary.dropdown-toggle,
.block-facet--checkbox
  .show
  > .bg-dark
  [type="checkbox"]:focus
  + label.btn-secondary.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.bg-dark .btn-outline-secondary {
  color: white;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px white;
  box-shadow: none;
}
.bg-dark .btn-outline-secondary:hover {
  color: #e6e6e6;
  box-shadow: inset 0 0 0 1px #e6e6e6;
}
.bg-dark .btn-outline-secondary:focus,
.bg-dark .form-check [type="checkbox"]:focus + label.btn-outline-secondary,
.form-check .bg-dark [type="checkbox"]:focus + label.btn-outline-secondary,
.bg-dark .form-check [type="radio"]:focus + label.btn-outline-secondary,
.form-check .bg-dark [type="radio"]:focus + label.btn-outline-secondary,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-secondary,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-secondary,
.bg-dark .btn-outline-secondary.focus {
  box-shadow: inset 0 0 0 1px #e6e6e6, 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.bg-dark .btn-outline-secondary.disabled,
.bg-dark .btn-outline-secondary:disabled {
  color: white;
  background-color: transparent;
}
.bg-dark .btn-outline-secondary:not(:disabled):not(.disabled):active,
.bg-dark .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .bg-dark .btn-outline-secondary.dropdown-toggle {
  color: #e6e6e6;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px #e6e6e6;
}
.bg-dark .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled):active,
.form-check
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled):active,
.bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled):active,
.form-check
  .bg-dark
  [type="radio"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled):active,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever:not(:disabled):not(.disabled):active,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever:not(:disabled):not(.disabled):active,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled):active,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled):active,
.bg-dark .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled).active,
.form-check
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled).active,
.bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled).active,
.form-check
  .bg-dark
  [type="radio"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled).active,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever:not(:disabled):not(.disabled).active,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever:not(:disabled):not(.disabled).active,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled).active,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .bg-dark .btn-outline-secondary.dropdown-toggle:focus,
.show
  > .bg-dark
  .form-check
  [type="checkbox"]:focus
  + label.btn-outline-secondary.dropdown-toggle,
.form-check
  .show
  > .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-secondary.dropdown-toggle,
.show
  > .bg-dark
  .form-check
  [type="radio"]:focus
  + label.btn-outline-secondary.dropdown-toggle,
.form-check
  .show
  > .bg-dark
  [type="radio"]:focus
  + label.btn-outline-secondary.dropdown-toggle,
.show
  > .bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-secondary.dropdown-toggle.lever,
.toggles
  label
  .show
  > .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-secondary.dropdown-toggle.lever,
.show
  > .bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-secondary.dropdown-toggle,
.block-facet--checkbox
  .show
  > .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-secondary.dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.bg-dark .btn-outline-secondary:hover {
  box-shadow: none;
}
.bg-dark .btn-outline-secondary:focus,
.bg-dark .form-check [type="checkbox"]:focus + label.btn-outline-secondary,
.form-check .bg-dark [type="checkbox"]:focus + label.btn-outline-secondary,
.bg-dark .form-check [type="radio"]:focus + label.btn-outline-secondary,
.form-check .bg-dark [type="radio"]:focus + label.btn-outline-secondary,
.bg-dark
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever,
.toggles
  label
  .bg-dark
  input[type="checkbox"]:focus
  + .btn-outline-secondary.lever,
.bg-dark
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-outline-secondary,
.block-facet--checkbox
  .bg-dark
  [type="checkbox"]:focus
  + label.btn-outline-secondary,
.bg-dark .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.bg-dark .btn-outline-secondary:not(:disabled):not(.disabled).active,
.bg-dark .btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .bg-dark .btn-outline-secondary.dropdown-toggle {
  box-shadow: none;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

label {
  color: #435a70;
}

.form-group {
  position: relative;
  margin-bottom: 3rem;
  margin-top: 0;
}
.form-group input,
.form-group optgroup,
.form-group textarea {
  color: #17324d;
}
.form-group label {
  background-color: transparent;
  position: absolute;
  font-weight: 600;
  line-height: calc(2.5rem - 1px);
  transition: 0.2s ease-out;
  top: 0;
  font-size: 1rem;
  cursor: text;
  display: block;
  max-width: 100%;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0.5rem;
}
.form-group label.active {
  transform: translateY(-75%);
  font-size: 0.777rem;
}
.form-group small.form-text {
  position: absolute;
  margin: 0;
  padding: 0.25rem 0.5rem;
  font-size: 0.777rem;
}
.form-group input[type="time"] ~ label {
  transform: translateY(-75%);
  font-size: 0.777rem;
}

::placeholder {
  font-weight: normal;
}

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
textarea {
  border: none;
  border-bottom: 1px solid #5c6f82;
  border-radius: 0;
  padding: 0.375rem 0.5rem;
  outline: 0;
  height: 2.5rem;
  width: 100%;
  box-shadow: none;
  transition: 0.2s ease-out;
  font-weight: 700;
}

textarea {
  border: 1px solid #5c6f82;
  height: auto;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
}
.form-control:disabled + label,
.form-control[readonly] + label {
  background-color: transparent;
  cursor: not-allowed;
}

.form-group.active .form-file-name {
  padding-bottom: 1.95rem;
}

.form-control-plaintext {
  padding: 0.375rem 0.5rem;
  background-color: white !important;
  cursor: not-allowed;
}
.form-control-plaintext + label {
  cursor: not-allowed;
}

.form-control {
  background-position: center right !important;
  background-repeat: no-repeat !important;
  background-size: 45px 45% !important;
}
.form-control:focus,
.form-check [type="checkbox"]:focus + label.form-control,
.form-check [type="radio"]:focus + label.form-control,
.toggles label input[type="checkbox"]:focus + .form-control.lever,
.block-facet--checkbox [type="checkbox"]:focus + label.form-control,
.form-control:active {
  box-shadow: none !important;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300cc85' viewBox='0 0 192 512'%3E%3Cpath d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f73e5a' viewBox='0 0 384 512'%3E%3Cpath d='M231.6 256l130.1-130.1c4.7-4.7 4.7-12.3 0-17l-22.6-22.6c-4.7-4.7-12.3-4.7-17 0L192 216.4 61.9 86.3c-4.7-4.7-12.3-4.7-17 0l-22.6 22.6c-4.7 4.7-4.7 12.3 0 17L152.4 256 22.3 386.1c-4.7 4.7-4.7 12.3 0 17l22.6 22.6c4.7 4.7 12.3 4.7 17 0L192 295.6l130.1 130.1c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17L231.6 256z'/%3E%3C/svg%3E");
}
.form-control.warning {
  background-size: 25px 45% !important;
  border-color: #a66300;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff9900' viewBox='0 0 192 512'%3E%3Cpath d='M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z'/%3E%3C/svg%3E");
}
.form-control.is-valid ~ .warning-feedback {
  display: block;
}

.warning-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.777rem;
  color: #a66300;
}

.valid-feedback,
.invalid-feedback,
.warning-feedback {
  margin-left: 0.5rem;
}

.input-group .input-group-text {
  padding: 0.375rem 0.5rem;
  border-bottom: 1px solid #5c6f82;
}

.input-group .input-group-prepend {
  margin-right: 0;
}
.input-group .input-group-prepend ~ label {
  left: 2.25rem;
  max-width: calc(100% - 2.25rem);
}
.input-group .input-group-prepend .btn {
  border-radius: 4px 0 0 4px;
}

.input-group .input-group-append {
  margin-left: 0;
}
.input-group .input-group-append .btn {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #5c6f82;
  border-radius: 0 4px 4px 0;
  box-shadow: none;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0 1rem;
  border-radius: 0;
}

.form-check {
  padding-left: 0;
  margin-top: 0.5rem;
}
.form-check [type="checkbox"],
.form-check [type="radio"] {
  position: absolute;
  left: -9999px;
}
.form-check [type="checkbox"] + label,
.form-check [type="radio"] + label {
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  font-size: 1rem;
  user-select: none;
}
.form-check [type="checkbox"] + label::after,
.form-check [type="checkbox"] + label::before {
  content: "";
  left: 0;
  position: absolute;
  transition: 0.2s ease-out;
  z-index: 1;
  border-style: solid;
  border-width: 2px;
}
.form-check [type="checkbox"] + label::before {
  top: 0;
  width: 17px;
  height: 17px;
  border: 1px solid #e6e9f2;
  border-radius: 1px;
  margin: 2px 5px;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}
.form-check [type="checkbox"] + label::after {
  border-radius: 4px;
  height: 20px;
  width: 20px;
  margin: 6px 5px;
  top: 0;
}
.form-check [type="checkbox"]:checked + label::before {
  top: 6px;
  left: 1px;
  width: 8px;
  height: 13px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent #fff #fff transparent;
  transform: rotate(40deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%;
  opacity: 0.8;
}
.form-check [type="checkbox"]:checked + label::after {
  border-color: #0048ad;
  background-color: #0048ad;
  z-index: 0;
}
.form-check [type="checkbox"]:not(:checked) + label::after {
  background-color: transparent;
  border-color: #5c6f82;
  z-index: 0;
}
.form-check [type="checkbox"]:not(:checked) + label::before {
  width: 0;
  height: 0;
  border-color: transparent;
  left: 6px;
  top: 10px;
}
.form-check [type="checkbox"]:disabled + label {
  cursor: not-allowed;
}
.form-check [type="checkbox"]:disabled:not(:checked) + label::after {
  border-color: #e6e9f2;
  background-color: #fff;
}
.form-check [type="checkbox"]:disabled:checked + label::after {
  background-color: #e6e9f2;
  border-color: #e6e9f2;
}
.form-check [type="radio"] + label {
  transition: 0.2s ease-out;
}
.form-check [type="radio"] + label::after,
.form-check [type="radio"] + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 5px;
  width: 22px;
  height: 22px;
  z-index: 0;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  transition: 0.2s ease-out;
}
.form-check [type="radio"]:not(:checked) + label::after,
.form-check [type="radio"]:not(:checked) + label::before {
  border-color: #5c6f82;
}
.form-check [type="radio"]:not(:checked) + label:after {
  z-index: -1;
  transform: scale(0);
}
.form-check [type="radio"]:checked + label::after {
  border-color: #0048ad;
  background-color: #0048ad;
  z-index: 0;
  transform: scale(0.64);
}
.form-check [type="radio"]:checked + label::before {
  border-color: #0048ad;
}
.form-check [type="radio"]:disabled + label {
  cursor: not-allowed;
}
.form-check [type="radio"]:disabled:not(:checked) + label::after,
.form-check [type="radio"]:disabled:not(:checked) + label::before {
  border-color: #e6e9f2;
}
.form-check [type="radio"]:disabled:checked + label::after {
  border-color: #e6e9f2;
  background-color: #e6e9f2;
}
.form-check [type="radio"]:disabled:checked + label::before {
  border-color: #e6e9f2;
}
.form-check.form-check-group {
  padding: 0 0 8px 0;
  margin-bottom: 16px;
  box-shadow: inset 0 -1px 0 0 rgba(1, 1, 1, 0.1);
}
.form-check.form-check-group [type="checkbox"] + label,
.form-check.form-check-group [type="radio"] + label {
  position: static;
  padding-left: 8px;
  padding-right: 52px;
}
.form-check.form-check-group [type="checkbox"] + label::after,
.form-check.form-check-group [type="checkbox"] + label::before,
.form-check.form-check-group [type="radio"] + label::after,
.form-check.form-check-group [type="radio"] + label::before {
  right: 15px;
  left: auto;
}
.form-check.form-check-group [type="checkbox"]:checked + label::before {
  right: 26px;
}
.form-check.form-check-group [type="radio"]:checked + label::before {
  right: 15px;
}
.form-check.form-check-group .form-text {
  opacity: 0.6;
  margin: 0;
  padding-left: 8px;
  padding-right: 52px;
}

input[type="file"] + label {
  background-color: transparent;
}

/* INPUT FILE */
.form-file input[type="file"] {
  filter: alpha(opacity=0);
  margin: 0;
  max-width: 100%;
  opacity: 0;
}

.form-file .form-file-name {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  color: #464a4c;
  left: 0;
  line-height: 1.7;
  overflow: hidden;
  padding: 0.5rem 0.6rem 2rem;
  max-height: 2rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 5;
}
.form-file .form-file-name::before {
  background-color: white;
  border-color: #0048ad;
  bottom: -1px;
  color: #0048ad;
  content: "Sfoglia";
  display: block;
  height: 2.5rem;
  line-height: 1.7;
  padding: 0.4rem 1rem 1.5rem;
  border: 1px solid #0048ad;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 6;
  border-top-right-radius: 4px;
}

.input-number {
  position: relative;
  width: 100%;
}
.input-number.input-number-adaptive {
  width: auto;
  display: inline;
}
.input-number.input-number-adaptive input[type="number"] {
  width: auto;
}
.input-number input[type="number"] {
  appearance: textfield;
  color: #5c6f82;
  padding-right: 32px;
  padding-left: 12px;
  min-width: 86px !important;
  width: 100%;
}
.input-number input[type="number"]::-webkit-inner-spin-button,
.input-number input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.input-number input[type="number"]::-ms-clear {
  display: none;
}
.input-number input[type="number"]:focus,
.input-number
  .toggles
  label
  input[type="checkbox"]:focus
  + input.lever[type="number"],
.toggles
  label
  .input-number
  input[type="checkbox"]:focus
  + input.lever[type="number"] {
  color: #17324d;
}
.input-number.input-number-currency:before,
.input-number.input-number-percentage:before {
  position: absolute;
  left: 14px;
  top: 0;
  color: #5c6f82;
  font-weight: bold;
  font-size: 1rem;
}
.input-number.input-number-currency input[type="number"],
.input-number.input-number-percentage input[type="number"] {
  padding-left: 40px;
}
.input-number.input-number-currency:before {
  content: "€";
}
.input-number.input-number-percentage:before {
  content: "%";
}
.input-number.disabled input[type="number"] {
  background-color: transparent;
  color: #adb2b8;
  border-bottom-color: #adb2b8;
}
.input-number.disabled input[type="number"]:hover {
  cursor: not-allowed;
  color: #adb2b8;
}
.input-number.disabled button {
  pointer-events: none;
}
.input-number.disabled button:hover {
  cursor: not-allowed;
}
.input-number.disabled button.input-number-add:after {
  border-color: transparent transparent #adb2b8 transparent;
}
.input-number.disabled button.input-number-sub:after {
  border-color: #adb2b8 transparent transparent transparent;
}
.input-number button {
  position: absolute;
  right: 2px;
  transition: opacity 0.1s;
  padding: 0;
  border: none;
  height: 20px;
  width: 24px;
  background: transparent;
}
.input-number button:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}
.input-number button:focus.input-number-add:after,
.input-number
  .toggles
  label
  input[type="checkbox"]:focus
  + button.input-number-add.lever:after,
.toggles
  label
  .input-number
  input[type="checkbox"]:focus
  + button.input-number-add.lever:after,
.input-number button:hover.input-number-add:after {
  border-color: transparent transparent #17324d transparent;
}
.input-number button:focus.input-number-sub:after,
.input-number
  .toggles
  label
  input[type="checkbox"]:focus
  + button.input-number-sub.lever:after,
.toggles
  label
  .input-number
  input[type="checkbox"]:focus
  + button.input-number-sub.lever:after,
.input-number button:hover.input-number-sub:after {
  border-color: #17324d transparent transparent transparent;
}
.input-number button:focus:not(.focus--mouse),
.input-number
  .toggles
  label
  input[type="checkbox"]:focus
  + button.lever:not(.focus--mouse),
.toggles
  label
  .input-number
  input[type="checkbox"]:focus
  + button.lever:not(.focus--mouse) {
  opacity: 1;
}
.input-number button.input-number-add {
  top: -6px;
}
.input-number button.input-number-add:after {
  border-width: 0 5px 6px 5px;
  border-color: transparent transparent #5c6f82 transparent;
}
.input-number button.input-number-sub {
  bottom: -6px;
}
.input-number button.input-number-sub:after {
  border-width: 6px 5px 0 5px;
  border-color: #5c6f82 transparent transparent transparent;
}
.input-number button:hover {
  cursor: pointer;
}

label.input-number-label {
  display: block;
  color: #5c6f82;
  font-weight: 600;
  line-height: 1em;
  font-size: 0.777rem;
  cursor: text;
  display: block;
  width: 100%;
  padding: 0 0.5rem;
}

@media (min-width: 1200px) {
  .input-number button {
    opacity: 0;
  }
  .input-number:hover button {
    opacity: 1;
  }
}

.upload,
.upload-avatar {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload[type="file"] + label {
  max-width: 80%;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 7px 24px;
  border-radius: 4px;
  color: white;
  background-color: #0048ad;
  transition: background-color 0.15s;
}
.upload[type="file"] + label:hover {
  background-color: #00337a;
}
.upload[type="file"] + label svg {
  fill: white;
  margin-right: 10px;
}

.upload:focus + label,
.form-check [type="checkbox"]:focus + label.upload + label,
.form-check [type="radio"]:focus + label.upload + label,
.toggles label input[type="checkbox"]:focus + .upload.lever + label,
.block-facet--checkbox [type="checkbox"]:focus + label.upload + label {
  background-color: #00337a;
  outline: auto 5px -webkit-focus-ring-color;
}

.upload-pictures-wall {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.upload-pictures-wall > li {
  list-style-type: none;
  margin-right: 8px;
  margin-bottom: 8px;
}
.upload-pictures-wall > li:last-child {
  margin-right: 0;
}
.upload-pictures-wall .upload[type="file"] + label {
  height: 128px;
  width: 128px;
  border-radius: 0;
  padding: 0;
  border: 2px dashed #bfdaff;
  background-color: #f2f8ff;
  color: #0055cc;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  transition: border-color 0.2s;
}
.upload-pictures-wall .upload[type="file"] + label .icon {
  display: block;
  fill: #0055cc;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}
.upload-pictures-wall .upload[type="file"] + label:hover {
  border: 2px dashed #0055cc;
}
.upload-pictures-wall .upload:focus[type="file"] + label,
.upload-pictures-wall
  .form-check
  [type="checkbox"]:focus
  + label.upload[type="file"]
  + label,
.form-check
  .upload-pictures-wall
  [type="checkbox"]:focus
  + label.upload[type="file"]
  + label,
.upload-pictures-wall
  .form-check
  [type="radio"]:focus
  + label.upload[type="file"]
  + label,
.form-check
  .upload-pictures-wall
  [type="radio"]:focus
  + label.upload[type="file"]
  + label,
.upload-pictures-wall
  .toggles
  label
  input[type="checkbox"]:focus
  + .upload.lever[type="file"]
  + label,
.toggles
  label
  .upload-pictures-wall
  input[type="checkbox"]:focus
  + .upload.lever[type="file"]
  + label,
.upload-pictures-wall
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.upload[type="file"]
  + label,
.block-facet--checkbox
  .upload-pictures-wall
  [type="checkbox"]:focus
  + label.upload[type="file"]
  + label,
.upload-pictures-wall .upload:active[type="file"] + label {
  border: 2px dashed #6a9feb;
}
.upload-pictures-wall .upload-image {
  flex-shrink: 0;
  width: 128px;
  height: 128px;
}
.upload-pictures-wall .upload-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.upload-file-list {
  margin-top: 5px;
  padding: 0;
}
.upload-file-list.upload-file-list-image .progress {
  left: 52px;
}
.upload-file-list .upload-file {
  list-style-type: none;
  display: flex;
  align-items: center;
  max-width: 375px;
  margin-bottom: 16px;
  position: relative;
}
.upload-file-list .upload-file.uploading .icon {
  fill: #5b6f82;
}
.upload-file-list .upload-file.uploading p {
  color: #5b6f82;
}
.upload-file-list .upload-file.success svg {
  fill: #0048ad;
}
.upload-file-list .upload-file.success p {
  color: #0048ad;
}
.upload-file-list .upload-file.success p .upload-file-weight {
  display: inline-block;
}
.upload-file-list .upload-file.error svg {
  fill: #d9364f;
}
.upload-file-list .upload-file.error p {
  color: #d9364f;
}
.upload-file-list .upload-file svg {
  flex-shrink: 0;
}
.upload-file-list .upload-file .upload-image {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}
.upload-file-list .upload-file .upload-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.upload-file-list .upload-file p {
  margin: 0;
  font-size: 0.889rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 12px;
  overflow: hidden;
}
.upload-file-list .upload-file p .upload-file-weight {
  color: #5b6f82;
  font-size: 0.75em;
  margin-left: 10px;
  display: none;
}
.upload-file-list .upload-file button {
  background: transparent;
  border: 0;
  padding: 0px;
  margin-left: auto;
}
.upload-file-list .upload-file button:hover {
  cursor: pointer;
}
.upload-file-list .upload-file button:hover svg {
  fill: #0048ad;
}
.upload-file-list .progress {
  position: absolute;
  bottom: -10px;
  left: 36px;
  width: calc(100% - 45px);
  height: 4px;
  background-color: #e8f2fc;
  box-shadow: none;
}
.upload-file-list .progress.progress-image {
  width: calc(100% - 60px);
}

.upload-avatar-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.upload-avatar-container .upload-avatar[type="file"] + label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.upload-avatar-container .upload-avatar[type="file"] + label svg {
  display: none;
}
.upload-avatar-container .upload-avatar[type="file"] + label span {
  display: none;
}

.avatar-upload-wrapper {
  position: relative;
  display: inline-block;
}
.avatar-upload-wrapper .avatar.avatar-upload {
  position: relative;
}
.avatar-upload-wrapper .avatar-upload-icon {
  position: absolute;
  bottom: 16px;
  right: 4px;
  width: 26px;
  height: 26px;
  background: #ebeced;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.avatar-upload-wrapper .avatar-upload-icon > svg {
  width: 16px;
  height: 16px;
}
.avatar-upload-wrapper.size-sm .avatar {
  width: 80px;
  height: 80px;
}
.avatar-upload-wrapper.size-sm .avatar-upload-icon {
  bottom: 6px;
  right: 4px;
  width: 18px;
  height: 18px;
}
.avatar-upload-wrapper.size-sm .avatar-upload-icon > svg {
  width: 12px;
  height: 12px;
}

.upload-dragdrop {
  display: inline-flex;
  align-items: center;
}
.upload-dragdrop.dragover .upload-dragdrop-image img,
.upload-dragdrop.loading .upload-dragdrop-image img {
  transform: translateX(-50%) translateY(-50%) scale(0.85);
}
.upload-dragdrop.loading p:not(.upload-dragdrop-weight) {
  font-style: italic;
}
.upload-dragdrop.loading .upload-dragdrop-loading {
  opacity: 1;
}
.upload-dragdrop.loading .upload-dragdrop-text .upload-dragdrop-weight,
.upload-dragdrop.success .upload-dragdrop-text .upload-dragdrop-weight {
  visibility: visible;
}
.upload-dragdrop.success .upload-dragdrop-success {
  opacity: 1;
  transform: scale(1);
}

.upload-dragdrop-text p {
  margin: 0;
  font-size: 0.889rem;
  color: #5c6f82;
}
.upload-dragdrop-text p.upload-dragdrop-weight {
  color: #5b6f82;
  font-size: 0.778rem;
  visibility: hidden;
}
.upload-dragdrop-text p.upload-dragdrop-weight svg {
  fill: #5b6f82;
}

.upload-dragdrop-text h5 {
  margin: 0;
  font-size: 1.333rem;
  font-weight: 700;
  color: #17324d;
}

.upload-dragdrop-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload-dragdrop-input[type="file"] + label {
  text-decoration: underline;
  color: #0048ad;
  cursor: pointer;
}
.upload-dragdrop-input:focus[type="file"] + label,
.form-check
  [type="checkbox"]:focus
  + label.upload-dragdrop-input[type="file"]
  + label,
.form-check
  [type="radio"]:focus
  + label.upload-dragdrop-input[type="file"]
  + label,
.toggles
  label
  input[type="checkbox"]:focus
  + .upload-dragdrop-input.lever[type="file"]
  + label,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label.upload-dragdrop-input[type="file"]
  + label {
  outline: auto 5px -webkit-focus-ring-color;
}

.upload-dragdrop-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}
.upload-dragdrop-loading .upload-progress {
  transform: scale(0.355);
  position: absolute;
  top: -162px;
  left: -162px;
}
.upload-dragdrop-loading .upload-progress .clProg {
  font-weight: 700;
}

.upload-dragdrop-image {
  width: 128px;
  height: 128px;
  flex-shrink: 0;
  position: relative;
  margin-right: 16px;
}
.upload-dragdrop-image img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  transform-origin: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.4s cubic-bezier(0.33, 1.07, 0.54, 1);
  transform: translateX(-50%) translateY(-50%);
}
.upload-dragdrop-image .upload-dragdrop-success {
  width: 36px;
  height: 36px;
  position: absolute;
  top: calc(50% - 18px);
  left: 20px;
  background: #0048ad;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 0.2s, transform 0.5s cubic-bezier(0.75, -0.5, 0, 1.75);
}
.upload-dragdrop-image .upload-dragdrop-success svg {
  fill: white;
  width: 30px;
  height: 30px;
}

@media (min-width: 576px) {
  .upload[type="file"] + label {
    font-size: 0.889rem;
  }
}

@media (min-width: 768px) {
  .upload-dragdrop-image {
    width: 180px;
    height: 180px;
    margin-right: 32px;
  }
  .upload-dragdrop-image .upload-dragdrop-success {
    left: 28px;
  }
  .upload-dragdrop-loading .upload-progress {
    transform: scale(0.5);
    top: -136px;
    left: -136px;
  }
}

@media (min-width: 1200px) {
  .avatar-upload:hover .upload-avatar-container,
  .avatar-upload:focus-within .upload-avatar-container {
    opacity: 1;
    transition: opacity 0.25s;
  }
  .upload-avatar-container {
    bottom: -10%;
    left: 0;
    right: 0;
    top: auto;
    width: 100%;
    height: 60%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity 0.15s;
    opacity: 0;
  }
  .upload-avatar-container .upload-avatar[type="file"] + label {
    margin: 0;
    padding-top: 10px;
    color: white;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  .upload-avatar-container .upload-avatar[type="file"] + label span {
    display: block;
  }
  .upload-avatar-container .upload-avatar[type="file"] + label svg {
    display: block;
    fill: white;
    width: 20px;
    height: 20px;
    display: block;
  }
  .upload-avatar-container
    .upload-avatar:focus:not(.focus--mouse)[type="file"]
    + label,
  .upload-avatar-container
    .form-check
    [type="checkbox"]:focus
    + label.upload-avatar:not(.focus--mouse)[type="file"]
    + label,
  .form-check
    .upload-avatar-container
    [type="checkbox"]:focus
    + label.upload-avatar:not(.focus--mouse)[type="file"]
    + label,
  .upload-avatar-container
    .form-check
    [type="radio"]:focus
    + label.upload-avatar:not(.focus--mouse)[type="file"]
    + label,
  .form-check
    .upload-avatar-container
    [type="radio"]:focus
    + label.upload-avatar:not(.focus--mouse)[type="file"]
    + label,
  .upload-avatar-container
    .toggles
    label
    input[type="checkbox"]:focus
    + .upload-avatar.lever:not(.focus--mouse)[type="file"]
    + label,
  .toggles
    label
    .upload-avatar-container
    input[type="checkbox"]:focus
    + .upload-avatar.lever:not(.focus--mouse)[type="file"]
    + label,
  .upload-avatar-container
    .block-facet--checkbox
    [type="checkbox"]:focus
    + label.upload-avatar:not(.focus--mouse)[type="file"]
    + label,
  .block-facet--checkbox
    .upload-avatar-container
    [type="checkbox"]:focus
    + label.upload-avatar:not(.focus--mouse)[type="file"]
    + label {
    outline: auto 5px -webkit-focus-ring-color;
  }
  .avatar-upload-wrapper .avatar-upload-icon {
    display: none;
  }
  .avatar-upload-wrapper.size-sm .upload-avatar[type="file"] + label span {
    display: none;
  }
}

.toggles,
.toggles * {
  appearance: none;
  user-select: none;
}

.toggles label {
  cursor: pointer;
  width: 100%;
  margin-bottom: 8px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
}
.toggles label input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggles label input[type="checkbox"] + .lever {
  content: "";
  position: relative;
  width: 46px;
  height: 16px;
  background-color: #e6e9f2;
  border-radius: 10px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
  float: right;
  margin-top: 8px;
}
.toggles label input[type="checkbox"] + .lever:before,
.toggles label input[type="checkbox"] + .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  left: 0;
  top: -5px;
  left: -3px;
  transition: left 0.3s ease, background 0.1s ease, transform 0.1s ease;
}
.toggles label input[type="checkbox"] + .lever:before {
  background-color: rgba(0, 72, 173, 0.15);
}
.toggles label input[type="checkbox"] + .lever:after {
  background-color: #5c6f82;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.8'%3e%3cg id='Data-entry/toggle/Light_bg/disabledOff' transform='translate(-8.000000, -11.000000)' fill='%23FFFFFF'%3e%3cpath d='M14.363961,15.9497475 L17.8994949,19.4852814 L16.4852814,20.8994949 L12.9497475,17.363961 L9.41421356,20.8994949 L8,19.4852814 L11.5355339,15.9497475 L8,12.4142136 L9.41421356,11 L12.9497475,14.5355339 L16.4852814,11 L17.8994949,12.4142136 L14.363961,15.9497475 Z' id='Combined-Shape'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
  border: 2px solid #fff;
}
.toggles label input[type="checkbox"]:checked + .lever {
  background-color: #e6e9f2;
}
.toggles label input[type="checkbox"]:checked + .lever:before,
.toggles label input[type="checkbox"]:checked + .lever:after {
  left: 23px;
}
.toggles label input[type="checkbox"]:checked + .lever:after {
  background-color: #0048ad;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14px' height='11px' viewBox='0 0 14 11'%3E%3Cg stroke='none' fill='none' opacity='0.5'%3E%3Cg transform='translate(-28.000000, -11.000000)' fill='%23FFF'%3E%3Cg transform='translate(24.000000, 16.000000) scale(-1, 1) translate(-24.000000, -16.000000) translate(1.000000, 4.000000)'%3E%3Cpath d='M13,15 L13,5 L15,5 L15,15 L15,17 L9,17 L9,15 L13,15 Z' transform='translate(12.000000, 11.000000) scale(-1, 1) rotate(45.000000) translate(-12.000000, -11.000000) '/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
.toggles label input[type="checkbox"][disabled] + .lever {
  cursor: default;
  background-color: #e6e9f2;
}
.toggles label input[type="checkbox"][disabled] + .lever:after {
  background-color: #e6e9f2;
}
.toggles label input[type="checkbox"][disabled]:checked + .lever:after {
  background-color: #e6e9f2;
}

.password-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 8;
  padding: 0 8px;
  background-color: white;
}
.password-icon .icon {
  fill: #5c6f82;
}

.password-meter {
  height: 4px;
  left: 10px;
  bottom: -6px;
  width: 100%;
  max-width: 180px;
}
.password-meter .col-3 {
  height: 4px;
}

@keyframes selectDropdownFadeIn {
  0% {
    opacity: 0;
    margin-top: 0;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes selectDropdownFadeInTop {
  0% {
    opacity: 0;
    margin-top: 0;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background: transparent;
  color: inherit;
}

.bootstrap-select.form-control {
  box-shadow: none;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.form-check
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder,
.form-check
  .bootstrap-select
  > [type="radio"]:focus
  + label.dropdown-toggle.bs-placeholder,
.toggles
  label
  .bootstrap-select
  > input[type="checkbox"]:focus
  + .dropdown-toggle.bs-placeholder.lever,
.block-facet--checkbox
  .bootstrap-select
  > [type="checkbox"]:focus
  + label.dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: #435a70;
  font-weight: normal;
}

.bootstrap-select-wrapper {
  position: relative;
  padding-top: 0;
}
.bootstrap-select-wrapper.no-tick
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a.selected
  span.check-mark {
  display: none;
}
.bootstrap-select-wrapper.disabled {
  opacity: 0.3;
}
.bootstrap-select-wrapper .dropdown-menu {
  padding: 0;
  margin: 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.bootstrap-select-wrapper
  .dropdown.show
  .dropdown-menu[x-placement="bottom-start"],
.bootstrap-select-wrapper
  .btn-group.show
  .dropdown-menu[x-placement="bottom-start"] {
  animation: selectDropdownFadeIn forwards 0.3s;
}
.bootstrap-select-wrapper
  .dropdown.show
  .dropdown-menu[x-placement="top-start"],
.bootstrap-select-wrapper
  .btn-group.show
  .dropdown-menu[x-placement="top-start"] {
  animation: selectDropdownFadeInTop forwards 0.3s;
}
.bootstrap-select-wrapper .dropdown-menu:before {
  display: none;
}
.bootstrap-select-wrapper > label {
  position: absolute;
  color: #435a70;
  font-size: 0.77778rem;
  display: block;
  font-weight: 600;
  padding: 0 0.5rem;
  line-height: calc(2.5rem - 1px);
  top: 0;
  transform: translateY(-75%);
}
.bootstrap-select-wrapper button {
  background: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  color: #435a70;
  padding: 8px;
  font-size: 1rem;
  font-weight: bold;
}
.bootstrap-select-wrapper button.disabled {
  background: transparent;
}
.bootstrap-select-wrapper button.disabled.btn-light {
  box-shadow: none;
}
.bootstrap-select-wrapper button .filter-option {
  position: relative;
}
.bootstrap-select-wrapper button .filter-option .filter-option-inner {
  display: flex;
}
.bootstrap-select-wrapper button .filter-option .filter-option-inner-inner {
  display: flex;
  align-items: center;
}
.bootstrap-select-wrapper
  button
  .filter-option
  .filter-option-inner-inner
  .icon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.bootstrap-select-wrapper button .filter-option:after {
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  background: #435a70;
  bottom: 0;
  transition: all 0.3s;
  transform-origin: bottom;
}
.bootstrap-select-wrapper button:hover:not(.disabled) .filter-option:after {
  transform: scaleY(2);
}
.bootstrap-select-wrapper button:hover {
  background: transparent;
}
.bootstrap-select-wrapper button.dropdown-toggle:after {
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: static;
  top: 0;
  transform: translateY(50%);
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .bs-searchbox {
  padding: 8px 24px;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-searchbox
  input[type="text"] {
  padding: 0;
  border-bottom: 1px solid #ebeced;
  font-size: 0.88889rem;
  font-weight: 600;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-searchbox
  input[type="text"]::placeholder {
  font-weight: 600;
  opacity: 0.5;
  font-size: 0.88889rem;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .no-results {
  padding: 8px 24px;
  background: transparent;
  margin: 0;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .dropdown-header .text {
  font-size: 0.83333rem;
  text-transform: uppercase;
  color: #435a70;
  font-weight: 600;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .dropdown-divider {
  border: none;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .form-check {
  margin-top: 0;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .form-check
  [type="checkbox"]
  + label,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .form-check
  [type="radio"]
  + label {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 0.88889rem;
  height: 30px;
  line-height: 30px;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .btn {
  height: 2.5rem;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .bs-actionsbox {
  position: relative;
  padding: 0;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .bs-actionsbox .btn-block {
  display: block;
  padding: 8px 24px;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  .btn {
  padding: 0;
  display: inline-block;
  font-size: 0.88889rem;
  color: #0048ad;
  font-weight: normal;
  width: auto;
  margin-right: 24px;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  .btn:active,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  .btn:focus,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  .form-check
  [type="checkbox"]:focus
  + label.btn,
.form-check
  .bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  [type="checkbox"]:focus
  + label.btn,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  .form-check
  [type="radio"]:focus
  + label.btn,
.form-check
  .bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  [type="radio"]:focus
  + label.btn,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn.lever,
.toggles
  label
  .bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  input[type="checkbox"]:focus
  + .btn.lever,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn,
.block-facet--checkbox
  .bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  [type="checkbox"]:focus
  + label.btn {
  box-shadow: none;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .bs-actionsbox
  .btn-block
  .btn:hover {
  text-decoration: underline;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .bs-actionsbox:after {
  content: "";
  display: block;
  height: 1px;
  width: 65px;
  background: #ebeced;
  position: absolute;
  bottom: -8px;
  left: 24px;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select.show-tick
  .filter-option
  .filter-option-inner-inner
  .select-pill {
  font-size: 0.77778rem;
  padding: 4px 8px;
  border: 1px solid #435a70;
  color: #435a70;
  line-height: 1;
  border-radius: 24px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select.show-tick
  .filter-option
  .filter-option-inner-inner
  .select-pill
  svg {
  fill: #435a70;
  margin-right: 0;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select.show-tick
  .filter-option
  .filter-option-inner-inner
  .icon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select.show-tick
  .filter-option
  .filter-option-inner-inner
  .form-check
  [type="checkbox"]
  + label,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select.show-tick
  .filter-option
  .filter-option-inner-inner
  .form-check
  [type="radio"]
  + label {
  font-size: 0.77778rem;
  padding: 4px 8px;
  border: 1px solid #435a70;
  color: #435a70;
  line-height: 1;
  border-radius: 24px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  height: auto;
  font-weight: 700;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select.show-tick
  .filter-option
  .filter-option-inner-inner
  .form-check
  [type="checkbox"]
  + label:before,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select.show-tick
  .filter-option
  .filter-option-inner-inner
  .form-check
  [type="checkbox"]
  + label:after,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select.show-tick
  .filter-option
  .filter-option-inner-inner
  .form-check
  [type="radio"]
  + label:before,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select.show-tick
  .filter-option
  .filter-option-inner-inner
  .form-check
  [type="radio"]
  + label:after {
  display: none;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .dropdown-menu {
  top: calc(-100% - 8px) !important;
  padding: 8px 0;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu[x-placement="top-start"] {
  top: auto !important;
  bottom: -8px !important;
  transform: none !important;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .dropdown-menu li a {
  font-size: 0.88889rem;
  padding: 8px 24px;
  color: #0048ad;
  white-space: inherit;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a.icon-options-li
  span.text {
  display: flex;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a.selected {
  color: #17324d;
  text-decoration: underline;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a.selected
  span.select-pill-text {
  text-decoration: underline;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a.selected
  span.check-mark {
  color: #0048ad;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .dropdown-menu li a:focus,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  .toggles
  label
  input[type="checkbox"]:focus
  + a.lever,
.toggles
  label
  .bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  input[type="checkbox"]:focus
  + a.lever,
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a:active {
  background: transparent;
  outline: none;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a
  span.check-mark {
  top: 10px;
  right: 24px;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a
  span.check-mark:after {
  content: "";
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a
  span.text {
  position: relative;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a
  span.text
  .select-pill
  .icon {
  display: none;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a
  span.text
  .icon {
  fill: #0048ad;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a
  span.text
  .reset-label {
  display: block;
  height: 1px;
  width: 65px;
  background: #ebeced;
  position: absolute;
  bottom: -8px;
}
.bootstrap-select-wrapper .dropdown.bootstrap-select .dropdown-menu li a:hover {
  background: transparent;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a:hover
  span.text {
  text-decoration: underline;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a.active {
  background: transparent;
  color: #17324d;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li
  a.active
  span.text {
  text-decoration: underline;
}
.bootstrap-select-wrapper
  .dropdown.bootstrap-select
  .dropdown-menu
  li.disabled
  a {
  color: #e6e9f2;
}

fieldset.disabled .bootstrap-select-wrapper {
  opacity: 0.3;
}
fieldset.disabled .bootstrap-select-wrapper button {
  background: transparent;
}
fieldset.disabled .bootstrap-select-wrapper button.btn-light {
  box-shadow: none;
}

.it-transfer-wrapper {
  border: 1px solid #c5c7c9;
  border-radius: 4px;
}
.it-transfer-wrapper .transfer-scroll {
  height: 240px;
  overflow-x: hidden;
  margin-bottom: 24px;
}
.it-transfer-wrapper .form-check {
  padding: 0 24px;
  position: relative;
}
.it-transfer-wrapper .transfer-header {
  font-weight: bold;
}
.it-transfer-wrapper .transfer-header:after {
  height: 1px;
  background: #c5c7c9;
  width: 65px;
  content: "";
  display: block;
  margin-left: 62px;
}
.it-transfer-wrapper .transfer-header .form-check {
  padding: 24px 24px 8px;
  margin-top: 0;
}
.it-transfer-wrapper .transfer-header .form-check [type="checkbox"] + label {
  display: flex;
  justify-content: space-between;
}
.it-transfer-wrapper .transfer-header .form-check .descr {
  display: block;
  font-size: 12px;
  color: #435a70;
  font-weight: normal;
}
.it-transfer-wrapper
  .form-check
  input.semi-checked:not(:checked)
  + label::before {
  top: 13px;
  left: 4px;
  width: 12px;
  height: 2px;
  border-style: none;
  border-width: 0;
  border-color: transparent;
  transform: none;
  backface-visibility: hidden;
  opacity: 1;
  background: #fff;
}
.it-transfer-wrapper
  .form-check
  input.semi-checked:not(:checked)
  + label::after {
  border-color: #206cd6;
  background-color: #206cd6;
  z-index: 0;
}

.it-transfer-buttons {
  display: flex;
  justify-content: center;
}
.it-transfer-buttons a {
  display: flex;
  width: 40px;
  height: 40px;
  border: 1px solid #c5c7c9;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  margin: 24px 8px;
  cursor: default;
  pointer-events: none;
}
.it-transfer-buttons a svg {
  width: 16px;
  height: 16px;
  fill: #c5c7c9;
}
.it-transfer-buttons a.transfer,
.it-transfer-buttons a.backtransfer {
  transform: rotate(90deg);
}
.it-transfer-buttons a.active {
  border-color: #206cd6;
  cursor: pointer;
  pointer-events: all;
}
.it-transfer-buttons a.active svg {
  fill: #206cd6;
}

@media (min-width: 768px) {
  .it-transfer-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .it-transfer-buttons a {
    display: inline-flex;
    width: 40px;
    height: 40px;
    border: 1px solid #c5c7c9;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    margin: 8px 8px;
    align-self: center;
  }
  .it-transfer-buttons a.transfer,
  .it-transfer-buttons a.backtransfer {
    transform: none;
  }
}

@keyframes dropdownFadeIn {
  0% {
    opacity: 0;
    margin-top: 0;
  }
  100% {
    opacity: 1;
    margin-top: 16px;
  }
}

@keyframes dropdownFadeInTop {
  0% {
    opacity: 0;
    margin-top: 0;
  }
  100% {
    opacity: 1;
    margin-top: -16px;
  }
}

.dropdown.show .dropdown-menu[x-placement="bottom-start"],
.btn-group.show .dropdown-menu[x-placement="bottom-start"] {
  animation: dropdownFadeIn forwards 0.3s;
}

.dropdown.show .dropdown-menu[x-placement="top-start"],
.btn-group.show .dropdown-menu[x-placement="top-start"] {
  animation: dropdownFadeInTop forwards 0.3s;
}

.btn-dropdown {
  color: #0048ad;
  padding: 0 4px;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 0;
}
.btn-dropdown:not(:disabled):not(.disabled):active {
  box-shadow: none;
}

.btn-dropdown:after,
.dropdown-toggle:after {
  content: "";
  speak: none;
  border: none;
  display: inline;
  margin: 0;
  vertical-align: bottom;
  width: auto;
  height: auto;
}

.btn-dropdown .icon-expand,
.dropdown-toggle .icon-expand {
  transition: transform 0.3s;
}

.btn-dropdown .icon.icon-xs,
.dropdown-toggle .icon.icon-xs {
  transition: transform 0.3s;
  width: 18px;
  height: 18px;
  transform: translateY(-1px);
}

.btn-dropdown[aria-expanded="true"] .icon-expand,
.dropdown-toggle[aria-expanded="true"] .icon-expand {
  transform: scaleY(-1);
}

.dropdown-menu {
  border-radius: 0 0 4px 4px;
  z-index: 8;
}
.dropdown-menu.full-width {
  width: 100%;
}
.dropdown-menu.full-width .link-list li {
  display: inline-block;
  width: auto;
}
.dropdown-menu.full-width .link-list li:hover,
.dropdown-menu.full-width .link-list li:focus,
.dropdown-menu.full-width
  .link-list
  .toggles
  label
  input[type="checkbox"]:focus
  + li.lever,
.toggles
  label
  .dropdown-menu.full-width
  .link-list
  input[type="checkbox"]:focus
  + li.lever {
  background: none;
  text-decoration: underline;
}
.dropdown-menu .link-list {
  margin-bottom: 0;
}
.dropdown-menu .link-list h3 {
  line-height: 2.3em;
  margin-bottom: 0;
}
.dropdown-menu:before {
  content: "";
  position: absolute;
  top: -6px;
  left: 24px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: white;
  transform: rotate(45deg);
}
.dropdown-menu[x-placement="top-start"] {
  border-radius: 4px 4px 0 0;
}
.dropdown-menu[x-placement="top-start"]:before {
  bottom: -6px;
  top: auto;
}
.dropdown-menu.dark {
  background-color: #435a70;
}
.dropdown-menu.dark .link-list-wrapper ul span.divider {
  background: #2e465e;
}
.dropdown-menu.dark .link-list-wrapper ul li a span,
.dropdown-menu.dark .link-list-wrapper ul li a:hover span,
.dropdown-menu.dark .link-list-wrapper ul li h3,
.dropdown-menu.dark .link-list-wrapper ul li i {
  color: #fff;
}
.dropdown-menu.dark
  .link-list-wrapper
  ul
  li
  a:not(.active):not(.disabled):hover
  i {
  color: #4fe0dc;
}
.dropdown-menu.dark .link-list-wrapper ul li a.disabled span {
  color: #adb2b8;
}
.dropdown-menu.dark .link-list-wrapper ul li a.active span {
  color: #00fff7;
}
.dropdown-menu.dark:before {
  background-color: #435a70;
}

nav.pagination-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
nav.pagination-wrapper a {
  text-decoration: none;
}
nav.pagination-wrapper.pagination-total {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
nav.pagination-wrapper.pagination-total ul {
  margin-bottom: 0.5rem;
}
nav.pagination-wrapper.pagination-total p {
  font-size: 0.88889rem;
  color: #5b6f82;
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: center;
}
nav.pagination-wrapper .form-group {
  margin-left: 2.5rem;
  width: 4.5rem;
  margin-top: 0;
}
nav.pagination-wrapper .form-group label {
  font-size: 0.88889rem;
  color: #9da2a6;
}
nav.pagination-wrapper .form-group.active label {
  font-size: 0.8rem;
}
nav.pagination-wrapper .btn.dropdown-toggle {
  font-size: 0.88889rem;
  color: #5b6f82;
  font-weight: 600;
  padding: 12px;
  border-bottom: 1px solid #5c6f82;
  height: 2.5rem;
  border: none;
  width: 100%;
}
nav.pagination-wrapper .btn.dropdown-toggle:after {
  color: #0048ad;
  margin-left: 0.5rem;
}

.pagination {
  flex-wrap: wrap;
}
.pagination .page-item {
  margin-right: 5px;
}
.pagination .page-item.disabled a.page-link {
  color: #c2c7cc;
}
.pagination .page-item.disabled a.page-link .icon {
  fill: #c2c7cc;
}
.pagination .page-item span.page-link {
  pointer-events: none;
}
.pagination .page-item .page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  min-width: 2.5rem;
  border-radius: 4px;
  border: none;
  font-size: 0.88889rem;
  font-weight: 600;
  color: #5b6f82;
  background-color: transparent;
}
.pagination .page-item .page-link[aria-current] {
  border: 1px solid #0048ad;
  color: #0048ad;
  pointer-events: none;
  z-index: 0;
}
.pagination .page-item .page-link.text {
  color: #0048ad;
}
.pagination .page-item .page-link span.d-inline-block {
  margin-right: 0.5rem;
}
.pagination .page-item .page-link:hover {
  color: #0048ad;
  background-color: transparent;
  z-index: 0;
}
.pagination .page-item .page-link:hover .icon {
  fill: #0048ad;
}

@media (min-width: 576px) {
  .pagination .page-item .page-link {
    height: 2.66667rem;
    min-width: 2.66667rem;
  }
  nav.pagination-wrapper .btn.dropdown-toggle {
    height: 2.66667rem;
  }
}

@media (min-width: 768px) {
  nav.pagination-wrapper {
    justify-content: flex-start;
  }
  nav.pagination-wrapper .dropdown {
    margin-left: 2.66667rem;
  }
}

.nav-tabs {
  position: relative;
  background-color: white;
  border-bottom-color: #dfe4f2;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap;
}
.nav-tabs a {
  text-decoration: none;
}
.nav-tabs .it-ico:before,
.nav-tabs .it-ico-lg:before {
  margin-right: 0;
}
.nav-tabs.auto .nav-link {
  flex: 1;
}
.nav-tabs.auto .nav-item {
  flex: 1;
  text-align: center;
}
.nav-tabs.no-border {
  border: none !important;
}
.nav-tabs.no-background {
  background-color: transparent;
}
.nav-tabs.no-background .nav-item .nav-link.active {
  background-color: transparent;
}
.nav-tabs.nav-tabs-icon-text .icon {
  margin-right: 0.889rem;
}
.nav-tabs.nav-tabs-icon-lg .nav-link {
  padding: 0.778rem 1.778em;
}
.nav-tabs.nav-tabs-vertical {
  flex-direction: column;
  border-bottom: none;
  border-right: 1px solid #dfe4f2;
}
.nav-tabs.nav-tabs-vertical .nav-link {
  justify-content: space-between;
  border-bottom: none;
  border-right: 2px solid transparent;
  white-space: normal;
}
.nav-tabs.nav-tabs-vertical .nav-link:hover {
  border-bottom: none;
}
.nav-tabs.nav-tabs-vertical .nav-link.justify-content-end .it-ico:before,
.nav-tabs.nav-tabs-vertical .nav-link.justify-content-end .it-ico-lg:before {
  margin-right: 0;
}
.nav-tabs.nav-tabs-vertical .nav-link.active,
.nav-tabs.nav-tabs-vertical .nav-item.show .nav-link {
  border-bottom: none;
  border-right-color: #0048ad;
}
.nav-tabs.nav-tabs-vertical.nav-tabs-vertical-background .nav-link.active,
.nav-tabs.nav-tabs-vertical.nav-tabs-vertical-background
  .nav-item.show
  .nav-link {
  background-color: #e6ecf2;
}
.nav-tabs .nav-link {
  border-radius: 0;
  font-weight: 600;
  color: #5b6f82;
  border: none;
  border-bottom: 2px solid transparent;
  padding: 0.778rem 1.333em;
  flex: 1 0 auto;
  white-space: nowrap;
  line-height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-tabs .nav-link:hover {
  color: #0048ad;
  border-bottom-color: transparent;
}
.nav-tabs .nav-link:focus,
.nav-tabs .form-check [type="checkbox"]:focus + label.nav-link,
.form-check .nav-tabs [type="checkbox"]:focus + label.nav-link,
.nav-tabs .form-check [type="radio"]:focus + label.nav-link,
.form-check .nav-tabs [type="radio"]:focus + label.nav-link,
.nav-tabs .toggles label input[type="checkbox"]:focus + .nav-link.lever,
.toggles label .nav-tabs input[type="checkbox"]:focus + .nav-link.lever,
.nav-tabs .block-facet--checkbox [type="checkbox"]:focus + label.nav-link,
.block-facet--checkbox .nav-tabs [type="checkbox"]:focus + label.nav-link {
  border-bottom-color: transparent;
}
.nav-tabs .nav-link.disabled {
  color: #b1b1b3;
  cursor: default;
}
.nav-tabs .nav-link.disabled .icon {
  fill: #b1b1b3;
}
.nav-tabs .nav-link .it-ico:before {
  font-size: 1.333rem;
}
.nav-tabs .nav-link .it-ico-lg:before {
  font-size: 1.778rem;
}
.nav-tabs .nav-link .icon {
  fill: #5c6f82;
}
.nav-tabs .nav-link.active .icon {
  fill: #0048ad;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-bottom-color: #0048ad;
  color: #0048ad;
  cursor: inherit;
}
.nav-tabs .nav-item {
  margin-bottom: 0;
  display: flex;
}
.nav-tabs.nav-dark {
  background-color: #455b71;
  border-bottom: none;
}
.nav-tabs.nav-dark .nav-link {
  color: #d9dadb;
}
.nav-tabs.nav-dark .nav-link .icon {
  fill: #d9dadb;
}
.nav-tabs.nav-dark .nav-link:hover {
  color: #00fff7;
}
.nav-tabs.nav-dark .nav-link:hover .icon {
  fill: #00fff7;
}
.nav-tabs.nav-dark .nav-link.disabled {
  color: #768594;
}
.nav-tabs.nav-dark .nav-link.disabled .icon {
  fill: #768594;
}
.nav-tabs.nav-dark .nav-link.active,
.nav-tabs.nav-dark .nav-item.show .nav-link {
  background-color: #455b71;
  color: #00fff7;
  border-bottom: 2px solid #00fff7;
}
.nav-tabs.nav-dark .nav-link.active .icon,
.nav-tabs.nav-dark .nav-item.show .nav-link .icon {
  fill: #00fff7;
}
.nav-tabs.nav-dark.nav-tabs-vertical .nav-link.active,
.nav-tabs.nav-dark.nav-tabs-vertical .nav-item.show .nav-link {
  border-right-color: #00fff7;
  border-bottom: none;
}
.nav-tabs.nav-tabs-cards {
  border-bottom: none;
}
.nav-tabs.nav-tabs-cards .nav-item-filler {
  flex-grow: 1;
}
.nav-tabs.nav-tabs-cards .nav-link {
  border: 1px solid transparent;
}
.nav-tabs.nav-tabs-cards .nav-link.active,
.nav-tabs.nav-tabs-cards .nav-item.show .nav-link {
  border: 1px solid #dfe4f2;
  border-bottom-color: #fff;
  border-radius: 4px 4px 0 0;
}
.nav-tabs.nav-tabs-editable .nav-item {
  position: relative;
}
.nav-tabs.nav-tabs-editable .nav-item-filler {
  width: 2em;
}
.nav-tabs.nav-tabs-editable .nav-tab-add {
  margin: -0.2em 1em 0;
  position: relative;
  width: 1.444rem;
  height: 1.444rem;
  top: 0.8rem;
  border: 1px solid #dfe4f2;
  border-radius: 50%;
  right: 0;
}
.nav-tabs.nav-tabs-editable .nav-tab-add:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 0.778rem;
  left: 0.611rem;
  top: 0.278rem;
  background-color: #0048ad;
}
.nav-tabs.nav-tabs-editable .nav-tab-add:before {
  content: "";
  position: absolute;
  width: 0.778rem;
  height: 2px;
  left: 0.278rem;
  top: 0.611rem;
  background-color: #0048ad;
}
.nav-tabs.nav-tabs-editable .nav-link {
  padding-right: 2.888em;
}
.nav-tabs.nav-tabs-editable .nav-link-close {
  cursor: pointer;
  position: absolute;
  top: calc(50% - 0.9rem);
  right: 0.889rem;
  color: #5b6f82;
  transition: color 0.2s;
}
.nav-tabs.nav-tabs-editable .nav-link-close .icon {
  fill: #5b6f82;
}
.nav-tabs.nav-tabs-editable .nav-link-close.disabled {
  color: #b1b1b3;
  cursor: pointer;
}
.nav-tabs.nav-tabs-editable .nav-link-close.disabled .icon {
  fill: #b1b1b3;
}
.nav-tabs.nav-tabs-editable .nav-link-close:hover {
  color: #00337a;
}
.nav-tabs.nav-tabs-editable .nav-link-close:hover .icon {
  fill: #00337a;
}
.nav-tabs.nav-tabs-editable .nav-link-close .it-ico {
  font-size: 0.6rem;
}
.nav-tabs.nav-tabs-editable .nav-link-close .it-ico:before {
  margin-right: 0;
}
.nav-tabs.nav-tabs-editable .nav-link.active .nav-link-close,
.nav-tabs.nav-tabs-editable .nav-item.show .nav-link .nav-link-close {
  color: #0048ad;
}
.nav-tabs.nav-tabs-editable .nav-link.active .nav-link-close .icon,
.nav-tabs.nav-tabs-editable .nav-item.show .nav-link .nav-link-close .icon {
  fill: #0048ad;
}

.flex-column-reverse .nav-tabs {
  border-bottom: none;
  border-top: 1px solid #dfe4f2;
}
.flex-column-reverse .nav-tabs .nav-link {
  border-bottom: none;
  border-top: 2px solid transparent;
}
.flex-column-reverse .nav-tabs .nav-link.active,
.flex-column-reverse .nav-tabs .nav-item.show .nav-link {
  border-top-color: #0048ad;
}

.flex-row-reverse .nav-tabs.nav-tabs-vertical {
  border-right: none;
  border-left: 1px solid #dfe4f2;
}
.flex-row-reverse .nav-tabs.nav-tabs-vertical .nav-link {
  justify-content: flex-start;
  border-bottom: none;
  border-right: none;
  border-left: 2px solid transparent;
}
.flex-row-reverse .nav-tabs.nav-tabs-vertical .nav-link:hover {
  border-bottom: none;
}
.flex-row-reverse .nav-tabs.nav-tabs-vertical .nav-link .icon {
  margin-right: 0.889rem;
}
.flex-row-reverse .nav-tabs.nav-tabs-vertical .nav-link.active,
.flex-row-reverse .nav-tabs.nav-tabs-vertical .nav-item.show .nav-link {
  border-left-color: #0048ad;
}

.flex-row-reverse .nav-tabs.nav-dark.nav-tabs-vertical .nav-link.active,
.flex-row-reverse
  .nav-tabs.nav-dark.nav-tabs-vertical
  .nav-item.show
  .nav-link {
  border-left-color: #00fff7;
}

.nav-tabs-hidescroll {
  height: 2.667rem;
  overflow: hidden;
}
.nav-tabs-hidescroll.hidescroll-ico {
  height: 2.944rem;
}
.nav-tabs-hidescroll.hidescroll-ico-lg {
  height: 3.444rem;
}
.nav-tabs-hidescroll .nav-tabs {
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  .nav-tabs {
    overflow-x: hidden;
    flex-wrap: wrap;
  }
  .nav-tabs.auto {
    flex-wrap: nowrap;
  }
  .nav-tabs-hidescroll {
    height: auto;
    overflow: initial;
  }
  .nav-tabs-hidescroll.hidescroll-ico,
  .nav-tabs-hidescroll.hidescroll-ico-lg {
    height: auto;
  }
  .nav-tabs-hidescroll .nav-tabs {
    padding-bottom: 0;
  }
}

.cookiebar {
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 1100;
  background-color: #435a70;
  padding: 24px 32px;
}
.cookiebar.cookiebar-top {
  bottom: auto;
  top: 0;
}
.cookiebar.show {
  display: block;
}
.cookiebar p {
  font-size: 1rem;
  line-height: 1.25em;
  color: white;
  margin-bottom: 0;
}
.cookiebar p br {
  display: none;
}
.cookiebar .cookiebar-buttons {
  text-align: right;
  margin-top: 32px;
  padding: 0;
  line-height: 1.25em;
}
.cookiebar .cookiebar-btn {
  background: transparent;
  border: none;
  padding: 0;
  color: white;
  letter-spacing: 0.1em;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  display: inline-block;
  line-height: 1em;
}
.cookiebar .cookiebar-btn:hover {
  text-decoration: underline;
  cursor: pointer;
}
.cookiebar .cookiebar-btn:last-child {
  margin-left: 32px;
}
.cookiebar .cookiebar-btn:not(.cookiebar-confirm) {
  color: #e6ecf2;
}

@media (min-width: 576px) {
  .cookiebar p {
    font-size: 0.889rem;
  }
  .cookiebar .cookiebar-btn {
    font-size: 0.778rem;
  }
}

@media (min-width: 768px) {
  .cookiebar {
    padding: 32px 48px;
  }
  .cookiebar.show {
    display: flex;
    justify-content: space-between;
  }
  .cookiebar p {
    width: 60%;
    margin-right: 24px;
  }
  .cookiebar p br {
    display: block;
  }
  .cookiebar .cookiebar-buttons {
    margin-top: 0;
    width: 40%;
  }
}

@media (min-width: 992px) {
  .cookiebar {
    max-width: 832px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    border-radius: 4px 4px 0 0;
  }
  .cookiebar p {
    width: 66%;
  }
  .cookiebar .cookiebar-buttons {
    width: 33%;
  }
}

.it-hero-wrapper {
  min-height: 380px;
  position: relative;
  background-color: #0055cc;
  display: flex;
  align-items: flex-end;
}
.it-hero-wrapper .img-responsive-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.it-hero-wrapper .img-responsive-wrapper .img-responsive {
  padding-bottom: inherit;
  height: 100%;
  position: initial;
}
.it-hero-wrapper.it-hero-small-size {
  min-height: 230px;
}
.it-hero-wrapper .it-hero-text-wrapper {
  padding: 48px 34px;
  background: transparent !important;
  position: relative;
}
.it-hero-wrapper .it-hero-text-wrapper :last-child {
  margin-bottom: 0;
}
.it-hero-wrapper .it-hero-text-wrapper span,
.it-hero-wrapper .it-hero-text-wrapper h1,
.it-hero-wrapper .it-hero-text-wrapper p {
  color: white;
  margin-bottom: 24px;
}
.it-hero-wrapper .it-hero-text-wrapper span.it-category {
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.9px;
}
.it-hero-wrapper .it-hero-text-wrapper h1 {
  font-size: 2rem;
  letter-spacing: unset;
}
.it-hero-wrapper .it-hero-text-wrapper p {
  font-size: 1rem;
  font-family: Lora, Georgia, serif;
}
.it-hero-wrapper .it-hero-text-wrapper .it-btn-container {
  margin-top: 8px;
}
.it-hero-wrapper.it-text-centered .it-hero-text-wrapper {
  text-align: center;
}
.it-hero-wrapper.it-overlay .img-responsive-wrapper:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.it-hero-wrapper.it-overlay.it-dark .img-responsive-wrapper:after {
  background: rgba(23, 50, 77, 0.54);
}
.it-hero-wrapper.it-overlay.it-primary .img-responsive-wrapper:after {
  background: rgba(0, 85, 204, 0.85);
}
.it-hero-wrapper.it-overlay.it-filter .img-responsive-wrapper img {
  mix-blend-mode: screen;
}
.it-hero-wrapper.it-overlay.it-filter .img-responsive-wrapper:after {
  display: none;
}
.it-hero-wrapper.it-bottom-overlapping-content {
  margin-bottom: -80px;
}
.it-hero-wrapper.it-bottom-overlapping-content .it-hero-text-wrapper {
  padding-bottom: 136px;
}
.it-hero-wrapper.it-wrapped-container {
  min-height: 0 !important;
  align-items: flex-end;
}
.it-hero-wrapper .it-hero-card {
  background-color: white;
}
.it-hero-wrapper .it-hero-card.it-hero-bottom-overlapping {
  margin-top: 80px;
  margin-bottom: -80px;
}

@media (max-width: 575px) {
  .it-hero-wrapper.it-wrapped-container {
    display: flex;
    flex-direction: column;
  }
  .it-hero-wrapper.it-wrapped-container .container {
    background-color: white;
  }
  .it-hero-wrapper.it-wrapped-container .img-responsive-wrapper {
    position: relative;
  }
  .it-hero-wrapper.it-wrapped-container .img-responsive-wrapper .img-wrapper {
    position: relative;
  }
  .it-hero-wrapper.it-wrapped-container .img-responsive-wrapper:after {
    content: none;
  }
  .it-hero-wrapper.it-wrapped-container .it-hero-card {
    background-color: white;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .it-hero-wrapper .it-hero-card.it-hero-bottom-overlapping {
    margin: 0;
  }
}

@media (min-width: 576px) {
  .it-hero-wrapper.it-wrapped-container {
    min-height: 0 !important;
    align-items: flex-end;
  }
  .it-hero-wrapper .it-hero-card {
    background-color: white;
  }
  .it-hero-wrapper .it-hero-card.it-hero-bottom-overlapping {
    margin-top: 80px;
    margin-bottom: -80px;
  }
}

@media (min-width: 768px) {
  .it-hero-wrapper.it-hero-small-size {
    min-height: 300px;
  }
  .it-hero-wrapper .it-hero-text-wrapper {
    padding: 48px 40% 48px 34px;
  }
  .it-hero-wrapper.it-text-centered .it-hero-text-wrapper {
    padding: 48px 20% 48px 20%;
  }
}

@media (min-width: 992px) {
  .it-hero-wrapper {
    position: relative;
    min-height: 620px;
    align-items: center;
  }
  .it-hero-wrapper.it-hero-small-size {
    min-height: 400px;
  }
  .it-hero-wrapper .it-hero-text-wrapper {
    padding: 120px 40% 168px 26px;
  }
  .it-hero-wrapper .it-hero-text-wrapper span.it-category {
    position: absolute;
    margin-top: -56px;
  }
  .it-hero-wrapper .it-hero-text-wrapper h1 {
    font-size: 2.66667rem;
  }
  .it-hero-wrapper .it-hero-text-wrapper p {
    margin-bottom: 0;
  }
  .it-hero-wrapper .it-hero-text-wrapper .it-btn-container {
    position: absolute;
    margin-top: 48px;
  }
  .it-hero-wrapper.it-text-centered .it-hero-text-wrapper {
    padding: 120px 20% 144px 20%;
  }
  .it-hero-wrapper.it-text-centered .it-hero-text-wrapper span.it-category {
    left: 20%;
    right: 20%;
  }
  .it-hero-wrapper.it-text-centered .it-hero-text-wrapper .it-btn-container {
    left: 20%;
    right: 20%;
  }
  .it-hero-wrapper.it-bottom-overlapping-content {
    margin-bottom: -80px;
  }
  .it-hero-wrapper.it-bottom-overlapping-content .it-hero-text-wrapper {
    padding-bottom: 240px;
    padding-top: 160px;
  }
}

.forward {
  display: block;
  text-align: center;
  font-size: 3rem;
  transition: all 0.2s ease-in-out;
}
.forward:hover {
  text-decoration: none;
}

/*back to top*/
.back-to-top {
  position: fixed;
  bottom: 16px;
  right: 16px;
  background: #0048ad;
  width: 40px;
  height: 40px;
  text-decoration: none;
  border-radius: 50%;
  display: block;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  transition: all 0.2s ease-in-out,
    transform 0.25s cubic-bezier(0.75, -0.5, 0, 1.75), visibility 0.3s linear,
    opacity 0.3s ease-in-out;
  z-index: 1;
  transform: scale(0.7);
  transform-origin: center center;
}
.back-to-top.back-to-top-show {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease-in-out, transform 0.05s ease-out,
    visibility 0s linear, opacity 0.1s ease-in-out;
  transform: scale(1);
}
.back-to-top:hover {
  background: #00337a;
  text-decoration: none;
}
.back-to-top .icon {
  margin: 0;
  position: relative;
  transform: scale(0.75);
  top: 4px;
}
.back-to-top .icon:before {
  margin: 0;
  color: white;
}
.back-to-top.shadow:hover {
  box-shadow: 0 0.3rem 0.75rem rgba(0, 0, 0, 0.3) !important;
}
.back-to-top.dark {
  background: #fff;
}
.back-to-top.dark:hover {
  background: #ebeced;
}
.back-to-top.dark .icon:before {
  color: #435a70;
}

@media (min-width: 768px) {
  /*back to top*/
  .back-to-top {
    width: 56px;
    height: 56px;
  }
  .back-to-top .icon {
    transform: scale(1);
    top: 10px;
  }
  .back-to-top.back-to-top-small {
    width: 40px;
    height: 40px;
  }
  .back-to-top.back-to-top-small .icon {
    transform: scale(0.75);
    top: 4px;
  }
}

@media (min-width: 1200px) {
  .back-to-top {
    bottom: 32px;
    right: 32px;
  }
}

.autocomplete {
  padding: 0.375rem 48px 0.375rem 24px;
}
.autocomplete::placeholder {
  color: #adb2b8;
}
.autocomplete:disabled {
  background-color: #cacacc;
}
.autocomplete:disabled::placeholder {
  color: #5c6f82;
}

.autocomplete-icon {
  position: absolute;
  right: 0;
  top: 0.5rem;
  padding: 0 8px;
  background-color: white;
}
.autocomplete-icon .icon {
  fill: #5c6f82;
}

.autocomplete-list {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  padding: 8px 0;
  box-shadow: 0 20px 30px 5px rgba(0, 0, 0, 0.05);
  border-top: 1px solid #5c6f82;
  transition: opacity 0.3s;
  display: none;
}
.autocomplete-list.autocomplete-list-show {
  display: block;
  z-index: 10;
}
.autocomplete-list li {
  list-style-type: none;
  padding: 0;
}
.autocomplete-list a {
  padding: 12px 24px;
  display: block;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #455b71;
  line-height: 1.2;
  font-size: 1rem;
}
.autocomplete-list a:hover {
  color: #0048ad;
  text-decoration: none;
}
.autocomplete-list a:hover .icon {
  fill: #0048ad;
}
.autocomplete-list a:hover .autocomplete-list-text span {
  text-decoration: underline;
}
.autocomplete-list a:hover .autocomplete-list-text em {
  color: #0048ad;
}
.autocomplete-list a:hover mark {
  color: #0048ad;
}
.autocomplete-list a .icon,
.autocomplete-list a .avatar {
  margin-right: 16px;
  flex-shrink: 0;
}
.autocomplete-list a .icon {
  fill: #455b71;
}
.autocomplete-list a .autocomplete-list-text {
  margin-top: 2px;
}
.autocomplete-list a .autocomplete-list-text em {
  font-style: normal;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #435a70;
  font-weight: 600;
  letter-spacing: 0.1em;
  display: block;
  margin-top: 4px;
}
.autocomplete-list mark {
  padding: 0;
  background: transparent;
  font-weight: bold;
}

.autocomplete-wrapper-big .autocomplete {
  height: 56px;
  font-size: 1.5rem;
  font-weight: normal;
  padding-left: 64px;
  padding-right: 24px;
}

.autocomplete-wrapper-big .autocomplete-icon {
  position: absolute;
  left: 0.5rem;
  right: auto;
  top: 1rem;
}
.autocomplete-wrapper-big .autocomplete-icon .icon {
  fill: #0048ad;
  width: 24px;
  height: 24px;
}

.autocomplete-wrapper-big .autocomplete-list a {
  font-size: 1.125rem;
}
.autocomplete-wrapper-big .autocomplete-list a .autocomplete-list-text {
  margin-top: 0;
}

@media (min-width: 576px) {
  .autocomplete-list a {
    font-size: 0.889rem;
    align-items: flex-start;
  }
  .autocomplete-list a .autocomplete-list-text span {
    margin-right: 8px;
  }
  .autocomplete-list a .autocomplete-list-text em {
    display: inline-block;
    font-size: 0.667rem;
    margin-top: 0;
  }
  .autocomplete-wrapper-big .autocomplete {
    height: 72px;
    font-size: 2.222rem;
    padding-left: 64px;
  }
  .autocomplete-wrapper-big .autocomplete-icon .icon {
    width: 40px;
    height: 40px;
  }
  .autocomplete-wrapper-big .autocomplete-list a {
    font-size: 1.111rem;
  }
  .autocomplete-wrapper-big .autocomplete-list a .autocomplete-list-text span {
    margin-right: 16px;
  }
}

.link-list-wrapper ul {
  padding: 0;
  list-style-type: none;
}
.link-list-wrapper ul.link-sublist {
  padding-left: 24px;
}
.link-list-wrapper ul li a {
  font-size: 0.889em;
  line-height: 2.3em;
  display: block;
  padding: 0 24px;
  position: relative;
  text-decoration: none;
}
.link-list-wrapper ul li a:hover:not(.disabled) {
  text-decoration: none;
}
.link-list-wrapper ul li a:hover:not(.disabled) span {
  color: #0048ad;
  text-decoration: underline;
}
.link-list-wrapper ul li a:hover:not(.disabled) p {
  color: #0048ad;
  text-decoration: none;
}
.link-list-wrapper ul li a:hover:not(.disabled) .icon {
  fill: #0048ad;
}
.link-list-wrapper ul li a.disabled:hover {
  text-decoration: none;
}
.link-list-wrapper ul li a.medium {
  font-weight: 600;
}
.link-list-wrapper ul li a span {
  color: #0048ad;
  display: inline-block;
  margin-right: 24px;
}
.link-list-wrapper ul li a .icon {
  display: inline-block;
  margin: 8px 0;
}
.link-list-wrapper ul li a .icon.icon-right {
  position: absolute;
  right: 0;
}
.link-list-wrapper ul li a .icon.right {
  margin-left: 8px;
}
.link-list-wrapper ul li a .icon.left {
  margin-right: 8px;
}
.link-list-wrapper ul li a.right-icon .icon.right {
  position: absolute;
  top: 0;
  right: 24px;
  transition: transform 0.3s;
}
.link-list-wrapper ul li a.right-icon .icon.right.secondary {
  color: #9da2a6;
}
.link-list-wrapper ul li a.right-icon[aria-expanded="true"] .icon.right {
  transform: scaleY(-1);
}
.link-list-wrapper ul li a.active span {
  color: #17324d;
  text-decoration: underline;
}
.link-list-wrapper ul li a.active .icon {
  color: #17324d;
}
.link-list-wrapper ul li a.disabled {
  cursor: not-allowed;
}
.link-list-wrapper ul li a.disabled span {
  color: #adb2b8;
}
.link-list-wrapper ul li a.disabled svg {
  fill: #adb2b8;
}
.link-list-wrapper ul li a.disabled svg.secondary {
  fill: #adb2b8;
}
.link-list-wrapper ul li a.large {
  font-size: 1em;
}
.link-list-wrapper ul li a p {
  font-size: 0.778em;
  line-height: initial;
  color: #5b6f82;
}
.link-list-wrapper ul li a.avatar {
  display: flex;
  margin-bottom: 8px;
}
.link-list-wrapper ul li a.avatar .avatar {
  display: block;
  border-radius: 50px;
  margin-right: 8px;
}
.link-list-wrapper ul li a.list-item.icon-left {
  position: relative;
  padding-left: 32px;
}
.link-list-wrapper ul li a.list-item.icon-left span {
  margin-top: 4px;
}
.link-list-wrapper ul li a.list-item.icon-left .icon {
  position: absolute;
  left: 0;
}
.link-list-wrapper ul li a.list-item.active {
  color: #18417a;
  text-decoration: underline;
}
.link-list-wrapper ul li a.list-item.active span {
  color: #18417a;
}
.link-list-wrapper ul li h3 {
  font-size: 1em;
  color: #17324d;
  padding: 0 24px;
}
.link-list-wrapper ul li h3 a {
  line-height: inherit;
  font-size: 1em;
  padding: 0;
}
.link-list-wrapper ul .divider {
  display: block;
  height: 1px;
  background: #ebeced;
  margin: 8px 0;
}
.link-list-wrapper ul .toggles label {
  padding: 0 24px;
  font-size: 0.889em;
  line-height: 2.3em;
  justify-content: space-between;
  height: auto;
  font-weight: inherit;
  margin: 0;
}
.link-list-wrapper ul .toggles input[type="checkbox"] + .lever {
  margin: 8px 0 0;
}
.link-list-wrapper ul .form-check.form-check-group {
  padding: 0 24px;
  box-shadow: none;
}
.link-list-wrapper ul .form-check.form-check-group [type="checkbox"] + label {
  padding-left: 0;
  font-size: 0.889em;
  line-height: 2.3em;
  height: inherit;
  margin-bottom: 0;
}
.link-list-wrapper
  ul
  .form-check.form-check-group
  [type="checkbox"]
  + label:after {
  right: 24px;
}
.link-list-wrapper
  ul
  .form-check.form-check-group
  [type="checkbox"]
  + label:before {
  right: 35px;
}
.link-list-wrapper
  ul
  .form-check.form-check-group
  [type="checkbox"][disabled]
  + label {
  color: #adb2b8;
}

@media (min-width: 576px) {
  .link-list-wrapper ul li a.large {
    font-size: 1em;
    line-height: 2.7em;
  }
}

@media (min-width: 768px) {
  .link-list-wrapper ul li a.large {
    line-height: 2.7em;
  }
}

@media (min-width: 992px) {
  .link-list-wrapper.menu-link-list {
    position: relative;
  }
  .link-list-wrapper.menu-link-list h3,
  .link-list-wrapper.menu-link-list h4 {
    position: relative;
    padding: 1rem;
    margin-top: 0;
    border-bottom: 4px solid #b1b1b3;
  }
  .link-list-wrapper.menu-link-list h3:after,
  .link-list-wrapper.menu-link-list h4:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 40px;
    height: 4px;
    background-color: #a66300;
  }
}

@keyframes megamenuFadeIn {
  0% {
    opacity: 0;
    margin-top: 16px;
  }
  100% {
    opacity: 1;
    margin-top: 16px;
  }
}

.navbar {
  background: #0048ad;
}
.navbar .overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: none;
}
.navbar .navbar-collapsable {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: none;
  width: 100%;
  /*lista menu*/
}
.navbar .navbar-collapsable .menu-wrapper {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 48px;
  left: 0;
  pointer-events: all;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: all 0.2s cubic-bezier(0.29, 0.85, 0.5, 0.99);
}
.navbar .navbar-collapsable .menu-wrapper .navbar-nav {
  padding: 24px 0 0;
  overflow: hidden;
}
.navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu {
  padding-bottom: 0;
}
.navbar .navbar-collapsable .navbar-nav {
  padding: 24px 0;
}
.navbar .navbar-collapsable .navbar-nav li a.nav-link {
  color: #0048ad;
  font-weight: 600;
  padding: 13px 24px;
  position: relative;
}
.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
  fill: #0048ad;
  transition: all 0.3s;
}
.navbar
  .navbar-collapsable
  .navbar-nav
  li
  a.nav-link.dropdown-toggle[aria-expanded="true"]
  .icon {
  transform: scaleY(-1);
}
.navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
  border-left: 3px solid #0048ad;
}
.navbar .navbar-collapsable .navbar-nav li a.nav-link:hover:not(.active) {
  text-decoration: underline;
}
.navbar .navbar-collapsable.expanded .menu-wrapper {
  transform: translateX(0);
  transition: all 0.3s cubic-bezier(0.29, 0.85, 0.5, 0.99);
}
.navbar .navbar-collapsable.expanded .close-div {
  transform: translateX(0);
}
.navbar .close-div {
  transform: translateX(-100%);
  padding: 24px 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: white;
  z-index: 10;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.1, 0.57, 0.4, 0.97);
}
.navbar .close-div .close-menu {
  background: transparent;
  pointer-events: all;
  color: #0048ad;
  text-align: center;
  font-size: 0.75em;
  text-transform: uppercase;
  padding: 0;
  float: right;
}
.navbar .close-div .close-menu span {
  display: block;
  text-align: center;
  font-size: 2em;
}
.navbar .close-div .close-menu span:before {
  margin-right: 0;
}
.navbar .dropdown-menu {
  background: transparent;
  box-shadow: none;
  padding-top: 0;
}
.navbar .dropdown-menu:before {
  display: none;
}
.navbar .dropdown-menu .link-list-wrapper {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
}
.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active {
  color: #18417a;
  text-decoration: underline;
}
.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span {
  color: #18417a;
}
.navbar .dropdown-menu .link-list-wrapper ul li a span {
  color: #0048ad;
}
.navbar .dropdown-menu .link-list-wrapper ul li a i {
  color: #0048ad;
}
.navbar .dropdown-menu .link-list-wrapper ul li h3 {
  text-transform: uppercase;
  font-size: 0.833em;
  font-weight: 600;
  letter-spacing: 1px;
}
.navbar .dropdown-menu .link-list-wrapper ul li .divider {
  width: 65px;
  background: #e6ecf2;
  margin-left: 24px;
}

.custom-navbar-toggler {
  background: none;
  border: none;
  cursor: pointer;
}
.custom-navbar-toggler span {
  color: white;
  font-size: 1.625em;
}
.custom-navbar-toggler svg {
  fill: white;
  width: 24px;
  height: 24px;
  display: block;
}

.nav-item.megamenu > a:before {
  display: none;
}

.inline-menu .link-list-wrapper .link-list {
  position: relative;
}
.inline-menu .link-list-wrapper .link-list li a {
  position: relative;
}
.inline-menu .link-list-wrapper .link-list li a i {
  color: #6a9feb;
}
.inline-menu .link-list-wrapper .link-list li a:after {
  content: "";
  display: block;
  width: 2px;
  background: #0048ad;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  transform-origin: center;
  transform: scaleY(0);
  transition: all 0.3s;
  z-index: 1;
}
.inline-menu .link-list-wrapper .link-list li a.active span {
  color: #0048ad;
  text-decoration: none;
}
.inline-menu .link-list-wrapper .link-list li a[aria-expanded="true"]:after {
  transform: scaleY(1);
}
.inline-menu .link-list-wrapper .link-list:after {
  content: "";
  display: block;
  width: 2px;
  background: linear-gradient(
    to bottom,
    #e6ecf2 0%,
    rgba(230, 236, 242, 0.3) 100%
  );
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

@media (min-width: 992px) {
  .navbar {
    background: #0048ad;
    padding: 0;
  }
  .navbar .navbar-collapsable {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    z-index: auto;
    background: none;
    display: block !important;
    transition: none;
    /*lista menu*/
  }
  .navbar .navbar-collapsable .overlay {
    display: none !important;
  }
  .navbar .navbar-collapsable .menu-wrapper {
    position: inherit;
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
    overflow-y: visible;
    transform: none;
    transition: none;
    background: #0048ad;
  }
  .navbar .navbar-collapsable .menu-wrapper .navbar-nav {
    margin-top: 0;
    padding: 0 24px;
    overflow: inherit;
  }
  .navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu {
    padding-bottom: 0.5rem;
  }
  .navbar .navbar-collapsable .navbar-nav {
    margin-top: 0;
    padding: 0 24px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .navbar .navbar-collapsable .navbar-nav li.nav-item {
    display: flex;
  }
  .navbar .navbar-collapsable .navbar-nav li.nav-item .nav-link {
    display: flex;
    align-self: flex-end;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-collapsable .navbar-nav li a.nav-link {
    font-weight: 400;
    padding: 13px 24px;
    color: white;
    border-bottom: 3px solid transparent;
  }
  .navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
    fill: white;
  }
  .navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
    border-left: 0;
    border-color: white;
    background: transparent;
  }
  .navbar .navbar-collapsable .navbar-nav li a.nav-link.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .navbar .close-div {
    display: none;
  }
  .navbar .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #19191a;
    text-align: left;
    list-style: none;
    background-color: white;
    background-clip: padding-box;
    border: 0 solid transparent;
    border-radius: 4px;
    box-shadow: 0 20px 30px 5px rgba(0, 0, 0, 0.05);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .navbar .dropdown-menu.show {
    animation: dropdownFadeIn forwards 0.3s;
    top: calc(100% - 16px);
  }
  .navbar .dropdown-menu:before {
    display: block;
  }
  .navbar .dropdown-menu .link-list-wrapper {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
  .navbar .dropdown-menu .link-list-wrapper ul li a span,
  .navbar .dropdown-menu .link-list-wrapper ul li a i,
  .navbar .dropdown-menu .link-list-wrapper ul li a h3 {
    color: #0048ad;
  }
  .navbar .dropdown-menu .link-list-wrapper ul li h3 {
    color: #19191a;
  }
  .navbar .dropdown-toggle {
    display: block;
    justify-content: inherit;
  }
  .custom-navbar-toggler {
    display: none;
  }
  .has-megamenu .navbar-collapsable {
    width: 100%;
  }
  .nav-item.megamenu {
    position: static;
  }
  .nav-item.megamenu > a.dropdown-toggle {
    position: relative;
  }
  .nav-item.megamenu > a.dropdown-toggle:before {
    content: "";
    position: absolute;
    top: auto;
    bottom: -12px;
    left: 24px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: white;
    transform: rotate(45deg);
    opacity: 0;
    display: block;
    transition: opacity 0.15s cubic-bezier(0.1, 0.57, 0.36, 0.99);
  }
  .nav-item.megamenu.show a.dropdown-toggle:before {
    opacity: 1;
  }
  .megamenu .dropdown-menu {
    min-width: auto;
    left: 24px;
    right: 24px;
    animation-timing-function: cubic-bezier(0.1, 0.57, 0.36, 0.99);
  }
  .megamenu .dropdown-menu:before {
    display: none;
  }
  .megamenu .dropdown-menu.show {
    animation: megamenuFadeIn forwards 0.15s;
  }
}

.skiplinks {
  background-color: #0bd9d2;
  text-align: center;
}
.skiplinks a {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  display: block;
  font-weight: 600;
  color: #5b6f82;
  text-decoration: underline;
}

.affix-top {
  position: sticky;
  top: 0;
}

.affix-bottom {
  position: sticky;
  bottom: 0;
}

.affix-parent {
  position: relative;
}

.sidebar-wrapper {
  padding: 24px 0;
}
.sidebar-wrapper h3 {
  font-weight: 600;
  margin-bottom: 0.8rem;
  font-size: 0.833em;
  margin-top: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 24px;
  padding-right: 24px;
}
.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper h3 {
  font-weight: 600;
  margin-bottom: 0.8rem;
  font-size: 0.833em;
  margin-top: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a {
  line-height: 1em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  font-size: 1em;
}
.sidebar-wrapper
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a
  i.it-expand {
  font-size: 0.8em;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar-wrapper
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[aria-expanded="true"]
  i {
  transform: translateY(-50%) scaleY(-1);
}
.sidebar-wrapper
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"].active {
  border-left: 2px solid #0048ad;
}
.sidebar-wrapper
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"].active
  span,
.sidebar-wrapper
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"].active
  i {
  color: #0048ad;
  text-decoration: none;
}
.sidebar-wrapper
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul.link-sublist
  li
  a {
  font-size: 0.889em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
}
.sidebar-wrapper .sidebar-linklist-wrapper.linklist-secondary:before {
  content: "";
  width: 65px;
  height: 1px;
  background: #e6ecf2;
  display: block;
  margin-left: 24px;
  margin-top: 24px;
}
.sidebar-wrapper.it-line-right-side {
  border-right: 1px solid #d9dadb;
}
.sidebar-wrapper.it-line-right-side
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"].active {
  border-right: 2px solid #0048ad;
  border-left: none;
}
.sidebar-wrapper.it-line-left-side {
  border-left: 1px solid #d9dadb;
}

.sidebar-wrapper.theme-dark {
  background: #435a70;
}
.sidebar-wrapper.theme-dark h3 {
  color: white;
}
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper h3 {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a.left-icon
  i.left {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a
  span {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a
  i.it-expand {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[aria-expanded="true"]
  i {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"] {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"].active {
  border-left: 2px solid white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"].active
  span,
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"].active
  i {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul.link-sublist
  li
  a {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul.link-sublist
  li
  a
  span {
  color: white;
}
.sidebar-wrapper.theme-dark
  .sidebar-linklist-wrapper.linklist-secondary:before {
  background: rgba(229, 229, 229, 0.3);
}
.sidebar-wrapper.theme-dark.it-line-right-side {
  border-right-color: rgba(229, 229, 229, 0.3);
}
.sidebar-wrapper.theme-dark.it-line-right-side
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"].active {
  border-left: none;
  border-right-color: white;
}
.sidebar-wrapper.theme-dark.it-line-left-side {
  border-left-color: rgba(229, 229, 229, 0.3);
}
.sidebar-wrapper.theme-dark.it-line-left-side
  .sidebar-linklist-wrapper
  .link-list-wrapper
  ul
  li
  a[data-toggle="collapse"].active {
  border-left-color: white;
}

.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .col-12:not(:first-child)
  .it-heading-megacolumn {
  margin-top: 48px;
  line-height: 2.3em;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .col-12:not(:first-child)
  .it-heading-megacolumn:before {
  content: "";
  display: block;
  height: 1px;
  width: 65px;
  background: #e6ecf2;
  margin-bottom: 8px;
}

.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .col-12:not(:first-child)
  .link-list-wrapper
  h3 {
  margin-top: 48px;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .col-12:not(:first-child)
  .link-list-wrapper
  h3:before {
  content: "";
  display: block;
  height: 1px;
  width: 65px;
  background: #e6ecf2;
  margin-bottom: 8px;
}

.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  h3.it-heading-megacolumn {
  margin: 0.5em 72px;
  line-height: 1.2em;
  font-size: 0.833em;
  text-transform: uppercase;
  padding-bottom: 0.5em;
}

.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .link-list-wrapper {
  margin-bottom: 0;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .link-list-wrapper
  ul.link-list
  li.it-more
  a {
  margin-top: 0;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .link-list-wrapper
  ul.link-list
  li.it-more
  a
  i {
  margin-left: 12px;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .link-list-wrapper
  ul.link-list
  li:last-child.it-more
  a {
  margin-top: 8px;
}

.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical {
  width: 100%;
  background: #f5f6f7;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 16px;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical
  .link-list-wrapper
  ul.link-list {
  width: 100%;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical
  .link-list-wrapper
  ul.link-list
  li
  a {
  display: flex;
  justify-content: space-between;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical
  .link-list-wrapper
  ul.link-list
  li
  a
  span {
  font-weight: 600;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical
  .link-list-wrapper
  ul.link-list
  li
  .divider {
  display: none;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical.it-description {
  background: transparent;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical.it-description
  .description-content {
  padding-left: 48px;
  padding-right: 48px;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical.it-description
  .description-content:before {
  content: "";
  display: block;
  height: 1px;
  width: 65px;
  background: #e6ecf2;
  margin-bottom: 16px;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical.it-description
  .description-content
  img {
  display: block;
  width: 100%;
  margin-bottom: 24px;
}
.navbar
  .navbar-collapsable
  .menu-wrapper
  .nav-item.megamenu
  .dropdown-menu
  .it-vertical.it-description
  .description-content
  p {
  font-size: 0.889em;
}

.navbar .dropdown-menu > .it-external {
  padding: 0;
}

.navbar .dropdown-menu .margin-right-col .it-external {
  padding: 0 12px;
}

.navbar .dropdown-menu .it-external {
  width: 100%;
}
.navbar
  .dropdown-menu
  .it-external
  .link-list-wrapper
  .link-list
  li.it-more
  a:before {
  content: "";
  display: block;
  height: 1px;
  width: 65px;
  background: #e6ecf2;
  margin-bottom: 8px;
}

.navbar .dropdown-menu .it-megamenu-footer {
  background: #f5f6f7;
  padding: 16px 0;
  margin-top: 16px;
}
.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper {
  margin-bottom: 0;
}
.navbar
  .dropdown-menu
  .it-megamenu-footer
  .link-list-wrapper
  .link-list
  li
  a.list-item
  span {
  display: inline;
  font-weight: 600;
}

@media (min-width: 992px) {
  .navbar .dropdown-menu .it-megamenu-footer {
    margin-top: 32px;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -32px;
    padding: 12px 24px;
  }
  .navbar
    .dropdown-menu
    .it-megamenu-footer
    .link-list-wrapper
    .link-list
    li
    a.list-item {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .navbar
    .dropdown-menu
    .it-megamenu-footer
    .link-list-wrapper
    .link-list
    li
    a.list-item
    span {
    display: inline;
    font-weight: 600;
  }
  .navbar .dropdown-menu .it-external {
    width: 100%;
    padding: 0;
  }
  .navbar
    .dropdown-menu
    .it-external
    .link-list-wrapper
    .link-list
    li.it-more
    a:before {
    content: "";
    display: block;
    height: 1px;
    width: 65px;
    background: #e6ecf2;
    margin-bottom: 8px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .row.max-height-col {
    min-height: 100%;
    margin-right: -37px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .row.max-height-col
    .row.max-height-col {
    margin-right: -12px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .row.max-height-col
    .margin-right-col {
    margin-right: 24px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical {
    background: #f5f6f7;
    padding-top: 32px;
    margin-top: -32px;
    margin-bottom: -32px;
    margin-right: -24px;
    margin-left: -12px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical
    .link-list-wrapper
    ul.link-list {
    width: 100%;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical
    .link-list-wrapper
    ul.link-list
    li
    a {
    display: flex;
    justify-content: space-between;
    padding-left: 36px;
    padding-right: 36px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical
    .link-list-wrapper
    ul.link-list
    li
    a
    span {
    font-weight: 600;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical
    .link-list-wrapper
    ul.link-list
    li
    .divider {
    height: 1px;
    display: block;
    background: #d9dadb;
    margin: 0 0 0 36px;
    width: 65px;
    margin-top: 25px;
    margin-bottom: 16px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical.it-description {
    background: transparent;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical.it-description
    .description-content {
    padding-left: 36px;
    padding-right: 36px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical.it-description
    .description-content:before {
    display: none;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical.it-description
    .description-content
    img {
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .it-vertical.it-description
    .description-content
    p {
    font-size: 0.889em;
  }
  .navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu {
    padding: 32px 24px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .col-12:not(:first-child)
    .link-list-wrapper
    h3 {
    margin-top: 5px;
    line-height: 1.2em;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .col-12:not(:first-child)
    .link-list-wrapper
    h3:before {
    display: none;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .col-12:not(:first-child)
    .it-heading-megacolumn {
    margin: 0.5em 24px;
    line-height: 1.2em;
    font-size: 0.833em;
    text-transform: uppercase;
    border-bottom: 1px solid #e6ecf2;
    padding-bottom: 0.5em;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .col-12:not(:first-child)
    .it-heading-megacolumn:before {
    content: "";
    display: none;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .col-12:first-child
    .link-list-wrapper {
    margin-bottom: 0;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    h3.it-heading-megacolumn {
    margin: 0.5em 24px;
    line-height: 1.2em;
    font-size: 0.833em;
    text-transform: uppercase;
    border-bottom: 1px solid #e6ecf2;
    padding-bottom: 0.5em;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .link-list-wrapper {
    display: flex;
    min-height: 100%;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .link-list-wrapper
    ul.link-list {
    display: flex;
    flex-direction: column;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .link-list-wrapper
    ul.link-list
    li {
    width: 100%;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .link-list-wrapper
    ul.link-list
    li
    a,
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .link-list-wrapper
    ul.link-list
    li
    h3 {
    padding: 0.5em 24px;
    line-height: inherit;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .link-list-wrapper
    ul.link-list
    li
    h3 {
    line-height: 1.2em;
    font-size: 0.833em;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .link-list-wrapper
    ul.link-list
    li.it-more {
    margin-top: auto;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .link-list-wrapper
    ul.link-list
    li.it-more
    a {
    margin-top: 12px;
  }
  .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .link-list-wrapper
    ul.link-list
    li.it-more
    a
    i {
    font-size: 0.8em;
    transform: translateY(-1px);
  }
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 96px;
}
.bottom-nav:before {
  content: "";
  position: absolute;
  height: 360px;
  width: 100%;
  top: 32px;
  z-index: 10;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 60%
  );
  border-radius: 50%;
  transform: translateY(-50%) scalex(1.4) scaleY(0.12);
  opacity: 0.18;
}
.bottom-nav ul {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 20;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 64px;
}
.bottom-nav ul li {
  list-style-type: none;
  margin: 8px;
  text-align: center;
}
.bottom-nav a {
  display: block;
  color: #5b6f82;
  text-decoration: none;
}
.bottom-nav a .icon {
  fill: #5b6f82;
}
.bottom-nav a.active {
  color: #0048ad;
}
.bottom-nav a.active .icon {
  fill: #0048ad;
}
.bottom-nav a:hover {
  text-decoration: none;
  color: #0048ad;
}
.bottom-nav a:hover .icon {
  fill: #0048ad;
}
.bottom-nav .it-ico {
  position: relative;
  font-size: 1.35rem;
  display: block;
  height: 32px;
}
.bottom-nav .it-ico:before {
  margin-right: 0;
}
.bottom-nav .badge-wrapper {
  position: relative;
}
.bottom-nav .bottom-nav-badge,
.bottom-nav .bottom-nav-alert {
  display: block;
  position: absolute;
  background-color: #0048ad;
  border: 1px solid white;
}
.bottom-nav .bottom-nav-badge {
  top: 0;
  right: 0;
  min-width: 18px;
  height: 18px;
  padding: 0 4px;
  color: white;
  font-size: 0.563rem;
  border-radius: 50%;
  font-style: normal;
}
.bottom-nav .bottom-nav-alert {
  top: 4px;
  right: 8px;
  min-width: 10px;
  height: 10px;
  font-size: 0.563rem;
  border-radius: 50%;
}
.bottom-nav .bottom-nav-label {
  margin-top: 6px;
  font-size: 0.688rem;
  line-height: 1;
  display: block;
  font-weight: 600;
}

.navbar.it-navscroll-wrapper {
  background: white;
}
.navbar.it-navscroll-wrapper .it-back-button {
  display: block;
  padding: 16px 24px;
  font-weight: 600;
  box-shadow: 0 0px 30px 5px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 10;
  display: none;
}
.navbar.it-navscroll-wrapper .it-back-button i {
  transform: scale(0.8);
  transform-origin: center;
  display: inline-block;
  margin-right: 8px;
}
.navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}
.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active {
  border-left: 2px solid #0048ad;
}
.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active span {
  color: #0048ad;
  text-decoration: none;
}
.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a {
  font-weight: 400;
}
.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active {
  font-weight: normal;
  border-left: none;
}
.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active span {
  text-decoration: underline;
  color: #17324d;
}
.navbar.it-navscroll-wrapper h3 {
  font-weight: 600;
  margin-bottom: 0.8rem;
  font-size: 0.833em;
  margin-top: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 24px;
  padding-right: 24px;
}
.navbar.it-navscroll-wrapper .custom-navbar-toggler {
  display: flex;
  color: #0048ad;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.navbar.it-navscroll-wrapper .custom-navbar-toggler span.it-list {
  color: #0048ad;
  margin-right: 8px;
}
.navbar.it-navscroll-wrapper .menu-wrapper {
  padding: 80px 0 24px;
  right: 0;
}

.navbar.it-bottom-navscroll {
  box-shadow: 0 0px 30px 5px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
}

.navbar.it-top-navscroll {
  box-shadow: 0 0px 30px 5px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
}

@media (min-width: 992px) {
  .navbar.it-navscroll-wrapper {
    background: white;
  }
  .navbar.it-navscroll-wrapper .it-back-button {
    display: none !important;
  }
  .navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
    font-size: 1em;
    font-weight: 600;
    line-height: 1em;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
  .navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active {
    border-left: 2px solid #0048ad;
  }
  .navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active span {
    color: #0048ad;
    text-decoration: none;
  }
  .navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a {
    font-weight: 400;
  }
  .navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active {
    font-weight: normal;
    border-left: none;
  }
  .navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active span {
    text-decoration: underline;
    color: #17324d;
  }
  .navbar.it-navscroll-wrapper h3 {
    font-weight: 600;
    margin-bottom: 0.8rem;
    font-size: 0.833em;
    margin-top: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 24px;
    padding-right: 24px;
  }
  .navbar.it-navscroll-wrapper .custom-navbar-toggler {
    display: none;
  }
  .navbar.it-navscroll-wrapper .menu-wrapper {
    padding: 16px 0 24px;
    background: white;
  }
  .navbar.it-bottom-navscroll {
    box-shadow: none;
    position: inherit;
  }
  .navbar.it-bottom-navscroll.affix-top {
    position: sticky;
    top: 0;
    left: inherit;
    right: inherit;
  }
  .navbar.it-top-navscroll {
    box-shadow: none;
    position: inherit;
  }
  .navbar.it-top-navscroll.affix-top {
    position: sticky;
    top: 0;
    left: inherit;
    right: inherit;
  }
  .navbar.it-left-side {
    border-left: 1px solid #d9dadb;
  }
  .navbar.it-right-side {
    border-right: 1px solid #d9dadb;
  }
}

@media (max-width: 992px) {
  .navbar.it-navscroll-wrapper.theme-dark-mobile {
    background: #435a70;
  }
  .navbar.it-navscroll-wrapper.theme-dark-mobile .custom-navbar-toggler {
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-mobile
    .custom-navbar-toggler
    span.it-list {
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-mobile .it-back-button {
    background: #435a70;
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-mobile
    .navbar-collapsable
    .menu-wrapper {
    background: #435a70;
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-mobile
    .navbar-collapsable
    .menu-wrapper
    .link-list-wrapper
    ul
    li
    a {
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-mobile
    .navbar-collapsable
    .menu-wrapper
    .link-list-wrapper
    ul
    li
    a
    span {
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-mobile
    .navbar-collapsable
    .menu-wrapper
    .link-list-wrapper
    ul
    li
    a.active {
    border-left-color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-mobile.it-left-side {
    border-left-color: rgba(229, 229, 229, 0.3);
  }
  .navbar.it-navscroll-wrapper.theme-dark-mobile.it-right-side {
    border-right-color: rgba(229, 229, 229, 0.3);
  }
}

/*
++++++++++++++++++++++++++++++Desktop light Theme+++++++++++++++++++++++++++++++++++++++++++++++++
*/
@media (min-width: 992px) {
  .navbar.it-navscroll-wrapper.theme-dark-desk {
    background: #435a70;
  }
  .navbar.it-navscroll-wrapper.theme-dark-desk .custom-navbar-toggler {
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-desk
    .custom-navbar-toggler
    span.it-list {
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-desk .it-back-button {
    background: #435a70;
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-desk
    .navbar-collapsable
    .menu-wrapper {
    background: #435a70;
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-desk
    .navbar-collapsable
    .menu-wrapper
    .link-list-wrapper
    ul
    li
    a {
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-desk
    .navbar-collapsable
    .menu-wrapper
    .link-list-wrapper
    ul
    li
    a
    span {
    color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-desk
    .navbar-collapsable
    .menu-wrapper
    .link-list-wrapper
    ul
    li
    a.active {
    border-left-color: white;
  }
  .navbar.it-navscroll-wrapper.theme-dark-desk.it-left-side {
    border-left-color: rgba(229, 229, 229, 0.3);
  }
  .navbar.it-navscroll-wrapper.theme-dark-desk.it-right-side {
    border-right-color: rgba(229, 229, 229, 0.3);
  }
}

.callout {
  padding: 2.5rem 2.222rem;
  border: 1px solid #5b6f82;
  position: relative;
  max-width: 60ch;
  margin: 1.75rem 0 1rem;
  border-radius: 8px;
}
.callout.callout-highlight {
  border: none;
  border-left: 2px solid rgba(223, 228, 242, 0.3);
  border-radius: 0;
  padding: 0 2.222rem;
}
.callout.callout-highlight .callout-title {
  margin-bottom: 1.556rem;
}
.callout.callout-highlight.success {
  border-color: rgba(0, 135, 88, 0.2);
}
.callout.callout-highlight.warning {
  border-color: rgba(166, 99, 0, 0.2);
}
.callout.callout-highlight.danger {
  border-color: rgba(217, 54, 79, 0.2);
}
.callout.callout-highlight.important {
  border-color: rgba(0, 128, 83, 0.2);
}
.callout.callout-highlight.note {
  border-color: rgba(0, 53, 128, 0.2);
}
.callout.callout-more {
  background: #fafaf7;
  border: none;
  border-radius: 0;
  padding: 2.222rem 2.222rem;
  position: relative;
}
.callout.callout-more:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 48px 48px 0;
  border-color: transparent white transparent transparent;
}
.callout.callout-more:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 48px 0 0 48px;
  border-color: transparent transparent transparent #e4e4db;
}
.callout.callout-more p {
  font-size: 0.778rem;
  line-height: 1.333rem;
  color: #17324d;
}
.callout.callout-more .callout-title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2.222rem;
}
.callout.callout-more .callout-title i {
  font-size: 1.25rem;
}
.callout.callout-more .callout-title span {
  border-bottom: 2px solid #19191a;
  padding-bottom: 0.1rem;
  display: inline-block;
  margin-top: -0.111rem;
}
.callout.success {
  border-color: #008758;
}
.callout.success .callout-title {
  color: #008758;
}
.callout.success .callout-title .icon {
  fill: #008758;
}
.callout.success .callout-title span {
  border-color: #008758;
}
.callout.warning {
  border-color: #a66300;
}
.callout.warning .callout-title {
  color: #a66300;
}
.callout.warning .callout-title .icon {
  fill: #a66300;
}
.callout.warning .callout-title span {
  border-color: #a66300;
}
.callout.danger {
  border-color: #d9364f;
}
.callout.danger .callout-title {
  color: #d9364f;
}
.callout.danger .callout-title .icon {
  fill: #d9364f;
}
.callout.danger .callout-title span {
  border-color: #d9364f;
}
.callout.important {
  border-color: #008053;
}
.callout.important .callout-title {
  color: #008053;
}
.callout.important .callout-title .icon {
  fill: #008053;
}
.callout.important .callout-title span {
  border-color: #008053;
}
.callout.note {
  border-color: #003580;
}
.callout.note .callout-title {
  color: #003580;
}
.callout.note .callout-title .icon {
  fill: #003580;
}
.callout.note .callout-title span {
  border-color: #003580;
}
.callout.note .collapse-div .collapse-header .callout-more-toggle {
  color: #003580;
}
.callout.note .collapse-div .collapse-header .callout-more-toggle:active,
.callout.note .collapse-div .collapse-header .callout-more-toggle:hover {
  color: #003580;
}
.callout.note .collapse-div .collapse-header .callout-more-toggle span {
  border-color: #003580;
}
.callout.note .collapse-div .collapse-header .callout-more-toggle span:before,
.callout.note .collapse-div .collapse-header .callout-more-toggle span:after {
  background: #003580;
}
.callout.note a {
  color: #003580;
}
.callout.note .icon {
  fill: #003580;
}
.callout .callout-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  margin-right: 2.222rem;
  letter-spacing: 0.06rem;
  color: #5b6f82;
}
.callout .callout-title .icon {
  fill: #5b6f82;
  margin-right: 0.444rem;
}
.callout:not(.callout-highlight):not(.callout-more) .callout-title {
  position: absolute;
  font-size: 1rem;
  padding: 0 1.389rem;
  background: white;
  top: -0.78em;
}
.callout p {
  font-family: Lora, Georgia, serif;
  font-size: 0.889rem;
  color: #5b6f82;
}
.callout p:last-child {
  margin: 0;
}
.callout p.callout-big-text {
  font-weight: bold;
  font-size: 1.111rem;
}
.callout .collapse-div {
  display: flex;
  flex-direction: column-reverse;
  border-bottom: none;
}
.callout .collapse-div .collapse-header {
  margin-top: 1.333rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dfe4f2;
  padding: 1.333rem 0 0;
  margin-top: 0;
}
.callout .collapse-div .collapse-header .callout-more-toggle {
  padding: 0;
  border: none;
  width: auto;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: #0048ad;
}
.callout .collapse-div .collapse-header .callout-more-toggle:hover,
.callout .collapse-div .collapse-header .callout-more-toggle:focus,
.callout
  .collapse-div
  .collapse-header
  .form-check
  [type="checkbox"]:focus
  + label.callout-more-toggle,
.form-check
  .callout
  .collapse-div
  .collapse-header
  [type="checkbox"]:focus
  + label.callout-more-toggle,
.callout
  .collapse-div
  .collapse-header
  .form-check
  [type="radio"]:focus
  + label.callout-more-toggle,
.form-check
  .callout
  .collapse-div
  .collapse-header
  [type="radio"]:focus
  + label.callout-more-toggle,
.callout
  .collapse-div
  .collapse-header
  .toggles
  label
  input[type="checkbox"]:focus
  + .callout-more-toggle.lever,
.toggles
  label
  .callout
  .collapse-div
  .collapse-header
  input[type="checkbox"]:focus
  + .callout-more-toggle.lever,
.callout
  .collapse-div
  .collapse-header
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.callout-more-toggle,
.block-facet--checkbox
  .callout
  .collapse-div
  .collapse-header
  [type="checkbox"]:focus
  + label.callout-more-toggle,
.callout .collapse-div .collapse-header .callout-more-toggle:active {
  background: none;
}
.callout .collapse-div .collapse-header .callout-more-toggle:hover {
  color: #002861;
}
.callout .collapse-div .collapse-header .callout-more-toggle:hover span {
  border-color: #002861;
}
.callout .collapse-div .collapse-header .callout-more-toggle:hover span:before,
.callout .collapse-div .collapse-header .callout-more-toggle:hover span:after {
  background: #002861;
}
.callout .collapse-div .collapse-header .callout-more-toggle:active {
  color: #0048ad;
}
.callout .collapse-div .collapse-header .callout-more-toggle:before,
.callout .collapse-div .collapse-header .callout-more-toggle:after {
  display: none;
}
.callout .collapse-div .collapse-header .callout-more-toggle span {
  position: relative;
  height: 15px;
  width: 15px;
  margin-left: 0.444rem;
  margin-top: 0.111rem;
  border: 1px solid #0048ad;
  border-radius: 50%;
  display: inline-block;
}
.callout .collapse-div .collapse-header .callout-more-toggle span:before,
.callout .collapse-div .collapse-header .callout-more-toggle span:after {
  content: "";
  position: absolute;
  background: #0048ad;
}
.callout .collapse-div .collapse-header .callout-more-toggle span:before {
  width: 9px;
  height: 1px;
  top: 6px;
  left: 2px;
}
.callout .collapse-div .collapse-header .callout-more-toggle span:after {
  height: 9px;
  width: 1px;
  top: 0;
  left: 6px;
  transform: translateY(2px);
  transition: transform 0.2s;
}
.callout
  .collapse-div
  .collapse-header
  .callout-more-toggle[aria-expanded="true"]
  span:after {
  transform: translateY(2px) rotate(90deg);
}
.callout .collapse-div .collapse-header .callout-more-download {
  margin-left: auto;
  font-weight: normal;
}
.callout .collapse-div .collapse-body {
  padding: 0;
}
.callout .collapse-div .collapse-body p:last-child {
  margin-bottom: 1rem;
}

_:-ms-fullscreen,
.callout {
  max-width: 32rem;
}

@media (min-width: 768px) {
  .callout.callout-highlight {
    padding: 0 0 0 2.222rem;
  }
  .callout.callout-more p {
    font-size: 0.722rem;
  }
}

.toolbar {
  width: 100%;
  height: 96px;
  position: relative;
}
.toolbar a {
  text-decoration: none;
}
.toolbar.toolbar-vertical {
  height: 100%;
  width: 96px;
  position: absolute;
  top: 0;
  bottom: 0;
}
.toolbar.toolbar-vertical > ul {
  height: 100%;
  flex-direction: column;
  width: 64px;
}
.toolbar.toolbar-vertical > ul > li {
  margin: 8px 0;
  display: flex;
  align-items: center;
}
.toolbar.toolbar-vertical > ul > li.toolbar-divider {
  margin: 0;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  width: calc(100% - 16px);
  max-width: inherit;
}
.toolbar.toolbar-vertical.toolbar-medium,
.toolbar.toolbar-vertical.toolbar-small {
  height: 100%;
}
.toolbar.toolbar-vertical.toolbar-medium > ul,
.toolbar.toolbar-vertical.toolbar-small > ul {
  height: 100%;
}
.toolbar.toolbar-vertical.toolbar-medium {
  width: 84px;
}
.toolbar.toolbar-vertical.toolbar-medium > ul {
  width: 48px;
}
.toolbar.toolbar-vertical.toolbar-medium .toolbar-badge {
  right: -4px;
}
.toolbar.toolbar-vertical.toolbar-medium .btn-dropdown:after {
  right: -10px;
  top: 13px;
  font-size: 0.39rem;
}
.toolbar.toolbar-vertical.toolbar-medium .dropdown-menu {
  top: calc(-100% - 25px) !important;
}
.toolbar.toolbar-vertical.toolbar-small {
  width: 72px;
}
.toolbar.toolbar-vertical.toolbar-small > ul {
  width: 40px;
}
.toolbar.toolbar-vertical.toolbar-small .toolbar-badge {
  right: -4px;
}
.toolbar.toolbar-vertical.toolbar-small .btn-dropdown:after {
  right: -9px;
  top: 11px;
  font-size: 0.33rem;
}
.toolbar.toolbar-vertical.toolbar-small .dropdown-menu {
  top: calc(-100% - 35px) !important;
}
.toolbar.toolbar-vertical .btn-dropdown:after {
  right: -12px;
  transform: rotate(-90deg);
}
.toolbar.toolbar-vertical .btn-dropdown[aria-expanded="true"]:after {
  transform: rotate(90deg) scaleX(-1);
}
.toolbar.toolbar-vertical .dropdown-menu {
  top: calc(-100% - 28px) !important;
  left: calc(100% + 24px) !important;
  margin: 0;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
}
.toolbar.toolbar-vertical .dropdown-menu:before {
  left: -6px;
  top: 24px;
}
.toolbar.toolbar-vertical:before {
  width: 360px;
  height: 100%;
  left: auto;
  top: 0;
  bottom: auto;
  right: 32px;
  z-index: 6;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 60%
  );
  border-radius: 50%;
  transform: translateX(50%) scalex(0.12) scaleY(1.25);
  opacity: 0.18;
}
.toolbar.toolbar-vertical .toolbar-badge {
  right: -10px;
}
.toolbar.toolbar-medium {
  height: 80px;
}
.toolbar.toolbar-medium > ul {
  height: 48px;
}
.toolbar.toolbar-medium a,
.toolbar.toolbar-medium .btn-dropdown {
  margin-bottom: 6px;
}
.toolbar.toolbar-medium .btn-dropdown:after {
  top: 12px;
  right: 1px;
}
.toolbar.toolbar-medium .it-ico {
  font-size: 1.35rem;
}
.toolbar.toolbar-medium .it-ico.it-more-actions:before {
  top: 33%;
}
.toolbar.toolbar-medium .toolbar-badge {
  height: 10px;
  width: 10px;
  min-width: 10px;
  right: 8px;
  top: 4px;
  padding: 0;
}
.toolbar.toolbar-small {
  height: 72px;
}
.toolbar.toolbar-small > ul {
  height: 40px;
}
.toolbar.toolbar-small .btn-dropdown:after {
  top: 12px;
  right: 2px;
}
.toolbar.toolbar-small .it-ico {
  font-size: 1.15rem;
}
.toolbar.toolbar-small .it-ico.it-more-actions:before {
  top: 25%;
}
.toolbar.toolbar-small .toolbar-badge {
  height: 8px;
  width: 8px;
  min-width: 8px;
  right: 10px;
  top: 2px;
  padding: 0;
}
.toolbar:before {
  content: "";
  position: absolute;
  height: 360px;
  width: 100%;
  top: auto;
  bottom: 32px;
  z-index: 6;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 60%
  );
  border-radius: 50%;
  transform: translateY(50%) scalex(1.4) scaleY(0.12);
  opacity: 0.18;
}
.toolbar > ul {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 7;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 64px;
}
.toolbar > ul > li {
  list-style-type: none;
  margin: 0 8px;
  text-align: center;
  flex: 1 100%;
}
.toolbar > ul > li.toolbar-divider {
  margin: 0;
  width: 1px;
  min-width: 1px;
  max-width: 1px;
  height: calc(100% - 16px);
  background: #dfe4f2;
}
.toolbar a,
.toolbar .btn-dropdown {
  display: block;
  color: #17324d;
}
.toolbar a .icon,
.toolbar .btn-dropdown .icon {
  fill: #17324d;
}
.toolbar a.active,
.toolbar .btn-dropdown.active {
  color: #0048ad;
}
.toolbar a.active .icon,
.toolbar .btn-dropdown.active .icon {
  fill: #0048ad;
}
.toolbar a.disabled,
.toolbar .btn-dropdown.disabled {
  cursor: not-allowed;
  color: #d9dadb;
}
.toolbar a.disabled .icon,
.toolbar .btn-dropdown.disabled .icon {
  fill: #d9dadb;
}
.toolbar a.disabled:hover,
.toolbar .btn-dropdown.disabled:hover {
  text-decoration: none;
  color: #d9dadb;
}
.toolbar a.disabled:hover .icon,
.toolbar .btn-dropdown.disabled:hover .icon {
  fill: #d9dadb;
}
.toolbar a.disabled:after,
.toolbar .btn-dropdown.disabled:after {
  color: #d9dadb;
}
.toolbar a.disabled:after .icon,
.toolbar .btn-dropdown.disabled:after .icon {
  fill: #d9dadb;
}
.toolbar a:not(.disabled):hover,
.toolbar .btn-dropdown:not(.disabled):hover {
  text-decoration: underline;
  color: #0048ad;
}
.toolbar a:not(.disabled):hover .icon,
.toolbar .btn-dropdown:not(.disabled):hover .icon {
  fill: #0048ad;
}
.toolbar a _:-ms-fullscreen,
.toolbar a:not(.disabled):hover,
.toolbar .btn-dropdown _:-ms-fullscreen,
.toolbar .btn-dropdown:not(.disabled):hover {
  text-decoration: none;
}
.toolbar .btn-dropdown {
  padding: 0;
  width: 100%;
  position: relative;
}
.toolbar .btn-dropdown.toolbar-more:after {
  display: none;
}
.toolbar .btn-dropdown:not(.disabled).active {
  box-shadow: none;
}
.toolbar .btn-dropdown:not(.disabled).active:focus,
.toolbar
  .form-check
  [type="checkbox"]:focus
  + label.btn-dropdown:not(.disabled).active,
.form-check
  .toolbar
  [type="checkbox"]:focus
  + label.btn-dropdown:not(.disabled).active,
.toolbar
  .form-check
  [type="radio"]:focus
  + label.btn-dropdown:not(.disabled).active,
.form-check
  .toolbar
  [type="radio"]:focus
  + label.btn-dropdown:not(.disabled).active,
.toolbar
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-dropdown.lever:not(.disabled).active,
.toggles
  label
  .toolbar
  input[type="checkbox"]:focus
  + .btn-dropdown.lever:not(.disabled).active,
.toolbar
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-dropdown:not(.disabled).active,
.block-facet--checkbox
  .toolbar
  [type="checkbox"]:focus
  + label.btn-dropdown:not(.disabled).active {
  box-shadow: 0 0 0 0.2rem rgba(0, 72, 173, 0.25);
}
.toolbar .btn-dropdown:focus,
.toolbar .form-check [type="checkbox"]:focus + label.btn-dropdown,
.form-check .toolbar [type="checkbox"]:focus + label.btn-dropdown,
.toolbar .form-check [type="radio"]:focus + label.btn-dropdown,
.form-check .toolbar [type="radio"]:focus + label.btn-dropdown,
.toolbar .toggles label input[type="checkbox"]:focus + .btn-dropdown.lever,
.toggles label .toolbar input[type="checkbox"]:focus + .btn-dropdown.lever,
.toolbar .block-facet--checkbox [type="checkbox"]:focus + label.btn-dropdown,
.block-facet--checkbox .toolbar [type="checkbox"]:focus + label.btn-dropdown {
  box-shadow: 0 0 0 0.2rem rgba(0, 72, 173, 0.25);
}
.toolbar .btn-dropdown:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 3px 0 3px;
  border-color: #17324d transparent transparent transparent;
  position: absolute;
  top: 16px;
  right: 2px;
  margin-left: 0;
}
.toolbar .it-ico {
  position: relative;
  font-size: 1.6rem;
  display: block;
  height: 32px;
}
.toolbar .it-ico:before {
  margin-right: 0;
}
.toolbar .it-ico.it-more-actions {
  font-size: 0.6rem;
}
.toolbar .it-ico.it-more-actions:before {
  position: relative;
  top: 50%;
}
.toolbar .badge-wrapper {
  position: relative;
}
.toolbar .toolbar-badge,
.toolbar .toolbar-alert {
  display: block;
  position: absolute;
  background-color: #0048ad;
  border: 1px solid white;
}
.toolbar .toolbar-badge {
  top: 0;
  right: 6px;
  min-width: 18px;
  height: 18px;
  padding: 0 4px;
  color: white;
  font-size: 0.563rem;
  border-radius: 50%;
  font-style: normal;
}
.toolbar .toolbar-label {
  margin-top: 8px;
  font-size: 0.688rem;
  line-height: 1;
  display: block;
  font-weight: 600;
}

.section {
  padding: 48px 24px;
}
.section .section-content {
  margin: 0 auto;
}
.section.section-muted {
  background: #f2f7fc;
}
.section.section-primary {
  background: #0048ad;
}
.section.section-neutral {
  background: #17324d;
}
.section.section-image {
  background-size: cover;
  background-position: center center;
}
.section.section-inset-shadow {
  box-shadow: inset 0 1rem 1rem -1rem rgba(0, 0, 0, 0.3);
}
.section.section-background-header {
  position: relative;
  background-size: cover;
}
.section.section-background-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #004ab3;
}
.section.section-background-header.section-user-header::before {
  content: "";
  min-height: 450px;
  background-color: #bfdaff;
}
.section.section-background-header.section-user-header .user-settings {
  font-size: 12px;
}
.section.section-background-header.section-user-header .user-settings a {
  text-decoration: none;
}
.section.section-background-header.section-user-header .user-settings a:hover {
  text-decoration: underline;
}
.section.section-background-header.section-user-header .user-settings a span {
  margin-left: 4px;
}

@media (min-width: 768px) {
  .section {
    padding: 80px 40px;
  }
}

@media (min-width: 992px) {
  .section {
    padding: 80px;
  }
  .section.section-background-header.section-user-header::before {
    content: "";
    min-height: 550px;
  }
  .section.section-background-header.section-user-header .user-settings {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .section {
    padding: 112px;
  }
  .section.section-background-header.section-user-header .user-settings {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
  }
}

.modal {
  padding-right: 0 !important;
}
.modal .modal-dialog {
  margin: 48px;
}
.modal .modal-dialog .modal-content {
  border: none;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.modal .modal-dialog .modal-content .modal-header {
  padding: 24px;
  padding-bottom: 0;
}
.modal .modal-dialog .modal-content .modal-header .close {
  padding: 16px;
}
.modal .modal-dialog .modal-content .modal-header h5 {
  font-size: 0.83333rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #17324d;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 24px;
  padding-bottom: 0;
}
.modal .modal-dialog .modal-content .modal-body p {
  font-size: 0.88889rem;
  margin-bottom: 0;
  line-height: 1.33333rem;
}
.modal .modal-dialog .modal-content .modal-footer {
  padding: 12px 24px;
}
.modal.alert-modal .modal-dialog .modal-content .modal-header {
  justify-content: left;
  align-items: start;
}
.modal.alert-modal .modal-dialog .modal-content .modal-header .icon {
  fill: #0055cc;
  margin-right: 16px;
  flex-shrink: 0;
}
.modal.it-dialog-link-list .modal-dialog .modal-content .modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 24px;
}
.modal.it-dialog-link-list .modal-dialog .modal-content .modal-header h5 {
  text-transform: none;
  font-weight: 700;
  letter-spacing: inherit;
}
.modal.it-dialog-link-list .modal-dialog .modal-content .modal-header h5 span {
  color: #0055cc;
  margin-right: 4px;
}
.modal.it-dialog-link-list
  .modal-dialog
  .modal-content
  .modal-body
  .link-list-wrapper
  .link-list {
  margin-bottom: 0;
  padding-bottom: 24px;
}
.modal.popconfirm-modal .modal-dialog {
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
}
.modal.popconfirm-modal .modal-dialog .modal-content {
  border-radius: 4px;
}
.modal.popconfirm-modal .modal-dialog .modal-header {
  padding-top: 16px;
  margin-bottom: -4px;
}
.modal.popconfirm-modal .modal-dialog .modal-body {
  padding-top: 16px;
}
.modal.popconfirm-modal .modal-dialog .modal-body p {
  font-size: 0.875rem;
  margin-bottom: 12px;
}
.modal.popconfirm-modal .modal-dialog .modal-footer {
  padding-bottom: 24px;
}
.modal.it-dialog-scrollable .modal-dialog {
  margin: 104px 48px;
}
.modal.it-dialog-scrollable .modal-dialog .modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 208px);
}
.modal.it-dialog-scrollable .modal-dialog .modal-content .modal-header {
  padding-bottom: 24px;
  background: white;
  flex-shrink: 0;
}
.modal.it-dialog-scrollable .modal-dialog .modal-content .modal-body {
  padding-top: 0;
  padding-bottom: 24px;
  overflow-y: auto;
}
.modal.it-dialog-scrollable .modal-dialog .modal-content .modal-footer {
  flex-shrink: 0;
  background: white;
}
.modal .modal-dialog.modal-dialog-left {
  height: 100vh;
  margin: 0 24px 0 0;
}
.modal .modal-dialog.modal-dialog-left .modal-content {
  height: 100vh;
}
.modal .modal-dialog.modal-dialog-right {
  height: 100vh;
  margin: 0 0 0 24px;
  float: right;
}
.modal .modal-dialog.modal-dialog-right .modal-content {
  height: 100vh;
}
.modal.fade .modal-dialog.modal-dialog-left {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}
.modal.fade.show .modal-dialog.modal-dialog-left {
  transform: translateX(0);
}
.modal.fade .modal-dialog.modal-dialog-right {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}
.modal.fade.show .modal-dialog.modal-dialog-right {
  transform: translateX(0);
}
.modal.fade .modal-dialog.modal-dialog-centered {
  transform: translate(0, -5%);
}
.modal.fade.show .modal-dialog.modal-dialog-centered {
  transform: translate(0, 0);
}

@media (min-width: 576px) {
  .modal.popconfirm-modal .modal-dialog .modal-body p {
    font-size: 0.778rem;
  }
}

@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-left: auto;
    margin-right: auto;
  }
  .modal.it-dialog-scrollable .modal-dialog {
    margin: 64px auto;
  }
  .modal.it-dialog-scrollable .modal-dialog .modal-content {
    height: calc(100vh - 128px);
  }
  .modal .modal-dialog.modal-dialog-left {
    margin: 0;
  }
  .modal .modal-dialog.modal-dialog-left .modal-content {
    height: 100vh;
  }
  .modal .modal-dialog.modal-dialog-right {
    margin: 0;
    float: right;
  }
  .modal .modal-dialog.modal-dialog-right .modal-content {
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .alert-modal .modal-dialog .modal-content .modal-body p {
    padding-left: 48px;
  }
}

.notification {
  width: 100%;
  padding: 1.5rem;
  padding-right: 4rem;
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15);
  background: white;
  position: fixed;
  bottom: 0;
  right: 0;
  display: none;
  border-top: 4px solid #5c6f82;
}
.notification h5 {
  font-size: 0.938rem;
  text-transform: uppercase;
  color: #17324d;
  letter-spacing: 0;
  margin: 0;
  position: relative;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: 0.025em;
}
.notification p {
  font-size: 0.875rem;
  margin: 1.5rem 0 0 0;
  color: #5c6f82;
}
.notification.with-icon {
  border-top: 4px solid #5c6f82;
}
.notification.with-icon h5 .icon {
  position: absolute;
  top: -8px;
  left: -38px;
}
.notification.with-icon h5,
.notification.with-icon p {
  margin-left: 2rem;
}
.notification.with-icon.success {
  border-color: #008758;
}
.notification.with-icon.success .icon {
  fill: #008758;
}
.notification.with-icon.error {
  border-color: #d9364f;
}
.notification.with-icon.error .icon {
  fill: #d9364f;
}
.notification.with-icon.info {
  border-color: #0048ad;
}
.notification.with-icon.info .icon {
  fill: #0048ad;
}
.notification.with-icon.warning {
  border-color: #a66300;
}
.notification.with-icon.warning .icon {
  fill: #a66300;
}
.notification.dismissable .notification-close {
  position: absolute;
  right: 20px;
  top: 15px;
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  background: none;
}
.notification.dismissable .notification-close .icon {
  fill: #17324d;
}
.notification.dismissable .notification-close:hover .icon {
  fill: #0048ad;
}
.notification.dismissable .notification-close:active {
  box-shadow: none;
}

@media (min-width: 576px) {
  .notification {
    padding: 1.333rem;
    padding-right: 3.556rem;
  }
  .notification h5 {
    font-size: 0.833rem;
  }
  .notification p {
    font-size: 0.778rem;
    margin: 1.333rem 0 0 0;
  }
  .notification.with-icon h5,
  .notification.with-icon p {
    margin-left: 1.778rem;
  }
}

@media (min-width: 768px) {
  .notification {
    width: 376px;
    bottom: 16px;
    right: 16px;
    border-radius: 4px;
    border-top: none;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
  }
  .notification.with-icon {
    border-left: 4px solid #5c6f82;
    border-top: none;
  }
  .notification.top-fix {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: auto;
    right: auto;
  }
  .notification.bottom-fix {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    right: auto;
  }
  .notification.left-fix {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    border-right-style: solid;
    border-right-width: 4px;
    left: 0;
    right: auto;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
  .notification.right-fix {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    right: 0;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

.avatar {
  display: inline-flex;
  overflow: hidden;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: #ebeced;
  color: #5c6f82;
  box-sizing: content-box;
  transition: background-color 0.2s;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: filter 0.2s;
  border-radius: 50%;
}
.avatar p {
  font-size: 16px;
  margin: 0;
  text-align: center;
  line-height: 1em;
  position: absolute;
}
.avatar _:-ms-fullscreen,
.avatar p {
  transform: translateX(-50%) translateY(-50%);
}
.avatar .icon {
  height: 16px;
  width: 16px;
}
.avatar.avatar-primary {
  background: #0048ad;
}
.avatar.avatar-secondary {
  background: #5c6f82;
}
.avatar.avatar-green {
  background: #00cc85;
}
.avatar.avatar-orange {
  background: #ff9900;
}
.avatar.avatar-red {
  background: #f73e5a;
}
.avatar.avatar-primary,
.avatar.avatar-secondary,
.avatar.avatar-green,
.avatar.avatar-orange,
.avatar.avatar-red {
  color: white;
}
.avatar.avatar-dropdown {
  overflow: visible;
  position: relative;
}
.avatar.avatar-dropdown .dropdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.avatar.avatar-dropdown .btn-dropdown {
  color: inherit;
  padding: 0;
  line-height: 0;
  font-size: 11px;
}
.avatar.avatar-dropdown .list-item {
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 0.889rem;
}
.avatar.avatar-dropdown .link-list {
  white-space: nowrap;
}
.avatar.avatar-dropdown .link-list span {
  line-height: 1em;
}
.avatar.avatar-dropdown .link-list li {
  margin-bottom: 16px;
}
.avatar.avatar-dropdown .link-list li:last-child {
  margin-bottom: 0;
}
.avatar.avatar-dropdown .link-list .avatar {
  margin-right: 16px;
}
.avatar.avatar-dropdown .link-list .avatar.size-sm {
  margin-right: 12px;
}
.avatar.avatar-dropdown .link-list .avatar p {
  color: white !important;
}
.avatar.avatar-dropdown .dropdown-menu {
  margin: -25px;
}
.avatar.avatar-dropdown.size-sm .dropdown-menu {
  margin: -27px;
}
.avatar.size-xs {
  width: 16px;
  height: 16px;
}
.avatar.size-xs p {
  font-size: 10px;
}
.avatar.size-xs .icon {
  height: 10px;
  width: 10px;
}
.avatar.size-sm {
  width: 24px;
  height: 24px;
}
.avatar.size-sm p {
  font-size: 14px;
}
.avatar.size-sm .icon {
  height: 12px;
  width: 12px;
}
.avatar.size-md {
  width: 32px;
  height: 32px;
}
.avatar.size-md p {
  font-size: 16px;
}
.avatar.size-md .icon {
  height: 16px;
  width: 16px;
}
.avatar.size-md .avatar-presence,
.avatar.size-md .avatar-status {
  border-width: 1px;
}
.avatar.size-md .avatar-presence .icon,
.avatar.size-md .avatar-status .icon {
  display: none;
}
.avatar.size-lg {
  width: 40px;
  height: 40px;
}
.avatar.size-lg p {
  font-size: 18px;
}
.avatar.size-lg .icon {
  height: 20px;
  width: 20px;
}
.avatar.size-lg .avatar-presence,
.avatar.size-lg .avatar-status {
  right: -2px;
  width: 15px;
  height: 15px;
}
.avatar.size-lg .avatar-status {
  top: 0.16667px;
}
.avatar.size-xl {
  width: 80px;
  height: 80px;
}
.avatar.size-xl p {
  font-size: 36px;
}
.avatar.size-xl .icon {
  height: 40px;
  width: 40px;
}
.avatar.size-xl .avatar-presence,
.avatar.size-xl .avatar-status {
  right: 6px;
  width: 18px;
  height: 18px;
}
.avatar.size-xl .avatar-presence {
  bottom: 8px;
}
.avatar.size-xl .avatar-status {
  top: 2px;
}
.avatar.size-xxl {
  width: 128px;
  height: 128px;
}
.avatar.size-xxl p {
  font-size: 58px;
}
.avatar.size-xxl .icon {
  height: 62px;
  width: 62px;
}
.avatar.size-xxl .avatar-presence,
.avatar.size-xxl .avatar-status {
  right: 4px;
  width: 24px;
  height: 24px;
}
.avatar.size-xxl .avatar-presence .icon,
.avatar.size-xxl .avatar-status .icon {
  width: 20px;
  height: 20px;
}
.avatar.size-xxl .avatar-presence {
  bottom: 16px;
}
.avatar.size-xxl .avatar-status {
  top: 8px;
}

a.avatar {
  filter: brightness(100%);
}
a.avatar:hover {
  background: #dddfe1;
}
a.avatar:hover img {
  filter: brightness(90%);
}
a.avatar:hover.avatar-primary {
  background: #003e94;
}
a.avatar:hover.avatar-secondary {
  background: #526273;
}
a.avatar:hover.avatar-green {
  background: #00b374;
}
a.avatar:hover.avatar-orange {
  background: #e68a00;
}
a.avatar:hover.avatar-red {
  background: #ef092c;
}

.avatar-dropdown:hover,
.avatar-dropdown:focus-within {
  background: #dddfe1;
}

.avatar-dropdown .btn-dropdown:focus,
.avatar-dropdown .form-check [type="checkbox"]:focus + label.btn-dropdown,
.form-check .avatar-dropdown [type="checkbox"]:focus + label.btn-dropdown,
.avatar-dropdown .form-check [type="radio"]:focus + label.btn-dropdown,
.form-check .avatar-dropdown [type="radio"]:focus + label.btn-dropdown,
.avatar-dropdown
  .toggles
  label
  input[type="checkbox"]:focus
  + .btn-dropdown.lever,
.toggles
  label
  .avatar-dropdown
  input[type="checkbox"]:focus
  + .btn-dropdown.lever,
.avatar-dropdown
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.btn-dropdown,
.block-facet--checkbox
  .avatar-dropdown
  [type="checkbox"]:focus
  + label.btn-dropdown {
  box-shadow: none;
}

.avatar-wrapper {
  position: relative;
}
.avatar-wrapper .avatar-presence,
.avatar-wrapper .avatar-status {
  position: absolute;
  right: 0;
  width: 10px;
  height: 10px;
  border: 2px solid white;
  background: #c5c7c9;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-wrapper .avatar-presence .icon,
.avatar-wrapper .avatar-status .icon {
  width: 18px;
  height: 18px;
}
.avatar-wrapper .avatar-presence {
  bottom: 8px;
}
.avatar-wrapper .avatar-presence.active {
  background: #00cc85;
}
.avatar-wrapper .avatar-presence.busy {
  background: #f73e5a;
}
.avatar-wrapper .avatar-presence.hidden:after {
  content: "";
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: white;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  border-radius: 50%;
}
.avatar-wrapper .avatar-status {
  top: 0;
}
.avatar-wrapper .avatar-status.approved {
  background: #00cc85;
}
.avatar-wrapper .avatar-status.declined {
  background: #f73e5a;
}
.avatar-wrapper .avatar-status.notify {
  background: #0048ad;
}
.avatar-wrapper.avatar-extra-text {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.avatar-wrapper.avatar-extra-text .avatar {
  flex-shrink: 0;
}
.avatar-wrapper.avatar-extra-text .extra-text {
  line-height: 1.2em;
  margin-left: 16px;
}
.avatar-wrapper.avatar-extra-text .extra-text h3,
.avatar-wrapper.avatar-extra-text .extra-text h4 {
  font-weight: 600;
  margin: 0;
  font-size: 1.111rem;
}
.avatar-wrapper.avatar-extra-text .extra-text h3 a,
.avatar-wrapper.avatar-extra-text .extra-text h4 a {
  display: inline-block;
}
.avatar-wrapper.avatar-extra-text .extra-text p,
.avatar-wrapper.avatar-extra-text .extra-text time {
  margin: 0;
  text-transform: uppercase;
  font-size: 0.778rem;
}

.avatar-group > li {
  margin-bottom: 16px;
  line-height: 1;
}
.avatar-group > li:last-child {
  margin-bottom: 0;
}
.avatar-group > li .list-item {
  display: inline-flex;
  align-items: center;
  padding: 0;
  line-height: 1em;
}
.avatar-group > li .list-item .avatar {
  margin-right: 12px;
}
.avatar-group > li .list-item .avatar p {
  font-size: 16px;
}
.avatar-group > li .list-item .avatar.avatar-primary p,
.avatar-group > li .list-item .avatar.avatar-secondary p,
.avatar-group > li .list-item .avatar.avatar-green p,
.avatar-group > li .list-item .avatar.avatar-orange p,
.avatar-group > li .list-item .avatar.avatar-red p {
  color: white;
  line-height: 0;
}
.avatar-group > li .list-item span {
  margin: 0;
  font-size: 0.889rem;
}

.avatar-group-stacked {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  flex-direction: row;
}
.avatar-group-stacked li {
  list-style-type: none;
  line-height: 0;
}
.avatar-group-stacked li > .avatar {
  margin-left: -6px;
  border: 2px solid white;
}

.thumb-nav {
  padding: 0;
  margin: 0 -8px;
  display: flex;
  justify-content: center;
}
.thumb-nav.thumb-nav-vertical {
  flex-direction: column;
  align-items: baseline;
}
.thumb-nav.thumb-nav-fixed {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.thumb-nav.thumb-nav-fixed li {
  flex-basis: 240px;
}
.thumb-nav.thumb-nav-fixed.thumb-nav-small li {
  flex-basis: 120px;
}
.thumb-nav.thumb-nav-auto {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.thumb-nav.thumb-nav-auto li .thumb-nav-resizer {
  max-width: 100%;
  max-height: 100%;
}
.thumb-nav.thumb-nav-auto.thumb-nav-auto-2 li {
  flex-basis: calc(50% - 16px);
}
.thumb-nav.thumb-nav-auto.thumb-nav-auto-3 li {
  flex-basis: calc(33.3333% - 16px);
}
.thumb-nav.thumb-nav-auto.thumb-nav-auto-4 li {
  flex-basis: calc(25% - 16px);
}
.thumb-nav.thumb-nav-auto.thumb-nav-auto-5 li {
  flex-basis: calc(20% - 16px);
}
.thumb-nav.thumb-nav-nozoom a:hover img {
  transform: none;
}
.thumb-nav.thumb-nav-black a:hover:after,
.thumb-nav.thumb-nav-primary a:hover:after {
  opacity: 1;
}
.thumb-nav.thumb-nav-black a:after {
  background: rgba(0, 0, 0, 0.8);
}
.thumb-nav.thumb-nav-primary a:after {
  background: rgba(0, 72, 173, 0.8);
}
.thumb-nav.thumb-nav-bottom,
.thumb-nav.thumb-nav-top {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
}
.thumb-nav.thumb-nav-bottom {
  bottom: 0;
  margin-bottom: 16px;
}
.thumb-nav.thumb-nav-top {
  top: 0;
  margin-top: 16px;
}
.thumb-nav.thumb-nav-left,
.thumb-nav.thumb-nav-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 90%;
}
.thumb-nav.thumb-nav-left {
  left: 0;
  margin-left: 16px;
}
.thumb-nav.thumb-nav-right {
  right: 0;
  margin-right: 16px;
}
.thumb-nav li {
  position: relative;
  list-style-type: none;
  margin: 8px;
}
.thumb-nav .thumb-nav-resizer {
  width: 100%;
  height: auto;
  max-width: 240px;
  max-height: 160px;
  visibility: hidden;
}
.thumb-nav.thumb-nav-small .thumb-nav-resizer {
  max-width: 120px;
  max-height: 80px;
}
.thumb-nav a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.thumb-nav a:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.15, 0.7, 0.36, 0.99);
}
.thumb-nav a.active {
  pointer-events: none;
}
.thumb-nav a.active:after {
  opacity: 1;
  background: rgba(0, 72, 173, 0.6);
}
.thumb-nav a:hover img {
  transform: scale(1.05);
  transition-duration: 1s;
}
.thumb-nav a:hover:after {
  transition-duration: 1s;
}
.thumb-nav a img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.4s cubic-bezier(0.15, 0.7, 0.36, 0.99);
}

.steppers .steppers-header {
  padding: 0 24px;
  height: 64px;
  background: white;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.steppers .steppers-header li {
  font-size: 1.125rem;
  color: #5c6f82;
  list-style-type: none;
  display: none;
}
.steppers .steppers-header li .icon {
  fill: #5c6f82;
}
.steppers .steppers-header li.active,
.steppers .steppers-header li.confirmed {
  color: #0048ad;
  font-weight: 600;
}
.steppers .steppers-header li.active .icon,
.steppers .steppers-header li.confirmed .icon {
  fill: #0048ad;
}
.steppers .steppers-header li.active,
.steppers .steppers-header li.steppers-index {
  display: block;
}
.steppers .steppers-header li.steppers-index {
  margin-left: auto;
  font-size: 0.875rem;
  font-weight: 600;
}
.steppers .steppers-header li.steppers-index span {
  margin-left: 0.25rem;
}
.steppers .steppers-header li.steppers-index span.active {
  color: #0048ad;
  text-decoration: underline;
}
.steppers .steppers-header li .icon {
  margin-right: 0.667rem;
  width: 24px;
  height: 24px;
}
.steppers .steppers-header li .steppers-number .icon {
  margin: 0;
}
.steppers .steppers-header li .steppers-number:after {
  content: ". ";
}

.steppers.bg-dark .steppers-header {
  background: none;
}
.steppers.bg-dark .steppers-header li {
  color: #d9dadb;
}
.steppers.bg-dark .steppers-header li .icon {
  fill: #d9dadb;
}
.steppers.bg-dark .steppers-header li.active {
  font-weight: 600;
  color: #0accc6;
}
.steppers.bg-dark .steppers-header li.active .icon {
  fill: #0accc6;
}
.steppers.bg-dark .steppers-header li.confirmed {
  font-weight: 600;
  color: white;
}
.steppers.bg-dark .steppers-header li.confirmed .icon {
  fill: white;
}
.steppers.bg-dark .steppers-header li.steppers-index span.active {
  color: #0accc6;
}

.steppers.bg-dark .steppers-nav {
  background: none;
}
.steppers.bg-dark .steppers-nav .progress-bar {
  background: #0accc6;
}
.steppers.bg-dark .steppers-nav .steppers-dots li.done {
  background: #0accc6;
}

.steppers .steppers-nav {
  display: flex;
  height: 64px;
  padding: 0 24px;
  box-shadow: 0 -8px 20px rgba(0, 0, 0, 0.1);
  background: white;
  align-items: center;
  justify-content: space-between;
}
.steppers .steppers-nav .steppers-progress,
.steppers .steppers-nav .steppers-dots {
  width: 20%;
}
.steppers .steppers-nav .steppers-progress .progress,
.steppers .steppers-nav .steppers-dots .progress {
  height: 2px;
}
.steppers .steppers-nav .steppers-progress .progress {
  height: 2px;
}
.steppers .steppers-nav .steppers-dots {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.steppers .steppers-nav .steppers-dots li {
  list-style-type: none;
  padding: 0;
  height: 4px;
  width: 4px;
  min-width: 4px;
  background: #cacacc;
  margin-right: 8px;
  border-radius: 50%;
}
.steppers .steppers-nav .steppers-dots li.done {
  background: #0048ad;
}

@media (min-width: 576px) {
  .steppers .steppers-header li {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .steppers .steppers-header {
    padding: 0;
    box-shadow: none;
    height: auto;
    display: flex;
    justify-content: space-between;
    background: transparent;
  }
  .steppers .steppers-header:before {
    display: none;
  }
  .steppers .steppers-header li {
    text-transform: uppercase;
    padding: 1.778rem 1.111rem;
    flex-grow: 1;
    border-left: 1px solid #dfe4f2;
    display: block;
    position: relative;
    display: flex;
    align-items: center;
  }
  .steppers .steppers-header li:nth-last-child(2) {
    border-right: 1px solid #dfe4f2;
  }
  .steppers .steppers-header li.steppers-index {
    display: none;
  }
  .steppers .steppers-header li.active:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - 2.222rem);
    height: 2px;
    background: #0048ad;
    left: 50%;
    transform: translateX(-50%);
  }
  .steppers .steppers-header li.active .steppers-number {
    color: white;
    background: #0048ad;
    border-color: #0048ad;
  }
  .steppers .steppers-header li.active.no-line:after {
    display: none;
  }
  .steppers .steppers-header li .steppers-success {
    margin-left: auto;
  }
  .steppers .steppers-header li .steppers-number {
    display: inline-block;
    width: 1.778rem;
    height: 1.778rem;
    border: 1px solid #dfe4f2;
    border-radius: 50%;
    text-align: center;
    margin-right: 0.667rem;
  }
  .steppers .steppers-header li .steppers-number:after {
    display: none;
  }
  .steppers .steppers-header li .steppers-number .icon:before {
    margin-right: 0;
    margin-top: 0.333rem;
  }
  .steppers.bg-dark .steppers-header li {
    border-color: #435a70;
  }
  .steppers.bg-dark .steppers-header li.active:after {
    background: #0accc6;
  }
  .steppers.bg-dark .steppers-header li.active .steppers-number {
    background: #0accc6;
    border-color: #0accc6;
    color: #17324d;
  }
  .steppers.bg-dark .steppers-header li .steppers-number {
    border-color: #768594;
  }
  .steppers .steppers-nav {
    box-shadow: none;
    margin-top: 0.889rem;
    align-items: center;
    background: none;
  }
  .steppers .steppers-nav .steppers-progress,
  .steppers .steppers-nav .steppers-dots {
    display: none;
  }
  .steppers .steppers-nav button .icon {
    display: none;
  }
  .steppers .steppers-nav button:not(.steppers-btn-confirm) {
    margin-right: 0.889rem;
  }
  .steppers .steppers-nav .steppers-btn-confirm {
    display: block;
    margin-left: auto;
  }
}

@media (max-width: 991px) {
  .steppers
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm) {
    font-size: 1rem;
    background: transparent;
    color: #0048ad;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  .steppers
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm):hover,
  .steppers
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm):focus,
  .steppers
    .steppers-nav
    .toggles
    label
    input[type="checkbox"]:focus
    + button.lever:not(.steppers-btn-save):not(.steppers-btn-confirm),
  .toggles
    label
    .steppers
    .steppers-nav
    input[type="checkbox"]:focus
    + button.lever:not(.steppers-btn-save):not(.steppers-btn-confirm),
  .steppers
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm):active {
    background: transparent !important;
    color: #0048ad !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 72, 173, 0.25) !important;
  }
  .steppers
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm)
    .icon {
    margin: 0 0.333rem;
    height: 24px;
    width: 24px;
  }
  .steppers.bg-dark
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm) {
    color: white;
  }
  .steppers.bg-dark
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm)
    .icon {
    fill: white;
  }
  .steppers.bg-dark
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm):hover,
  .steppers.bg-dark
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm):focus,
  .steppers.bg-dark
    .steppers-nav
    .toggles
    label
    input[type="checkbox"]:focus
    + button.lever:not(.steppers-btn-save):not(.steppers-btn-confirm),
  .toggles
    label
    .steppers.bg-dark
    .steppers-nav
    input[type="checkbox"]:focus
    + button.lever:not(.steppers-btn-save):not(.steppers-btn-confirm),
  .steppers.bg-dark
    .steppers-nav
    button:not(.steppers-btn-save):not(.steppers-btn-confirm):active {
    color: #0accc6 !important;
  }
}

.card-wrapper {
  padding-bottom: 24px;
  display: flex;
}
.card-wrapper.card-column {
  flex-direction: column;
}
.card-wrapper.card-column .card {
  height: auto !important;
}
.card-wrapper.card-column .card + .card {
  margin-top: 1rem;
}
.card-wrapper.card-teaser-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-wrapper.card-teaser-wrapper.card-teaser-wrapper-equal {
  align-items: stretch;
}
.card-wrapper.card-teaser-wrapper > .card-teaser {
  flex: 0 0 100%;
  margin: 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.card-wrapper.card-teaser-wrapper > .card-teaser.border {
  border-color: #b1b1b3;
}
.card-wrapper.card-teaser-wrapper > .card-teaser.card-teaser-image {
  padding: 0 !important;
}
.card-wrapper.card-teaser-wrapper
  > .card-teaser.card-teaser-image
  > .card-image-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.card-wrapper.card-teaser-wrapper
  > .card-teaser.card-teaser-image
  > .card-image-wrapper.with-read-more
  .card-image-rounded
  img {
  border-bottom-right-radius: 0 !important;
}
.card-wrapper.card-teaser-wrapper .card-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
}
.card-wrapper.card-teaser-wrapper .card-flex .card-image {
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-wrapper.card-teaser-wrapper .card-flex .card-image.card-image-rounded {
  border-radius: 0 4px 4px 0;
}
.card-wrapper.card-teaser-wrapper
  .card-flex
  .card-image.card-image-rounded
  img {
  border-radius: 0 4px 4px 0;
}
.card-wrapper.card-teaser-wrapper .card-flex .card-image .card-date {
  background-color: white;
  padding: 0.5em 1em;
  z-index: 1;
}
.card-wrapper.card-teaser-wrapper
  .card-flex
  .card-image
  .card-date
  > *:first-child {
  font-size: 120%;
}
.card-wrapper.card-teaser-wrapper
  .card-flex
  .card-image
  .card-date
  > *:last-child {
  font-size: 80%;
}
.card-wrapper.card-teaser-wrapper .card-flex .card-image img {
  z-index: auto;
  object-fit: cover;
}
@media (min-width: 768px) {
  .card-wrapper.card-teaser-wrapper .card-teaser {
    flex: 0 0 49%;
  }
  .card-wrapper.card-teaser-wrapper.card-teaser-block-2 {
    width: 100%;
    justify-content: space-between;
  }
  .card-wrapper.card-teaser-wrapper.card-teaser-block-2 > .card-teaser {
    flex: 0 0 49%;
  }
  .card-wrapper.card-teaser-wrapper.card-teaser-block-3 {
    width: 100%;
    justify-content: space-between;
  }
  .card-wrapper.card-teaser-wrapper.card-teaser-block-3 > .card-teaser {
    flex: 0 0 32%;
  }
  .card-wrapper.card-teaser-wrapper.card-teaser-start {
    justify-content: flex-start !important;
  }
  .card-wrapper.card-teaser-wrapper.card-teaser-start
    .card-teaser
    + .card-teaser {
    margin-left: 1.35rem;
  }
  .card-wrapper.card-teaser-wrapper.card-teaser-end {
    justify-content: flex-end !important;
  }
  .card-wrapper.card-teaser-wrapper.card-teaser-end
    .card-teaser
    + .card-teaser {
    margin-left: 1.35rem;
  }
}
@media (min-width: 1200px) {
  .card-wrapper.card-teaser-block-4 {
    width: 100%;
    justify-content: space-between;
  }
  .card-wrapper.card-teaser-block-4 > .card-teaser {
    flex: 0 0 24%;
  }
  .card-wrapper.card-overlapping {
    margin-top: -32px;
  }
}

.card {
  border: none;
  padding: 0;
  position: relative;
  width: 100%;
}
.card.card-bg-primary {
  background-color: #0048ad;
}
.card.card-bg-primary .card-body .card-title,
.card.card-bg-primary .card-body .card-text {
  color: white;
}
.card.card-bg-secondary {
  background-color: #5c6f82;
}
.card.card-bg-secondary .card-body .card-title,
.card.card-bg-secondary .card-body .card-text {
  color: white;
}
.card.card-bg-success {
  background-color: #008758;
}
.card.card-bg-success .card-body .card-title,
.card.card-bg-success .card-body .card-text {
  color: white;
}
.card.card-bg-info {
  background-color: #979899;
}
.card.card-bg-info .card-body .card-title,
.card.card-bg-info .card-body .card-text {
  color: white;
}
.card.card-bg-warning {
  background-color: #a66300;
}
.card.card-bg-warning .card-body .card-title,
.card.card-bg-warning .card-body .card-text {
  color: white;
}
.card.card-bg-danger {
  background-color: #d9364f;
}
.card.card-bg-danger .card-body .card-title,
.card.card-bg-danger .card-body .card-text {
  color: white;
}
.card.card-bg-light {
  background-color: #e9e6f2;
}
.card.card-bg-light .card-body .card-title,
.card.card-bg-light .card-body .card-text {
  color: white;
}
.card.card-bg-dark {
  background-color: #17324d;
}
.card.card-bg-dark .card-body .card-title,
.card.card-bg-dark .card-body .card-text {
  color: white;
}
.card.card-bg-black {
  background-color: black;
}
.card.card-bg-black .card-body .card-title,
.card.card-bg-black .card-body .card-text {
  color: white;
}
.card.card-bg-white {
  background-color: white;
}
.card.card-bg-white .card-body .card-title,
.card.card-bg-white .card-body .card-text {
  color: white;
}
.card.card-bg-100 {
  background-color: #e3e4e6;
}
.card.card-bg-100 .card-body .card-title,
.card.card-bg-100 .card-body .card-text {
  color: white;
}
.card.card-bg-200 {
  background-color: #cacacc;
}
.card.card-bg-200 .card-body .card-title,
.card.card-bg-200 .card-body .card-text {
  color: white;
}
.card.card-bg-300 {
  background-color: #b1b1b3;
}
.card.card-bg-300 .card-body .card-title,
.card.card-bg-300 .card-body .card-text {
  color: white;
}
.card.card-bg-400 {
  background-color: #979899;
}
.card.card-bg-400 .card-body .card-title,
.card.card-bg-400 .card-body .card-text {
  color: white;
}
.card.card-bg-500 {
  background-color: #7e7f80;
}
.card.card-bg-500 .card-body .card-title,
.card.card-bg-500 .card-body .card-text {
  color: white;
}
.card.card-bg-600 {
  background-color: #656566;
}
.card.card-bg-600 .card-body .card-title,
.card.card-bg-600 .card-body .card-text {
  color: white;
}
.card.card-bg-700 {
  background-color: #4c4c4d;
}
.card.card-bg-700 .card-body .card-title,
.card.card-bg-700 .card-body .card-text {
  color: white;
}
.card.card-bg-800 {
  background-color: #323333;
}
.card.card-bg-800 .card-body .card-title,
.card.card-bg-800 .card-body .card-text {
  color: white;
}
.card.card-bg-900 {
  background-color: #19191a;
}
.card.card-bg-900 .card-body .card-title,
.card.card-bg-900 .card-body .card-text {
  color: white;
}
.card .card-body {
  padding: 24px;
}
.card .card-body h4.card-title + .card-text {
  padding-top: 16px;
}
.card .card-body h5.card-title {
  font-size: 1.125rem;
  line-height: 1.44444rem;
  font-weight: 700;
  color: #17324d;
  margin-bottom: 16px;
  transition: all 0.3s;
}
.card .card-body h5.card-title.big-heading {
  font-size: 1.55556rem;
  line-height: 1.77778rem;
}
.card .card-body h5.card-title.card-title-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.card .card-body h5.card-title.card-title-icon .icon {
  margin-right: 0.5em;
}
.card .card-body h6.card-subtitle {
  font-weight: normal;
  margin-bottom: 16px;
  margin-top: -16px;
}
.card .card-body a h5.card-title {
  color: #0055cc;
}
.card .card-body .card-text {
  font-family: Lora, Georgia, serif;
  font-size: 0.875rem;
  line-height: 1.2rem;
  color: #435a70;
}
.card .card-body .card-signature {
  font-style: italic;
  font-family: Lora, Georgia, serif;
  color: #435a70;
  font-weight: 700;
  font-size: 0.88889rem;
  margin-bottom: 24px;
  display: block;
}
.card .card-body .category-top {
  font-size: 0.77778rem;
  text-transform: uppercase;
  color: #435a70;
  margin-bottom: 16px;
}
.card .card-body .category-top a.category {
  color: #435a70;
}
.card .card-body .category-top a.category:hover {
  text-decoration: underline;
}
.card .card-body .category-top .category {
  font-weight: bold;
  letter-spacing: 0.9px;
}
.card .card-body .category-top .data:before {
  content: "—";
  display: inline-block;
  margin: 0 8px;
}
.card:after {
  content: "";
  display: block;
  margin-top: 48px;
  margin-left: 24px;
}
.card.no-after {
  content: none;
}
.card a.read-more {
  position: absolute;
  bottom: 24px;
}
.card .categoryicon-top {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.card .categoryicon-top .text {
  font-size: 0.77778rem;
  text-transform: uppercase;
  letter-spacing: 0.9px;
  color: #435a70;
  line-height: 1rem;
}
.card .categoryicon-top .icon {
  width: 40px;
  height: 40px;
  fill: #0055cc;
  margin-right: 8px;
  flex-shrink: 0;
}
.card .simple-link {
  font-weight: 600;
  font-size: 0.88889rem;
  margin-top: 32px;
  display: block;
}
.card.card-bg {
  margin-left: 8px;
  margin-right: 8px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: white;
  border-right: none;
}
.card.card-bg:after {
  background: transparent;
}
.card.card-img {
  border-right: none;
}
.card.card-img h5.card-title {
  font-size: 1.11111rem;
  line-height: 1.55556rem;
}
.card.card-img .img-responsive-wrapper .img-responsive {
  padding-bottom: 61.29%;
  /* image proportion 310 x 190 */
}
.card.card-img
  .img-responsive-wrapper
  .img-responsive.img-responsive-panoramic {
  padding-bottom: 30.645%;
  /* image proportion 310 x 95 */
}
.card.card-img.rounded .img-responsive-wrapper {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.card .card-calendar {
  height: 80%;
  max-height: 80px;
  width: 80px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 32px;
  top: 10%;
  color: #455a64;
  text-align: center;
  font-size: 0.889em;
  font-weight: 600;
  line-height: 1.3;
  text-transform: capitalize;
}
.card .card-calendar .card-date {
  font-size: 1.667em;
  font-weight: 700;
  display: block;
}
.card.no-after {
  position: relative;
}
.card.no-after:after {
  display: none;
}
.card.no-after > .read-more {
  padding-top: 16px;
}
.card.no-after > .read-more::before {
  content: "";
  height: 24px;
  display: block;
}
.card.special-card .img-responsive-wrapper {
  overflow: visible;
  position: relative;
  width: 174px;
  margin-bottom: 24px;
}
.card.special-card .img-responsive-wrapper::before,
.card.special-card .img-responsive-wrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
}
.card.special-card .img-responsive-wrapper:before {
  background: #6a9feb;
  left: 24px;
  top: 24px;
  opacity: 0.3;
  transition: all 0.3s;
}
.card.special-card .img-responsive-wrapper:after {
  background: #6a9feb;
  left: 12px;
  top: 12px;
  transition: all 0.3s;
}
.card.special-card .img-responsive-wrapper .img-responsive {
  padding-bottom: 122.98%;
  /* image proportion 214 x 174 */
  z-index: 1;
}
.card.special-card .head-tags {
  margin-bottom: 24px;
}
.card.special-card .head-tags span.data {
  font-weight: normal;
}
.card.special-card:hover {
  text-decoration: none;
}
.card.special-card:hover h5 {
  text-decoration: underline;
  color: #0055cc;
}
.card.special-card:hover .img-responsive-wrapper:before {
  background: #0055cc;
  opacity: 0.3;
}
.card.special-card:hover .img-responsive-wrapper:after {
  background: #0055cc;
}
.card.card-big .card-body {
  padding: 48px;
}
.card.card-big .card-body .top-icon {
  margin-bottom: 24px;
}
.card.card-big .card-body .top-icon .icon {
  width: 80px;
  height: 80px;
  fill: #0055cc;
}
.card.card-big .card-body h5.card-title {
  font-size: 1.33333rem;
  line-height: 1.55556rem;
}
.card.card-big .card-body .card-text {
  font-size: 0.88889rem;
  line-height: 1.33333rem;
}
.card.card-big .flag-icon {
  margin-left: 48px;
}
.card.card-big .etichetta {
  position: absolute;
  right: 48px;
  top: 32px;
  justify-content: flex-end;
}
.card.border-bottom-card::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  bottom: 0;
  background: #0055cc;
}
.card .head-tags {
  justify-content: space-between;
  display: flex;
  margin-bottom: 48px;
}
.card .head-tags .data {
  font-size: 0.77778rem;
  text-transform: uppercase;
  color: #435a70;
}
.card .card-tag {
  color: #0055cc;
  font-size: 0.77778rem;
  font-weight: bold;
  letter-spacing: 0.9px;
  padding: 0 32px;
  border-radius: 50px;
  border: 1px solid #0055cc;
}
.card .it-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
}
.card .it-card-footer .card-signature {
  font-style: italic;
  font-family: Lora, Georgia, serif;
  color: #435a70;
  font-weight: 700;
  font-size: 0.88889rem;
  display: block;
  margin-bottom: 0;
}
.card.card-teaser {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
}
.card.card-teaser:after {
  content: none;
}
.card.card-teaser.card-column {
  flex-direction: column;
}
.card.card-teaser .card-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  width: 100%;
}
.card.card-teaser .card-header .icon {
  margin-right: 0.5rem;
}
.card.card-teaser .card-header h1 {
  line-height: 1.5;
  margin-bottom: 0;
}
.card.card-teaser .card-header h2 {
  line-height: 1.5;
  margin-bottom: 0;
}
.card.card-teaser .card-header h3 {
  line-height: 1.5;
  margin-bottom: 0;
}
.card.card-teaser .card-header h4 {
  line-height: 1.5;
  margin-bottom: 0;
}
.card.card-teaser .card-header h5 {
  line-height: 1.5;
  margin-bottom: 0;
}
.card.card-teaser .card-header h6 {
  line-height: 1.5;
  margin-bottom: 0;
}
.card.card-teaser .card-body {
  padding: 0;
  flex: auto;
}
.card.card-teaser .card-body .card-title {
  margin: 0;
}
.card.card-teaser .card-body .card-text,
.card.card-teaser .card-body .card-text > p {
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
  font-size: 0.88889rem;
  margin: 0;
}
.card.card-teaser .card-body .card-text + .card-text,
.card.card-teaser .card-body .card-text > p + .card-text {
  margin-top: 16px;
}
.card.card-teaser .icon {
  min-width: 32px;
}
.card.card-teaser .icon + .card-body {
  margin-left: 1em;
}
.card.card-teaser .avatar.size-xs {
  min-width: 16px;
}
.card.card-teaser .avatar.size-sm {
  min-width: 24px;
}
.card.card-teaser .avatar.size-md {
  min-width: 32px;
}
.card.card-teaser .avatar.size-lg {
  min-width: 40px;
}
.card.card-teaser .avatar.size-xl {
  min-width: 80px;
}
.card.card-teaser .avatar + .card-body {
  flex: 1;
}
.card.card-teaser-primary {
  border-left: 8px solid #0048ad;
}
.card.card-teaser-secondary {
  border-left: 8px solid #5c6f82;
}
.card.card-teaser-success {
  border-left: 8px solid #008758;
}
.card.card-teaser-info {
  border-left: 8px solid #979899;
}
.card.card-teaser-warning {
  border-left: 8px solid #a66300;
}
.card.card-teaser-danger {
  border-left: 8px solid #d9364f;
}
.card.card-teaser-light {
  border-left: 8px solid #e9e6f2;
}
.card.card-teaser-dark {
  border-left: 8px solid #17324d;
}
.card.card-teaser-black {
  border-left: 8px solid black;
}
.card.card-teaser-white {
  border-left: 8px solid white;
}
.card.card-teaser-100 {
  border-left: 8px solid #e3e4e6;
}
.card.card-teaser-200 {
  border-left: 8px solid #cacacc;
}
.card.card-teaser-300 {
  border-left: 8px solid #b1b1b3;
}
.card.card-teaser-400 {
  border-left: 8px solid #979899;
}
.card.card-teaser-500 {
  border-left: 8px solid #7e7f80;
}
.card.card-teaser-600 {
  border-left: 8px solid #656566;
}
.card.card-teaser-700 {
  border-left: 8px solid #4c4c4d;
}
.card.card-teaser-800 {
  border-left: 8px solid #323333;
}
.card.card-teaser-900 {
  border-left: 8px solid #19191a;
}

.flag-icon {
  width: 32px;
  height: 48px;
  box-sizing: content-box;
  position: relative;
  background: #0055cc;
  color: white;
  text-align: center;
  text-transform: uppercase;
}

.flag-icon:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 13px solid white;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
}

a.read-more {
  display: flex;
  align-items: center;
  color: #0055cc;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.9px;
  font-size: 0.77778rem;
}
a.read-more .icon {
  margin-left: 8px;
  fill: #0055cc;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.etichetta {
  display: flex;
  align-items: center;
  font-size: 0.77778rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.9px;
  color: #435a70;
}
.etichetta .icon {
  fill: #435a70;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  flex-shrink: 0;
}

.row [class*="col-"] > .card-wrapper {
  height: 100%;
}
.row [class*="col-"] > .card-wrapper > .card {
  height: 100%;
}

@media (min-width: 992px) {
  .card-wrapper {
    padding-bottom: 0;
  }
  .card-wrapper.card-space {
    padding-bottom: 12px;
  }
  .card-wrapper.card-offset {
    margin-top: 90px;
  }
  .card {
    border: none;
    margin-right: -12px;
  }
  .card.card-bg,
  .card.card-img {
    margin: 0;
  }
  .card .card-body .card-text {
    font-size: 0.77778rem;
  }
  .card .card-body h5.card-title {
    font-size: 1rem;
  }
}

.img-responsive-wrapper {
  overflow: hidden;
}
.img-responsive-wrapper .img-responsive {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}
.img-responsive-wrapper .img-responsive .img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.img-responsive-wrapper .img-responsive .img-wrapper.custom-object-fit {
  background-size: cover;
  background-position: center;
  background-color: #ebeced;
}
.img-responsive-wrapper .img-responsive .img-wrapper.custom-object-fit img {
  visibility: hidden;
}
.img-responsive-wrapper .img-responsive .img-wrapper img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

.overlay-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.overlay-wrapper.overlay-hover .overlay-panel {
  opacity: 0;
  transition: opacity 0.1s;
}
.overlay-wrapper.overlay-hover:hover .overlay-panel {
  opacity: 1;
  transition: opacity 0.2s;
}

.overlay-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 16px 24px;
  background-color: rgba(0, 72, 173, 0.85);
  color: white;
  font-weight: 600;
  font-size: 1rem;
  max-height: 56px;
}
.overlay-panel span {
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.overlay-panel.overlay-panel-fullheight,
.overlay-panel.overlay-icon {
  top: 0;
  height: 100%;
  max-height: 100%;
  display: flex;
}
.overlay-panel.overlay-panel-fullheight {
  align-items: flex-end;
}
.overlay-panel.overlay-icon {
  justify-content: center;
  align-items: center;
}
.overlay-panel.overlay-icon .icon {
  fill: white;
}
.overlay-panel.overlay-black {
  background-color: rgba(0, 0, 0, 0.54);
}

@media (min-width: 576px) {
  .overlay-panel {
    font-size: 0.889rem;
  }
}

.progress-donut-wrapper {
  width: 128px;
  height: 128px;
  position: relative;
}
.progress-donut-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.85);
  background: #d4e6ff;
  border-radius: 50%;
}

.progress-donut {
  transform: scale(0.355);
  position: absolute;
  top: -162px;
  left: -162px;
}
.progress-donut .clProg {
  font-weight: 700;
}

@media (min-width: 768px) {
  .progress-donut-wrapper {
    width: 180px;
    height: 180px;
  }
  .progress-donut {
    transform: scale(0.5);
    top: -136px;
    left: -136px;
  }
}

@keyframes progressBarIndeterminate {
  0% {
    left: -5%;
  }
  50% {
    width: 66%;
  }
  100% {
    left: 100%;
    width: 33%;
  }
}

.progress {
  height: 4px;
  box-shadow: none;
  background-color: #d4e6ff;
}
.progress.progress-color {
  background-color: #e6ecf2;
}
.progress.progress-indeterminate {
  position: relative;
}
.progress.progress-indeterminate .progress-bar {
  width: 0;
  animation: progressBarIndeterminate 1.4s cubic-bezier(0.77, 0, 0.175, 1)
    infinite forwards;
  position: absolute;
  top: 0;
  bottom: 0;
}

.progress-bar {
  background-color: #0048ad;
}

.progress-bar-label {
  text-align: right;
  font-size: 0.75rem;
  color: #17324d;
  font-weight: 500;
}

.btn-progress .progress {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  border-radius: 0 0 4px 4px;
}

.btn-progress .progress-bar {
  height: 4px;
}

@media (min-width: 576px) {
  .progress-bar-label {
    font-size: 0.667rem;
  }
}

@keyframes spinnerAnim {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinnerAnimInner1 {
  0% {
    transform: rotate(60deg);
  }
  100% {
    transform: rotate(205deg);
  }
}

@keyframes spinnerAnimInner2 {
  0% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(-105deg);
  }
}

.progress-spinner {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 4px solid #d4e6ff;
}
.progress-spinner.progress-spinner-active {
  animation: spinnerAnim 0.75s linear infinite;
}
.progress-spinner.progress-spinner-active:not(.progress-spinner-double) {
  border-color: #0048ad;
  border-bottom-color: #d4e6ff;
}
.progress-spinner.size-sm {
  width: 32px;
  height: 32px;
}
.progress-spinner.progress-spinner-double {
  display: inline-block;
}
.progress-spinner.progress-spinner-double.size-sm {
  width: 32px;
  height: 32px;
}
.progress-spinner.progress-spinner-double.size-sm .progress-spinner-inner {
  width: 32px;
  height: 16px;
}
.progress-spinner.progress-spinner-double.progress-spinner-active
  .progress-spinner-inner {
  opacity: 1;
}
.progress-spinner.progress-spinner-double .progress-spinner-inner {
  width: 48px;
  height: 24px;
  overflow: hidden;
  margin-left: -4px;
  opacity: 0;
  transition: opacity 0.3s;
}
.progress-spinner.progress-spinner-double .progress-spinner-inner:nth-child(1) {
  margin-top: -4px;
}
.progress-spinner.progress-spinner-double .progress-spinner-inner:nth-child(2) {
  transform: rotate(180deg);
}
.progress-spinner.progress-spinner-double
  .progress-spinner-inner:nth-child(2):after {
  animation-name: spinnerAnimInner2;
}
.progress-spinner.progress-spinner-double .progress-spinner-inner:after {
  content: "";
  display: inline-block;
  transform: rotate(45deg);
  border-radius: 50%;
  border: 4px solid #0048ad;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  width: 100%;
  height: 200%;
  animation: spinnerAnimInner1 0.75s cubic-bezier(0.25, 0.1, 0.5, 1) infinite
    alternate;
}

.it-grid-item-wrapper {
  position: relative;
}
.it-grid-item-wrapper .img-responsive-wrapper {
  border-radius: 4px;
}
.it-grid-item-wrapper .img-responsive-wrapper .img-responsive {
  padding-bottom: 66.81223%;
}
.it-grid-item-wrapper .it-griditem-text-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px 8px;
  align-items: center;
}
.it-grid-item-wrapper .it-griditem-text-wrapper .it-griditem-text {
  font-size: 0.8rem;
  color: #435a70;
  font-weight: 600;
}
.it-grid-item-wrapper .it-griditem-text-wrapper .icon {
  flex-shrink: 0;
  position: relative;
  right: -8px;
  fill: #0055cc;
  width: 24px;
  height: 24px;
}
.it-grid-item-wrapper.it-grid-item-overlay .it-griditem-text-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(23, 50, 77, 0.85);
  padding: 8px 16px;
}
.it-grid-item-wrapper.it-grid-item-overlay
  .it-griditem-text-wrapper
  .it-griditem-text {
  color: #fff;
}
.it-grid-item-wrapper.it-grid-item-overlay .it-griditem-text-wrapper .icon {
  fill: #fff;
}
.it-grid-item-wrapper a:hover .it-griditem-text {
  text-decoration: underline;
}
.it-grid-item-wrapper.it-grid-item-double-w
  .img-responsive-wrapper
  .img-responsive {
  padding-bottom: calc(33.40611% - 2px);
}
.it-grid-item-wrapper.it-grid-item-overlay {
  position: relative;
}
.it-grid-item-wrapper.it-grid-item-overlay .it-griditem-text-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  color: white;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  background-color: transparent;
}

.it-grid-list-wrapper {
  padding-left: 4px;
  padding-right: 4px;
}
.it-grid-list-wrapper div[class^="col-"] {
  padding: 0 2px 4px 2px;
}
.it-grid-list-wrapper .grid-row {
  display: flex;
  margin-left: -2px;
  margin-right: -2px;
  flex-wrap: wrap;
}
.it-grid-list-wrapper.it-image-label-grid {
  padding-left: 16px;
  padding-right: 16px;
}
.it-grid-list-wrapper.it-image-label-grid div[class^="col-"] {
  padding: 0 8px 16px 8px;
}
.it-grid-list-wrapper.it-image-label-grid .grid-row {
  margin-left: -20px;
  margin-right: -20px;
}
.it-grid-list-wrapper.it-masonry .card-columns {
  margin-left: -20px;
  margin-right: -20px;
  column-gap: 0;
}
.it-grid-list-wrapper.it-masonry .card-columns .it-grid-item-wrapper {
  break-inside: avoid-column;
}
.it-grid-list-wrapper.it-masonry
  .card-columns
  .it-grid-item-wrapper
  .img-responsive {
  padding-bottom: initial;
  height: auto;
}
.it-grid-list-wrapper.it-masonry
  .card-columns
  .it-grid-item-wrapper
  .img-responsive
  .img-wrapper {
  position: inherit;
  left: initial;
  right: initial;
  width: initial;
  height: initial;
}
.it-grid-list-wrapper.it-masonry
  .card-columns
  .it-grid-item-wrapper
  .img-responsive
  .img-wrapper
  img {
  height: auto;
  object-fit: initial;
}

@media (min-width: 992px) {
  .it-grid-item-wrapper.it-grid-item-overlay .it-griditem-text-wrapper {
    padding: 16px 24px;
  }
  .it-grid-item-wrapper.it-grid-item-overlay
    .it-griditem-text-wrapper
    .it-griditem-text {
    font-size: 1rem;
  }
  .it-grid-item-wrapper .it-griditem-text-wrapper .it-griditem-text {
    font-size: 1rem;
  }
  .it-grid-item-wrapper .it-griditem-text-wrapper .icon {
    width: 32px;
    height: 32px;
  }
}

.popover {
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.1);
}
.popover.bs-popover-top,
.popover.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 16px;
}
.popover.bs-popover-top .arrow:after,
.popover.bs-popover-auto[x-placement^="top"] .arrow:after {
  bottom: 2px;
}
.popover.bs-popover-bottom,
.popover.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 16px;
}
.popover.bs-popover-bottom .arrow:after,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow:after {
  top: 2px;
}
.popover.bs-popover-left,
.popover.bs-popover-auto[x-placement^="left"] {
  margin-right: 16px;
}
.popover.bs-popover-left .arrow:after,
.popover.bs-popover-auto[x-placement^="left"] .arrow:after {
  right: 2px;
}
.popover.bs-popover-right,
.popover.bs-popover-auto[x-placement^="right"] {
  margin-left: 16px;
}
.popover.bs-popover-right .arrow:after,
.popover.bs-popover-auto[x-placement^="right"] .arrow:after {
  left: 2px;
}
.popover .arrow:before {
  display: none;
}
.popover .popover-header {
  background: none;
  border: none;
  text-transform: uppercase;
  color: #17324d;
  font-size: 0.938rem;
  padding: 16px 24px 0;
  letter-spacing: 0.05em;
}
.popover .popover-header .icon {
  fill: #0048ad;
  margin-right: 8px;
  height: 24px;
  width: 24px;
}
.popover .popover-body {
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #5a768a;
  padding: 16px 24px;
}
.popover .popover-body a.popover-inner-link {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  display: block;
  margin-top: 16px;
  font-size: 0.857em;
  text-align: right;
  font-weight: 600;
}
.popover .popover-body a.popover-inner-link:hover {
  color: #0048ad;
}
.popover .popover-body a.popover-inner-link .icon {
  fill: #0048ad;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  margin-bottom: 4px;
}

@media (min-width: 576px) {
  .popover .popover-header {
    font-size: 0.833rem;
  }
  .popover .popover-body {
    font-size: 0.778rem;
  }
}

.tooltip {
  border-radius: 4px;
  font-size: 0.75rem;
}
.tooltip .arrow {
  display: none;
}
.tooltip .tooltip-inner {
  padding: 7px 16px;
  background-color: #455b71;
}
.tooltip .tooltip-inner a {
  color: white;
  text-decoration: underline;
}
.tooltip.bs-tooltip-top,
.tooltip.bs-tooltip-auto[x-placement^="top"],
.tooltip.bs-tooltip-bottom,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 8px 0;
}
.tooltip.bs-tooltip-left,
.tooltip.bs-tooltip-auto[x-placement^="left"],
.tooltip.bs-tooltip-right,
.tooltip.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 8px;
}

@media (min-width: 576px) {
  .tooltip {
    font-size: 0.667rem;
  }
}

@media (min-width: 768px) {
  .tooltip {
    font-size: 0.556rem;
  }
  .tooltip .tooltip-inner {
    padding: 4.5px 8px;
  }
}

.it-list-wrapper .it-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.it-list-wrapper .it-list a {
  display: block;
  transition: all 0.3s;
  margin-top: -1px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.it-list-wrapper .it-list a .avatar,
.it-list-wrapper .it-list a .it-rounded-icon,
.it-list-wrapper .it-list a .it-thumb {
  flex-shrink: 0;
  margin-left: 24px;
}
.it-list-wrapper .it-list a .it-rounded-icon {
  width: 40px;
}
.it-list-wrapper .it-list a .it-rounded-icon svg {
  fill: #206cd6;
}
.it-list-wrapper .it-list a .form-check {
  margin: 0;
  width: 40px;
  height: 32px;
  text-align: center;
}
.it-list-wrapper .it-list a .form-check label {
  padding-left: 0;
  margin-bottom: 0;
}
.it-list-wrapper .it-list a .it-thumb {
  width: 40px;
  height: 40px;
}
.it-list-wrapper .it-list a .it-thumb img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.it-list-wrapper .it-list a .it-right-zone {
  padding: 16px 0 16px 0;
  border-bottom: 1px solid #c5c7c9;
  margin-left: 24px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.it-list-wrapper .it-list a .it-right-zone svg {
  fill: #206cd6;
  margin-right: 24px;
  width: 24px;
  height: 24px;
}
.it-list-wrapper .it-list a .it-right-zone span.it-multiple {
  margin-right: 24px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.it-list-wrapper .it-list a .it-right-zone span.it-multiple span.metadata {
  margin-right: 0;
  width: 100%;
  text-align: right;
}
.it-list-wrapper .it-list a .it-right-zone span.it-multiple svg {
  margin-left: 8px;
  margin-right: 0;
}
.it-list-wrapper .it-list a .it-right-zone .toggles {
  height: 32px;
  margin-right: 24px;
}
.it-list-wrapper .it-list a .it-right-zone span.metadata {
  color: #5b6f82;
  margin-right: 24px;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.it-list-wrapper .it-list a span.text {
  font-size: 1rem;
  font-weight: 600;
  display: block;
}
.it-list-wrapper .it-list a span.text em {
  display: block;
  font-size: 14px;
  color: #435a70;
  font-style: normal;
  font-weight: normal;
}
.it-list-wrapper .it-list a.active {
  color: #17324d;
}
.it-list-wrapper .it-list li:last-child a span.text {
  border-bottom: 1px solid transparent;
}

@media (min-width: 1200px) {
  .it-list-wrapper .it-list a:hover {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    color: #206cd6;
    text-decoration: none;
    background: #fff;
    position: relative;
    z-index: 1;
    transition: none;
  }
  .it-list-wrapper .it-list a:hover .it-right-zone {
    border-bottom: 1px solid transparent;
  }
  .it-list-wrapper .it-list a:hover span.text {
    text-decoration: underline;
  }
}

.chip {
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  background: #fcfdff;
  border: 1px solid #dfe4f2;
  border-radius: 12px;
  height: 24px;
  min-width: 100px;
  padding: 0 16px 2px 8px;
  transition: all 0.05s;
  margin-right: 4px;
}
.chip .chip-label {
  font-size: 0.875rem;
  height: 16px;
  font-weight: 600;
  color: #5c6f82;
  margin-bottom: 0;
  transform: translateY(-2px);
  transition: color 0.05s;
  text-decoration: none;
}
.chip .chip-label:hover {
  text-decoration: none;
}
.chip button {
  padding: 0;
  background: transparent;
  border: none;
  margin-left: auto;
  width: 8px;
  height: 8px;
  position: relative;
}
.chip button:hover:not([disabled]) {
  cursor: pointer;
}
.chip button:hover[disabled] {
  cursor: not-allowed;
}
.chip button .icon {
  width: 22px;
  height: 22px;
  fill: #5c6f82;
  position: absolute;
  top: -6px;
  left: 0;
  transition: fill 0.05s;
}
.chip > .icon {
  fill: #0048ad;
  transform: translateX(-5px) translateY(1px);
  transition: fill 0.05s;
}
.chip .avatar {
  transform: translateX(-5px) translateY(1px);
}
.chip.chip-lg {
  height: 32px;
  min-width: 120px;
  border-radius: 16px;
  padding: 2px 24px 0 16px;
  margin: 4px 8px 4px auto;
}
.chip.chip-lg .chip-label {
  font-size: 1rem;
  height: 12px;
  transform: translateY(-8px);
}
.chip.chip-lg button .icon {
  width: 28px;
  height: 28px;
  top: -12px;
  left: 0;
}
.chip.chip-lg > .icon {
  height: 24px;
  width: 24px;
  margin-right: -8px;
  fill: #0048ad;
  transform: translateX(-13px) translateY(-1px);
}
.chip.chip-lg .avatar {
  width: 24px;
  height: 24px;
  margin-right: -4px;
  transform: translateX(-12px) translateY(-1px);
}
.chip.chip-lg.chip-simple {
  padding-right: 16px;
}
.chip.chip-simple {
  padding-right: 8px;
  margin: 4px auto;
}
.chip:hover:not(.chip-disabled) {
  background: #8b98a6;
  border-color: #8b98a6;
  transition: background-color 0.1s;
}
.chip:hover:not(.chip-disabled) .chip-label {
  color: white;
  transition: color 0.1s;
}
.chip:hover:not(.chip-disabled) button .icon {
  fill: white;
  transition: fill 0.1s;
}
.chip:hover:not(.chip-disabled) > .icon {
  fill: white;
  transition: fill 0.1s;
}
.chip.chip-disabled {
  background: white;
}
.chip.chip-disabled:hover {
  cursor: not-allowed;
}
.chip.chip-disabled .chip-label {
  color: #5b6f82;
}
.chip.chip-disabled button .icon {
  fill: #ebeced;
}
.chip.chip-disabled > .icon {
  fill: #ebeced;
}
.chip.chip-disabled .avatar img {
  filter: grayscale(100%);
}
.chip.chip-primary {
  background-color: transparent;
  border-color: #0048ad;
  color: #0048ad;
}
.chip.chip-primary > .chip-label {
  color: #0048ad;
}
.chip.chip-primary:hover {
  background-color: #0048ad;
  border-color: #0048ad;
}
.chip.chip-primary:hover > .chip-label {
  color: white;
}
.chip.chip-secondary {
  background-color: transparent;
  border-color: #5c6f82;
  color: #0048ad;
}
.chip.chip-secondary > .chip-label {
  color: #5c6f82;
}
.chip.chip-secondary:hover {
  background-color: #5c6f82;
  border-color: #5c6f82;
}
.chip.chip-secondary:hover > .chip-label {
  color: white;
}
.chip.chip-success {
  background-color: transparent;
  border-color: #008758;
  color: #008758;
}
.chip.chip-success > .chip-label {
  color: #008758;
}
.chip.chip-success:hover {
  background-color: #008758;
  border-color: #008758;
}
.chip.chip-success:hover > .chip-label {
  color: white;
}
.chip.chip-danger {
  background-color: transparent;
  border-color: #d9364f;
  color: #d9364f;
}
.chip.chip-danger > .chip-label {
  color: #d9364f;
}
.chip.chip-danger:hover {
  background-color: #d9364f;
  border-color: #d9364f;
}
.chip.chip-danger:hover > .chip-label {
  color: white;
}
.chip.chip-info {
  background-color: transparent;
  border-color: #979899;
  color: #979899;
}
.chip.chip-info > .chip-label {
  color: #979899;
}
.chip.chip-info:hover {
  background-color: #979899;
  border-color: #979899;
}
.chip.chip-info:hover > .chip-label {
  color: white;
}
.chip.chip-warning {
  background-color: transparent;
  border-color: #a66300;
  color: #a66300;
}
.chip.chip-warning > .chip-label {
  color: #a66300;
}
.chip.chip-warning:hover {
  background-color: #a66300;
  border-color: #a66300;
}
.chip.chip-warning:hover > .chip-label {
  color: white;
}

@media (min-width: 576px) {
  .chip .chip-label {
    font-size: 0.778rem;
  }
  .chip.chip-lg .chip-label {
    font-size: 0.889rem;
  }
}

.rating {
  border: none;
  float: left;
}
.rating > input {
  position: absolute;
  left: -9999px;
}
.rating > label {
  float: right;
  margin: 0;
  padding-right: 2px;
}
.rating > label:first-of-type {
  margin-right: 0;
}
.rating > label svg {
  fill: #c3cfdb;
  position: relative;
  transform-origin: center center;
  transition: transform 0.1s;
}
.rating > label:hover {
  cursor: pointer;
}
.rating > label:hover svg {
  transform: scale(1.1);
}
.rating > legend {
  display: inline-block;
  color: #17324d;
  font-size: 0.875rem;
  float: right;
  width: auto;
  margin: 4px 0 0 16px;
  font-weight: 500;
}
.rating.rating-read-only > input,
.rating.rating-read-only > label {
  pointer-events: none;
}

.rating > input:checked ~ label svg,
.rating:not(:checked) > label:hover svg,
.rating:not(:checked) > label:hover ~ label svg {
  fill: #0048ad;
}

.rating > input:checked + label:hover + svg,
.rating > input:checked ~ label:hover + svg,
.rating > label:hover ~ input:checked ~ label + svg,
.rating > input:checked ~ label:hover ~ label + svg {
  fill: #0048ad;
}

@media (min-width: 576px) {
  .rating > legend {
    font-size: 0.778rem;
  }
}

.dimmable {
  position: relative;
}

.dimmer {
  display: none;
  background-color: #1f2933;
  opacity: 0.92;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 32px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
}
.dimmer.dimmer-primary {
  background-color: #0048ad;
  opacity: 0.95;
}
.dimmer h1,
.dimmer h2,
.dimmer h3,
.dimmer h4,
.dimmer h5,
.dimmer h6,
.dimmer p {
  color: white;
  margin-bottom: 0;
}
.dimmer h4 {
  text-align: center;
  font-size: 2.222rem;
}
.dimmer p {
  text-align: justify;
  font-family: Lora, Georgia, serif;
  font-size: 1rem;
}
.dimmer .dimmer-inner {
  width: 100%;
  max-width: 480px;
}
.dimmer .dimmer-icon {
  text-align: center;
  margin-bottom: 24px;
}
.dimmer .dimmer-icon .icon {
  fill: white;
}
.dimmer .dimmer-buttons {
  background-color: transparent !important;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.dimmer .dimmer-buttons button {
  width: 100%;
}
.dimmer .dimmer-buttons button:last-child {
  margin-top: 16px;
}
.dimmer .dimmer-buttons.single-button button {
  margin-top: 0;
}

@media (min-width: 768px) {
  .dimmer {
    padding: 24px;
  }
  .dimmer .dimmer-icon {
    margin-bottom: 48px;
  }
  .dimmer .dimmer-buttons {
    flex-wrap: nowrap;
  }
  .dimmer .dimmer-buttons button {
    width: 50%;
    margin-top: 0 !important;
  }
  .dimmer .dimmer-buttons button:last-child {
    margin-left: 24px;
  }
  .dimmer .dimmer-buttons.single-button button {
    width: auto;
    min-width: 50%;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .dimmer {
    align-items: center;
  }
}

.it-timeline-wrapper .it-now-label {
  position: absolute;
  top: 50%;
  z-index: 10;
  font-size: 1rem;
  font-family: "Roboto Mono", monospace;
  color: #0055cc;
  font-weight: 600;
}

.it-timeline-wrapper .row {
  position: relative;
  padding-top: 48px;
}
.it-timeline-wrapper .row:after {
  content: "";
  width: 4px;
  background: linear-gradient(0deg, #0048ad 0%, #004080 100%);
  position: absolute;
  left: 40px;
  top: -16px;
  bottom: -16px;
}

.it-timeline-wrapper .timeline-element {
  padding: 18px 0 18px 40px;
}

.it-timeline-wrapper .it-pin-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: -24px;
  z-index: 5;
  left: 12px;
}
.it-timeline-wrapper .it-pin-wrapper .pin-icon {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border: 8px solid white;
  margin-right: 16px;
  background: #fff;
}
.it-timeline-wrapper .it-pin-wrapper svg {
  width: 24px;
  height: 24px;
  fill: #0055cc;
}
.it-timeline-wrapper .it-pin-wrapper .pin-text {
  color: #435a70;
  font-weight: 600;
  text-transform: uppercase;
  background: #bbc4d0;
  border-radius: 4px;
  font-family: "Roboto Mono", monospace;
}
.it-timeline-wrapper .it-pin-wrapper .pin-text span {
  padding: 4px 16px;
  display: block;
  position: relative;
}
.it-timeline-wrapper .it-pin-wrapper .pin-text span:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #bbc4d0;
  border-radius: 3px;
  position: absolute;
  left: -4px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.it-timeline-wrapper .it-pin-wrapper.it-now .pin-icon {
  background: #0055cc;
}
.it-timeline-wrapper .it-pin-wrapper.it-now svg {
  fill: #fff;
}
.it-timeline-wrapper .it-pin-wrapper.it-now .pin-text {
  color: #fff;
  background: #0055cc;
}
.it-timeline-wrapper .it-pin-wrapper.it-now .pin-text span:after {
  background: #0055cc;
}
.it-timeline-wrapper .it-pin-wrapper.it-evidence .pin-icon {
  background: #002b66;
}
.it-timeline-wrapper .it-pin-wrapper.it-evidence svg {
  fill: #fff;
}
.it-timeline-wrapper .it-pin-wrapper.it-evidence .pin-text {
  color: #fff;
  background: #002b66;
}
.it-timeline-wrapper .it-pin-wrapper.it-evidence .pin-text span:after {
  background: #002b66;
}

@media (min-width: 992px) {
  .it-timeline-wrapper .row:after {
    content: "";
    left: calc(50% - 4px);
  }
  .it-timeline-wrapper .timeline-element {
    padding: 18px 0 18px 40px;
    width: 50%;
  }
  .it-timeline-wrapper .col-12 .card-wrapper .card {
    border: none;
  }
  .it-timeline-wrapper .col-12:nth-child(odd) .it-now-label {
    right: 50%;
    transform: translateX(100%);
    display: flex;
    align-items: center;
  }
  .it-timeline-wrapper .col-12:nth-child(odd) .it-now-label:before {
    content: "";
    display: inline-block;
    height: 1px;
    width: 16px;
    background: #0055cc;
    margin-right: 16px;
  }
  .it-timeline-wrapper .col-12:nth-child(odd) .timeline-element {
    padding: 18px 40px 18px 18px;
  }
  .it-timeline-wrapper .col-12:nth-child(odd) .card-wrapper .card {
    text-align: right;
  }
  .it-timeline-wrapper .col-12:nth-child(odd) .card-wrapper .card:after {
    margin-left: auto;
    display: inline-block;
    margin-right: 24px;
  }
  .it-timeline-wrapper .col-12:nth-child(odd) .card-wrapper .card a.read-more {
    right: 24px;
  }
  .it-timeline-wrapper .col-12:nth-child(odd) .it-pin-wrapper {
    flex-direction: row-reverse;
    left: auto;
    right: -62px;
  }
  .it-timeline-wrapper .col-12:nth-child(odd) .it-pin-wrapper .pin-icon {
    margin-right: 0;
    margin-left: 16px;
  }
  .it-timeline-wrapper
    .col-12:nth-child(odd)
    .it-pin-wrapper
    .pin-text
    span:after {
    left: auto;
    right: -4px;
  }
  .it-timeline-wrapper
    .col-12:nth-child(odd)
    .it-pin-wrapper.it-evidence
    .pin-text
    span:after {
    border-color: transparent transparent transparent #0055cc;
  }
  .it-timeline-wrapper .col-12:nth-child(even) .it-now-label {
    left: 0;
    transform: translateX(-100%);
    display: flex;
    align-items: center;
  }
  .it-timeline-wrapper .col-12:nth-child(even) .it-now-label:after {
    content: "";
    display: inline-block;
    height: 1px;
    width: 16px;
    background: #0055cc;
    margin-left: 16px;
  }
  .it-timeline-wrapper .col-12:nth-child(even) .timeline-element {
    position: relative;
    left: 50%;
  }
  .it-timeline-wrapper
    .col-12:nth-child(even)
    .timeline-element
    .it-pin-wrapper {
    left: -66px;
  }
  .it-timeline-wrapper .it-pin-wrapper {
    position: relative;
  }
  .it-timeline-wrapper .it-pin-wrapper .pin-text {
    font-size: 16px;
  }
}

.anchor-offset:before {
  content: "";
  display: block;
  height: 96px;
  margin-top: -96px;
}
@media (min-width: 992px) {
  .anchor-offset:before {
    height: 72px;
    margin-top: -72px;
  }
}

.map-wrapper {
  min-height: 280px;
}
.map-wrapper.map-column {
  margin: 0 -24px;
}
@media (min-width: 992px) {
  .map-wrapper.map-column {
    margin-left: -3rem;
    margin-right: 0;
  }
}

.point-list-wrapper .point-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.point-list-wrapper .point-list .point-list-aside {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #0048ad;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #0048ad;
}
.point-list-wrapper .point-list .point-list-aside:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  top: 50%;
  right: -5px;
  transform: translateY(-5px);
  border-radius: 50%;
  background-color: #0048ad;
}
.point-list-wrapper .point-list .point-list-aside .point-date {
  flex: 1;
  font-size: 2.75rem;
  line-height: 1.1;
}
.point-list-wrapper .point-list .point-list-aside .point-month {
  flex: 1;
  font-size: 1rem;
  text-transform: uppercase;
}
.point-list-wrapper .point-list .point-list-aside.point-list-primary {
  color: #0048ad;
  border-color: #0048ad;
}
.point-list-wrapper .point-list .point-list-aside.point-list-primary:after {
  background-color: #0048ad;
}
.point-list-wrapper .point-list .point-list-aside.point-list-secondary {
  color: #5c6f82;
  border-color: #5c6f82;
}
.point-list-wrapper .point-list .point-list-aside.point-list-secondary:after {
  background-color: #5c6f82;
}
.point-list-wrapper .point-list .point-list-aside.point-list-success {
  color: #008758;
  border-color: #008758;
}
.point-list-wrapper .point-list .point-list-aside.point-list-success:after {
  background-color: #008758;
}
.point-list-wrapper .point-list .point-list-aside.point-list-info {
  color: #979899;
  border-color: #979899;
}
.point-list-wrapper .point-list .point-list-aside.point-list-info:after {
  background-color: #979899;
}
.point-list-wrapper .point-list .point-list-aside.point-list-warning {
  color: #a66300;
  border-color: #a66300;
}
.point-list-wrapper .point-list .point-list-aside.point-list-warning:after {
  background-color: #a66300;
}
.point-list-wrapper .point-list .point-list-aside.point-list-danger {
  color: #d9364f;
  border-color: #d9364f;
}
.point-list-wrapper .point-list .point-list-aside.point-list-danger:after {
  background-color: #d9364f;
}
.point-list-wrapper .point-list .point-list-aside.point-list-light {
  color: #e9e6f2;
  border-color: #e9e6f2;
}
.point-list-wrapper .point-list .point-list-aside.point-list-light:after {
  background-color: #e9e6f2;
}
.point-list-wrapper .point-list .point-list-aside.point-list-dark {
  color: #17324d;
  border-color: #17324d;
}
.point-list-wrapper .point-list .point-list-aside.point-list-dark:after {
  background-color: #17324d;
}
.point-list-wrapper .point-list .point-list-aside.point-list-black {
  color: black;
  border-color: black;
}
.point-list-wrapper .point-list .point-list-aside.point-list-black:after {
  background-color: black;
}
.point-list-wrapper .point-list .point-list-aside.point-list-white {
  color: white;
  border-color: white;
}
.point-list-wrapper .point-list .point-list-aside.point-list-white:after {
  background-color: white;
}
.point-list-wrapper .point-list .point-list-aside.point-list-100 {
  color: #e3e4e6;
  border-color: #e3e4e6;
}
.point-list-wrapper .point-list .point-list-aside.point-list-100:after {
  background-color: #e3e4e6;
}
.point-list-wrapper .point-list .point-list-aside.point-list-200 {
  color: #cacacc;
  border-color: #cacacc;
}
.point-list-wrapper .point-list .point-list-aside.point-list-200:after {
  background-color: #cacacc;
}
.point-list-wrapper .point-list .point-list-aside.point-list-300 {
  color: #b1b1b3;
  border-color: #b1b1b3;
}
.point-list-wrapper .point-list .point-list-aside.point-list-300:after {
  background-color: #b1b1b3;
}
.point-list-wrapper .point-list .point-list-aside.point-list-400 {
  color: #979899;
  border-color: #979899;
}
.point-list-wrapper .point-list .point-list-aside.point-list-400:after {
  background-color: #979899;
}
.point-list-wrapper .point-list .point-list-aside.point-list-500 {
  color: #7e7f80;
  border-color: #7e7f80;
}
.point-list-wrapper .point-list .point-list-aside.point-list-500:after {
  background-color: #7e7f80;
}
.point-list-wrapper .point-list .point-list-aside.point-list-600 {
  color: #656566;
  border-color: #656566;
}
.point-list-wrapper .point-list .point-list-aside.point-list-600:after {
  background-color: #656566;
}
.point-list-wrapper .point-list .point-list-aside.point-list-700 {
  color: #4c4c4d;
  border-color: #4c4c4d;
}
.point-list-wrapper .point-list .point-list-aside.point-list-700:after {
  background-color: #4c4c4d;
}
.point-list-wrapper .point-list .point-list-aside.point-list-800 {
  color: #323333;
  border-color: #323333;
}
.point-list-wrapper .point-list .point-list-aside.point-list-800:after {
  background-color: #323333;
}
.point-list-wrapper .point-list .point-list-aside.point-list-900 {
  color: #19191a;
  border-color: #19191a;
}
.point-list-wrapper .point-list .point-list-aside.point-list-900:after {
  background-color: #19191a;
}
.point-list-wrapper .point-list .point-list-content {
  flex: 1;
  margin-left: 2em;
  align-self: center;
}
.point-list-wrapper .point-list .point-list-content > * {
  width: 100%;
}
.point-list-wrapper .point-list.point-list-step .point-list-aside {
  min-width: 54px;
  padding: 0.3rem 1rem 0.3rem 0;
}
.point-list-wrapper .point-list.point-list-step .point-list-aside .point-date {
  flex: 0;
  font-size: 1.6rem;
  line-height: 1;
  min-width: 32px;
}
.point-list-wrapper .point-list.point-list-step .point-list-aside .point-month {
  flex: 0;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.point-list-wrapper .point-list.point-list-step .point-list-content {
  margin-left: 1em;
}

@media (min-width: 992px) {
  .point-list-wrapper.point-list-step-wrapper {
    display: flex;
    flex-direction: row;
  }
  .point-list-wrapper.point-list-step-wrapper .point-list-step {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .point-list-wrapper.point-list-step-wrapper
    .point-list-step
    .point-list-aside {
    padding: 16px 0;
    min-height: 65px;
    width: 100%;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
  .point-list-wrapper.point-list-step-wrapper
    .point-list-step
    .point-list-aside
    .point-month {
    margin-left: 8px;
    margin-bottom: -2px;
  }
  .point-list-wrapper.point-list-step-wrapper
    .point-list-step
    .point-list-aside:after {
    top: 100%;
    right: 50%;
    transform: translate(2px, -4px);
  }
  .point-list-wrapper.point-list-step-wrapper
    .point-list-step
    .point-list-content {
    margin: 0;
    text-align: center;
  }
}

.rating-list-wrapper .rating-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.rating-list-wrapper .rating-list .rating-list-aside {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #0048ad;
  border-right: 1px solid #0048ad;
}
.rating-list-wrapper .rating-list .rating-list-aside .rating-value {
  display: flex;
  flex: 1.2;
  font-size: 2.75rem;
  line-height: 1;
  align-items: flex-end;
}
.rating-list-wrapper .rating-list .rating-list-aside .rating-total {
  display: flex;
  flex: 0.8;
  font-size: 1rem;
  line-height: 1;
  text-transform: lowercase;
  align-items: flex-start;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-primary {
  color: #0048ad;
  border-right: 1px solid #0048ad;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-secondary {
  color: #5c6f82;
  border-right: 1px solid #5c6f82;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-success {
  color: #008758;
  border-right: 1px solid #008758;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-info {
  color: #979899;
  border-right: 1px solid #979899;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-warning {
  color: #a66300;
  border-right: 1px solid #a66300;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-danger {
  color: #d9364f;
  border-right: 1px solid #d9364f;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-light {
  color: #e9e6f2;
  border-right: 1px solid #e9e6f2;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-dark {
  color: #17324d;
  border-right: 1px solid #17324d;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-black {
  color: black;
  border-right: 1px solid black;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-white {
  color: white;
  border-right: 1px solid white;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-100 {
  color: #e3e4e6;
  border-right: 1px solid #e3e4e6;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-200 {
  color: #cacacc;
  border-right: 1px solid #cacacc;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-300 {
  color: #b1b1b3;
  border-right: 1px solid #b1b1b3;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-400 {
  color: #979899;
  border-right: 1px solid #979899;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-500 {
  color: #7e7f80;
  border-right: 1px solid #7e7f80;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-600 {
  color: #656566;
  border-right: 1px solid #656566;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-700 {
  color: #4c4c4d;
  border-right: 1px solid #4c4c4d;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-800 {
  color: #323333;
  border-right: 1px solid #323333;
}
.rating-list-wrapper .rating-list .rating-list-aside.rating-list-900 {
  color: #19191a;
  border-right: 1px solid #19191a;
}
.rating-list-wrapper .rating-list .rating-list-content {
  flex: 1;
  align-self: center;
}
.rating-list-wrapper .rating-list .rating-list-content .rating-list-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.rating-list-wrapper
  .rating-list
  .rating-list-content
  .rating-list-row
  .rating-list-stars {
  flex: 0.3;
}
.rating-list-wrapper
  .rating-list
  .rating-list-content
  .rating-list-row
  .rating-list-stars
  > .rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  float: none !important;
  height: 0.7em;
}
.rating-list-wrapper
  .rating-list
  .rating-list-content
  .rating-list-row
  .rating-list-stars
  > .rating
  .icon {
  width: 0.7em;
  height: 0.7em;
}
.rating-list-wrapper
  .rating-list
  .rating-list-content
  .rating-list-row
  .rating-list-progress {
  flex: 0.65;
  display: flex;
  height: 3.5em;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.rating-list-wrapper
  .rating-list
  .rating-list-content
  .rating-list-row
  .rating-list-progress
  > .progress {
  width: 100%;
}
@media (min-width: 576px) {
  .rating-list-wrapper
    .rating-list
    .rating-list-content
    .rating-list-row
    .rating-list-stars {
    flex: 0.2;
  }
  .rating-list-wrapper
    .rating-list
    .rating-list-content
    .rating-list-row
    .rating-list-stars
    > .rating {
    height: 1em;
  }
  .rating-list-wrapper
    .rating-list
    .rating-list-content
    .rating-list-row
    .rating-list-stars
    > .rating
    .icon {
    width: 1em;
    height: 1em;
  }
  .rating-list-wrapper
    .rating-list
    .rating-list-content
    .rating-list-row
    .rating-list-progress {
    flex: 0.75;
    height: 5em;
  }
}
@media (min-width: 992px) {
  .rating-list-wrapper
    .rating-list
    .rating-list-content
    .rating-list-row
    .rating-list-stars {
    flex: 0.25;
  }
  .rating-list-wrapper
    .rating-list
    .rating-list-content
    .rating-list-row
    .rating-list-progress {
    flex: 0.7;
  }
}

.badge.badge-outline-primary {
  color: #0048ad;
  border: 1px solid #0048ad;
  background-color: transparent !important;
}

.badge.badge-outline-secondary {
  color: #5c6f82;
  border: 1px solid #5c6f82;
  background-color: transparent !important;
}

.badge.badge-outline-success {
  color: #008758;
  border: 1px solid #008758;
  background-color: transparent !important;
}

.badge.badge-outline-info {
  color: #979899;
  border: 1px solid #979899;
  background-color: transparent !important;
}

.badge.badge-outline-warning {
  color: #a66300;
  border: 1px solid #a66300;
  background-color: transparent !important;
}

.badge.badge-outline-danger {
  color: #d9364f;
  border: 1px solid #d9364f;
  background-color: transparent !important;
}

.badge.badge-outline-light {
  color: #e9e6f2;
  border: 1px solid #e9e6f2;
  background-color: transparent !important;
}

.badge.badge-outline-dark {
  color: #17324d;
  border: 1px solid #17324d;
  background-color: transparent !important;
}

.badge.badge-outline-black {
  color: black;
  border: 1px solid black;
  background-color: transparent !important;
}

.badge.badge-outline-white {
  color: white;
  border: 1px solid white;
  background-color: transparent !important;
}

.badge.badge-outline-100 {
  color: #e3e4e6;
  border: 1px solid #e3e4e6;
  background-color: transparent !important;
}

.badge.badge-outline-200 {
  color: #cacacc;
  border: 1px solid #cacacc;
  background-color: transparent !important;
}

.badge.badge-outline-300 {
  color: #b1b1b3;
  border: 1px solid #b1b1b3;
  background-color: transparent !important;
}

.badge.badge-outline-400 {
  color: #979899;
  border: 1px solid #979899;
  background-color: transparent !important;
}

.badge.badge-outline-500 {
  color: #7e7f80;
  border: 1px solid #7e7f80;
  background-color: transparent !important;
}

.badge.badge-outline-600 {
  color: #656566;
  border: 1px solid #656566;
  background-color: transparent !important;
}

.badge.badge-outline-700 {
  color: #4c4c4d;
  border: 1px solid #4c4c4d;
  background-color: transparent !important;
}

.badge.badge-outline-800 {
  color: #323333;
  border: 1px solid #323333;
  background-color: transparent !important;
}

.badge.badge-outline-900 {
  color: #19191a;
  border: 1px solid #19191a;
  background-color: transparent !important;
}

a.datepicker-button {
  cursor: pointer;
}
a.datepicker-button.default:hover,
a.datepicker-button.default:focus,
.toggles
  label
  input[type="checkbox"]:focus
  + a.datepicker-button.default.lever {
  background-color: #ddd;
}

.datepicker-calendar {
  margin: 0 10px 10px 0;
  font-size: 0.9em;
  padding: 2px;
  position: absolute;
  width: 261px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 50;
}
.datepicker-calendar .datepicker-month-wrap {
  margin: 0;
  padding-top: 1px;
  text-align: center;
  height: 30px;
}
.datepicker-calendar .datepicker-month-fast-prev.disabled,
.datepicker-calendar .datepicker-month-fast-next.disabled,
.datepicker-calendar .datepicker-month-prev.disabled,
.datepicker-calendar .datepicker-month-next.disabled {
  border: none;
  cursor: default;
  color: #999;
}
.datepicker-calendar .datepicker-month-fast-prev,
.datepicker-calendar .datepicker-month-fast-next,
.datepicker-calendar .datepicker-month-prev,
.datepicker-calendar .datepicker-month-next {
  cursor: pointer;
  margin: 3px;
  width: 24px;
  height: 24px;
  padding-top: 3px;
}
.datepicker-calendar .datepicker-month-fast-prev {
  padding-right: 2px;
}
.datepicker-calendar .datepicker-month-fast-next {
  padding-left: 2px;
}
.datepicker-calendar .datepicker-month-fast-prev.enabled:hover,
.datepicker-calendar .datepicker-month-fast-prev.enabled:focus,
.datepicker-calendar
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month-fast-prev.enabled,
.form-check
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month-fast-prev.enabled,
.datepicker-calendar
  .form-check
  [type="radio"]:focus
  + label.datepicker-month-fast-prev.enabled,
.form-check
  .datepicker-calendar
  [type="radio"]:focus
  + label.datepicker-month-fast-prev.enabled,
.datepicker-calendar
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month-fast-prev.enabled.lever,
.toggles
  label
  .datepicker-calendar
  input[type="checkbox"]:focus
  + .datepicker-month-fast-prev.enabled.lever,
.datepicker-calendar
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month-fast-prev.enabled,
.block-facet--checkbox
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month-fast-prev.enabled,
.datepicker-calendar .datepicker-month-fast-next.enabled:hover,
.datepicker-calendar .datepicker-month-fast-next.enabled:focus,
.datepicker-calendar
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month-fast-next.enabled,
.form-check
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month-fast-next.enabled,
.datepicker-calendar
  .form-check
  [type="radio"]:focus
  + label.datepicker-month-fast-next.enabled,
.form-check
  .datepicker-calendar
  [type="radio"]:focus
  + label.datepicker-month-fast-next.enabled,
.datepicker-calendar
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month-fast-next.enabled.lever,
.toggles
  label
  .datepicker-calendar
  input[type="checkbox"]:focus
  + .datepicker-month-fast-next.enabled.lever,
.datepicker-calendar
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month-fast-next.enabled,
.block-facet--checkbox
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month-fast-next.enabled,
.datepicker-calendar .datepicker-month-prev.enabled:hover,
.datepicker-calendar .datepicker-month-prev.enabled:focus,
.datepicker-calendar
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month-prev.enabled,
.form-check
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month-prev.enabled,
.datepicker-calendar
  .form-check
  [type="radio"]:focus
  + label.datepicker-month-prev.enabled,
.form-check
  .datepicker-calendar
  [type="radio"]:focus
  + label.datepicker-month-prev.enabled,
.datepicker-calendar
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month-prev.enabled.lever,
.toggles
  label
  .datepicker-calendar
  input[type="checkbox"]:focus
  + .datepicker-month-prev.enabled.lever,
.datepicker-calendar
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month-prev.enabled,
.block-facet--checkbox
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month-prev.enabled,
.datepicker-calendar .datepicker-month-next.enabled:hover,
.datepicker-calendar .datepicker-month-next.enabled:focus,
.datepicker-calendar
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month-next.enabled,
.form-check
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month-next.enabled,
.datepicker-calendar
  .form-check
  [type="radio"]:focus
  + label.datepicker-month-next.enabled,
.form-check
  .datepicker-calendar
  [type="radio"]:focus
  + label.datepicker-month-next.enabled,
.datepicker-calendar
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month-next.enabled.lever,
.toggles
  label
  .datepicker-calendar
  input[type="checkbox"]:focus
  + .datepicker-month-next.enabled.lever,
.datepicker-calendar
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month-next.enabled,
.block-facet--checkbox
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month-next.enabled {
  margin-top: 2px;
  border: 1px solid #800;
  border-radius: 4px;
}
.datepicker-calendar .datepicker-month {
  margin: 3px 56px 2px 56px;
  height: 24px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
  padding-top: 1px;
}
.datepicker-calendar .datepicker-month:after {
  font-family: "Glyphicons Halflings";
  font-size: 0.6em;
  display: inline-block;
  content: "\e252";
}
.datepicker-calendar .datepicker-month:hover,
.datepicker-calendar .datepicker-month:focus,
.datepicker-calendar
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month,
.form-check
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month,
.datepicker-calendar .form-check [type="radio"]:focus + label.datepicker-month,
.form-check .datepicker-calendar [type="radio"]:focus + label.datepicker-month,
.datepicker-calendar
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month.lever,
.toggles
  label
  .datepicker-calendar
  input[type="checkbox"]:focus
  + .datepicker-month.lever,
.datepicker-calendar
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month,
.block-facet--checkbox
  .datepicker-calendar
  [type="checkbox"]:focus
  + label.datepicker-month {
  padding-top: 0;
  border: 1px solid #800;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.datepicker-calendar table.datepicker-grid {
  width: 100%;
  font-size: 1.2em;
  text-align: center;
}
.datepicker-calendar table.datepicker-grid.rtl {
  direction: rtl;
}
.datepicker-calendar table.datepicker-grid:focus,
.datepicker-calendar
  .toggles
  label
  input[type="checkbox"]:focus
  + table.datepicker-grid.lever,
.toggles
  label
  .datepicker-calendar
  input[type="checkbox"]:focus
  + table.datepicker-grid.lever {
  outline: none;
}
.datepicker-calendar table.datepicker-grid th,
.datepicker-calendar table.datepicker-grid td {
  text-align: center;
  padding: 0;
}
.datepicker-calendar table.datepicker-grid th {
  height: 30px;
}
.datepicker-calendar table.datepicker-grid th abbr {
  border: none;
}
.datepicker-calendar table.datepicker-grid td {
  border: 1px solid #999;
  cursor: pointer;
}
.datepicker-calendar table.datepicker-grid td.unselectable {
  cursor: default;
}
.datepicker-calendar table.datepicker-grid td.day {
  height: 30px;
}
.datepicker-calendar table.datepicker-grid td.month {
  height: 60px;
}
.datepicker-calendar table.datepicker-grid td.year {
  height: 45px;
}
.datepicker-calendar table.datepicker-grid td.curDay,
.datepicker-calendar table.datepicker-grid td.curMonth,
.datepicker-calendar table.datepicker-grid td.curYear {
  border: 1px solid #999;
}
.datepicker-calendar table.datepicker-grid td.empty {
  border: 1px solid #999;
  cursor: default;
}
.datepicker-calendar .offscreen {
  position: absolute;
  left: -200em;
  top: -100em;
}
.datepicker-calendar button.datepicker-close {
  height: 30px;
  width: 100%;
  font-weight: bold;
  margin-top: 1px;
  border: 1px solid #eee;
  border-radius: 2px;
}
.datepicker-calendar.default {
  background-color: #fff;
}
.datepicker-calendar.default button.datepicker-close:focus,
.datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + button.datepicker-close.lever,
.toggles
  label
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + button.datepicker-close.lever,
.datepicker-calendar.default button.datepicker-close:hover,
.datepicker-calendar.default .datepicker-month-fast-next.enabled:focus,
.datepicker-calendar.default
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month-fast-next.enabled,
.form-check
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month-fast-next.enabled,
.datepicker-calendar.default
  .form-check
  [type="radio"]:focus
  + label.datepicker-month-fast-next.enabled,
.form-check
  .datepicker-calendar.default
  [type="radio"]:focus
  + label.datepicker-month-fast-next.enabled,
.datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month-fast-next.enabled.lever,
.toggles
  label
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + .datepicker-month-fast-next.enabled.lever,
.datepicker-calendar.default
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month-fast-next.enabled,
.block-facet--checkbox
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month-fast-next.enabled,
.datepicker-calendar.default .datepicker-month-fast-next.enabled:hover,
.datepicker-calendar.default .datepicker-month-fast-prev.enabled:focus,
.datepicker-calendar.default
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month-fast-prev.enabled,
.form-check
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month-fast-prev.enabled,
.datepicker-calendar.default
  .form-check
  [type="radio"]:focus
  + label.datepicker-month-fast-prev.enabled,
.form-check
  .datepicker-calendar.default
  [type="radio"]:focus
  + label.datepicker-month-fast-prev.enabled,
.datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month-fast-prev.enabled.lever,
.toggles
  label
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + .datepicker-month-fast-prev.enabled.lever,
.datepicker-calendar.default
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month-fast-prev.enabled,
.block-facet--checkbox
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month-fast-prev.enabled,
.datepicker-calendar.default .datepicker-month-fast-prev.enabled:hover,
.datepicker-calendar.default .datepicker-month-next.enabled:focus,
.datepicker-calendar.default
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month-next.enabled,
.form-check
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month-next.enabled,
.datepicker-calendar.default
  .form-check
  [type="radio"]:focus
  + label.datepicker-month-next.enabled,
.form-check
  .datepicker-calendar.default
  [type="radio"]:focus
  + label.datepicker-month-next.enabled,
.datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month-next.enabled.lever,
.toggles
  label
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + .datepicker-month-next.enabled.lever,
.datepicker-calendar.default
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month-next.enabled,
.block-facet--checkbox
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month-next.enabled,
.datepicker-calendar.default .datepicker-month-next.enabled:hover,
.datepicker-calendar.default .datepicker-month-prev.enabled:focus,
.datepicker-calendar.default
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month-prev.enabled,
.form-check
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month-prev.enabled,
.datepicker-calendar.default
  .form-check
  [type="radio"]:focus
  + label.datepicker-month-prev.enabled,
.form-check
  .datepicker-calendar.default
  [type="radio"]:focus
  + label.datepicker-month-prev.enabled,
.datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month-prev.enabled.lever,
.toggles
  label
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + .datepicker-month-prev.enabled.lever,
.datepicker-calendar.default
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month-prev.enabled,
.block-facet--checkbox
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month-prev.enabled,
.datepicker-calendar.default .datepicker-month-prev.enabled:hover,
.datepicker-calendar.default .datepicker-month:focus,
.datepicker-calendar.default
  .form-check
  [type="checkbox"]:focus
  + label.datepicker-month,
.form-check
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month,
.datepicker-calendar.default
  .form-check
  [type="radio"]:focus
  + label.datepicker-month,
.form-check
  .datepicker-calendar.default
  [type="radio"]:focus
  + label.datepicker-month,
.datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + .datepicker-month.lever,
.toggles
  label
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + .datepicker-month.lever,
.datepicker-calendar.default
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label.datepicker-month,
.block-facet--checkbox
  .datepicker-calendar.default
  [type="checkbox"]:focus
  + label.datepicker-month,
.datepicker-calendar.default .datepicker-month:hover {
  background-color: #eee;
  border-color: #999;
  border: none;
  margin: 0;
  padding: 0;
}
.datepicker-calendar.default .datepicker-month-fast-next,
.datepicker-calendar.default .datepicker-month-fast-prev,
.datepicker-calendar.default .datepicker-month-next,
.datepicker-calendar.default .datepicker-month-prev {
  margin: 0;
  padding: 0;
}
.datepicker-calendar.default table.datepicker-grid {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.1px;
}
.datepicker-calendar.default table.datepicker-grid:focus,
.datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + table.datepicker-grid.lever,
.toggles
  label
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + table.datepicker-grid.lever {
  outline: none;
}
.datepicker-calendar.default table.datepicker-grid th {
  background-color: transparent;
  border: none;
}
.datepicker-calendar.default table.datepicker-grid td {
  border: none;
  color: #000;
  background: transparent;
}
.datepicker-calendar.default table.datepicker-grid td span {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50px;
  text-align: center;
  transition: all 0.3s;
}
.datepicker-calendar.default table.datepicker-grid td:hover {
  background: transparent;
}
.datepicker-calendar.default table.datepicker-grid td:hover span {
  background: #ebeced;
}
.datepicker-calendar.default table.datepicker-grid td.unselectable {
  color: #999;
}
.datepicker-calendar.default table.datepicker-grid td.curDay,
.datepicker-calendar.default table.datepicker-grid td.curMonth,
.datepicker-calendar.default table.datepicker-grid td.curYear {
  background: transparent;
}
.datepicker-calendar.default table.datepicker-grid td.curDay span,
.datepicker-calendar.default table.datepicker-grid td.curMonth span,
.datepicker-calendar.default table.datepicker-grid td.curYear span {
  background: #e6f0fa;
}
.datepicker-calendar.default table.datepicker-grid td.empty {
  border: none;
  color: #ccc;
}
.datepicker-calendar.default table.datepicker-grid td.empty:hover {
  background: transparent;
}
.datepicker-calendar.default table.datepicker-grid td.empty:hover span {
  background: #0055cc;
  color: #fff;
}
.datepicker-calendar.default table.datepicker-grid td.selectable:hover,
.datepicker-calendar.default table.datepicker-grid td.selectable.focus {
  background-color: transparent;
}
.datepicker-calendar.default table.datepicker-grid td.selectable.focus span {
  background: #0055cc;
  color: #fff;
}
.datepicker-calendar.default tr.datepicker-weekdays {
  border: none;
}
.datepicker-calendar.default button.datepicker-close {
  background-color: #ddd;
  border-color: #999;
  color: #000;
}

.datepicker-overlay {
  background: #777;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  height: 100%;
  min-height: 100%;
  z-index: 40;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: none;
  cursor: default;
}

.it-datepicker-wrapper {
  position: relative;
}
.it-datepicker-wrapper .form-group > label {
  background-color: rgba(255, 255, 255, 0);
  transform: translateY(-75%);
  font-size: 0.777rem;
}
.it-datepicker-wrapper input[type="time"] {
  color: #435a70;
}
.it-datepicker-wrapper label.pickerlabel {
  color: #5c6f82;
  font-weight: 600;
  line-height: 2.5rem;
  transition: 0.2s ease-out;
  top: 1rem;
  font-size: 0.777rem;
  cursor: text;
  display: block;
  width: 100%;
  padding: 0 0.5rem;
  z-index: 6;
}
.it-datepicker-wrapper .datepicker-calendar {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border: none;
  width: 100%;
  border-top: 2px solid #435a70;
  margin-top: -8px;
  color: #435a70;
  padding: 24px;
}
.it-datepicker-wrapper .datepicker-calendar[aria-hidden="true"] {
  display: inherit;
}
.it-datepicker-wrapper .datepicker-calendar .datepicker-month-wrap {
  padding: 0;
  color: #435a70;
  margin-bottom: 16px;
}
.it-datepicker-wrapper
  .datepicker-calendar
  .datepicker-month-wrap
  .datepicker-month {
  font-size: 0.83333rem;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}
.it-datepicker-wrapper
  .datepicker-calendar
  .datepicker-month-wrap
  .datepicker-month:after {
  display: none;
}
.it-datepicker-wrapper .datepicker-calendar .datepicker-month-wrap .icon-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 6px;
  border-color: transparent transparent transparent #000;
  display: inline-block;
  transform: translateY(-3px);
}
.it-datepicker-wrapper .datepicker-calendar .datepicker-month-wrap .icon-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 6px 3px 0;
  border-color: transparent #000 transparent transparent;
  display: inline-block;
  transform: translateY(-3px);
}
.it-datepicker-wrapper
  .datepicker-calendar
  .datepicker-month-wrap
  .datepicker-month-fast-next {
  float: right;
  display: inline-block;
  min-width: 32px;
}
.it-datepicker-wrapper
  .datepicker-calendar
  .datepicker-month-wrap
  .datepicker-month-fast-next
  svg:first-child {
  margin-right: -8px;
}
.it-datepicker-wrapper
  .datepicker-calendar
  .datepicker-month-wrap
  .datepicker-month-next {
  float: right;
}
.it-datepicker-wrapper
  .datepicker-calendar
  .datepicker-month-wrap
  .datepicker-month-fast-prev {
  float: left;
  display: inline-block;
  min-width: 32px;
}
.it-datepicker-wrapper
  .datepicker-calendar
  .datepicker-month-wrap
  .datepicker-month-fast-prev
  svg:last-child {
  margin-left: -8px;
}
.it-datepicker-wrapper
  .datepicker-calendar
  .datepicker-month-wrap
  .datepicker-month-prev {
  float: left;
}
.it-datepicker-wrapper .datepicker-calendar .datepicker-month {
  margin: 0;
  padding: 0;
}
.it-datepicker-wrapper .input-group {
  position: relative;
}
.it-datepicker-wrapper .input-group:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #435a70;
  transform-origin: bottom;
  transition: all 0.3s;
  left: 0;
}
.it-datepicker-wrapper .input-group .it-date-datepicker {
  border-bottom: none;
  background: transparent;
}
.it-datepicker-wrapper .input-group .it-date-datepicker::placeholder {
  color: #435a70;
}
.it-datepicker-wrapper .input-group .datepicker-button {
  padding: 0;
  all: unset;
  margin-right: 16px;
  position: relative;
  height: 40px;
  cursor: pointer;
}
.it-datepicker-wrapper .input-group .datepicker-button:hover {
  all: unset;
  margin-right: 16px;
  height: 40px;
}
.it-datepicker-wrapper .input-group .datepicker-button:hover .icon {
  fill: #0055cc;
}
.it-datepicker-wrapper .input-group .datepicker-button .icon {
  fill: #435a70;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  button.datepicker-close:focus,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + button.datepicker-close.lever,
.toggles
  label
  .it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + button.datepicker-close.lever,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  button.datepicker-close:hover,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month-fast-next.enabled:focus,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + div.datepicker-month-fast-next.enabled.lever,
.toggles
  label
  .it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + div.datepicker-month-fast-next.enabled.lever,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month-fast-next.enabled:hover,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month-fast-prev.enabled:focus,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + div.datepicker-month-fast-prev.enabled.lever,
.toggles
  label
  .it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + div.datepicker-month-fast-prev.enabled.lever,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month-fast-prev.enabled:hover,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month-next.enabled:focus,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + div.datepicker-month-next.enabled.lever,
.toggles
  label
  .it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + div.datepicker-month-next.enabled.lever,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month-next.enabled:hover,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month-prev.enabled:focus,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + div.datepicker-month-prev.enabled.lever,
.toggles
  label
  .it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + div.datepicker-month-prev.enabled.lever,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month-prev.enabled:hover,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month:focus,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  .toggles
  label
  input[type="checkbox"]:focus
  + div.datepicker-month.lever,
.toggles
  label
  .it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  input[type="checkbox"]:focus
  + div.datepicker-month.lever,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  div.datepicker-month:hover {
  background: transparent;
  border-color: transparent;
}

.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td {
  background: transparent;
  color: #fff;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td:hover {
  background: transparent;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td:hover
  span {
  background: rgba(255, 255, 255, 0.1);
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.empty:hover {
  background: transparent;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.empty:hover
  span {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.selectable.focus,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.selectable:hover {
  background-color: transparent;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.selectable.focus {
  background: transparent;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.selectable.focus
  span {
  background: #fff;
  color: #0055cc;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.curDay,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.curMonth,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.curYear {
  background: transparent;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.curDay
  span,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.curMonth
  span,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.curYear
  span {
  background: rgba(255, 255, 255, 0.1);
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td.empty {
  opacity: 0.3;
}

.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  td,
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar.default
  table.datepicker-grid
  th {
  background: transparent;
}

.it-datepicker-wrapper.theme-dark .datepicker-calendar {
  border-top: 2px solid #435a70;
  color: #fff;
  background-color: #0055cc;
}
.it-datepicker-wrapper.theme-dark .datepicker-calendar .datepicker-month-wrap {
  color: #fff;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar
  .datepicker-month-wrap
  svg {
  fill: #fff;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar
  .datepicker-month-wrap
  .icon-right {
  border-color: transparent transparent transparent #fff;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar
  .datepicker-month-wrap
  .icon-left {
  border-color: transparent #fff transparent transparent;
}
.it-datepicker-wrapper.theme-dark
  .datepicker-calendar
  .datepicker-month-wrap
  .datepicker-month-next {
  float: right;
}

.it-datepicker-wrapper.theme-dark .input-group:after {
  background: #435a70;
}

.it-datepicker-wrapper.theme-dark .input-group .it-date-datepicker {
  background: transparent;
}
.it-datepicker-wrapper.theme-dark
  .input-group
  .it-date-datepicker::placeholder {
  color: #435a70;
}

.calendar-input-container {
  z-index: 1;
}

#error_container {
  color: #8b0000;
  font-weight: bold;
}

.off-screen {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  z-index: 0;
}

.error_container {
  color: #8b0000;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  margin-top: -12px;
}

.error-label {
  color: #8b0000;
}

/*SPINNER*/
.spinner-control > div .ui-spinner {
  display: block;
  text-align: center;
}

.spinner-control > div button {
  background-color: transparent;
  border: 1px solid transparent;
}

.spinner-control > div button:hover {
  border: 1px solid #777;
  cursor: pointer;
}

.it-timepicker-wrapper .form-group label {
  left: 0;
}

.it-timepicker-wrapper label.pickerlabel {
  color: #5c6f82;
  font-weight: 600;
  line-height: 2.5rem;
  transition: 0.2s ease-out;
  top: 1rem;
  font-size: 0.777rem;
  cursor: text;
  display: block;
  width: 100%;
  padding: 0 0.5rem;
  z-index: 6;
}

.it-timepicker-wrapper .calendar-input-container {
  position: relative;
  display: flex;
}
.it-timepicker-wrapper .calendar-input-container .form-group {
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
}
.it-timepicker-wrapper .calendar-input-container:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #435a70;
  transform-origin: bottom;
  transition: all 0.3s;
  left: 0;
}
.it-timepicker-wrapper .calendar-input-container input.form-control {
  border-bottom: none;
  background: transparent;
  color: #435a70;
  flex: 1;
}
.it-timepicker-wrapper
  .calendar-input-container
  input.form-control::placeholder {
  color: #435a70;
}
.it-timepicker-wrapper .calendar-input-container .btn-time {
  border: none;
  background: transparent;
}
.it-timepicker-wrapper .calendar-input-container .btn-time:hover svg {
  fill: #0055cc;
}
.it-timepicker-wrapper .calendar-input-container .btn-time svg {
  fill: #435a70;
  transition: all 0.3s;
}

.it-timepicker-wrapper .time-spinner {
  position: relative;
}

.it-timepicker-wrapper .spinner-control {
  position: absolute;
  padding: 24px;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-top: 1px solid #435a70;
  display: none;
  margin-top: -2px;
}
.it-timepicker-wrapper .spinner-control .spinner {
  float: left;
  position: relative;
}
.it-timepicker-wrapper .spinner-control .spinner:not(:last-child) {
  margin-right: 24px;
}
.it-timepicker-wrapper .spinner-control .spinner .button-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  bottom: 0;
  flex-direction: column;
}
.it-timepicker-wrapper .spinner-control .spinner .button-wrapper button {
  position: relative;
  flex-basis: 50%;
}
.it-timepicker-wrapper
  .spinner-control
  .spinner
  .button-wrapper
  button
  .icon-up {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  left: 1px;
  border-style: solid;
  border-width: 0 5px 6px 5px;
  border-color: transparent transparent #000000 transparent;
  display: block;
}
.it-timepicker-wrapper
  .spinner-control
  .spinner
  .button-wrapper
  button
  .icon-down {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  left: 1px;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #000000 transparent transparent transparent;
  display: block;
}
.it-timepicker-wrapper .spinner-control input {
  color: #435a70;
  max-width: 80px;
  border: none;
  border-bottom: 1px solid #435a70;
  background: transparent;
  border-radius: 0;
  font-weight: 600;
  box-shadow: none;
}
.it-timepicker-wrapper .spinner-control input[type="number"] {
  -moz-appearance: textfield;
}
.it-timepicker-wrapper .spinner-control input::-webkit-outer-spin-button,
.it-timepicker-wrapper .spinner-control input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.it-timepicker-wrapper.theme-dark .calendar-input-container:after {
  content: "";
  background: #435a70;
}

.it-timepicker-wrapper.theme-dark .calendar-input-container input.form-control {
  background: transparent;
}
.it-timepicker-wrapper.theme-dark
  .calendar-input-container
  input.form-control::placeholder {
  color: #435a70;
}

.it-timepicker-wrapper.theme-dark .calendar-input-container .btn-time {
  background: transparent;
}

.it-timepicker-wrapper.theme-dark .spinner-control {
  background: #0055cc;
}
.it-timepicker-wrapper.theme-dark
  .spinner-control
  .spinner
  .button-wrapper
  button
  .icon-up {
  border-color: transparent transparent #fff transparent;
}
.it-timepicker-wrapper.theme-dark
  .spinner-control
  .spinner
  .button-wrapper
  button
  .icon-down {
  border-color: #fff transparent transparent transparent;
}
.it-timepicker-wrapper.theme-dark .spinner-control input {
  color: #fff;
  border-bottom: 1px solid #fff;
  background: transparent;
}

.it-header-slim-wrapper {
  background: #004ab3;
  padding: 6.5px 18px;
}
.it-header-slim-wrapper .it-header-slim-wrapper-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.it-header-slim-wrapper .it-header-slim-wrapper-content .dropdown-menu {
  top: -9px !important;
}
.it-header-slim-wrapper .it-header-slim-wrapper-content a {
  color: white;
  text-decoration: none;
}
.it-header-slim-wrapper .it-header-slim-wrapper-content a:hover:not(.active) {
  text-decoration: underline;
}
.it-header-slim-wrapper .it-header-slim-wrapper-content a .icon {
  width: 18px;
  height: 18px;
  transition: all 0.3s;
  transform-origin: center;
  fill: white;
}
.it-header-slim-wrapper .it-header-slim-wrapper-content a.dropdown-toggle {
  text-transform: uppercase;
  font-size: 0.778em;
  text-decoration: underline;
  display: flex;
  align-items: center;
}
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  a.dropdown-toggle[aria-expanded="true"]
  .icon {
  transform: scaleY(-1);
}
.it-header-slim-wrapper .it-header-slim-wrapper-content a.it-opener {
  font-size: 0.778em;
  padding: 0.5rem 0;
  display: block;
  text-decoration: none;
}
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  a.it-opener[aria-expanded="true"]
  .icon {
  transform: scaleY(-1);
}
.it-header-slim-wrapper .it-header-slim-wrapper-content .navbar-brand {
  color: white;
  font-size: 0.778em;
  padding: 0;
}
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone,
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .header-slim-right-zone {
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  button,
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  .it-access-top-wrapper
  > a,
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  button,
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  .it-access-top-wrapper
  > a {
  background: #003580;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  button:hover,
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  .it-access-top-wrapper
  > a:hover,
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  button:hover,
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  .it-access-top-wrapper
  > a:hover {
  background: #004099;
}
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .nav-mobile
  ul.link-list {
  margin-top: 16px;
  margin-bottom: 24px;
}
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .nav-mobile
  ul.link-list
  a.list-item.active {
  color: white;
  cursor: default;
  text-decoration: none;
}
.it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .nav-mobile
  ul.link-list
  a.list-item:hover:not(.active) {
  text-decoration: underline;
}

@media (min-width: 992px) {
  .it-header-slim-wrapper {
    height: 48px;
    padding: 0;
  }
  .it-header-slim-wrapper .it-header-slim-wrapper-content {
    padding: 0 18px;
    height: 48px;
  }
  .it-header-slim-wrapper .it-header-slim-wrapper-content .dropdown-menu {
    top: -14px !important;
  }
  .it-header-slim-wrapper .it-header-slim-wrapper-content .nav-mobile {
    flex-grow: 2;
  }
  .it-header-slim-wrapper
    .it-header-slim-wrapper-content
    .nav-mobile
    ul.link-list {
    margin: 0;
    display: flex;
    padding: 0 24px;
    margin-right: 16px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    height: 48px;
  }
  .it-header-slim-wrapper
    .it-header-slim-wrapper-content
    .nav-mobile
    ul.link-list
    a {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .it-header-slim-wrapper
    .it-header-slim-wrapper-content
    .nav-mobile
    ul.link-list
    a.active {
    border-bottom: 2px solid white;
    text-decoration: none;
  }
  .it-header-slim-wrapper .it-header-slim-wrapper-content a.dropdown-toggle {
    padding-top: 12px;
    padding-bottom: 12px;
    text-decoration: none;
  }
  .it-header-slim-wrapper
    .it-header-slim-wrapper-content
    a.dropdown-toggle
    .icon {
    margin-left: 4px;
  }
  .it-header-slim-wrapper .it-header-slim-wrapper-content a.navbar-brand {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .it-header-slim-wrapper
    .it-header-slim-wrapper-content
    .it-header-slim-right-zone,
  .it-header-slim-wrapper
    .it-header-slim-wrapper-content
    .header-slim-right-zone {
    height: 48px;
  }
  .it-header-slim-wrapper .it-header-slim-wrapper-content {
    align-items: center;
  }
  .it-header-slim-wrapper .collapse:not(.show) {
    display: flex;
    justify-content: flex-end;
  }
}

.it-header-slim-wrapper.theme-light {
  background: white;
  border-bottom: 1px solid rgba(0, 74, 179, 0.2);
}
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  .dropdown-menu:before,
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  .dropdown-menu:before {
  background: #004ab3;
}
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  .dropdown-menu,
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  .dropdown-menu {
  background-color: #004ab3;
}
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  .dropdown-menu
  a,
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  .dropdown-menu
  a
  span,
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  .dropdown-menu
  a,
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  .dropdown-menu
  a
  span {
  color: white;
}
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  .btn,
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  .btn {
  background: #0048ad;
  color: white;
}
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone
  .btn:hover,
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .header-slim-right-zone
  .btn:hover {
  background: #0055cc;
}
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content a {
  color: #004ab3;
}
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content a .icon {
  fill: #004ab3;
}
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  a.it-opener[aria-expanded="true"]
  .icon {
  fill: #004ab3;
}
.it-header-slim-wrapper.theme-light
  .it-header-slim-wrapper-content
  .navbar-brand {
  color: #004ab3;
}
.it-header-slim-wrapper.theme-light .btn-full.btn-info,
.it-header-slim-wrapper.theme-light .btn-full.btn-danger,
.it-header-slim-wrapper.theme-light .btn-full.btn-success,
.it-header-slim-wrapper.theme-light .btn-full.btn-warning,
.it-header-slim-wrapper.theme-light .btn-full.btn-secondary,
.it-header-slim-wrapper.theme-light .btn-full.btn-primary {
  color: white !important;
}

@media (min-width: 992px) {
  .it-header-slim-wrapper.theme-light
    .it-header-slim-wrapper-content
    .nav-mobile
    ul.link-list {
    border-left: 1px solid rgba(0, 74, 179, 0.2);
    border-right: 1px solid rgba(0, 74, 179, 0.2);
  }
  .it-header-slim-wrapper.theme-light
    .it-header-slim-wrapper-content
    .nav-mobile
    ul.link-list
    a.list-item.active {
    color: #0048ad;
    border-bottom: 2px solid #004ab3;
  }
}

.it-user-wrapper .dropdown-menu.show {
  transform: translate3d(-120px, 42px, 0) !important;
}
.it-user-wrapper .dropdown-menu.show::before {
  right: 12px;
  left: auto !important;
}

.it-user-wrapper.cloned {
  margin-left: 1em;
  align-self: center;
}

.it-user-wrapper .link-list-wrapper > ul > li > a {
  line-height: 3em;
}

@media (min-width: 576px) {
  .it-user-wrapper .dropdown-menu.show {
    transform: translate3d(-132px, 50px, 0) !important;
  }
  .it-user-wrapper .dropdown-menu.show::before {
    right: 12px;
    left: auto !important;
  }
}

@media (min-width: 992px) {
  .it-user-wrapper .dropdown-menu.show {
    transform: translate3d(0, 56px, 0) !important;
  }
  .it-user-wrapper .dropdown-menu.show::before {
    right: auto;
    left: 27px !important;
  }
  .it-user-wrapper.dropdown .icon {
    margin-left: 4px;
  }
  .it-user-wrapper.dropdown.cloned .btn-full {
    height: 56px;
  }
}

.it-header-center-wrapper {
  background: #0055cc;
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
}
.it-header-center-wrapper.it-small-header {
  height: 64px;
}
.it-header-center-wrapper .it-header-center-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  .it-brand-text {
  padding-right: 24px;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a {
  display: flex;
  color: white;
  align-items: center;
  text-decoration: none;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a
  h2 {
  font-size: 1.25em;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: unset;
  line-height: 1.1;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a
  h3 {
  font-size: 0.889em;
  font-weight: normal;
  margin-bottom: 0;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a:hover {
  text-decoration: none;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a
  .icon {
  fill: white;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  flex-shrink: 0;
}
.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone {
  color: white;
  display: flex;
  align-items: center;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-right-zone
  .it-socials {
  align-items: center;
  font-size: 0.889em;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-right-zone
  .it-socials
  ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-right-zone
  .it-socials
  ul
  .icon {
  fill: white;
  width: 24px;
  height: 24px;
  margin-left: 16px;
}
.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper {
  display: flex;
  align-items: center;
  font-size: 0.889em;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-search-wrapper
  a.rounded-icon {
  width: 24px;
  height: 24px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-search-wrapper
  a.rounded-icon
  svg {
  fill: white;
  width: 24px;
  height: 24px;
}

@media (min-width: 768px) {
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-search-wrapper {
    margin-left: 40px;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-search-wrapper
    a.rounded-icon {
    margin-left: 8px;
  }
}

@media (min-width: 992px) {
  .it-header-center-wrapper {
    height: 120px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 6px;
  }
  .it-header-center-wrapper.it-small-header {
    height: 104px;
  }
  .it-header-center-wrapper.it-small-header
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a
    h2 {
    font-size: 1.333em;
  }
  .it-header-center-wrapper.it-small-header
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a
    h3 {
    font-size: 0.778em;
    margin-top: 4px;
  }
  .it-header-center-wrapper .it-header-center-content-wrapper {
    padding-left: 18px;
    padding-right: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-brand-wrapper {
    margin-left: 0;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a {
    display: flex;
    align-items: center;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a
    h2 {
    font-size: 1.778em;
    margin-bottom: 0;
    font-weight: 600;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a
    h3 {
    font-size: 0.889em;
    font-weight: normal;
    margin-bottom: 0;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a:hover {
    text-decoration: none;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a
    .icon {
    width: 82px;
    height: 82px;
    margin-right: 16px;
  }
  .it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone {
    display: flex;
    align-items: center;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-right-zone
    .it-socials {
    align-items: center;
    font-size: 0.889em;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-right-zone
    .it-socials
    ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-right-zone
    .it-socials
    ul
    .icon {
    width: 24px;
    height: 24px;
    margin-left: 16px;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-right-zone
    .it-socials
    ul
    a:hover
    svg {
    fill: #f2f2f2;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-search-wrapper {
    display: flex;
    align-items: center;
    margin-left: 80px;
    font-size: 0.889em;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-search-wrapper
    a.rounded-icon {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: block;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    transition: all 0.3s;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-search-wrapper
    a.rounded-icon:hover {
    background: #f2f2f2;
  }
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-search-wrapper
    a.rounded-icon
    svg {
    fill: #0055cc;
    width: 24px;
    height: 24px;
  }
}

.it-header-center-wrapper.theme-light {
  background: white;
}
.it-header-center-wrapper.theme-light
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a {
  color: #0055cc;
}
.it-header-center-wrapper.theme-light
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a
  .icon {
  fill: #0055cc;
}
.it-header-center-wrapper.theme-light
  .it-header-center-content-wrapper
  .it-right-zone {
  color: #0055cc;
}
.it-header-center-wrapper.theme-light
  .it-header-center-content-wrapper
  .it-right-zone
  .it-socials
  ul
  .icon {
  fill: #0055cc;
}
.it-header-center-wrapper.theme-light
  .it-header-center-content-wrapper
  .it-search-wrapper
  a.rounded-icon
  svg {
  fill: #0055cc;
}

@media (min-width: 992px) {
  .it-header-center-wrapper.theme-light
    .it-header-center-content-wrapper
    .it-right-zone
    .it-socials
    ul
    a:hover
    svg {
    fill: #004ab3;
  }
  .it-header-center-wrapper.theme-light
    .it-header-center-content-wrapper
    .it-search-wrapper
    a {
    background: #0055cc;
  }
  .it-header-center-wrapper.theme-light
    .it-header-center-content-wrapper
    .it-search-wrapper
    a.rounded-icon
    svg {
    fill: white;
  }
  .it-header-center-wrapper.theme-light
    .it-header-center-content-wrapper
    .it-search-wrapper
    a.rounded-icon:hover {
    background: #004ab3;
  }
}

.it-header-navbar-wrapper nav {
  background: transparent;
}

@media (min-width: 992px) {
  .it-header-navbar-wrapper {
    background: #0055cc;
  }
  .it-header-navbar-wrapper .nav-item.megamenu > a.dropdown-toggle:before {
    bottom: -14px;
  }
  .it-header-navbar-wrapper nav a {
    text-decoration: none;
  }
  .it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper .navbar-nav {
    padding: 0;
  }
  .it-header-navbar-wrapper
    nav
    .navbar-collapsable
    .menu-wrapper
    .navbar-nav.navbar-secondary {
    display: flex;
    justify-content: flex-end;
  }
  .it-header-navbar-wrapper
    nav
    .navbar-collapsable
    .menu-wrapper
    .navbar-nav.navbar-secondary
    a {
    font-size: 0.85em;
    line-height: 1.6;
  }
}

@media (max-width: 992px) {
  .it-header-navbar-wrapper.theme-light-desk .custom-navbar-toggler .icon {
    fill: #0048ad;
  }
  .it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable {
    /*lista menu*/
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .menu-wrapper {
    background: #0048ad;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .col-12:not(:first-child)
    .it-heading-megacolumn:before {
    background: #6893d0;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .menu-wrapper
    .navbar-nav
    .dropdown-menu
    .link-list
    h3 {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .menu-wrapper
    .navbar-nav
    .dropdown-menu
    .col-12:not(:first-child)
    .link-list-wrapper
    h3:before {
    background: #6893d0;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .navbar-nav
    .it-external
    .link-list-wrapper
    .link-list
    li.it-more
    a:before {
    background: #6893d0;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .it-megamenu-footer {
    background: #004ab3;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical {
    background: #004ab3;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical
    .link-list-wrapper
    .link-list
    li
    .divider {
    background: #6893d0;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical.it-description
    p {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical.it-description
    .description-content:before {
    background: #6893d0;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .navbar-nav
    li
    a.nav-link {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .navbar-collapsable
    .navbar-nav
    li
    a.nav-link.active {
    border-left-color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile .navbar .close-div .close-menu {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu h3 {
    font-weight: 600;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .dropdown-menu
    h3.it-heading-megacolumn {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a.list-item.active {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a.list-item.active
    span {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a
    span {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a
    i {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a
    h3 {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    h3 {
    color: white;
  }
  .it-header-navbar-wrapper.theme-dark-mobile
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    .divider {
    background: #6893d0;
  }
}

/*
++++++++++++++++++++++++++++++Desktop light Theme+++++++++++++++++++++++++++++++++++++++++++++++++
*/
@media (min-width: 992px) {
  .it-header-navbar-wrapper.theme-light-desk {
    background: white;
    box-shadow: 0 20px 30px 5px rgba(0, 0, 0, 0.05);
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .navbar-nav
    li
    a.nav-link.dropdown-toggle
    svg {
    fill: #0048ad;
  }
  .it-header-navbar-wrapper.theme-light-desk .navbar {
    background: transparent;
  }
  .it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable {
    /*lista menu*/
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .menu-wrapper {
    background: white;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .menu-wrapper
    .navbar-nav
    .dropdown-menu
    .link-list
    h3 {
    color: white;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .it-external
    .link-list-wrapper
    .link-list
    li.it-more
    a:before {
    background: #6893d0;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    a.nav-link:before {
    background-color: #0048ad;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .it-megamenu-footer {
    background: #004ab3;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical {
    background: #004ab3;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical
    .link-list-wrapper
    .link-list
    li
    .divider {
    background: #6893d0;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .it-description
    p {
    color: white;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .navbar-nav
    li
    a.nav-link {
    color: #0048ad;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .navbar-collapsable
    .navbar-nav
    li
    a.nav-link.active {
    border-bottom-color: #0048ad;
  }
  .it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu {
    background: #0048ad;
  }
  .it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu h3 {
    font-weight: 600;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .dropdown-menu
    h3.it-heading-megacolumn {
    color: white;
  }
  .it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu:before {
    background: #0048ad;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a.list-item.active {
    color: white;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a.list-item.active
    span {
    color: white;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a
    span {
    color: white;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a
    i {
    color: white;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a
    h3 {
    color: white;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    h3 {
    color: white;
  }
  .it-header-navbar-wrapper.theme-light-desk
    .navbar
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    .divider {
    background: #d9dadb;
  }
}

.it-header-wrapper .it-nav-wrapper {
  position: relative;
}
.it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
  padding-left: 32px;
}
.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper {
  transition: padding-top 0.3s ease;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -22px;
}
.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper nav {
  padding-left: 18px;
  padding-right: 18px;
}
.it-header-wrapper
  .it-nav-wrapper
  .it-header-navbar-wrapper
  nav
  .custom-navbar-toggler {
  padding: 0;
  transform: translateX(-2px);
  cursor: pointer;
}

.it-header-wrapper.it-header-sticky.is-sticky .it-nav-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

@media (min-width: 992px) {
  .it-header-wrapper.it-header-sticky.is-sticky .it-header-slim-wrapper,
  .it-header-wrapper.it-header-sticky.is-sticky .it-header-center-wrapper {
    display: none;
  }
  .it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-brand-wrapper {
    align-self: center;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-brand-wrapper.cloned
    .it-brand-text {
    display: none;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-brand-wrapper.cloned
    a {
    color: white;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-brand-wrapper.cloned
    a:hover {
    text-decoration: none;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-brand-wrapper.cloned
    a
    .icon {
    fill: white;
    width: 41px;
    height: 41px;
    margin-right: 16px;
    flex-shrink: 0;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-search-wrapper {
    display: flex;
    align-items: center;
    align-self: center;
    margin-left: 26.66667px;
    font-size: 0.889em;
    color: white;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-search-wrapper
    a.rounded-icon {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: block;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    transition: all 0.3s;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-search-wrapper
    a.rounded-icon:hover {
    background: #f2f2f2;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-search-wrapper
    a.rounded-icon
    svg {
    fill: #0055cc;
    width: 24px;
    height: 24px;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-search-wrapper.cloned
    a {
    width: 35px;
    height: 35px;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .it-search-wrapper.cloned
    a
    svg {
    width: 20px;
    height: 20px;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .navbar-nav {
    flex: 1;
  }
  .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper
    .menu-wrapper
    .navbar-nav.navbar-secondary {
    display: none;
  }
}

.it-header-wrapper.it-header-sticky.is-sticky ~ * .owl-carousel {
  z-index: auto;
}

@media (min-width: 992px) {
  .it-header-wrapper .it-nav-wrapper {
    position: relative;
  }
  .it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
    padding-left: 0;
  }
  .it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper {
    position: inherit;
    left: auto;
    top: inherit;
    margin-top: 0;
  }
  .it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper nav {
    padding-left: 18px;
    padding-right: 18px;
  }
  .it-header-wrapper
    .navbar
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu {
    left: 0;
    right: 0;
  }
}

.it-footer-main {
  background-color:var(--footer-bg-color);
  color: white;
  font-size: 16px;
}
.it-footer-main h4 a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
}
.it-footer-main .link-list-wrapper ul li a {
  padding: 0;
  font-size: 1em;
  line-height: 2em;
  color: white;
}
.it-footer-main .link-list-wrapper ul li a:hover:not(.disabled) {
  text-decoration: underline;
}
.it-footer-main .it-brand-wrapper {
  padding: 32px 0;
}
.it-footer-main .it-brand-wrapper .it-brand-text {
  padding-right: 24px;
}
.it-footer-main .it-brand-wrapper a {
  display: flex;
  color: white;
  align-items: center;
  text-decoration: none;
}
.it-footer-main .it-brand-wrapper a h2 {
  font-size: 1.25em;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: unset;
  line-height: 1.1;
}
.it-footer-main .it-brand-wrapper a h3 {
  font-size: 0.889em;
  font-weight: normal;
  margin-bottom: 0;
}
.it-footer-main .it-brand-wrapper a:hover {
  text-decoration: none;
}
.it-footer-main .it-brand-wrapper a .icon {
  fill: white;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  flex-shrink: 0;
}
.it-footer-main section {
  padding: 0 16px;
}
.it-footer-main label {
  font-weight: 600;
}

.it-footer-small-prints {
  background-color: #001533;
}
.it-footer-small-prints ul.it-footer-small-prints-list {
  padding: 1.5rem 1rem;
}
.it-footer-small-prints ul.it-footer-small-prints-list li {
  padding: 0.5rem 0;
}
.it-footer-small-prints ul.it-footer-small-prints-list li:first-child {
  padding-top: 0;
}
.it-footer-small-prints ul.it-footer-small-prints-list li:last-child {
  padding-bottom: 0;
}
.it-footer-small-prints a,
.it-footer-small-prints a:hover:not(.disabled) {
  color: white;
  font-size: 15px;
  text-decoration: none;
}

.form-newsletter input[type="email"] {
  font-size: 1em;
}
.form-newsletter input[type="email"] ~ * {
  margin-top: 1em;
}

.form-newsletter button {
  display: block;
  width: 100%;
}

@media (min-width: 992px) {
  .form-newsletter button {
    display: inline-block;
    width: auto;
  }
}

@media (min-width: 768px) {
  .it-footer-small-prints ul.it-footer-small-prints-list li {
    padding: 0 1rem;
  }
  .it-footer-small-prints ul.it-footer-small-prints-list li:first-child {
    padding-left: 0;
  }
  .it-footer-small-prints ul.it-footer-small-prints-list li:last-child {
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .navbar.theme-dark-mobile .navbar-collapsable {
    /*lista menu*/
  }
  .navbar.theme-dark-mobile .navbar-collapsable .menu-wrapper {
    background: #0048ad;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .menu-wrapper
    .nav-item.megamenu
    .dropdown-menu
    .col-12:not(:first-child)
    .it-heading-megacolumn:before {
    background: #6893d0;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .menu-wrapper
    .navbar-nav
    .dropdown-menu
    .link-list
    h3 {
    color: white;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .menu-wrapper
    .navbar-nav
    .dropdown-menu
    .col-12:not(:first-child)
    .link-list-wrapper
    h3:before {
    background: #6893d0;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .navbar-nav
    .it-external
    .link-list-wrapper
    .link-list
    li.it-more
    a:before {
    background: #6893d0;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .it-megamenu-footer {
    background: #004ab3;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical {
    background: #004ab3;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical
    .link-list-wrapper
    .link-list
    li
    .divider {
    background: #6893d0;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical.it-description
    p {
    color: white;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical.it-description
    .description-content:before {
    background: #6893d0;
  }
  .navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li a.nav-link {
    color: white;
  }
  .navbar.theme-dark-mobile
    .navbar-collapsable
    .navbar-nav
    li
    a.nav-link.active {
    border-left-color: white;
  }
  .navbar.theme-dark-mobile .close-div .close-menu {
    color: white;
  }
  .navbar.theme-dark-mobile .dropdown-menu h3 {
    font-weight: 600;
  }
  .navbar.theme-dark-mobile .dropdown-menu h3.it-heading-megacolumn {
    color: white;
  }
  .navbar.theme-dark-mobile
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a.list-item.active {
    color: white;
  }
  .navbar.theme-dark-mobile
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a.list-item.active
    span {
    color: white;
  }
  .navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a span {
    color: white;
  }
  .navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a i {
    color: white;
  }
  .navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a h3 {
    color: white;
  }
  .navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li h3 {
    color: white;
  }
  .navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li .divider {
    background: #6893d0;
  }
}

/*
++++++++++++++++++++++++++++++Desktop light Theme+++++++++++++++++++++++++++++++++++++++++++++++++
*/
@media (min-width: 992px) {
  .navbar.theme-light-desk {
    background: white;
    box-shadow: 0 20px 30px 5px rgba(0, 0, 0, 0.05);
  }
  .navbar.theme-light-desk .navbar-collapsable {
    /*lista menu*/
  }
  .navbar.theme-light-desk .navbar-collapsable .menu-wrapper {
    background: white;
  }
  .navbar.theme-light-desk
    .navbar-collapsable
    .menu-wrapper
    .navbar-nav
    .dropdown-menu
    .link-list
    h3 {
    color: white;
  }
  .navbar.theme-light-desk
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .it-external
    .link-list-wrapper
    .link-list
    li.it-more
    a:before {
    background: #6893d0;
  }
  .navbar.theme-light-desk
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    a.nav-link:before {
    background-color: #0048ad;
  }
  .navbar.theme-light-desk
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .it-megamenu-footer {
    background: #004ab3;
  }
  .navbar.theme-light-desk
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical {
    background: #004ab3;
  }
  .navbar.theme-light-desk
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .dropdown-menu
    .it-vertical
    .link-list-wrapper
    .link-list
    li
    .divider {
    background: #6893d0;
  }
  .navbar.theme-light-desk
    .navbar-collapsable
    .navbar-nav
    li.megamenu
    .it-description
    p {
    color: white;
  }
  .navbar.theme-light-desk .navbar-collapsable .navbar-nav li a.nav-link {
    color: #0048ad;
  }
  .navbar.theme-light-desk
    .navbar-collapsable
    .navbar-nav
    li
    a.nav-link.active {
    border-bottom-color: #0048ad;
  }
  .navbar.theme-light-desk .dropdown-menu {
    background: #0048ad;
  }
  .navbar.theme-light-desk .dropdown-menu h3 {
    font-weight: 600;
  }
  .navbar.theme-light-desk .dropdown-menu h3.it-heading-megacolumn {
    color: white;
  }
  .navbar.theme-light-desk .dropdown-menu:before {
    background: #0048ad;
  }
  .navbar.theme-light-desk
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a.list-item.active {
    color: white;
  }
  .navbar.theme-light-desk
    .dropdown-menu
    .link-list-wrapper
    ul
    li
    a.list-item.active
    span {
    color: white;
  }
  .navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a span {
    color: white;
  }
  .navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a i {
    color: white;
  }
  .navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a h3 {
    color: white;
  }
  .navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li h3 {
    color: white;
  }
  .navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li .divider {
    background: #d9dadb;
  }
}

.componente-base {
  background-color: #0048ad;
  color: white;
  cursor: pointer;
  padding: 8px 8px;
}

:focus,
.form-check [type="checkbox"]:focus + label,
.form-check [type="radio"]:focus + label,
.toggles label input[type="checkbox"]:focus + .lever,
.block-facet--checkbox [type="checkbox"]:focus + label {
  border-color: #ff9900;
  box-shadow: 0 0 0 2px #ff9900;
  outline: none;
}

[tabindex="-1"]:focus:not(:focus-visible),
.form-check [type="checkbox"]:focus + label[tabindex="-1"]:not(:focus-visible),
.form-check [type="radio"]:focus + label[tabindex="-1"]:not(:focus-visible),
.toggles
  label
  input[type="checkbox"]:focus
  + .lever[tabindex="-1"]:not(:focus-visible),
.block-facet--checkbox
  [type="checkbox"]:focus
  + label[tabindex="-1"]:not(:focus-visible) {
  box-shadow: none;
}

.focus--mouse,
.form-check [type="checkbox"]:focus.focus--mouse + label,
.form-check
  [type="checkbox"]:focus
  + label[type="checkbox"].focus--mouse
  + label,
.form-check [type="radio"]:focus + label[type="checkbox"].focus--mouse + label,
.form-check
  .toggles
  label
  input[type="checkbox"]:focus
  + .lever[type="checkbox"].focus--mouse
  + label,
.toggles
  label
  .form-check
  input[type="checkbox"]:focus
  + .lever[type="checkbox"].focus--mouse
  + label,
.form-check [type="radio"]:focus.focus--mouse + label,
.form-check [type="checkbox"]:focus + label[type="radio"].focus--mouse + label,
.form-check [type="radio"]:focus + label[type="radio"].focus--mouse + label,
.form-check
  .toggles
  label
  input[type="checkbox"]:focus
  + .lever[type="radio"].focus--mouse
  + label,
.toggles
  label
  .form-check
  input[type="checkbox"]:focus
  + .lever[type="radio"].focus--mouse
  + label,
.form-check
  .block-facet--checkbox
  [type="checkbox"]:focus
  + label[type="radio"].focus--mouse
  + label,
.toggles label input[type="checkbox"]:focus.focus--mouse + .lever,
.toggles
  label
  input[type="checkbox"]:focus
  + input.lever[type="checkbox"].focus--mouse
  + .lever,
.block-facet--checkbox [type="checkbox"]:focus.focus--mouse + label,
.form-check
  .block-facet--checkbox
  [type="radio"]:focus
  + label[type="checkbox"].focus--mouse
  + label,
.block-facet--checkbox
  .toggles
  label
  input[type="checkbox"]:focus
  + .lever[type="checkbox"].focus--mouse
  + label,
.toggles
  label
  .block-facet--checkbox
  input[type="checkbox"]:focus
  + .lever[type="checkbox"].focus--mouse
  + label,
.block-facet--checkbox
  [type="checkbox"]:focus
  + label[type="checkbox"].focus--mouse
  + label {
  border-color: inherit;
  box-shadow: none;
  outline: none;
}

.icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
}
.icon.icon-padded {
  padding: 8px;
}
.icon.icon-xs {
  width: 16px;
  height: 16px;
}
.icon.icon-xs.icon-padded {
  padding: 4px;
}
.icon.icon-sm {
  width: 24px;
  height: 24px;
}
.icon.icon-sm.icon-padded {
  padding: 6px;
}
.icon.icon-lg {
  width: 48px;
  height: 48px;
}
.icon.icon-lg.icon-padded {
  padding: 12px;
}
.icon.icon-xl {
  width: 64px;
  height: 64px;
}
.icon.icon-xl.icon-padded {
  padding: 16px;
}

.icon-primary {
  fill: #0048ad !important;
}

.icon-secondary {
  fill: #5c6f82 !important;
}

.icon-success {
  fill: #008758 !important;
}

.icon-info {
  fill: #979899 !important;
}

.icon-warning {
  fill: #a66300 !important;
}

.icon-danger {
  fill: #d9364f !important;
}

.icon-light {
  fill: #e9e6f2 !important;
}

.icon-dark {
  fill: #17324d !important;
}

.icon-black {
  fill: black !important;
}

.icon-white {
  fill: white !important;
}

.icon-100 {
  fill: #e3e4e6 !important;
}

.icon-200 {
  fill: #cacacc !important;
}

.icon-300 {
  fill: #b1b1b3 !important;
}

.icon-400 {
  fill: #979899 !important;
}

.icon-500 {
  fill: #7e7f80 !important;
}

.icon-600 {
  fill: #656566 !important;
}

.icon-700 {
  fill: #4c4c4d !important;
}

.icon-800 {
  fill: #323333 !important;
}

.icon-900 {
  fill: #19191a !important;
}

/* Header */
/* Footer */
/* Person */
/* Card Message */
/* Res Badge */
/* Card Theme*/
/* Text media */
/**
 * Drupal Fix
 */
#toolbar-administration.toolbar,
#toolbar-administration.toolbar::before {
  height: 0;
}

#toolbar-administration.toolbar {
  position: static;
}

@media (min-width: 992px) {
  body.toolbar-horizontal.toolbar-fixed
    .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper {
    top: 39px;
  }
  body.toolbar-horizontal.toolbar-fixed.toolbar-tray-open
    .it-header-wrapper.it-header-sticky.is-sticky
    .it-header-navbar-wrapper {
    top: 79px;
  }
}

.form-control {
  background-size: 18px 30% !important;
}

/*
 * Bootstrap 4 Fix
 */
.form-text {
  margin-top: 0;
}

.form-group small.form-text {
  position: relative;
}

/*
 * Fix input-number spinner up and down. See templates/form/input--number.html.twig
 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

.back-to-top {
  z-index: 3;
}

#search-modal .modal-body {
  padding-bottom: 24px;
}

#search-modal h2 {
  color: #17324d;
}

.it-footer-main h4,
.it-footer-main .it-socials > span {
  color: #9ae2f8;
  font-size: 1rem;
  text-transform: uppercase;
}

.it-footer-main .it-socials {
  display: flex !important;
  flex-direction: column;
}
.it-footer-main .it-socials > span {
  margin-bottom: 8px;
  font-weight: 700;
}
.it-footer-main .it-socials ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.it-footer-main .it-socials ul .icon {
  fill: white;
  width: 24px;
  height: 24px;
  margin-left: 16px;
}
.it-footer-main .it-socials ul li:first-child .icon {
  margin-left: 0;
}

.footer_first p {
  line-height: 2em;
}

.res-entity-update__label {
  margin-bottom: 0.5rem;
}

.res-entity-update time {
  font-weight: 700;
}

.res-sharethis__label {
  margin-bottom: 0.5rem;
}

.res-sharethis span {
  margin-right: 0.5rem;
  cursor: pointer;
}

.res-sharethis .icon {
  fill: #4c4c4d;
}

.page-header {
  padding-left: 30px;
  padding-right: 30px;
}

#it-block-page-title {
  margin-bottom: 32px;
}

#it-block-page-subtitle {
  margin-bottom: 32px;
}

.res-chips-links {
  display: flex;
  flex-wrap: wrap;
}
.res-chips-links .res-chip-link {
  margin-bottom: 5px;
  margin-right: 5px;
}

#it-block-laregioneinforma {
  margin-bottom: 24px;
}

[data-block-plugin-id="views_block:councillorship_news_and_communications-counc_news"] {
  margin-top: 40px;
}
[data-block-plugin-id="views_block:councillorship_news_and_communications-counc_news"]
  .heading-cta-carousel {
  margin-bottom: 16px;
}

.res-menu-siblings {
  margin-top: 16px;
}
@media (min-width: 992px) {
  .res-menu-siblings {
    margin-top: 0;
  }
}

.res-block-siti-tematici {
  padding-bottom: 48px;
  padding-top: 48px;
}

[data-block-plugin-id="views_block:councillorship_offices_and_departments-deps_and_offices"] {
  margin-top: 32px;
}

[data-block-plugin-id="field_block:group:councillorship:field_cta"] {
  margin-top: 32px;
}

[data-block-plugin-id="field_block:node:decree:field_note"] {
  margin-top: 48px;
  padding-top: 32px;
  border-top: 1px solid lightgray;
}

[data-block-plugin-id="field_block:node:decree:field_text"] {
  margin-top: 32px;
}

[data-block-plugin-id="views_block:res_revision_logs-content_revisions"] {
  margin-top: 40px;
}

#it-block-ibandidellaregionesiciliana {
  margin-bottom: 24px;
}

.footer-logos .logo {
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}

.footer-logos .region-logo,
.footer-logos .europe-logo,
.footer-logos .republic-logo {
  max-height: 50px;
}
@media (min-width: 768px) {
  .footer-logos .region-logo,
  .footer-logos .europe-logo,
  .footer-logos .republic-logo {
    max-height: 60px;
  }
}
@media (min-width: 992px) {
  .footer-logos .region-logo,
  .footer-logos .europe-logo,
  .footer-logos .republic-logo {
    max-height: 65px;
  }
}

.footer-logos .pofesr-logo {
  margin-top: 2px;
  max-height: 30px;
}
@media (min-width: 768px) {
  .footer-logos .pofesr-logo {
    max-height: 38px;
  }
}

.it-footer-small-prints .footer_credits {
  padding: 1.5rem 1rem;
}
@media (min-width: 1200px) {
  .it-footer-small-prints .footer_credits {
    padding-left: 0;
    padding-right: 0;
  }
}

.block-banner {
  margin-bottom: 3rem;
}

[data-block-plugin-id="field_block:node:news:field_theme"] a::after {
  content: ",";
}

[data-block-plugin-id="field_block:node:news:field_theme"] a:last-child::after {
  content: none;
}

@media (min-width: 992px) {
  .it-sidebar-first__wrapper .sidebar_first {
    border-right: 1px solid #d9dadb;
  }
}

.it-sidebar-first__wrapper .collapse-header *[data-toggle="collapse"] {
  padding: 8px;
  border-top: 0;
  font-size: 0.8888888889rem;
}

.it-sidebar-first__wrapper .collapse-body {
  padding: 0 32px 0 8px;
}

.it-sidebar-first__wrapper .block-facet--checkbox {
  margin-right: 16px;
  margin-left: 16px;
}

.it-sidebar-first__wrapper .block-facet--switch .collapse-body {
  padding: 16px 8px 16px 24px;
}

.it-sidebar-first__wrapper .facets-widget-switch {
  font-size: 0.8888888889rem;
}
.it-sidebar-first__wrapper .facets-widget-switch ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.it-sidebar-first__wrapper .views-exposed-form > form {
  padding-right: 16px;
  padding-left: 16px;
}
.it-sidebar-first__wrapper .views-exposed-form > form .form-group {
  margin-top: 32px;
}
.it-sidebar-first__wrapper .views-exposed-form > form > div:nth-last-child(2) {
  margin-bottom: 24px;
}

.it-sidebar-first__wrapper .block-facet--checkbox ul {
  padding-left: 32px;
  list-style: none;
}

.it-sidebar-first__wrapper .block-facet--checkbox .js-facets-checkbox-links {
  padding-left: 0;
}

.it-sidebar-first__wrapper .block-facet--checkbox .facet-item {
  position: relative;
  margin-bottom: 8px;
}

.it-sidebar-first__wrapper .sidebar__title__reset {
  text-decoration: underline;
  text-transform: none;
}

.it-sidebar-first__wrapper .facets-widget- {
  margin-top: 8px;
}
.it-sidebar-first__wrapper .facets-widget- label {
  font-size: 0.8333333333rem;
}

.facets-collapse {
  position: absolute;
  right: -31px;
  top: 0;
  width: 41px;
  background-color: transparent;
  border: 0;
}
.facets-collapse::before {
  display: inline-block;
  width: 27px;
  color: #6893d0;
  line-height: 0.1rem;
  transition: transform 0.3s;
}
.facets-collapse[aria-expanded="false"]::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPml0LWNvbGxhcHNlPC90aXRsZT48ZyBpZD0iTGl2ZWxsb18xMyIgZGF0YS1uYW1lPSJMaXZlbGxvIDEzIj48cGF0aCBmaWxsPSIjN0ZCMkU1IiBkPSJNMTIsMTAuMjUsMTYuNzcsMTVhLjc1Ljc1LDAsMCwwLDEuMDYsMCwuNzQuNzQsMCwwLDAsMC0xLjA2TDEzLjA2LDkuMTlhMS41MSwxLjUxLDAsMCwwLTIuMTIsMEw2LjE3LDE0YS43NC43NCwwLDAsMCwwLDEuMDYuNzUuNzUsMCwwLDAsMS4wNiwwWiIvPjwvZz48L3N2Zz4K");
  transform: scaleY(-1);
}
.facets-collapse[aria-expanded="true"]::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPml0LWNvbGxhcHNlPC90aXRsZT48ZyBpZD0iTGl2ZWxsb18xMyIgZGF0YS1uYW1lPSJMaXZlbGxvIDEzIj48cGF0aCBmaWxsPSIjN0ZCMkU1IiBkPSJNMTIsMTAuMjUsMTYuNzcsMTVhLjc1Ljc1LDAsMCwwLDEuMDYsMCwuNzQuNzQsMCwwLDAsMC0xLjA2TDEzLjA2LDkuMTlhMS41MSwxLjUxLDAsMCwwLTIuMTIsMEw2LjE3LDE0YS43NC43NCwwLDAsMCwwLDEuMDYuNzUuNzUsMCwwLDAsMS4wNiwwWiIvPjwvZz48L3N2Zz4K");
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-control:valid,
.form-control.is-valid {
  padding-right: 16px;
}

.badge-res {
  padding: 0.25rem 1.25rem;
  background-color: #e3e6e9;
  color: #8491a0;
  text-transform: uppercase;
}

.it-datepicker-wrapper .datepicker-calendar {
  padding: 8px;
}

.table--smaller-font {
  font-size: 0.8333333333rem;
}

.res-accordion .collapse-header *[data-toggle="collapse"] {
  border-top: 0;
}

.res-accordion__item {
  margin-bottom: 8px;
}

.it-carousel-wrapper.it-carousel-landscape-abstract {
  margin-bottom: 50px;
  max-width: none;
}
.it-carousel-wrapper.it-carousel-landscape-abstract .owl-stage-outer {
  border-right-color: transparent;
}
.before_content_full_width
  .it-carousel-wrapper.it-carousel-landscape-abstract
  .owl-stage-outer {
  padding-top: 0;
}
.it-carousel-wrapper.it-carousel-landscape-abstract .owl-stage {
  max-height: 438px;
}
.it-carousel-wrapper.it-carousel-landscape-abstract .owl-dots {
  bottom: -35px;
  left: 0;
  right: auto;
  margin-left: 0;
  width: 100%;
  text-align: center;
}

.home-third-row__wrapper
  .it-carousel-landscape-abstract-three-cols
  .owl-dots
  .owl-dot {
  background-color: #d9dadb;
}
.home-third-row__wrapper
  .it-carousel-landscape-abstract-three-cols
  .owl-dots
  .owl-dot.active {
  background-color: white;
}

.heading-cta-carousel h3 + div {
  line-height: 1;
}

.heading-cta-carousel .read-more {
  margin-bottom: 5px;
}

.home-third-row__wrapper .heading-cta-carousel h3,
.home-third-row__wrapper .heading-cta-carousel .read-more {
  color: white;
}

.hero-message {
  background-color: #0048ad;
}
.hero-message__second {
  height: 100%;
  max-width: 690px;
  margin-left: auto;
  padding: 32px;
  background-color: #0048ad;
  color: white;
  font-size: 22px;
}
.hero-message__img {
  max-width: 690px;
  margin-right: auto;
}
.hero-message__title {
  margin-bottom: 32px;
}
.hero-message__date {
  text-transform: uppercase;
  opacity: 0.7;
  font-size: 14px;
  margin-bottom: 22px;
}
.hero-message__action {
  margin-top: 32px;
}
.hero-message .h3 {
  font-size: 40px;
}
.hero-message img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.it-hero-wrapper .it-hero-text-wrapper p {
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
}

.it-hero-wrapper {
  min-height: auto;
}
.it-hero-wrapper .img-wrapper > div > img {
  height: 399px !important;
}

.it-btn-container a {
  background-color: white;
  box-shadow: none;
  color: #0048ad;
}

.it-hero-text__text {
  color: white;
}

use.res-icon-custom {
  stroke: #00265a;
}
use.res-icon-custom.urp,
use.res-icon-custom.hand-cart {
  stroke: #0048ad;
}

.map-viewer__second {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  background-color: #00265a;
  color: white;
}
.map-viewer__second a {
  color: white;
  font-weight: 600;
}

.map-viewer__label {
  font-weight: 600;
}

.map-viewer__map {
  min-height: 400px;
  height: 100%;
}

.map-viewer__row {
  display: flex;
  flex-direction: column;
}

.map-viewer__list-item {
  margin-bottom: 16px;
  margin-top: 8px;
}

.map-viewer__action {
  margin-top: auto;
}

.map-viewer .office-hours__item-comments {
  margin-left: 16px;
}

.map-viewer .address .postal-code::after,
.map-viewer .address .locality::after {
  content: ", ";
}

.res-person {
  font-size: 0.88889rem;
}
.res-person__text,
.res-person__media {
  height: 100%;
}
.res-person__text {
  padding: 35px;
  display: flex;
  flex-direction: column;
  word-break: break-word;
}
.res-person__date_of_birth_death {
  font-weight: 600;
}
.res-person__legislature {
  display: inline;
}
.res-person__legislature__label {
  display: inline;
  font-size: 0.94444rem;
}
.res-person__mandate__label {
  font-size: 0.94444rem;
}
.res-person__media img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}
@media (min-width: 768px) {
  .res-person__media img {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .res-person__media img {
    max-width: 400px;
  }
}
.res-person__fullname {
  font-size: 1.55556rem;
}
.res-person__position {
  margin-bottom: 1.33333rem;
  font-size: 0.94444rem;
  font-weight: 700;
}
.res-person__membership {
  font-style: italic;
}
.res-person__description {
  margin-bottom: 2.22222rem;
  margin-top: 2.22222rem;
}
.res-person__email {
  display: flex;
}
.res-person__email svg + .emails {
  margin-left: 0.25rem;
}
.res-person__fax > a::after,
.res-person__phone > a::after {
  content: " - ";
}
.res-person__fax > a:last-child::after,
.res-person__phone > a:last-child::after {
  content: none;
}
.res-person__action,
.res-person__sheet,
.res-person .read-more {
  margin-top: auto;
}
.res-person__sheet {
  color: #0048ad;
  font-size: 0.77778rem;
}
.res-person__sheet > div {
  margin-top: 8px;
}
.res-person__label {
  font-weight: 700;
}
.res-person__action a {
  word-break: break-word;
}
.res-person a {
  font-weight: 700;
}

.read-more.read-more--no-transform {
  text-transform: none;
  font-size: 0.8888888889rem;
}

.res-card-competition-announcement__category {
  margin-left: 8px;
  margin-top: 0.188rem;
}

.res-card-competition-announcement__title a {
  font-size: 1.25rem;
}

.res-card-competition-announcement__deadline .label {
  margin-bottom: 0;
  font-size: 0.875rem;
}

.res-card-competition-announcement__deadline .date {
  font-weight: 600;
  font-size: 0.938rem;
}

.res-card-file .card {
  display: flex;
  align-items: stretch;
}
.res-card-file .card a.read-more {
  position: static;
  bottom: auto;
}
.res-card-file .card .card-body {
  display: flex;
  flex-direction: column;
}

.res-card-logo {
  padding-bottom: 0;
}
.res-card-logo .card {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: #e6efff;
}
.res-card-logo .card .res-card-logo__media,
.res-card-logo .card .res-card-logo__body {
  display: flex;
  align-items: center;
}
.res-card-logo .card .res-card-logo__media {
  flex-basis: 130px;
  background-color: white;
  text-align: center;
}
.res-card-logo .card .res-card-logo__media img {
  max-width: 100%;
  height: auto;
}
.res-card-logo .card .res-card-logo__body {
  padding: 16px;
  width: 100%;
  font-weight: 600;
}

.res-card-thematic .card-body .card-title {
  display: inline-flex;
  align-items: center;
}
.res-card-thematic .card-body .card-title .res-card-thematic__icon {
  margin-bottom: 16px;
  margin-right: 16px;
}
.res-card-thematic .card-body .card-title h5.res-card-thematic__title {
  font-size: 1.3rem;
}

.res-card-thematic .card-body .card-text {
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
  font-size: 0.889rem;
  font-weight: 400;
  line-height: 1.5;
}

.res-card-message__media {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
}
.res-card-message__media img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.res-card-message__icon {
  margin-right: 8px;
}

.res-card-message .card .card-body .card-text {
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.res-card-message .card .card-body .category-name {
  font-weight: 700;
}

.res-card-message .card .card-body .category-link::after,
.res-card-message .card .card-body .category-name::after {
  content: " — ";
  font-weight: 400;
}

.res-card-message .card .card-body .date {
  font-weight: 400;
}

.res-card-message-date-block .data {
  display: block;
}

.res-card-message-date-block .card .card-body .category-top .data::before {
  content: none;
}

.res-card-message-no-image .card .card-body .category-top {
  display: flex;
}
.res-card-message-no-image .card .card-body .category-top .category-name {
  margin-top: 4px;
}

.res-card-message-no-image .card .card-body h5.card-title.big-heading {
  font-size: 18px;
}
.res-card-message-no-image .card .card-body h5.card-title.big-heading a {
  color: #3d3d3d;
}

.res-card-message-no-image .card .card-body .category-name::after {
  content: none;
}

.res-card-message-main .card-body,
.res-card-message-main .res-card-message__media {
  min-width: 50%;
}

.res-card-theme .res-card-theme-box {
  padding: 2.5rem 1.5rem;
  border-left: 0.25rem solid #0048ad;
}
.res-card-theme .res-card-theme-box-body {
  display: inline-flex;
  align-items: stretch;
}
.res-card-theme .res-card-theme-box-body .res-card-theme__icon {
  align-self: center;
}
.res-card-theme .res-card-theme-box-body .res-card-theme__title {
  margin-left: 0.625rem;
  margin-top: 0.5rem;
  font-size: 1.188rem;
}
.res-card-theme .res-card-theme-box-body .res-card-theme__title a {
  color: #818181;
  font-weight: 600;
}

.res-chip-link .chip {
  padding-bottom: 0;
  height: auto;
}
.res-chip-link .chip .chip-label {
  color: #0048ad;
  height: auto;
}

.res-cta-banner__image .quickedit-field {
  height: 100%;
}

.res-cta-banner__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.res-cta-banner__text {
  background-color: #0048ad;
  color: white;
}
.res-cta-banner__text__content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 700px;
  height: 100%;
}
@media (min-width: 1440px) {
  .res-cta-banner__text__content {
    margin-right: 140px;
    max-width: 760px;
  }
}
.res-cta-banner__text__content__title {
  margin: 40px 40px 16px;
}
.res-cta-banner__text__content__body {
  margin-bottom: 24px;
  margin-left: 40px;
  margin-right: 40px;
}
.res-cta-banner__text__content__action {
  margin: auto 40px 40px;
}
.res-cta-banner__text__content__action .btn-primary {
  background-color: white;
  color: #0048ad;
}
.res-cta-banner__text__content__action a {
  margin-right: 16px;
  margin-top: 16px;
}

.res-cta-banner-no_image .res-cta-banner__text__content {
  margin-left: 0;
  max-width: 700px;
}
@media (min-width: 1200px) {
  .res-cta-banner-no_image .res-cta-banner__text__content {
    max-width: 800px;
  }
}

.res-link-list .list-item {
  font-size: 1rem;
  text-decoration: underline;
}

.res-link-list:not(.multiline) .list-item {
  padding: 0;
}

.res-link-list__description {
  font-size: 1rem;
}

.res-link-list-primary_action + .res-link-list__description {
  padding-left: 27.2px;
}

.res-link-list.multiline > ul > li {
  border-bottom: 1px solid #d9dadb;
}
.res-link-list.multiline > ul > li > a {
  text-decoration: none;
}

.sidebar-wrapper {
  padding-top: 0;
}
.sidebar-wrapper .link-list-wrapper > .link-list > li > .list-item.active {
  border-left: 2px solid #0048ad;
}

.sidebar__title {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 16px;
  padding-top: 16px;
  background-color: #e6efff;
  text-transform: none;
}

.res-hero-links {
  background-color: #0055cc;
}
@media (min-width: 768px) {
  .res-hero-links .it-hero-text-wrapper {
    padding-right: 34px;
  }
}
@media (min-width: 992px) {
  .res-hero-links .it-hero-text-wrapper {
    padding-bottom: 80px;
    padding-right: 26px;
    padding-top: 80px;
  }
}
.res-hero-links .it-hero-text-wrapper .no_toc {
  color: white;
}
.res-hero-links .it-hero-text-wrapper a {
  display: block;
  padding: 24px 16px;
  border-bottom: 1px solid white;
  color: white;
  font-size: 1rem;
  text-decoration: underline;
}
.res-hero-links-light {
  background-color: white;
  color: #19191a;
}
.res-hero-links-light .it-hero-text-wrapper .no_toc {
  color: #19191a;
}
.res-hero-links-light .it-hero-text-wrapper a {
  border-bottom-color: #d9dadb;
  color: #0048ad;
}

[data-block-plugin-id="field_block:taxonomy_term:themes:field_links"]
  .res-hero-links
  .it-hero-text-wrapper,
[data-block-plugin-id="field_block:taxonomy_term:themes:field_links"]
  .res-hero-links
  .container {
  padding-left: 0;
  padding-right: 0;
}

[data-block-plugin-id="field_block:taxonomy_term:themes:field_links"]
  .res-hero-links
  .it-hero-text-wrapper {
  padding-bottom: 40px;
  padding-top: 40px;
}

.res-label-text__label {
  font-weight: 600;
}

.res-label-text__text {
  margin-left: 0.188rem;
}

.text-media__image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  overflow: hidden;
}

.text-media .read-more {
  justify-content: flex-end;
}

.text-media img {
  padding: 8px;
  max-width: 100%;
  height: auto;
}
.page-node-typeillustrative-page .text-media img {
  padding: 16px;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-node-typeillustrative-page .text-media img {
    padding: 8px;
  }
}

[data-block-plugin-id="field_block:node:illustrative_page:field_p_illustrative_first"]
  .paragraph:first-child {
  margin-top: 0;
}

[data-block-plugin-id="field_block:node:illustrative_page:field_p_illustrative_second"]
  img {
  object-fit: contain;
  height: 325px;
  width: 100%;
}

body:not(.page-node-typenews):not(.page-node-typeact) .main-container-top {
  padding-bottom: 56px;
  background-color: #e6efff;
}

.main-container-middle {
  padding-bottom: 32px;
  padding-top: 56px;
}
.page-node-typenews .main-container-middle,
.page-node-typeact .main-container-middle,
.page-themes-child .main-container-middle {
  padding-top: 0;
}
.mcm-no-padding-bottom .main-container-middle {
  padding-bottom: 0;
}
.mcm-no-padding-top .main-container-middle {
  padding-top: 0;
}

.breadcrumb {
  margin-bottom: 8px;
}

.breadcrumb-container .breadcrumb .breadcrumb-item a {
  color: #0048ad;
}

.home-third-row__wrapper,
.home-fourth-row__wrapper,
.home-fifth-row__wrapper {
  padding-bottom: 48px;
  padding-top: 48px;
}

.home-third-row__wrapper {
  background-color: #0049ae;
}

.home-fifth-row__wrapper {
  background-color: #e6efff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #e6f0fa;
}

.res-act--full [data-block-plugin-id="field_block:node:act:title"] {
  margin-bottom: 96px;
}

.res-act--full .layout-header-act {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid lightgray;
}

.res-act--full .media-document--res-card-file,
.res-act--full [data-block-plugin-id="component_blocks:media:res-card-file"],
.res-act--full .res-card-file {
  height: 100%;
}

.res-act--full
  [data-block-plugin-id="extra_field_block:node:act:extra_field_department_office_name"]
  label::after {
  content: ":";
}

.res-act--teaser {
  height: 100%;
}
.res-act--teaser
  [data-block-plugin-id="component_blocks:node:res-card-message"],
.res-act--teaser .res-card-message-default-no-image {
  height: 100%;
}

.res-cta--full {
  height: 100%;
}
.res-cta--full [data-block-plugin-id="component_blocks:node:res-card-logo"],
.res-cta--full .res-card-logo {
  height: 100%;
}
.res-cta--full
  [data-block-plugin-id="component_blocks:node:res-card-logo"]
  .card,
.res-cta--full .res-card-logo .card {
  background-color: #0049ad;
}
.res-cta--full
  [data-block-plugin-id="component_blocks:node:res-card-logo"]
  .card
  a,
.res-cta--full .res-card-logo .card a {
  color: #ffffff;
}
.res-cta--full [data-block-plugin-id="component_blocks:node:person"],
.res-cta--full .res-person-teaser,
.res-cta--full .res-person-teaser > div {
  height: 100%;
}

.res-junta .res-referent--person-sheet,
.res-junta [data-block-plugin-id="component_blocks:node:person"] {
  height: 100%;
}
.res-junta .res-referent--person-sheet > .res-person,
.res-junta .res-referent--person-sheet > .res-person > div,
.res-junta [data-block-plugin-id="component_blocks:node:person"] > .res-person,
.res-junta
  [data-block-plugin-id="component_blocks:node:person"]
  > .res-person
  > div {
  height: 100%;
}

.res-junta .res-referent--person-sheet-icons {
  margin-bottom: 24px;
}

.res-gallery--carousel-abstract-three-cols > .res-two-columns--space-between {
  margin-bottom: 16px;
}
.res-gallery--carousel-abstract-three-cols
  > .res-two-columns--space-between
  div:nth-of-type(2) {
  font-weight: 600;
  text-align: right;
}

.res-gallery--carousel-abstract-three-cols .it-carousel-wrapper {
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}
.res-gallery--carousel-abstract-three-cols .it-carousel-wrapper .owl-dots {
  margin-top: 8px;
}
.res-gallery--carousel-abstract-three-cols
  .it-carousel-wrapper
  .it-single-slide-wrapper {
  margin: 0 8px;
}

.res-listing .link-list-wrapper {
  margin-top: 24px;
}

.it-carousel-landscape-abstract .res-news--hero-message {
  width: 100%;
}
.it-carousel-landscape-abstract .res-news--hero-message a {
  color: white;
}

@media (min-width: 576px) {
  .field-node-field-p-news-body {
    font-size: 20px !important;
  }
}

.it-carousel-landscape-abstract-three-cols .res-news--teaser,
.it-carousel-landscape-abstract-three-cols .it-single-slide-wrapper,
.it-carousel-landscape-abstract-three-cols
  [data-block-plugin-id="component_blocks:node:res-card-message"],
.it-carousel-landscape-abstract-three-cols .card-wrapper {
  height: 100%;
}

.it-carousel-landscape-abstract-three-cols .owl-stage {
  display: flex;
}

.it-carousel-landscape-abstract-three-cols .owl-item .card-wrapper {
  padding-bottom: 26px;
}
@media (min-width: 992px) {
  .it-carousel-landscape-abstract-three-cols .owl-item .card-wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.it-carousel-landscape-abstract-three-cols .owl-item .card-wrapper .card {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.it-carousel-landscape-abstract
  [data-block-plugin-id="component_blocks:node:hero-message"],
.it-carousel-landscape-abstract .hero-message,
.it-carousel-landscape-abstract
  [data-block-plugin-id="component_blocks:node:res-cta-banner"],
.it-carousel-landscape-abstract .res-cta-banner {
  height: 100%;
}

#it-block-inevidenza .res-news {
  width: 100%;
}

[data-block-plugin-id="field_block:node:service_page:field_urp_reference"]
  .res-urp--teaser,
[data-block-plugin-id="field_block:node:service_page:field_urp_reference"]
  [data-block-plugin-id="component_blocks:node:res-card-message"],
[data-block-plugin-id="field_block:node:service_page:field_urp_reference"]
  .card-wrapper {
  height: 100%;
}

[data-block-plugin-id="field_block:node:service_page:field_urp_reference"]
  .res-urp--teaser
  .read-more {
  font-weight: 400;
  text-transform: none;
}

[data-block-plugin-id="field_block:node:service_page:field_urp_reference"]
  .field-urp-reference-item {
  margin-bottom: 40px;
}

.office-group-item .group--teaser,
.office-group-item
  [data-block-plugin-id="component_blocks:group:res-card-message"],
.office-group-item .res-card-message {
  height: 100%;
}

[data-block-plugin-id="field_block:group:presidency:field_depsoff_ref"] {
  margin-bottom: 40px;
}

.page-illustrative
  [data-block-plugin-id="field_block:node:illustrative_page:field_p_illustrative_first"]
  .text-media__image {
  height: auto;
  width: 100%;
}
@media (min-width: 1200px) {
  .page-illustrative
    [data-block-plugin-id="field_block:node:illustrative_page:field_p_illustrative_first"]
    .text-media__image {
    width: 85%;
  }
}

@media (min-width: 1200px) {
  .page-illustrative
    [data-block-plugin-id="field_block:node:illustrative_page:field_p_illustrative_first"]
    .text-media__actions {
    width: 85%;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .page-illustrative
    [data-block-plugin-id="field_block:node:illustrative_page:field_p_illustrative_first"]
    .text-media__body {
    margin-left: 4rem;
  }
}

.page-illustrative svg.paragraph--type--image-sources-text,
.page-illustrative svg.paragraph--type--media-video {
  margin-bottom: 0;
  margin-top: 0;
}

.res-junta-person {
  margin-top: 24px;
  margin-bottom: 24px;
}

.res-base-page .media-document--res-card-file,
.res-referent--full .media-document--res-card-file {
  height: 100%;
}
.res-base-page .media-document--res-card-file > div,
.res-base-page .media-document--res-card-file .card-wrapper,
.res-referent--full .media-document--res-card-file > div,
.res-referent--full .media-document--res-card-file .card-wrapper {
  height: 100%;
}

.field-documents-row {
  margin-bottom: 8px;
  margin-top: 8px;
}

.media-document--link-list-primary-action {
  margin-bottom: 16px;
  margin-top: 16px;
}

.field-node-title-news {
  font-size: 36px;
}

.field-node-field-date {
  font-weight: 700;
  margin-left: 0.2rem;
}

.news-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

[data-block-plugin-id="extra_field_block:node:news:extra_field_department_office_name"]
  label::after {
  content: ":";
}

.it-carousel-landscape-abstract
  [data-block-plugin-id="component_blocks:paragraph:map-viewer"],
.it-carousel-landscape-abstract .map-viewer {
  height: 100%;
}

.field-block-content-list-cta-item,
.field-group-field-cta-councillorship-item {
  margin-bottom: 16px;
  margin-top: 16px;
}

[data-block-plugin-id="field_block:node:announcement:field_documents"] {
  margin-top: 56px;
}
[data-block-plugin-id="field_block:node:announcement:field_documents"]
  .media-document--res-card-file,
[data-block-plugin-id="field_block:node:announcement:field_documents"]
  [data-block-plugin-id="component_blocks:media:res-card-file"],
[data-block-plugin-id="field_block:node:announcement:field_documents"]
  .res-card-file {
  height: 100%;
}

[data-block-plugin-id="field_block:node:decree:field_documents"] {
  margin-top: 32px;
}
[data-block-plugin-id="field_block:node:decree:field_documents"]
  .media-document--res-card-file,
[data-block-plugin-id="field_block:node:decree:field_documents"]
  [data-block-plugin-id="component_blocks:media:res-card-file"],
[data-block-plugin-id="field_block:node:decree:field_documents"]
  .res-card-file {
  height: 100%;
}

.iframe-wrapper {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
}
.iframe-wrapper iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border: 0;
}
.iframe-wrapper .ytp-autohide {
  cursor: initial;
}

.block-facet--checkbox [type="checkbox"] {
  position: absolute;
  left: -9999px;
}
.block-facet--checkbox [type="checkbox"] + label {
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  display: inline-block;
  height: 27px;
  line-height: 27px;
  font-size: 0.8888888889rem;
  user-select: none;
}

.block-facet--checkbox [type="checkbox"] + label::after,
.block-facet--checkbox [type="checkbox"] + label::before {
  content: "";
  left: 0;
  position: absolute;
  transition: 0.2s ease-out;
  z-index: 1;
  border-style: solid;
  border-width: 2px;
}

.block-facet--checkbox [type="checkbox"] + label::before {
  top: 0;
  width: 17px;
  height: 17px;
  border: 1px solid #e6e9f2;
  border-radius: 1px;
  margin: 2px 5px;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}

.block-facet--checkbox [type="checkbox"] + label::after {
  border-radius: 4px;
  height: 20px;
  width: 20px;
  margin: 6px 5px;
  top: 0;
}

.block-facet--checkbox
  [type="checkbox"]:not([checked="checked"])
  + label::after {
  background-color: transparent;
  border-color: #5c6f82;
  z-index: 0;
}

.block-facet--checkbox
  [type="checkbox"]:not([checked="checked"])
  + label::before {
  width: 0;
  height: 0;
  border-color: transparent;
  left: 6px;
  top: 10px;
}

.block-facet--checkbox [type="checkbox"][checked="checked"] + label::before {
  top: 6px;
  left: 1px;
  width: 8px;
  height: 13px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent #fff #fff transparent;
  transform: rotate(40deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%;
  opacity: 0.8;
}

.block-facet--checkbox [type="checkbox"][checked="checked"] + label::after {
  border-color: #0048ad;
  background-color: #0048ad;
  z-index: 0;
}

.block-facet--checkbox [type="checkbox"]:disabled + label {
  cursor: not-allowed;
}

.block-facet--checkbox
  [type="checkbox"]:disabled:not([checked="checked"])
  + label::after {
  border-color: #e6e9f2;
  background-color: #fff;
}

.block-facet--checkbox
  [type="checkbox"]:disabled[checked="checked"]
  + label::after {
  background-color: #e6e9f2;
  border-color: #e6e9f2;
}

.it-sidebar-first__wrapper .views-exposed-form input:not([type="button"]) {
  font-size: 0.8888888889rem;
  font-weight: 400;
}

.it-sidebar-first__wrapper .views-exposed-form label {
  font-size: 0.8888888889rem;
}

.it-sidebar-first__wrapper .form-actions {
  display: none;
}

.it-sidebar-first__wrapper .facets-widget- label {
  font-size: 0.8333333333rem;
}

.res-search-form {
  margin-bottom: 2rem;
}
.res-search-form .form-item-keys {
  margin-bottom: 0;
}
.res-search-form ~ .it-list-wrapper {
  margin-bottom: 24px;
}

.form-required::after {
  content: " * ";
}

.form-item-email .invalid-feedback {
  display: block;
}

.openid-connect-login-form {
  margin-top: 1rem;
}

.it-footer-main,
.it-footer-small-prints {
  background-color: var(--footer-bg-color);
}

.it-footer-small-prints {
  border-top: 1px solid rgba(255, 255, 255, 0.53);
}

.res-footer-blocks {
  border-top: 1px solid rgba(255, 255, 255, 0.53);
}

.it-footer-main .it-brand-wrapper a .icon {
  height: 59px;
  width: 48px;
}

.it-footer-main .link-list-wrapper .list-item.active {
  color: white;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .it-header-slim-wrapper-content .btn-full {
    padding: 15px;
  }
}

.it-nav-wrapper {
  box-shadow: 0 0.11111rem 1rem rgba(0, 0, 0, 0.06);
}
@media (min-width: 768px) {
  .it-nav-wrapper {
    /* background-image: url("/themes/custom/res/src/images/simbolo-regione.svg"); */
    background-position: right 56%;
    background-repeat: no-repeat;
    background-size: 665px 700px;
  }
}
@media (min-width: 1440px) {
  .it-nav-wrapper {
    background-position-x: 81%;
  }
}

.it-header-center-wrapper,
.it-header-navbar-wrapper {
  background-color: transparent;
  padding-top: 18px;
}

.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  .it-brand-text
  > h2.no_toc {
  font-size: 36px;
  font-weight: bold;
}

.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a {
  color: black;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a
  .icon {
  height: 59px;
  width: 48px;
}
@media (min-width: 992px) {
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a
    .icon {
    height: 118px;
    width: 96px;
  }
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone {
  color: #0048ad;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-right-zone
  .it-socials
  ul
  .icon {
  fill: #0048ad;
}
.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-right-zone
  .it-socials
  ul
  a:hover
  svg {
  fill: #00337a;
}

@media (min-width: 992px) {
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-search-wrapper
    a.rounded-icon {
    background-color: #0048ad;
  }
}

.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-search-wrapper
  a.rounded-icon:hover {
  background-color: transparent;
}
@media (min-width: 992px) {
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-search-wrapper
    a.rounded-icon:hover {
    background-color: #00337a;
  }
}

.it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-search-wrapper
  a.rounded-icon
  svg {
  fill: #0048ad;
}
@media (min-width: 992px) {
  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-search-wrapper
    a.rounded-icon
    svg {
    fill: white;
  }
}

.custom-navbar-toggler svg {
  fill: #0048ad;
}

.back-to-top {
  background: #003e94;
}

@media (min-width: 992px) {
  body:not(.user-logged-in) .it-header-slim-right-zone {
    min-width: 260px;
  }
}

.res-two-columns {
  margin-bottom: 40px;
  margin-top: 64px;
}

.layout-header-news-communication {
  margin-bottom: 40px;
  margin-top: 16px;
  padding-bottom: 40px;
  border-bottom: 1.5px solid #e9e6f2;
}

#page-user-login {
  background-color: #0048ad;
  min-height: 100vh;
}
#page-user-login .main-container #it-region-brand a {
  display: flex;
  text-decoration: none;
}
#page-user-login .main-container #it-region-brand a .icon {
  height: 59px;
  width: 48px;
  margin-right: 16px;
  fill: #ffffff;
}
#page-user-login .main-container #it-region-brand a h2,
#page-user-login .main-container #it-region-brand a h3 {
  color: #ffffff;
}
#page-user-login .main-container #it-region-brand a h2 {
  font-size: 1.778em;
  margin-bottom: 0;
  font-weight: 600;
}
#page-user-login .main-container #it-region-brand a h3 {
  font-size: 0.889em;
  font-weight: normal;
  margin-bottom: 0;
}
#page-user-login .main-container .login-box > div {
  background-color: #ffffff;
}

.it-sidebar-first__wrapper {
  margin-bottom: 24px;
}

.sidebar__title_separator {
  height: 4px;
  border-radius: 1px;
  background-color: #e5e5e5;
}
.sidebar__title_separator > div {
  width: 40px;
  height: 4px;
}

.sidebar__title {
  font-weight: 600;
  font-size: 0.778em;
  text-transform: uppercase;
  padding: 16px 24px;
  margin: 0;
  letter-spacing: 0;
}

.page--taxonomy--how--to .res-accordion .views-element-container {
  width: 100%;
}

.page--taxonomy--how--to .res-how-to-child .taxonomy-term,
.page--taxonomy--how--to
  .res-how-to-child
  [data-block-plugin-id="component_blocks:taxonomy_term:res-card-message"],
.page--taxonomy--how--to .res-how-to-child .res-card-message {
  height: 100%;
}

.download-button-item {
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 24px;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .download-button-item {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}
.download-button-item__text {
  color: #19191a;
  font-size: 0.889rem;
  font-weight: 600;
}

.paragraph--type--media-second .read-more {
  margin-top: 4px;
}

.pattern-row {
  margin-bottom: 60px;
}

.pattern-preview__variants {
  margin-top: 30px;
}

.block-region.demo-block {
  background-color: #aaa;
  padding: 1em;
  color: #000 !important;
  font-weight: bold;
}

.it-header-wrapper.it-header-sticky.is-sticky
  #it-region-header-nav
  .menu-wrapper
  > ul:nth-child(3) {
  display: none;
}

.navbar .navbar-collapsable {
  z-index: 3;
}
@media (min-width: 992px) {
  .navbar .navbar-collapsable .navbar-nav {
    width: 100%;
  }
}
@media (min-width: 992px) {
  /* .navbar .navbar-collapsable .navbar-nav li:last-child {
    margin-left: auto;
  } */
}
.navbar .navbar-collapsable .navbar-nav li a.nav-link {
  color: #0048ad;
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
  fill: #0048ad;
}

.paragraph {
  margin-bottom: 40px;
  margin-top: 48px;
}
.paragraph--type--text {
  margin-top: 40px;
}
.paragraph--type--table--text {
  margin-bottom: 48px;
}
.paragraph--type--former-referent {
  margin-top: 0;
}
.paragraph--type--image-text {
  margin-bottom: 32px;
  margin-top: 24px;
}
.paragraph--type--image-text:nth-child(even) .text-media .row {
  flex-direction: row-reverse;
}
.paragraph--type--map-contacts {
  margin-bottom: 0;
  width: 100%;
}

[data-block-plugin-id="field_block:node:generic_page:field_p_maps"]
  .it-carousel-wrapper
  .paragraph--type--map-contacts {
  margin-top: 0;
}

.paragraph--type--media-video .video-wrapper {
  border: solid 16px white;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .paragraph--type--media-video .video-wrapper {
    border-width: 8px;
  }
}

.paragraph--type--media-video_subtitle a {
  text-decoration: underline;
}

.view--res-regional-structure .views-row,
.view--res-gallery .views-row,
.view--res-news .views-row,
.view--councillorship-news-and-communications .views-row {
  margin-bottom: 40px;
}

.view--res-gallery .contextual + .res-two-columns--space-between,
.view--res-gallery:first-child .res-two-columns--space-between {
  margin-top: 16px;
}

.view--res-news .res-news--teaser,
.view--res-news [data-block-plugin-id="component_blocks:node:res-card-message"],
.view--res-news .card-wrapper,
.view--councillorship-news-and-communications .res-news--teaser,
.view--councillorship-news-and-communications
  [data-block-plugin-id="component_blocks:node:res-card-message"],
.view--councillorship-news-and-communications .card-wrapper {
  height: 100%;
}

.view--res-regional-structure .card-wrapper {
  padding-bottom: 0;
}

.views-element-container header {
  margin-bottom: 8px;
  color: #0048ad;
  text-align: right;
}

[data-block-plugin-id="views_block:res_urp_office-office_of_presidency"]
  .res-urp--teaser,
[data-block-plugin-id="views_block:res_urp_office-office_of_presidency"]
  [data-block-plugin-id="component_blocks:node:res-card-message"],
[data-block-plugin-id="views_block:res_urp_office-office_of_presidency"]
  .res-card-message-no-image,
[data-block-plugin-id="views_block:res_urp_department-departament_of_presidency"]
  .res-urp--teaser,
[data-block-plugin-id="views_block:res_urp_department-departament_of_presidency"]
  [data-block-plugin-id="component_blocks:node:res-card-message"],
[data-block-plugin-id="views_block:res_urp_department-departament_of_presidency"]
  .res-card-message-no-image {
  height: 100%;
}
[data-block-plugin-id="views_block:res_urp_office-office_of_presidency"]
  .res-urp--teaser
  .read-more,
[data-block-plugin-id="views_block:res_urp_office-office_of_presidency"]
  [data-block-plugin-id="component_blocks:node:res-card-message"]
  .read-more,
[data-block-plugin-id="views_block:res_urp_office-office_of_presidency"]
  .res-card-message-no-image
  .read-more,
[data-block-plugin-id="views_block:res_urp_department-departament_of_presidency"]
  .res-urp--teaser
  .read-more,
[data-block-plugin-id="views_block:res_urp_department-departament_of_presidency"]
  [data-block-plugin-id="component_blocks:node:res-card-message"]
  .read-more,
[data-block-plugin-id="views_block:res_urp_department-departament_of_presidency"]
  .res-card-message-no-image
  .read-more {
  font-weight: 400;
  text-transform: none;
}

[data-block-plugin-id="views_block:res_themes-block_1"] .res-card-theme,
[data-block-plugin-id="views_block:res_themes-block_1"] .res-card-theme-box,
[data-block-plugin-id="views_block:res_themes-block_1"]
  .res-card-theme-box-body {
  height: 100%;
}
[data-block-plugin-id="views_block:res_themes-block_1"]
  .res-card-theme
  use.res-icon-custom,
[data-block-plugin-id="views_block:res_themes-block_1"]
  .res-card-theme-box
  use.res-icon-custom,
[data-block-plugin-id="views_block:res_themes-block_1"]
  .res-card-theme-box-body
  use.res-icon-custom {
  stroke: #0048ad;
}
[data-block-plugin-id="views_block:res_themes-block_1"]
  .res-card-theme
  .hand-cart,
[data-block-plugin-id="views_block:res_themes-block_1"]
  .res-card-theme-box
  .hand-cart,
[data-block-plugin-id="views_block:res_themes-block_1"]
  .res-card-theme-box-body
  .hand-cart {
  stroke-width: 0.1;
}

.view-res-theme header {
  color: #19191a;
  text-align: left;
}

.view-res-theme-items use.res-icon-custom {
  stroke: #0048ad;
}

.view-res-theme-items .taxonomy-term--themes,
.view-res-theme-items
  [data-block-plugin-id="component_blocks:taxonomy_term:res-card-message"],
.view-res-theme-items .res-card-message {
  height: 100%;
}

.view-res-theme-items--news {
  margin-bottom: 40px;
  margin-top: 16px;
}

.res-press-accreditation-form .form-item > h2 {
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 1.555rem;
  line-height: 1.428;
}

.res-press-accreditation-form #edit-intro {
  padding-bottom: 3rem;
  border-bottom: 1px solid lightgray;
  width: 100%;
}

.res-press-accreditation-form #edit-profession--wrapper {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .res-press-accreditation-form #edit-profession--wrapper legend {
    float: left;
    margin-right: 1rem;
    width: 6.5rem;
  }
}
@media (max-width: 767.98px) {
  .res-press-accreditation-form
    #edit-profession--wrapper
    .form-item-profession {
    display: block;
  }
}
.res-press-accreditation-form #edit-profession--wrapper .fieldset-legend {
  color: #435a70;
  font-weight: 600;
  font-size: 1rem;
}

.res-press-accreditation-form .form-item-newspaper {
  clear: left;
}

.res-press-accreditation-form .form-item-email-mail-2 {
  margin-bottom: 0;
}

.res-press-accreditation-form .form-item-privacy-policy {
  margin-bottom: 0.5rem;
}

.res-press-accreditation-form #edit-actions,
.res-press-accreditation-form .captcha {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .res-press-accreditation-form .form-group {
    width: 60%;
  }
}

fieldset.webform-composite-hidden-title
  .fieldset-wrapper
  > .form-item:first-child,
fieldset.webform-composite-hidden-title
  .fieldset-wrapper
  > .form-item:last-child {
  margin-bottom: 3rem;
}

.res-bg-white {
  background-color: white !important;
}

.res-bg-azure {
  background-color: #e6efff !important;
}

@media(min-width: 992px){
  .footer-logos .logo img {
    max-height: 65px;
  }
}
@media(min-width: 768px){
  .footer-logos .logo img {
    max-height: 60px;
  }
}
.footer-logos .logo img {
  max-height: 50px;
}
.link-list-wrapper ul li a span p {
  padding: 0;
  font-size: 1em;
  line-height: 2em;
  color: #fff;
}
.it-footer-main .it-socials ul {
  gap: 15px;
}
@media(max-width: 575px){
  .footer-logos .logo.class img {
      max-height: 30px;
  }
}

/*# sourceMappingURL=main.css.map*/
